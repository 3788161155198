import type { FC } from 'react';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Alert, Link, Stack, Typography } from '@mui/material';
import {
  FTextInput,
  LoadingPromiseButtonAdvanced,
} from '@pflegenavi/web-components';
import { Formik, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { ResetPassword } from './ResetPassword';
import { ResetPasswordConfirm } from './ResetPasswordConfirm';
import { PageContainer } from './components/PageContainer';
import PflegenaviLogo from './assets/pflegenavi_logo.svg';
import {
  RequiredActions,
  shouldUseNewLogin,
  useAuthentication,
} from '@pflegenavi/shared-frontend/authentication';
import { useAuthentication as useWrappedAuthentication } from '@pflegenavi/frontend/authentication';
import { TermsAndConditions } from './TermsAndConditions';
import { RequiredResetPassword } from './RequiredResetPassword';
import { Welcome } from './Welcome';

import { has as objectHasKey } from 'lodash';
import { PasswordField } from './components/PasswordField';
import { VerifyEmail } from './VerifyEmail';

interface IFormValues {
  email: string;
  password: string;
}

export const Login: FC = () => {
  const navigate = useNavigate();
  const auth = useAuthentication(shouldUseNewLogin());
  const wrappedAuth = useWrappedAuthentication();

  const comingFromLogin = useRef(false);

  useEffect(() => {
    if (auth?.redirectUri && comingFromLogin.current) {
      const url = new URL(auth.redirectUri);
      // TODO: We might want to compare hostname
      navigate(url.pathname);
    } else if (
      auth?.requiredActions.includes(RequiredActions.UPDATE_PASSWORD)
    ) {
      navigate('/required/reset-password?pn-login');
    } else if (
      auth?.requiredActions.includes(RequiredActions.TERMS_AND_CONDITIONS)
    ) {
      navigate('/required/terms-and-conditions?pn-login');
    } else if (auth?.requiredActions.includes(RequiredActions.VERIFY_EMAIL)) {
      navigate('/required/verify-email?pn-login');
    }
  }, [auth?.requiredActions, navigate, auth?.redirectUri]);

  useEffect(() => {
    // If the token includes actions, redirect the user based on those actions
    // This might be a redirect URI instead? OR we forward to the parent based on names
  }, []);

  // Due to re-renderings, we might still be in Login but actually already have an user.
  // In this case we need to render null, as rendering the Routes will cause a redirect to the dashboard when
  // refreshing the page
  const userLoggedIn = auth?.token || wrappedAuth.user;
  if (auth?.initializing || userLoggedIn) {
    return null;
  }

  // The user saw a login screen or similar
  comingFromLogin.current = true;

  return (
    <Routes>
      <Route path={'/'}>
        <Route index element={<LoginScreen />} />
        <Route path={'/welcome'} element={<Welcome />} />
        <Route
          path={'/reset-password/:token'}
          element={<ResetPasswordConfirm />}
        />
        <Route path={'/reset-password'} element={<ResetPassword />} />
        <Route
          path={'/required/reset-password'}
          element={<RequiredResetPassword />}
        />
        <Route
          path={'/required/terms-and-conditions'}
          element={<TermsAndConditions />}
        />
        <Route path={'/required/verify-email'} element={<VerifyEmail />} />
      </Route>
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export const LoginScreen: FC = () => {
  const { t } = useTranslation();
  const auth = useAuthentication(shouldUseNewLogin());

  const { state } = useLocation();
  const success = objectHasKey(state, 'success')
    ? (
        state as {
          success: boolean;
        }
      ).success
    : false;

  const [error, setError] = useState<string | undefined>(undefined);

  const initialValues = useMemo<IFormValues>(() => {
    return {
      email: '',
      password: '',
    };
  }, []);

  const onSubmit = useCallback(
    async (values: IFormValues) => {
      const result = await auth?.login(values.email, values.password);
      if (result && 'status' in result && result.status === 'error') {
        if (result.errorMessage === 'User is disabled') {
          setError(t('authentication.errors.account-disabled'));
        } else {
          setError(t('authentication.errors.username-or-password-incorrect'));
        }
      }
    },
    [auth, t]
  );

  return (
    <PageContainer>
      <Stack gap={3} width={550} alignItems="center">
        <img src={PflegenaviLogo} width={360} />
        <Stack gap={1} width="100%">
          {error && (
            <Typography variant="body1" color="error">
              {error}
            </Typography>
          )}
          {success && (
            <Alert severity="success">
              {' '}
              {t('authentication.reset-password-success')}
            </Alert>
          )}
          <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {({ handleSubmit }) => {
              return (
                <form
                  onSubmit={handleSubmit}
                  style={{
                    maxWidth: 560,
                    width: '100%',
                  }}
                >
                  <LoginForm />
                </form>
              );
            }}
          </Formik>
        </Stack>
      </Stack>
    </PageContainer>
  );
};

const LoginForm: FC = () => {
  const { t } = useTranslation();
  const formik = useFormikContext<IFormValues>();
  const { values, setFieldValue } = formik;

  const navigate = useNavigate();

  const onResetPassword = useCallback(() => {
    navigate('/reset-password?pn-login');
  }, [navigate]);

  return (
    <Stack gap={3}>
      <Stack gap={1}>
        <FTextInput
          name="email"
          placeholder={t('common.email-address')}
          formik={formik}
          sx={{
            outlineColor: 'red',
          }}
        />
        <PasswordField
          name="password"
          placeholder={t('common.password')}
          value={values.password}
          onChange={(e) => setFieldValue('password', e.target.value)}
        />
        <Stack alignItems="flex-end">
          <Link
            onClick={onResetPassword}
            sx={{
              cursor: 'pointer',
            }}
          >
            {t('authentication.forgot-password')}
          </Link>
        </Stack>
      </Stack>
      <LoadingPromiseButtonAdvanced
        variant="contained"
        type="submit"
        onClick={formik.submitForm}
        sx={{
          py: 1.8,
          fontSize: 16,
        }}
      >
        {t('authentication.login')}
      </LoadingPromiseButtonAdvanced>
    </Stack>
  );
};
