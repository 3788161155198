import { useCallback, useEffect, useState } from 'react';

interface UseNavigateReceiptsProps {
  activeIndex: number;
  setActiveIndex: (index: number) => void;
  receiptsLength: number;
}

export const useNavigateReceipts = ({
  activeIndex,
  setActiveIndex,
  receiptsLength,
}: UseNavigateReceiptsProps): void => {
  const [keyPressed, setKeyPressed] = useState(false);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      switch (event.key) {
        case 'ArrowUp':
          event.preventDefault();
          if (!keyPressed && activeIndex > 0) {
            setKeyPressed(true);
            setTimeout(() => {
              setKeyPressed(false);
            }, 500);
            setActiveIndex(activeIndex - 1);
          }
          break;
        case 'ArrowDown':
          event.preventDefault();
          if (!keyPressed && activeIndex < receiptsLength - 1) {
            setKeyPressed(true);
            setTimeout(() => {
              setKeyPressed(false);
            }, 500);
            setActiveIndex(activeIndex + 1);
          }
          break;
        default:
          break;
      }
    },
    [activeIndex, keyPressed, receiptsLength, setActiveIndex]
  );

  const handleKeyUp = useCallback(() => {
    setKeyPressed(false);
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [activeIndex, handleKeyDown, handleKeyUp, receiptsLength]);
};
