import { useNursingHomeContext } from '@pflegenavi/frontend/nursing-home-context';
import type { CashList } from '@pflegenavi/frontend/api-nursing-home';
import {
  useAllResidentBalance,
  useCashListConfiguration,
  usePaymentDashboardStatistics,
  useServiceProviderPayments,
  useStripeAccountDetails,
} from '@pflegenavi/frontend/api-nursing-home';
import type {
  AllResidentBalanceDto,
  StripeAccount,
} from '@pflegenavi/shared/api';
import {
  CashListStorageType,
  FeatureFlag,
  PaymentStatus,
} from '@pflegenavi/shared/api';
import { useMemo } from 'react';
import { useFeatureFlagEnabled } from '@pflegenavi/frontend/feature-flags';

interface useNursingHomeQuickOverviewResult {
  isLoading: boolean;
  totalResidentsBalance: number;
  cashRegisterBalance: number;
  difference: number;
  stripeBalance: number;
  bankAccountCashLists: CashList[];
  cashAccountCashLists: CashList[];
  bankAccountAmount: number;
  cashAccountAmount: number;
  stripeAccount?: StripeAccount;
  supervisor: boolean;
  serviceProviderPaymentSum: number;
  serviceProviderPaymentTargetCashListId?: string;
}

export const useNursingHomeQuickOverview =
  (): useNursingHomeQuickOverviewResult => {
    const newAccountingPaymentsEnabled = useFeatureFlagEnabled(
      FeatureFlag.AccountingPaymentsNew
    );

    const { selectedNursingHome } = useNursingHomeContext();

    const { data: stripeAccount, isLoading: isStripeAccountLoading } =
      useStripeAccountDetails(selectedNursingHome?.id);

    const { data: allResidentBalance, isLoading: isAllResidentBalanceLoading } =
      useAllResidentBalance(selectedNursingHome?.id);

    const cashListConfiguration = useCashListConfiguration();

    const { selectedCashListId: defaultCashListId } = cashListConfiguration;
    const cashList = cashListConfiguration.cashLists.find(
      (cashList) => cashList.id === defaultCashListId
    )!;

    const {
      data: serviceProviderPayments,
      isLoading: isLoadingServiceProviderPayments,
    } = useServiceProviderPayments({
      nursingHomeId: selectedNursingHome?.id,
      states: [PaymentStatus.Pending],
      page: 0,
      pageSize: 50,
    });

    const serviceProviderPaymentSum = useMemo(() => {
      return (
        serviceProviderPayments?.data.reduce(
          (acc, curr) => acc + curr.amount,
          0
        ) ?? 0
      );
    }, [serviceProviderPayments]);

    const {
      data: paymentDashboardStatsData,
      isLoading: isPaymentDashboardStatsLoading,
    } = usePaymentDashboardStatistics(selectedNursingHome?.id);

    const bankAccountCashLists = cashListConfiguration.cashLists.filter(
      (cashList) =>
        cashList && cashList.storageType === CashListStorageType.BankAccount
    );
    // Note that the deprecated CashListStorageType.CashOrBankAccount will count as a cashAccountCashList here.
    // For the cashAccountAmount and bankAccountAmount values, the bank and cash parts of CashListStorageType.CashOrBankAccount are counted separately.
    const cashAccountCashLists = cashListConfiguration.cashLists.filter(
      (cashList) => cashList.storageType & CashListStorageType.Cash
    );

    const totalResidentsBalance = allResidentBalance?.balanceInCents ?? 0;
    const cashRegisterBalance = cashList?.totalInCent ?? 0;
    // Payouts are part of the Stripe balance while they are pending or in_transit.
    // Once they are paid, they are immediately added to and therefore part of the bank account amount.
    // Therefore, there is no need to consider them separately
    const stripeBalance =
      paymentDashboardStatsData?.statistics.Stripe.amountInCents ?? 0;
    const bankAccountAmount =
      paymentDashboardStatsData?.statistics.Bank.amountInCents ?? 0;
    const cashAccountAmount =
      paymentDashboardStatsData?.statistics.Cash.amountInCents ?? 0;

    const difference =
      allResidentBalance !== undefined && cashList !== undefined
        ? calculateDifference(allResidentBalance, cashList.totalInCent)
        : 0;

    const differenceNew =
      allResidentBalance !== undefined &&
      cashList !== undefined &&
      paymentDashboardStatsData !== undefined
        ? calculateDifferenceNew(
            totalResidentsBalance,
            cashAccountAmount,
            stripeBalance,
            bankAccountAmount,
            serviceProviderPaymentSum
          )
        : 0;

    return {
      isLoading:
        isAllResidentBalanceLoading ||
        isLoadingServiceProviderPayments ||
        isPaymentDashboardStatsLoading ||
        isStripeAccountLoading,
      totalResidentsBalance,
      cashRegisterBalance,
      difference: newAccountingPaymentsEnabled ? differenceNew : difference,
      stripeBalance,
      bankAccountCashLists,
      cashAccountCashLists,
      bankAccountAmount,
      cashAccountAmount,
      stripeAccount,
      serviceProviderPaymentSum,
      serviceProviderPaymentTargetCashListId:
        cashListConfiguration.serviceProviderPaymentTargetCashListId,
      supervisor: selectedNursingHome?.isSupervisor ?? false,
    };
  };

export const calculateDifference = (
  monthlyReportBalance: Pick<AllResidentBalanceDto, 'balanceInCents'>,
  totalInCents?: number
): number => {
  return Math.abs(
    (monthlyReportBalance.balanceInCents ?? 0) - (totalInCents ?? 0)
  );
};

export const calculateDifferenceNew = (
  totalResidentsBalance: number,
  cashRegisterBalance: number,
  stripeBalance: number,
  bankAccountBalance: number,
  serviceProviderPaymentSum: number
): number => {
  return Math.abs(
    totalResidentsBalance -
      (cashRegisterBalance +
        stripeBalance +
        bankAccountBalance -
        serviceProviderPaymentSum)
  );
};
