import type { CardProps } from '@mui/material';
import { Card, Divider, Skeleton, Stack, Typography } from '@mui/material';
import type { FC, ReactNode } from 'react';
import React, { memo } from 'react';
import { Iconify, OneLineTypography } from '@pflegenavi/web-components';
import {
  useFormatDate,
  useFormatTime,
  useFormatting,
} from '@pflegenavi/frontend/localization';
import {
  TIME_FORMAT,
  YEAR_MONTH_DAY_SHORT_FORMAT,
} from '@pflegenavi/shared/constants';
import { useTranslation } from 'react-i18next';
import { DropDownMenu } from './DropDownMenu';
import { CustomAlert } from './CustomAlert';

interface CashLinkViewProps extends ContentProps {
  notes?: string[];
  handleRemoveLink: () => void;
  groupId?: string;
  sx?: CardProps['sx'];
  disabled?: boolean;
  disabledTooltipTitle?: string;
  singleReceipt?: boolean;
}

export const CashLinkView: FC<CashLinkViewProps> = memo(
  ({
    notes,
    groupId,
    handleRemoveLink,
    sx,
    disabled,
    disabledTooltipTitle,
    singleReceipt,
    ...props
  }) => {
    notes = notes?.filter((note) => note !== '');
    notes = notes?.length ? notes : undefined;

    return (
      <Card sx={{ p: 2, ...sx }}>
        <Stack gap={2}>
          <Header
            linkedCount={props.linkedCount}
            handleRemoveLink={handleRemoveLink}
            groupId={groupId}
            disabled={disabled}
            disabledTooltipTitle={disabledTooltipTitle}
            singleReceipt={singleReceipt}
          />
          <Divider
            sx={{
              // Counter the card padding
              ml: -2,
              mr: -2,
            }}
          />
          <Content {...props} />
          {notes && <Notes notes={notes} />}
        </Stack>
      </Card>
    );
  }
);

interface NotesProps {
  notes: string[];
}

const Notes: FC<NotesProps> = ({ notes }) => {
  const { t } = useTranslation();
  return (
    <Stack gap={1}>
      <Divider
        sx={{
          // Counter the card padding
          ml: -2,
          mr: -2,
        }}
      />
      <Typography variant="overline" color="grey.600">
        {t('cashManagement.link.notes-title')}
      </Typography>
      {notes.map((note) => (
        <Stack
          sx={{
            borderRadius: 2,
            backgroundColor: 'grey.200',
            p: 2,
          }}
        >
          <Typography
            variant="body2"
            color="grey.900"
            sx={{
              maxWidth: 270,
            }}
          >
            {note}
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
};

interface HeaderProps {
  handleRemoveLink: () => void;
  linkedCount: number;
  groupId?: string;
  disabled?: boolean;
  disabledTooltipTitle?: string;
  singleReceipt?: boolean;
}

const Header: FC<HeaderProps> = ({
  handleRemoveLink,
  linkedCount,
  groupId,
  disabled,
  disabledTooltipTitle,
  singleReceipt,
}) => {
  const { t } = useTranslation();
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Stack direction="row" gap={1.5} alignItems="center">
        <Stack
          sx={{
            backgroundColor: 'grey.300',
            borderRadius: 2,
          }}
          justifyContent="center"
          alignItems="center"
          height={40}
          width={40}
        >
          <Iconify width={24} height={24} icon={'mdi:receipt-text'} />
        </Stack>
        <Stack gap={0.5}>
          <OneLineTypography variant="subtitle2" fontWeight={700}>
            {t('cashManagement.link.cash-link.title')}
          </OneLineTypography>
          <OneLineTypography fontSize={14} color="grey.600">
            {singleReceipt
              ? t('cashManagement.link.title-single-link')
              : t('cashManagement.link.title-multi-link')}
          </OneLineTypography>
        </Stack>
      </Stack>

      <DropDownMenu
        handleRemoveLink={handleRemoveLink}
        groupId={groupId}
        disabled={disabled}
        disabledTooltipTitle={disabledTooltipTitle}
      />
    </Stack>
  );
};

interface ContentProps {
  date: Date;
  cashAmountCents: number;
  linkedAmountCents: number;
  linkedCount: number;
  receiptAmountCents: number;
  isLoading?: boolean;
  alwaysShowLinkedAmount?: boolean;
  hideReceiptAmount?: boolean;
}

const Content: FC<ContentProps> = ({
  date,
  cashAmountCents,
  receiptAmountCents,
  linkedAmountCents,
  linkedCount,
  isLoading,
  hideReceiptAmount,
  alwaysShowLinkedAmount,
}) => {
  const { t } = useTranslation();
  const { fCurrencyCents } = useFormatting();
  const fDate = useFormatDate();
  const fTime = useFormatTime();

  const mismatch = cashAmountCents !== linkedAmountCents;
  const multiLink = linkedCount > 1 || alwaysShowLinkedAmount;

  return (
    <Stack gap={2}>
      <Stack gap={1.5}>
        <Row
          isLoading={isLoading}
          icon={<DateIcon />}
          label={t('cashManagement.link.date')}
          value={
            <Stack direction="row">
              <Typography fontSize={14} color="text.primary">
                {fDate(date, YEAR_MONTH_DAY_SHORT_FORMAT)},&nbsp;
              </Typography>
              <Typography fontSize={14} color="grey.600">
                {fTime(date, TIME_FORMAT)}
              </Typography>
            </Stack>
          }
        />
        <Row
          isLoading={isLoading}
          icon={<CashIcon />}
          label={t('cashManagement.link.cash-amount')}
          value={
            <Typography fontSize={14} color="text.primary">
              {fCurrencyCents(cashAmountCents)}
            </Typography>
          }
        />
        {multiLink && (
          <Row
            isLoading={isLoading}
            icon={<LinkedIcon />}
            label={`${t('cashManagement.link.linked-amount')} (${linkedCount})`}
            value={
              <Typography fontSize={14} color="text.primary">
                {fCurrencyCents(linkedAmountCents)}
              </Typography>
            }
          />
        )}
        {!hideReceiptAmount && (
          <Row
            isLoading={isLoading}
            icon={<ReceiptIcon />}
            label={t('cashManagement.link.receipt-amount')}
            value={
              <Typography fontSize={14} color="text.primary">
                {fCurrencyCents(receiptAmountCents)}
              </Typography>
            }
          />
        )}
      </Stack>
      <CustomAlert variant={mismatch ? 'warning' : 'success'}>
        <Typography fontSize={14}>
          {mismatch
            ? t('cashManagement.link.amount-mismatch')
            : t('cashManagement.link.amount-no-mismatch')}
        </Typography>
      </CustomAlert>
    </Stack>
  );
};

interface RowProps {
  icon: ReactNode;
  label: ReactNode;
  value: ReactNode;
  isLoading?: boolean;
}

const Row: FC<RowProps> = ({ icon, label, value, isLoading }) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      gap={4}
    >
      <Stack direction="row" gap={1} alignItems="center">
        {icon}
        <Typography fontSize={14} color="text.primary">
          {label}
        </Typography>
      </Stack>
      {isLoading && (
        <Typography fontSize={14} color="text.primary">
          <Skeleton variant="text" width={100} />
        </Typography>
      )}
      {!isLoading && value}
    </Stack>
  );
};

export const DateIcon = (): JSX.Element => {
  return (
    <Iconify sx={{ mt: '-4px' }} icon="eva:calendar-fill" color="grey.600" />
  );
};

const CashIcon = () => {
  return (
    <Iconify
      sx={{ mt: '-2px' }}
      icon="majesticons:euro-circle"
      color="grey.600"
    />
  );
};

const LinkedIcon = () => {
  return (
    <Iconify sx={{ mt: '-1px' }} icon="eva:link-2-fill" color="grey.600" />
  );
};

const ReceiptIcon = () => {
  return (
    <Iconify sx={{ mt: '-1px' }} icon={'mdi:receipt-text'} color="grey.600" />
  );
};
