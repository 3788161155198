import { useCallback, useEffect, useState } from 'react';

export type UrlTypeNameMapping = Map<string, ValueType>;

export interface ValueType {
  type: string;
  name: string;
  data: Promise<Blob>;
}

export function useFileUrlToTypeMapping(
  fileUrls?: string[]
): UrlTypeNameMapping {
  const [imageUrlToTypeMap, setImageUrlToTypeMap] = useState(
    new Map<string, ValueType>()
  );

  const fetchUrlTypes = useCallback(async () => {
    const fileUrlToType = (await Promise.all(
      (fileUrls ?? []).map(async (fileUrl) => {
        try {
          const result = await fetch(fileUrl);
          const extractedFileType = result.headers.get('content-type');
          const extractedFileName =
            result.headers.get('content-disposition')?.match(/"(.*?)"/)?.[1] ??
            'unknown';
          return [
            fileUrl,
            {
              type: extractedFileType ?? 'type-unknown',
              name: extractedFileName,
              data: result.blob(),
            },
          ];
        } catch {
          return Promise.resolve([
            fileUrl,
            {
              type: 'image/*', // TODO: Why image/* if error
              name: 'unknown',
              data: Promise.resolve(new Blob()),
            },
          ]); // TODO: huck :(
        }
      })
    )) as unknown as ReadonlyArray<
      [string, { type: string; name: string; data: Promise<Blob> }]
    >;
    setImageUrlToTypeMap(new Map(fileUrlToType));
  }, [fileUrls]);

  useEffect(() => {
    if (fileUrls) {
      fetchUrlTypes();
    }
  }, [fileUrls, fetchUrlTypes]);

  return imageUrlToTypeMap;
}
