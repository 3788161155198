import type { FC } from 'react';
import React, { useState } from 'react';
import {
  Grid,
  Slider,
  Typography,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useTranslation } from 'react-i18next';

interface RangeSelectProps {
  onRangeChange: (min: number | null, max: number | null) => void;
  minValue?: number;
  maxValue?: number;
  step?: number;
  label?: string;
}

enum RangeMode {
  Both,
  MinOnly,
  MaxOnly,
}

export const NumberRange: FC<RangeSelectProps> = ({
  onRangeChange,
  label,
  minValue = 0,
  maxValue = 100,
  step = 1,
}) => {
  const [selectedValues, setSelectedValues] = useState<[number, number]>([
    minValue,
    maxValue,
  ]);
  const [rangeMode, setRangeMode] = useState(RangeMode.Both);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { t } = useTranslation();

  const handleSliderChange = (_: any, newValue: number | number[]) => {
    const newValues = (
      rangeMode === RangeMode.Both
        ? newValue
        : rangeMode === RangeMode.MinOnly
        ? [newValue, maxValue]
        : [minValue, newValue]
    ) as [number, number];

    setSelectedValues(newValues);

    if (rangeMode === RangeMode.MinOnly) {
      onRangeChange(newValues[0], null);
    } else if (rangeMode === RangeMode.MaxOnly) {
      onRangeChange(null, newValues[1]);
    } else {
      onRangeChange(newValues[0], newValues[1]);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (mode: RangeMode) => {
    setRangeMode(mode);
    setAnchorEl(null);
  };

  const marks = [
    { value: minValue, label: minValue },
    { value: maxValue, label: maxValue },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={9}>
        <Typography variant="h6">
          {label ?? t('table.filters.number-range.placeholder')}
        </Typography>
      </Grid>

      <Grid item xs={3}>
        <IconButton
          aria-label="range mode"
          aria-controls="range-mode-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>

        <Menu
          id="range-mode-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem onClick={() => handleMenuClose(RangeMode.Both)}>
            Min & Max
          </MenuItem>
          <MenuItem onClick={() => handleMenuClose(RangeMode.MinOnly)}>
            Min Only
          </MenuItem>
          <MenuItem onClick={() => handleMenuClose(RangeMode.MaxOnly)}>
            Max Only
          </MenuItem>
        </Menu>
      </Grid>

      <Grid item xs={12}>
        <Slider
          value={
            rangeMode === RangeMode.Both
              ? selectedValues
              : rangeMode === RangeMode.MinOnly
              ? selectedValues[0]
              : selectedValues[1]
          }
          onChange={handleSliderChange}
          min={rangeMode !== RangeMode.MaxOnly ? minValue : undefined}
          max={rangeMode !== RangeMode.MinOnly ? maxValue : undefined}
          step={step}
          valueLabelDisplay="auto"
          aria-labelledby="range-slider"
          marks={marks}
          track={rangeMode === RangeMode.MinOnly ? 'inverted' : undefined}
        />
      </Grid>
    </Grid>
  );
};
