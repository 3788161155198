import type { ReactNode } from 'react';
import isString from 'lodash/isString';
// @mui
import { Box, Link, Typography } from '@mui/material';
//
import type { Props as BreadcrumbsProps } from './Breadcrumbs';
import Breadcrumbs from './Breadcrumbs';
import useMediaQuery from '@mui/material/useMediaQuery';

// ----------------------------------------------------------------------

interface Props extends BreadcrumbsProps {
  action?: ReactNode;
  heading: string;
  moreLink?: string | string[];
}

export function HeaderBreadcrumbs({
  links,
  action,
  heading,
  moreLink = '',
  sx,
  ...other
}: Props): JSX.Element {
  const smallScreens = useMediaQuery('(max-width:900px)');
  return (
    <Box data-cy="header-breadcrumbs" sx={{ mb: 3, ...sx }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant={smallScreens ? 'h5' : 'h4'} gutterBottom>
            {heading}
          </Typography>
          <Breadcrumbs links={links} {...other} />
        </Box>

        {action && !smallScreens && <Box sx={{ flexShrink: 0 }}>{action}</Box>}
      </Box>

      <Box sx={{ mt: 2 }}>
        {isString(moreLink) ? (
          <Link
            href={moreLink as string}
            target="_blank"
            rel="noopener"
            variant="body2"
          >
            {moreLink}
          </Link>
        ) : (
          moreLink.map((href) => (
            <Link
              noWrap
              key={href}
              href={href}
              variant="body2"
              target="_blank"
              rel="noopener"
              sx={{ display: 'table' }}
            >
              {href}
            </Link>
          ))
        )}
      </Box>
    </Box>
  );
}
