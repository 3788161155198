import type { FC } from 'react';
import * as React from 'react';
import { useCallback, useMemo, useRef } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, Stack, Tooltip } from '@mui/material';
import { Iconify, LoadingContainer } from '@pflegenavi/web-components';
import { ImportFamilyMembersTableRowContainer } from './ImportFamilyMembersTableRowContainer';
import { FieldArray, useFormikContext } from 'formik';
import type { FamilyMemberImportEntryDto } from '@pflegenavi/shared/api';
import { v4 as uuid } from 'uuid';
import { useActiveResidents } from '@pflegenavi/frontend/api-nursing-home';
import { useNursingHomeContext } from '@pflegenavi/frontend/nursing-home-context';
import { useTranslation } from 'react-i18next';
import { ImportFamilyMembersEmpty } from './ImportFamilyMembersEmpty';
import {
  clearFMImportStateFromLocalStorage,
  usePersistFMImportState,
} from '../hooks/usePersistFMImportState';
import { FMImportActionsButtons } from './FMImportActionsButtons';
import { DEBOUNCE_SAVE_FM_IMPORT_STATE_DELAY_MS } from '@pflegenavi/shared/constants';

export interface ImportFamilyMemberFormValues {
  familyMembers: CreateNewFamilyMemberType[];
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface ImportFamilyMembersTableProps {}

export const ImportFamilyMembersTable: FC<
  ImportFamilyMembersTableProps
> = () => {
  const { t } = useTranslation();

  const { selectedNursingHome } = useNursingHomeContext();

  const {
    values: { familyMembers },
    setFieldValue,
    setErrors,
  } = useFormikContext<ImportFamilyMemberFormValues>();

  const { data: residentsData, isLoading: isResidentsLoading } =
    useActiveResidents(selectedNursingHome?.id ?? '');

  const residents = useMemo(() => {
    return (residentsData ?? [])
      .filter(
        (resident) =>
          !resident.familyMembers || resident.familyMembers.length === 0
      )
      .map((resident) => ({
        id: resident.id,
        name: resident.name,
        gender: resident.gender,
      }));
  }, [residentsData]);

  const alreadySelectedResidentIdsRef = useRef<Set<string>>(
    new Set(familyMembers.map((familyMember) => familyMember.residentId))
  );

  const getResidents = useCallback(() => {
    return residents.filter(
      (resident) => !alreadySelectedResidentIdsRef.current.has(resident.id)
    );
  }, [residents]);

  const handleSetResidentId = useCallback(
    (id: string, index: number, type: 'add' | 'delete') => {
      if (type === 'add') {
        setFieldValue(`familyMembers.${index}.residentId`, id);
        alreadySelectedResidentIdsRef.current.add(id);
      } else if (type === 'delete') {
        setFieldValue(`familyMembers.${index}.residentId`, undefined);
        alreadySelectedResidentIdsRef.current.delete(id);
      }
    },
    [setFieldValue]
  );

  const resetForm = useCallback(() => {
    setFieldValue('familyMembers', [createNewFamilyMember()]);
    alreadySelectedResidentIdsRef.current.clear();
    setTimeout(() => {
      clearFMImportStateFromLocalStorage();
    }, DEBOUNCE_SAVE_FM_IMPORT_STATE_DELAY_MS + 100);
    setErrors({});
  }, [setErrors, setFieldValue]);

  // save the state whenever we leave the page
  usePersistFMImportState({
    familyMembers,
  });

  if (isResidentsLoading) {
    return <LoadingContainer />;
  }

  if (residents.length === 0) {
    return <ImportFamilyMembersEmpty />;
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{t('family-members-import.table.resident')}</TableCell>
            <TableCell align="left" sx={{ paddingLeft: '32px' }}>
              {t('family-members-import.table.first-name')}
            </TableCell>
            <TableCell align="left">
              {t('family-members-import.table.last-name')}
            </TableCell>
            <TableCell align="left">
              {t('family-members-import.table.email')}
            </TableCell>
            <TableCell
              sx={{
                maxWidth: '10px',
                p: 0,
              }}
            />
          </TableRow>
        </TableHead>
        <TableBody>
          <FieldArray name="familyMembers">
            {({ remove, push }) => (
              <>
                {familyMembers.map((familyMember, index) => (
                  <ImportFamilyMembersTableRowContainer
                    key={familyMember.id}
                    index={index}
                    familyMember={familyMember}
                    startingResidents={residents}
                    getResidents={getResidents}
                    setResidentId={handleSetResidentId}
                    remove={remove}
                    alreadySelectedResidentsIdsRef={
                      alreadySelectedResidentIdsRef
                    }
                  />
                ))}
                <TableRow>
                  <TableCell>
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Tooltip
                        title={
                          familyMembers.length >= 50
                            ? t(
                                'family-members-import.table.add-row-disabled-tooltip'
                              )
                            : undefined
                        }
                        arrow
                        placement="bottom"
                      >
                        <div>
                          <Button
                            startIcon={<Iconify icon="eva:plus-fill" />}
                            onClick={() => push(createNewFamilyMember())}
                            disabled={familyMembers.length >= 50}
                          >
                            {t('family-members-import.table.add-row')}
                          </Button>
                        </div>
                      </Tooltip>
                    </Stack>
                  </TableCell>
                </TableRow>
              </>
            )}
          </FieldArray>
        </TableBody>
      </Table>
      <Stack direction="row" justifyContent="flex-end">
        <FMImportActionsButtons resetForm={resetForm} />
      </Stack>
    </TableContainer>
  );
};

export interface CreateNewFamilyMemberType extends FamilyMemberImportEntryDto {
  id: string;
}

export const createNewFamilyMember = (): CreateNewFamilyMemberType => ({
  // the id is used for react keys and should be unique
  id: `new-${uuid()}`,
  residentId: '',
  firstName: '',
  lastName: '',
  email: '',
});
