import type { Tenant } from '@pflegenavi/frontend/tenant';
import type { AuthenticationContext } from '@pflegenavi/frontend/authentication';
import type {
  CashListTransferDto,
  Coin,
  CreateCashTransactionGroupTransactionResultDto,
  DeleteCashTransactionGroupLinkDto,
  DeleteCashTransactionGroupLinkResultDto,
  GetCashListNursingHomeConfigurationDto,
  GetCashManagementTransactionDto,
  GetCashManagementWithTransactionDto,
  GetCashTransactionGroupDto,
  GetCashTransactionGroupListResponseClient,
  GetCashTransactionGroupResultDto,
  GetCashTransactionGroupsDto,
  PostCashManagementDto,
  PostCashTransactionGroupLinkDto,
  PostCashTransactionGroupLinkResultDto,
  PostCashTransactionGroupTransactionDto,
} from '@pflegenavi/shared/api';
import { endpoints } from '@pflegenavi/shared/api';
import type { Api, ModifyResponse } from '@pflegenavi/shared-frontend/platform';
import {
  get,
  getApiBaseUrl,
  modify,
  modifyWithHeaders,
  superFetch,
} from '@pflegenavi/shared-frontend/platform';

export interface ICashManagementApi extends Api {
  getCashListConfiguration(dataIn: {
    params: { nursingHomeId: string };
  }): Promise<GetCashListNursingHomeConfigurationDto>;

  getTransactions(dataIn: {
    params: { cashListId: string; dateFrom: Date; dateTo: Date };
  }): Promise<GetCashManagementTransactionDto[]>;

  getTransactionById(dataIn: {
    params: { id: string };
  }): Promise<GetCashManagementTransactionDto>;

  getTransactionByTransactionId(dataIn: {
    params: { transactionId: string };
  }): Promise<GetCashManagementTransactionDto>;

  addTransaction(dataIn: {
    body: PostCashManagementDto;
  }): Promise<ModifyResponse<GetCashManagementWithTransactionDto>>;

  postCashTransactionGroupLink(dataIn: {
    body: Omit<PostCashTransactionGroupLinkDto, 'cashTransactionGroupId'>;
    params: Pick<PostCashTransactionGroupLinkDto, 'cashTransactionGroupId'>;
  }): Promise<ModifyResponse<PostCashTransactionGroupLinkResultDto>>;

  deleteCashTransactionGroupLink(dataIn: {
    params: DeleteCashTransactionGroupLinkDto;
  }): Promise<ModifyResponse<DeleteCashTransactionGroupLinkResultDto>>;

  postCashTransactionGroupTransaction(dataIn: {
    body: Omit<
      PostCashTransactionGroupTransactionDto,
      'cashTransactionGroupId'
    >;
    params: Pick<
      PostCashTransactionGroupTransactionDto,
      'cashTransactionGroupId'
    >;
  }): Promise<ModifyResponse<CreateCashTransactionGroupTransactionResultDto>>;

  getCashTransactionGroups(
    dataIn: GetCashTransactionGroupsDto
  ): Promise<GetCashTransactionGroupListResponseClient>;

  getCashTransactionGroup(dataIn: {
    params: GetCashTransactionGroupDto;
  }): Promise<GetCashTransactionGroupResultDto>;

  confirmMismatch(dataIn: {
    params: { cashTransactionGroupId: string };
  }): Promise<ModifyResponse<void>>;

  unconfirmMismatch(dataIn: {
    params: { cashTransactionGroupId: string };
  }): Promise<ModifyResponse<void>>;

  transfer(dataIn: {
    params: { fromCashListId: string; toCashListId: string };
    body: CashListTransferDto;
  }): Promise<{ success: true }>;
}

export class CashManagementApi implements ICashManagementApi {
  public getCashListConfiguration: ICashManagementApi['getCashListConfiguration'];

  public getTransactionById: ICashManagementApi['getTransactionById'];
  public getTransactionByTransactionId: ICashManagementApi['getTransactionByTransactionId'];
  public getTransactions: ICashManagementApi['getTransactions'];
  public addTransaction: ICashManagementApi['addTransaction'];

  public postCashTransactionGroupLink: ICashManagementApi['postCashTransactionGroupLink'];
  public deleteCashTransactionGroupLink: ICashManagementApi['deleteCashTransactionGroupLink'];
  public postCashTransactionGroupTransaction: ICashManagementApi['postCashTransactionGroupTransaction'];

  public getCashTransactionGroup: ICashManagementApi['getCashTransactionGroup'];

  public confirmMismatch: ICashManagementApi['confirmMismatch'];
  public unconfirmMismatch: ICashManagementApi['unconfirmMismatch'];

  public transfer: ICashManagementApi['transfer'];

  private baseUrl: string;

  constructor(
    tenantId: Tenant,
    public authContext: AuthenticationContext,
    apiUrl?: string
  ) {
    this.baseUrl = getApiBaseUrl(tenantId, apiUrl);

    this.getCashListConfiguration = get({
      authContext,
      url: ({ nursingHomeId }) =>
        `${this.baseUrl}/${
          endpoints.cashListConfiguration
        }/${endpoints.cashListConfigurationGetCashListConfiguration.replace(
          ':nursingHomeId',
          nursingHomeId
        )}`,
    });

    this.getTransactionById = get({
      authContext,
      url: ({ id }) =>
        `${this.baseUrl}/${
          endpoints.cashManagement
        }/${endpoints.cashManagementGetTransactionId.replace(':id', id)}`,
    });

    this.getTransactionByTransactionId = get({
      authContext,
      url: ({ transactionId }) =>
        `${this.baseUrl}/${
          endpoints.cashManagement
        }/${endpoints.cashManagementGetTransactionByTransactionId.replace(
          ':transactionId',
          transactionId
        )}`,
    });

    this.getTransactions = get({
      authContext,
      url: ({ cashListId, dateTo, dateFrom }) =>
        `${this.baseUrl}/${endpoints.cashManagement}/${
          endpoints.cashManagementGetTransactionsForPeriod
        }?cashListId=${cashListId}&date_from=${dateFrom.toISOString()}&date_to=${dateTo.toISOString()}`,
    });

    this.addTransaction = modifyWithHeaders({
      authContext,
      url: `${this.baseUrl}/${endpoints.cashManagement}/${endpoints.cashManagementPostTransactions}`,
    });

    this.postCashTransactionGroupLink = modifyWithHeaders({
      authContext,
      method: 'POST',
      url: ({ cashTransactionGroupId }) =>
        `${this.baseUrl}/${
          endpoints.cashManagement
        }/${endpoints.cashManagementPostTransactionGroupLink.replace(
          ':groupId',
          cashTransactionGroupId
        )}`,
    });

    this.deleteCashTransactionGroupLink = modifyWithHeaders({
      authContext,
      method: 'DELETE',
      url: ({
        cashTransactionGroupId,
        transactionId,
        receiptId,
        receiptIds,
        transactionIds,
      }) => {
        let link = '';
        if (transactionId) {
          link = `transaction:${transactionId}`;
        }
        if (receiptId) {
          link = `receipt:${receiptId}`;
        }
        if (receiptIds) {
          link = `receipts:${receiptIds.join(':')}`;
        }
        if (transactionIds) {
          link = `transactions:${transactionIds.join(':')}`;
        }

        return `${this.baseUrl}/${
          endpoints.cashManagement
        }/${endpoints.cashManagementDeleteTransactionGroupLink
          .replace(':groupId', cashTransactionGroupId)
          .replace(':link', link)}`;
      },
    });

    this.postCashTransactionGroupTransaction = modifyWithHeaders({
      authContext,
      method: 'POST',
      url: ({ cashTransactionGroupId }) =>
        `${this.baseUrl}/${
          endpoints.cashManagement
        }/${endpoints.cashManagementPostTransactionGroupTransaction.replace(
          ':groupId',
          cashTransactionGroupId
        )}`,
    });

    this.getCashTransactionGroup = get({
      authContext,
      url: ({ groupId }) =>
        `${this.baseUrl}/${
          endpoints.cashManagement
        }/${endpoints.cashManagementGetTransactionGroup.replace(
          ':groupId',
          groupId
        )}`,
    });

    this.confirmMismatch = modifyWithHeaders({
      authContext,
      method: 'POST',
      url: ({ cashTransactionGroupId }) =>
        `${this.baseUrl}/${
          endpoints.cashManagement
        }/${endpoints.cashManagementTransactionGroupMismatch.replace(
          ':groupId',
          cashTransactionGroupId
        )}`,
    });

    this.unconfirmMismatch = modifyWithHeaders({
      authContext,
      method: 'DELETE',
      url: ({ cashTransactionGroupId }) =>
        `${this.baseUrl}/${
          endpoints.cashManagement
        }/${endpoints.cashManagementTransactionGroupMismatch.replace(
          ':groupId',
          cashTransactionGroupId
        )}`,
    });

    this.transfer = modify({
      authContext,
      method: 'POST',
      url: ({ fromCashListId, toCashListId }) =>
        `${this.baseUrl}/${endpoints.cashList}/${endpoints.cashListPostTransfer
          .replace(':fromCashListId', fromCashListId)
          .replace(':toCashListId', toCashListId)}`,
    });
  }

  async getCashTransactionGroups(
    dataIn: GetCashTransactionGroupsDto
  ): Promise<GetCashTransactionGroupListResponseClient> {
    const query = [];
    if (dataIn.cashListId !== undefined) {
      query.push(`cashListId=${dataIn.cashListId}`);
    }

    dataIn.groupType?.forEach((value, i) =>
      query.push(`groupType[${i}]=${value}`)
    );
    if (dataIn.sort !== undefined) {
      query.push(`sort=${dataIn.sort}`);
    }

    if (dataIn.receiptId !== undefined) {
      query.push(`receiptId=${dataIn.receiptId}`);
    }

    if (dataIn.transactionId !== undefined) {
      query.push(`transactionId=${dataIn.transactionId}`);
    }

    dataIn.transactionIds?.forEach((value, i) =>
      query.push(`transactionIds[${i}]=${value}`)
    );

    if (dataIn.sortOrder !== undefined) {
      query.push(`sortOrder=${dataIn.sortOrder}`);
    }

    dataIn.fields?.forEach((value, i) => query.push(`fields[${i}]=${value}`));
    if (dataIn.page !== undefined) {
      query.push(`page=${dataIn.page}`);
    }

    if (dataIn.pageSize !== undefined) {
      query.push(`pageSize=${dataIn.pageSize}`);
    }

    if (dataIn.mismatchOnly !== undefined) {
      query.push(`mismatchOnly=${dataIn.mismatchOnly}`);
    }

    const queryString = query.length > 0 ? `?${query.join('&')}` : '';
    const result = await superFetch(
      this.authContext,
      `${this.baseUrl}/${endpoints.cashManagement}/${endpoints.cashManagementGetTransactionGroups}${queryString}`
    );
    return await result.json();
  }
}

export const addMissingFactorsAndSort = (coins: Coin[]): Coin[] => {
  const allFactors = new Set([
    1, 2, 5, 10, 20, 50, 100, 200, 500, 1000, 2000, 5000, 10000, 20000, 50000,
  ]);
  for (const coin of coins) {
    allFactors.delete(coin.factor);
  }

  const allCoins = [
    ...coins,
    ...Array.from(allFactors).map((factor) => ({ factor, amount: 0 })),
  ];

  allCoins.sort((a, b) => (a.factor < b.factor ? -1 : 1));

  return allCoins;
};
