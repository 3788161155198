import type { FC } from 'react';
import React, { useEffect, useMemo, useState } from 'react';
import { useReceiptTypes } from '@pflegenavi/frontend/api-nursing-home';
import { useTranslateAndSortReceiptTypes } from '@pflegenavi/shared/frontend';
import type { SelectProps } from '@pflegenavi/web-components';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@mui/material/Autocomplete';
import { createFilterOptions, TextField } from '@mui/material';

interface ReceiptTypeDropdownProps {
  receiptTypeId?: string;
  onChangeReceiptType: (receiptTypeId?: string) => void;
}

export const ReceiptTypeDropdown: FC<ReceiptTypeDropdownProps> = ({
  receiptTypeId,
  onChangeReceiptType,
}) => {
  const { data: transactionTypes } = useReceiptTypes({
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });

  const { translateAndSortReceiptTypes } = useTranslateAndSortReceiptTypes();
  const translatedReceiptTypes = useMemo(() => {
    return translateAndSortReceiptTypes({ transactionTypes });
  }, [transactionTypes, translateAndSortReceiptTypes]);

  return (
    <ReceiptTypeDropdownBase
      receiptTypes={translatedReceiptTypes}
      receiptType={receiptTypeId}
      onChangeReceiptType={onChangeReceiptType}
    />
  );
};

export const ReceiptTypeWithServiceProviderRequiredDropdown: FC<
  ReceiptTypeDropdownProps
> = ({ receiptTypeId, onChangeReceiptType }) => {
  const { data: transactionTypes } = useReceiptTypes({
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });

  const { translateAndSortReceiptTypes } = useTranslateAndSortReceiptTypes();
  const translatedReceiptTypes = useMemo(() => {
    const filteredReceiptTypes = transactionTypes?.filter(
      (receiptType) => receiptType.requiresServiceProvider
    );
    return translateAndSortReceiptTypes({
      transactionTypes: filteredReceiptTypes,
    });
  }, [transactionTypes, translateAndSortReceiptTypes]);

  return (
    <ReceiptTypeDropdownBase
      receiptTypes={translatedReceiptTypes}
      receiptType={receiptTypeId}
      onChangeReceiptType={onChangeReceiptType}
    />
  );
};

export type ReceiptTypesFieldProps = SelectProps & {
  disabled?: boolean;
  receiptTypes: Array<{
    value: string;
    label: string;
  }>;
  receiptType?: string;
  onChangeReceiptType: (value?: string) => void;
  errorMessage?: string;
};

export const ReceiptTypeDropdownBase = ({
  receiptType,
  onChangeReceiptType,
  receiptTypes,
  disabled,
  errorMessage,
}: ReceiptTypesFieldProps): JSX.Element => {
  const { t } = useTranslation();

  const selected = receiptTypes.find((type) => type.value === receiptType);

  const defaultInputValue = selected?.label ?? '';

  const [inputValue, setInputValue] = useState(defaultInputValue);
  useEffect(() => {
    setInputValue(defaultInputValue);
  }, [setInputValue, defaultInputValue]);

  const value = useMemo(() => {
    return selected ?? { label: '', value: '' };
  }, [selected]);

  return (
    <Autocomplete
      data-cy="transaction-type"
      disabled={disabled}
      openOnFocus={true}
      value={value}
      onChange={(_, value) => {
        onChangeReceiptType(value?.value);
      }}
      filterOptions={createFilterOptions({ limit: 7 })}
      inputValue={inputValue}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      onInputChange={(_, value) => {
        setInputValue(value);
      }}
      renderInput={({ ...params }) => (
        <TextField
          label={t('receipts.select-type')}
          type={'text'}
          error={Boolean(errorMessage)}
          helperText={errorMessage}
          sx={{ minWidth: 260 }}
          {...params}
        />
      )}
      options={receiptTypes ?? []}
    />
  );
};
