import type { FC } from 'react';
import React, { useCallback, useState } from 'react';
import type { ReceiptBatchTableFilters } from './ReceiptBatchOverviewTableView';
import {
  ReceiptBatchOverviewTableView,
  ReceiptBatchStatus,
} from './ReceiptBatchOverviewTableView';
import {
  useGenerateReceiptBatchPreview,
  useGetReceiptBatchListPaginated,
} from '@pflegenavi/frontend/api-nursing-home';
import { useNursingHomeContext } from '@pflegenavi/frontend/nursing-home-context';
import { ReceiptBatchJobState } from '@pflegenavi/shared/api';
import { useTrackStructuredEvent } from '@pflegenavi/frontend/tracking';
import { ReceiptTypeDropdown } from '../ReceiptTypeDropdown';
import { ReceiptBatchTableEnlargeGallery } from './ReceiptBatchTableEnlargeGallery';
import type { UrlTypeNameMapping } from '@pflegenavi/web-components';
import { useErrorSnackbar } from '@pflegenavi/web-components';

interface ReceiptBatchOverviewTableProps {
  serviceProviderIds?: string[];
  showReceiptTypeDropdown?: boolean;
  filters?: ReceiptBatchTableFilters[];
  recurringItemId?: string;
  dateFrom?: Date;
  dateTo?: Date;
}

export const ReceiptBatchOverviewTable: FC<ReceiptBatchOverviewTableProps> = ({
  serviceProviderIds,
  showReceiptTypeDropdown,
  filters,
  recurringItemId,
  dateFrom,
  dateTo,
}) => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [query, setQuery] = useState<string>('');
  const { selectedNursingHome } = useNursingHomeContext();
  const [receiptTypeId, setReceiptTypeId] = useState<string | undefined>(
    undefined
  );
  const [tab, setTab] = useState<ReceiptBatchStatus | 'all'>('all');
  const [receiptDateRange, setReceiptDateRange] = useState<
    | {
        from?: Date;
        to?: Date;
      }
    | undefined
  >({
    from: dateFrom,
    to: dateTo,
  });
  const [submissionDateRange, setSubmissionDateRange] = useState<
    | {
        from?: Date;
        to?: Date;
      }
    | undefined
  >(undefined);
  const [amountRange, setAmountRange] = useState<
    | {
        from?: number;
        to?: number;
      }
    | undefined
  >(undefined);
  const [receiptCountRange, setReceiptCountRange] = useState<
    | {
        from?: number;
        to?: number;
      }
    | undefined
  >(undefined);

  const { data, isLoading } = useGetReceiptBatchListPaginated(
    {
      nursingHomeId: selectedNursingHome?.id,
      page,
      pageSize,
      sort: 'lastUpdatedOn',
      sortOrder: 'desc',
      serviceProviderNameSearch: query,
      receiptTypeIds: receiptTypeId ? [receiptTypeId] : undefined,
      recurringItemId,
      serviceProviderIds,
      state:
        tab === 'all'
          ? undefined
          : tab === ReceiptBatchStatus.Submitted
          ? [
              ReceiptBatchJobState.Failed,
              ReceiptBatchJobState.Pending,
              ReceiptBatchJobState.Partial,
              ReceiptBatchJobState.Successful,
            ]
          : null,
      receiptDateRange: receiptDateRange
        ? {
            gte: receiptDateRange.from,
            lte: receiptDateRange.to,
          }
        : undefined,
      submissionDateRange: submissionDateRange
        ? {
            gte: submissionDateRange.from,
            lte: submissionDateRange.to,
          }
        : undefined,
      amount: amountRange
        ? {
            gte: amountRange.from,
            lte: amountRange.to,
          }
        : undefined,
      receiptCount: receiptCountRange
        ? {
            gte: receiptCountRange.from,
            lte: receiptCountRange.to,
          }
        : undefined,
    },
    { keepPreviousData: true, enabled: selectedNursingHome?.id !== undefined }
  );

  const [receiptImageIds, setReceiptImageIds] = useState<string[] | undefined>(
    undefined
  );
  const [imageGalleryTitle, setImageGalleryTitle] = useState<
    string | undefined
  >(undefined);

  const [openImageGallery, setOpenImageGallery] = useState(false);

  const { mutateAsync: generateReceiptBatch } = useGenerateReceiptBatchPreview(
    useErrorSnackbar().enqueueErrorSnackbar
  );

  const [generatedReceiptImageUrls, setGeneratedReceiptImageUrls] = useState<
    string[]
  >([]);
  const [generatedReceiptTypeMap, setGeneratedReceiptTypeMap] =
    useState<UrlTypeNameMapping>(new Map());

  const generateReceiptBatchPreview = useCallback(
    async (batchId: string, filename: string) => {
      try {
        const receiptBlob = await generateReceiptBatch({
          batchId,
        });
        if (receiptBlob) {
          const file = window.URL.createObjectURL(receiptBlob);

          setGeneratedReceiptImageUrls([file]);
          setGeneratedReceiptTypeMap(
            new Map([
              [
                file,
                {
                  type: 'application/pdf',
                  data: Promise.resolve(receiptBlob),
                  name: filename,
                },
              ],
            ])
          );
          setOpenImageGallery(true);
        }
      } catch {
        // handleFailedPreviewGenerationError(e);
      }
    },
    [generateReceiptBatch]
  );

  const onClickReceiptIcon = useCallback(
    ({
      id,
      receiptImageIds,
      title,
      generateReceipts,
    }: {
      id: string;
      receiptImageIds: string[] | undefined;
      title: string;
      generateReceipts: boolean;
    }) => {
      if (generateReceipts) {
        generateReceiptBatchPreview(id, title); // Title contains the filename when in preview mode
        return;
      }
      setReceiptImageIds(receiptImageIds);
      setImageGalleryTitle(title);
      if (receiptImageIds && receiptImageIds.length > 0) {
        setOpenImageGallery(true);
      }
    },
    [generateReceiptBatchPreview]
  );

  return (
    <>
      <ReceiptBatchOverviewTableView
        data={data?.data ?? []}
        query={query}
        onChangeQuery={setQuery}
        receiptTypeId={receiptTypeId}
        setReceiptTypeId={setReceiptTypeId}
        totalRowCount={data?.meta?.totalItems ?? 0}
        page={page}
        pageSize={pageSize}
        onChangePage={setPage}
        onChangePageSize={setPageSize}
        isLoading={isLoading}
        onClickReceiptIcon={onClickReceiptIcon}
        filters={filters ?? []}
        receiptDateRange={receiptDateRange}
        onChangeReceiptDateRange={setReceiptDateRange}
        submissionDateRange={submissionDateRange}
        onChangeSubmissionDateRange={setSubmissionDateRange}
        amountRange={amountRange}
        onChangeAmountRange={setAmountRange}
        receiptCountRange={receiptCountRange}
        onChangeReceiptCountRange={setReceiptCountRange}
        DropdownComponent={showReceiptTypeDropdown ? ReceiptTypeDropdown : null}
        tab={tab}
        setTab={setTab}
        useTrackStructuredEvent={useTrackStructuredEvent}
      />
      <ReceiptBatchTableEnlargeGallery
        openImageGallery={openImageGallery}
        setOpenImageGallery={setOpenImageGallery}
        batchImageIds={receiptImageIds}
        title={imageGalleryTitle ?? ''}
        generatedReceiptImageUrls={generatedReceiptImageUrls}
        setGeneratedReceiptImageUrls={setGeneratedReceiptImageUrls}
        generatedReceiptTypeMap={generatedReceiptTypeMap}
      />
    </>
  );
};
