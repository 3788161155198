import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Drawer, Stack } from '@mui/material';
// config
import { NAVBAR } from '../config';
// components
import {
  cssStyles,
  NavSection,
  useResponsive,
} from '@pflegenavi/web-components';
//
import type { ConfigKey } from './UseNavConfig';
import useNavConfig from './UseNavConfig';

import { Logo } from './Logo';
import { UserAvatar } from './UserAvatar';

// ----------------------------------------------------------------------

interface Props {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
  configKey: ConfigKey;
  renderSwitchLanguageMenuItem?: ({
    handleClose,
  }: {
    handleClose: () => void;
  }) => React.ReactElement;
  // component to display under the pflegenavi logo
  UnderLogoComponent?: React.ReactNode;
}

export default function SideNavBar({
  isOpenSidebar,
  onCloseSidebar,
  configKey,
  UnderLogoComponent,
  renderSwitchLanguageMenuItem,
}: Props): JSX.Element {
  const theme = useTheme();

  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  // const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } =
  //   useCollapseDrawer();
  const isCollapse = false;
  const collapseClick = false;
  const collapseHover = false;

  const onHoverEnter = () => {
    return;
  };
  const onHoverLeave = () => {
    return;
  };

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const navConfig = useNavConfig({ configKey });

  const renderContent = (
    <Stack
      direction="column"
      sx={{
        bgcolor: 'secondary.lighter',
        pl: 1.5,
        pr: 1.5,
        pb: 3,
        height: 1,
        justifyContent: 'space-between',
      }}
    >
      <Stack direction="column">
        <Stack
          sx={{
            pt: 3,
            pl: 2,
            pb: 8,
            flexShrink: 0,
            ...(isCollapse ? { alignItems: 'center' } : {}),
          }}
        >
          <Logo />
        </Stack>

        {UnderLogoComponent}

        <NavSection navConfig={navConfig} isCollapse={isCollapse} />
      </Stack>

      <UserAvatar
        configKey={configKey}
        renderSwitchLanguageMenuItem={renderSwitchLanguageMenuItem}
      />
    </Stack>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse
            ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH
            : NAVBAR.DASHBOARD_WIDTH,
        },
        ...(collapseClick
          ? {
              position: 'absolute',
            }
          : {}),
      }}
    >
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: {
              width: NAVBAR.DASHBOARD_WIDTH,
              bgcolor: 'secondary.lighter',
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: NAVBAR.DASHBOARD_WIDTH,
              borderRightStyle: 'dashed',
              bgcolor: 'secondary.lighter',
              transition: (theme) =>
                theme.transitions.create('width', {
                  duration: theme.transitions.duration.standard,
                }),
              ...(isCollapse
                ? {
                    width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
                  }
                : {}),
              ...(collapseHover
                ? {
                    ...cssStyles(theme).bgBlur(),
                  }
                : {}),
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}

// ----------------------------------------------------------------------
// ----------------------------------------------------------------------
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));
