import { makeApiProvider } from '@pflegenavi/shared-frontend/platform';
import {
  useMutation,
  type UseMutationResult,
  useQueryClient,
} from 'react-query';

import { RESIDENTS_KEY } from '../resident/queryKeys';
import { createContext } from 'react';
import type { IReceiptPhoenixApi } from './api';
import { ReceiptPhoenixApi } from './api';
import { invalidateAllResidentBalance } from '../reporting/queryKeys';
import {
  getReceiptKey,
  RECEIPTS_AND_RECEIPT_BATCHES_KEY,
  RECEIPTS_KEY,
} from '../transaction/queryKeys';

const ApiContext = createContext<IReceiptPhoenixApi | undefined>(undefined);
const {
  useApi: useReceiptsPhoenixApi,
  ApiProvider: ReceiptsPhoenixApiProvider,
} = makeApiProvider({
  name: 'ReceiptsPhoenix',
  ApiContext,
  newApi: (tenantId, auth, apiUrl) =>
    new ReceiptPhoenixApi(tenantId, auth, apiUrl),
});

export type { ReceiptPhoenixApi, IReceiptPhoenixApi };
export {
  useReceiptsPhoenixApi,
  ReceiptsPhoenixApiProvider,
  ApiContext as ReceiptsPhoenixApiContext,
};

const SUBMIT_RECEIPTS_KEY = 'submit-receipts';
const REFUND_RECEIPTS_KEY = 'refund-receipts';

export const useSubmitReceiptsPhoenix = (): UseMutationResult<
  { data: Array<{ id: string; status?: number; message?: string }> },
  unknown,
  {
    body: { receipt_ids: string[] };
    nursingHomeId?: string;
  }
> => {
  const api = useReceiptsPhoenixApi();
  const queryClient = useQueryClient();
  return useMutation<
    { data: Array<{ id: string; status?: number; message?: string }> },
    unknown,
    {
      body: { receipt_ids: string[] };
      nursingHomeId?: string;
    },
    unknown
  >(SUBMIT_RECEIPTS_KEY, (params) => api.submit(params), {
    onSettled: async (_data, _error, params) => {
      await Promise.all([
        queryClient.invalidateQueries(RECEIPTS_KEY),

        invalidateAllResidentBalance(queryClient, params.nursingHomeId),
        queryClient.invalidateQueries(RESIDENTS_KEY(params.nursingHomeId)),
        queryClient.invalidateQueries(
          RECEIPTS_AND_RECEIPT_BATCHES_KEY(params.nursingHomeId)
        ),
      ]);
    },
  });
};

export const useCancelReceiptPhoenix = (
  nursingHomeId?: string
): UseMutationResult<
  void,
  unknown,
  {
    body: { data: { note: string } };
    params: { receipt_id: string };
  }
> => {
  const api = useReceiptsPhoenixApi();
  const queryClient = useQueryClient();
  return useMutation<
    void,
    unknown,
    {
      body: { data: { note: string } };
      params: { receipt_id: string };
    },
    unknown
  >(REFUND_RECEIPTS_KEY, (params) => api.cancel(params), {
    onSettled: async (_data, _error, params) => {
      await Promise.all([
        queryClient.invalidateQueries(getReceiptKey(params.params.receipt_id)),
        queryClient.invalidateQueries(RESIDENTS_KEY(nursingHomeId)),
        invalidateAllResidentBalance(queryClient, nursingHomeId),
      ]);
    },
  });
};
