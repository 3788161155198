/* eslint-disable @typescript-eslint/explicit-module-boundary-types,max-depth */
import {
  PaymentInitiatedBy,
  PaymentSchedule,
  ResidentPendingChargePaymentStatus,
  ResidentState,
} from '@pflegenavi/shared/api';
import Fuse from 'fuse.js';
import type { ResidentTableRowModel } from '../interfaces/ResidentTableRowModel';
import { CircleFilterValues, TabFilterValues } from '../hooks/useResidentsPage';

export function applySortFilter({
  dataFiltered,
  comparator,
  filterTerms,
  filterStatus,
  circleFilter,
  isMangopay,
}: {
  dataFiltered: ResidentTableRowModel[];
  comparator: (a: any, b: any) => number;
  filterTerms: string;
  filterStatus: TabFilterValues;
  circleFilter: CircleFilterValues;
  locale: string;
  isMangopay: boolean;
}) {
  const stabilizedThis = dataFiltered.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });

  dataFiltered = stabilizedThis.map((el) => el[0]);

  if (filterTerms) {
    const fuse = new Fuse(dataFiltered, {
      includeScore: true, // mostly for test purposes
      threshold: 0.4,
      keys: [
        'name',
        'nameInitials',
        'gender',
        'residentAccountingId',
        'stateString',
        'balanceString',
        'familyMember',
      ],

      getFn: (obj, path) => {
        if (path[0] === 'nameInitials') {
          const initials = `${obj?.firstName?.[0] ?? ''}${
            obj?.lastName?.[0] ?? ''
          }`;
          return initials;
        }

        const getValue = (innerObj: object | string, indx: number): string => {
          if (innerObj === undefined || indx >= path.length) {
            return typeof innerObj === 'string' ? innerObj : '';
          }
          // @ts-expect-error index types unknown
          return getValue(innerObj[path[indx]], ++indx);
        };
        return getValue(obj, 0);
      },
    });
    const rawFilteredData = fuse.search(filterTerms);
    dataFiltered = rawFilteredData.map(({ item }) => item);
  }

  const dataFilteredAfterStatus = getDataByStatus(
    dataFiltered,
    filterStatus,
    circleFilter,
    isMangopay
  );

  return {
    dataFiltered,
    dataFilteredAfterStatus,
  };
}

export const getDataByStatus = (
  data: ResidentTableRowModel[],
  status: TabFilterValues,
  circleFilter: CircleFilterValues,
  isMangopay: boolean
) => {
  return data.filter((item) =>
    statusFilter(item, status, circleFilter, isMangopay)
  );
};

export function statusFilter(
  resident: ResidentTableRowModel,
  status: TabFilterValues,
  circleFilter: CircleFilterValues,
  isMangopay: boolean
): ResidentTableRowModel | undefined {
  switch (circleFilter) {
    case CircleFilterValues.ACCOUNT_BALANCE:
      switch (status) {
        case TabFilterValues.LOW_BALANCE:
          if (
            resident.lowBalance &&
            resident.balance >= 0 &&
            resident.state === ResidentState.active
          ) {
            return resident;
          }
          break;
        case TabFilterValues.NEGATIVE_BALANCE:
          if (resident.balance < 0 && resident.state === ResidentState.active) {
            return resident;
          }
          break;
        case TabFilterValues.SETTLEMENT:
          if (resident.settlement) {
            return resident;
          }
          break;
        case TabFilterValues.ALL:
          return resident;
      }
      break;

    case CircleFilterValues.PAYMENT_PRECONDITIONS:
      if (!resident.settlement && !resident.cashPayer) {
        switch (status) {
          case TabFilterValues.MISSING_FAMILY_MEMBER:
            if (resident.familyMemberMissing) {
              return resident;
            }
            break;
          case TabFilterValues.SEPA_DEBIT_MISSING:
            if (
              resident.sepaMissing &&
              resident.familyMemberEmailVerified &&
              !resident.familyMemberMissing
            ) {
              return resident;
            }
            break;
          case TabFilterValues.AMOUNT_TOO_HIGH:
            if (
              resident.amountTooHigh &&
              resident.familyMemberEmailVerified &&
              !resident.sepaMissing &&
              !resident.familyMemberMissing
            ) {
              return resident;
            }
            break;
          case TabFilterValues.SEPA_ACTIVE:
            if (
              !resident.amountTooHigh &&
              resident.familyMemberEmailVerified &&
              !resident.sepaMissing &&
              !resident.familyMemberMissing
            ) {
              return resident;
            }
            break;
          case TabFilterValues.EMAIL_NOT_VERIFIED:
            if (
              !resident.familyMemberEmailVerified &&
              !resident.familyMemberMissing
            ) {
              return resident;
            }
            break;
          case TabFilterValues.ALL:
            if (resident.state === ResidentState.active) {
              return resident;
            }
            break;
        }
      }
      break;

    case CircleFilterValues.ONGOING_PAYMENTS:
      if (!resident.settlement) {
        if (isMangopay) {
          switch (status) {
            case TabFilterValues.PENDING:
              if (
                resident.payment_info_phoenix?.pending_charge
                  ?.pending_charge_status ===
                ResidentPendingChargePaymentStatus.Pending
              ) {
                return resident;
              }
              break;
            case TabFilterValues.UPCOMING:
              if (
                resident.payment_info_phoenix?.pending_charge
                  ?.pending_charge_status ===
                ResidentPendingChargePaymentStatus.Upcoming
              ) {
                return resident;
              }
              break;
            case TabFilterValues.FAILED:
              if (
                resident.payment_info_phoenix?.pending_charge
                  ?.pending_charge_status ===
                ResidentPendingChargePaymentStatus.Failed
              ) {
                return resident;
              }
              break;
            case TabFilterValues.ALL:
              if (resident.onGoingPayments) {
                return resident;
              }
              break;
          }
        } else {
          switch (status) {
            case TabFilterValues.PENDING:
              if (
                resident.paymentInfo?.status ===
                ResidentPendingChargePaymentStatus.Pending
              ) {
                return resident;
              }
              break;
            case TabFilterValues.UPCOMING:
              if (
                resident.paymentInfo?.status ===
                ResidentPendingChargePaymentStatus.Upcoming
              ) {
                return resident;
              }
              break;
            case TabFilterValues.FAILED:
              if (
                resident.paymentInfo?.status ===
                ResidentPendingChargePaymentStatus.Failed
              ) {
                return resident;
              }
              break;
            case TabFilterValues.ALL:
              if (resident.onGoingPayments) {
                return resident;
              }
              break;
          }
        }
      }
      break;

    case CircleFilterValues.PAYMENT_METHODS:
      if (!resident.settlement) {
        switch (status) {
          case TabFilterValues.CASH_PAYER:
            if (resident.cashPayer) {
              return resident;
            }
            break;
          case TabFilterValues.THRESHOLD:
            if (
              resident.paymentInfo?.initiatedBy === PaymentInitiatedBy.System &&
              resident.paymentInfo.schedule === PaymentSchedule.Threshold &&
              !resident.cashPayer
            ) {
              return resident;
            }
            break;
          case TabFilterValues.MONTHLY:
            if (
              resident.paymentInfo?.initiatedBy === PaymentInitiatedBy.System &&
              resident.paymentInfo.schedule === PaymentSchedule.Monthly &&
              !resident.cashPayer
            ) {
              return resident;
            }
            break;
          case TabFilterValues.MANUAL:
            if (
              resident.paymentInfo?.initiatedBy ===
                PaymentInitiatedBy.FamilyMember &&
              !resident.cashPayer
            ) {
              return resident;
            }
            break;
          case TabFilterValues.DISABLED:
            if (
              resident.paymentInfo?.initiatedBy ===
                PaymentInitiatedBy.Disabled &&
              !resident.cashPayer
            ) {
              return resident;
            }
            break;
          case TabFilterValues.ALL:
            if (resident.paymentInfo || resident.cashPayer) {
              return resident;
            }
            break;
        }
        break;
      }
  }

  return undefined;
}
