import type { FC, SVGProps } from 'react';
import React from 'react';
import IconRoundsPeople from '~icons/ic/round-people';
import IconEuroCircle from '~icons/majesticons/euro-circle';
import IconWallet from '~icons/ant-design/wallet-filled';
import IconBankFilled from '~icons/ant-design/bank-filled';
import {
  Avatar,
  Box,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Skeleton,
  Tooltip,
  Typography,
} from '@mui/material';
import { MenuPopover } from '@pflegenavi/web-components';
import { styled, useTheme } from '@mui/material/styles';
import { useFormatting } from '@pflegenavi/frontend/localization';

import HomeStatusIcon from './home_status.svg';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { nhAppMainPages } from '@pflegenavi/frontend/routing';
import { useMenuPopover } from './useMenuPopover';
import { MenuBarIconButton } from '@pflegenavi/frontend/layout';
import { useNursingHomeQuickOverviewMangopay } from './useNursingHomeQuickOverviewMangopay';

export const NursingHomeQuickOverviewMangopay: FC = () => {
  const { t } = useTranslation();
  const { fCurrencyCents } = useFormatting();
  const navigate = useNavigate();

  const {
    isLoading,
    totalResidentsBalance,
    nursingHomeWalletAmount,
    bankAccountCashLists,
    cashAccountCashLists,
  } = useNursingHomeQuickOverviewMangopay();

  const { open, handleClick, handleClose, anchorEl } = useMenuPopover(
    'NursingHomeQuickOverview'
  );

  const navigateToAccountingOverview = () => {
    navigate(nhAppMainPages.NURSING_HOME_ACCOUNTING_OVERVIEW_NEW);
    handleClose();
  };

  return (
    <>
      <Tooltip title={t('menu-bar.nursing-home-status.title')}>
        <MenuBarIconButton onClick={handleClick} open={Boolean(open)}>
          <img alt="" src={HomeStatusIcon} width={24} height={24} />
        </MenuBarIconButton>
      </Tooltip>
      <MenuPopover
        open={Boolean(open)}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{
          width: 360,
          p: 0,
          mt: 0.5,
          ml: 0.5,
        }}
      >
        <MenuTitleContainer>
          <Typography variant="subtitle1" color="primary">
            {t('menu-bar.nursing-home-status.title')}
          </Typography>
        </MenuTitleContainer>

        <Divider />
        {isLoading ? (
          <NursingHomeStatusLoadingSkeleton />
        ) : (
          <>
            <List
              disablePadding
              subheader={
                <ListSubheader
                  disableSticky
                  sx={{
                    py: 1,
                    px: 2.5,
                    typography: 'overline',
                    mt: 1,
                  }}
                >
                  {t('menu-bar.nursing-home-status.account-balance')}
                </ListSubheader>
              }
              sx={{
                maxHeight: 'calc(100vh - 150px)',
                overflowY: 'auto',
              }}
            >
              <AccountBalanceItem
                onClick={navigateToAccountingOverview}
                title={t('menu-bar.nursing-home-status.total-resident-balance')}
                subtitle={fCurrencyCents(totalResidentsBalance)}
                Icon={IconRoundsPeople}
              />
              <AccountBalanceItem
                onClick={navigateToAccountingOverview}
                title={t('menu-bar.nursing-home-status.wallet')}
                subtitle={fCurrencyCents(nursingHomeWalletAmount)}
                Icon={IconWallet}
              />
              {bankAccountCashLists.map((cashList) => (
                <AccountBalanceItem
                  key={cashList.id}
                  onClick={navigateToAccountingOverview}
                  title={cashList.name}
                  subtitle={`${fCurrencyCents(cashList.totalInCent)}`}
                  Icon={IconBankFilled}
                />
              ))}

              {cashAccountCashLists.map((cashList) => (
                <AccountBalanceItem
                  key={cashList.id}
                  onClick={navigateToAccountingOverview}
                  title={cashList.name}
                  subtitle={fCurrencyCents(cashList.totalInCent)}
                  Icon={IconEuroCircle}
                />
              ))}

              <ListItem
                sx={{
                  py: 0.5,
                }}
              ></ListItem>
            </List>
          </>
        )}
      </MenuPopover>
    </>
  );
};

// ----------------------------------------------------------------------

interface AccountBalanceItemProps {
  title: string;
  subtitle: string;
  onClick?: () => void;
  Icon: React.ComponentType<SVGProps<SVGSVGElement>>;
}

const AccountBalanceItem: FC<AccountBalanceItemProps> = ({
  title,
  subtitle,
  onClick,
  Icon,
}) => {
  const theme = useTheme();

  return (
    <ListItemButton onClick={onClick}>
      <ListItemAvatar>
        <Avatar
          sx={{
            bgcolor: 'background.neutral',
          }}
        >
          <Icon color={theme.palette.primary.main} />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        primaryTypographyProps={{
          color: 'primary',
          fontWeight: 600,
        }}
        secondary={
          <Typography
            variant="subtitle2"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {subtitle}
          </Typography>
        }
      />
    </ListItemButton>
  );
};

// ----------------------------------------------------------------------

const NursingHomeStatusLoadingSkeleton = () => {
  return (
    <>
      <Skeleton variant="rectangular" height={90} />
      <Divider />
      <Skeleton variant="rectangular" height={90} />
    </>
  );
};

const MenuTitleContainer = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    py: 2,
    px: 2.5,
  })
);
