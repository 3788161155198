import type { FC } from 'react';
import React from 'react';
import { Iconify } from '@pflegenavi/web-components';
import { IconButton, Stack, styled, Typography } from '@mui/material';

interface ImageNavigationButtonsProps {
  currentImageIndex: number;
  imagesCount: number;
  disabledLeft: boolean;
  disabledRight: boolean;
  showButtons: boolean;
  onPrevious: () => void;
  onNext: () => void;
}

export const ImageNavigationButtons: FC<ImageNavigationButtonsProps> = ({
  currentImageIndex,
  imagesCount,
  showButtons,
  disabledLeft,
  disabledRight,
  onPrevious,
  onNext,
}) => {
  return (
    <NavigationContainer
      direction="row"
      justifyContent="center"
      alignItems="center"
      p={2}
    >
      {showButtons && (
        <ImageNavigationButton onClick={onPrevious} disabled={disabledLeft}>
          <Iconify icon="eva:arrow-left-fill" fontSize={25} color="white" />
        </ImageNavigationButton>
      )}

      <CountImageLabel
        imagesCount={imagesCount}
        currentImageIndex={currentImageIndex}
      />

      {showButtons && (
        <ImageNavigationButton onClick={onNext} disabled={disabledRight}>
          <Iconify icon="eva:arrow-right-fill" fontSize={25} color="white" />
        </ImageNavigationButton>
      )}
    </NavigationContainer>
  );
};

interface CountImageLabelProps {
  currentImageIndex: number;
  imagesCount: number;
}

export const CountImageLabel: FC<CountImageLabelProps> = ({
  currentImageIndex,
  imagesCount,
}) => {
  return (
    <Typography variant="body2">
      {`${currentImageIndex + 1} / ${imagesCount}`}
    </Typography>
  );
};

const NavigationContainer = styled(Stack)(({ theme }) =>
  theme.unstable_sx({
    background: 'rgba(0, 0, 0, 0.7)',
    p: '1px 4px',
    color: 'white',
    fontSize: 15,
    borderRadius: 2,
    minWidth: 40,
  })
);

const ImageNavigationButton = styled(IconButton)(({ theme }) =>
  theme.unstable_sx({
    borderRadius: 2,
    opacity: 1,
    color: 'white',
    padding: 0,
    ':disabled': {
      opacity: 0.5,
    },
  })
);
