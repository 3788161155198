import * as React from 'react';
import { type FC, memo, useCallback, useMemo, useState } from 'react';
import { SelectAllResidentsView } from '@pflegenavi/frontend/resident-components';
import { type FormikErrors, useFormikContext } from 'formik';
import type {
  CreateNewFamilyMemberType,
  ImportFamilyMemberFormValues,
} from './ImportFamilyMembersTable';
import { createNewFamilyMember } from './ImportFamilyMembersTable';
import type { Gender } from '@pflegenavi/shared/api';
import { ImportFamilyMembersTableRowView } from './ImportFamilyMembersTableRowView';
import { useFMImportTableNavigation } from '../hooks/useFMImportTableNavigation';
import { FMImportErrorHelperText } from './FMImportErrorHelperText';

interface ImportFamilyMembersTableRowContainerProps {
  index: number;
  familyMember: CreateNewFamilyMemberType;
  startingResidents: Array<{
    id: string;
    name: string;
    gender: Gender;
  }>;
  getResidents: () => Resident[];
  setResidentId: (id: string, index: number, type: 'add' | 'delete') => void;
  remove: (index: number) => void;
  alreadySelectedResidentsIdsRef: React.MutableRefObject<Set<string>>;
}

export const ImportFamilyMembersTableRowContainer: FC<
  ImportFamilyMembersTableRowContainerProps
> = ({
  index,
  familyMember,
  startingResidents,
  getResidents,
  setResidentId,
  remove,
  alreadySelectedResidentsIdsRef,
}) => {
  const formik = useFormikContext<ImportFamilyMemberFormValues>();

  const {
    values: { familyMembers },
    errors: familyMembersErrors,
    touched: familyMembersTouched,
    resetForm,
    setFieldValue,
    setFieldTouched,
  } = formik;

  const { lastInputRef } = useFMImportTableNavigation({ index });

  const handleRemoveRow = useCallback(() => {
    if (familyMembers.length === 1) {
      resetForm({
        values: {
          familyMembers: [createNewFamilyMember()],
        },
      });
      alreadySelectedResidentsIdsRef.current.clear();
      return;
    }
    remove(index);
    alreadySelectedResidentsIdsRef.current.delete(familyMember.residentId);
  }, [
    alreadySelectedResidentsIdsRef,
    familyMember.residentId,
    familyMembers.length,
    index,
    remove,
    resetForm,
  ]);

  const selectedResident = useMemo(() => {
    return (
      startingResidents?.find(
        (resident) => resident.id === familyMembers[index].residentId
      ) ?? undefined
    );
  }, [familyMembers, index, startingResidents]);

  const errors = familyMembersErrors.familyMembers
    ? (familyMembersErrors.familyMembers[
        index
      ] as FormikErrors<CreateNewFamilyMemberType>)
    : undefined;

  const touched = familyMembersTouched.familyMembers
    ? familyMembersTouched.familyMembers[index] ?? undefined
    : undefined;

  const showResidentIdError =
    Boolean(errors?.residentId) &&
    (familyMembers[index].residentId !== '' ||
      (familyMembers[index].residentId === '' && touched !== undefined));

  const SelectResidents = useMemo(() => {
    return (
      <SelectResidentWrapper
        index={index}
        getResidents={getResidents}
        setResidentId={setResidentId}
        selectedResident={selectedResident}
        error={showResidentIdError}
        helperText={showResidentIdError ? errors?.residentId : undefined}
        handleBlur={() => setFieldTouched(`familyMembers.${index}.residentId`)}
      />
    );
  }, [
    getResidents,
    errors?.residentId,
    setResidentId,
    index,
    selectedResident,
    setFieldTouched,
    showResidentIdError,
  ]);

  return (
    <ImportFamilyMembersTableRowView
      index={index}
      firstName={familyMember.firstName}
      lastName={familyMember.lastName}
      email={familyMember.email}
      setFieldValue={setFieldValue}
      setFieldTouched={setFieldTouched}
      removeRow={handleRemoveRow}
      SelectResidents={SelectResidents}
      errors={errors}
      touched={touched}
      lastInputRef={lastInputRef}
      formik={formik}
    />
  );
};

interface Resident {
  id: string;
  name: string;
  gender: Gender;
}

interface SelectResidentWrapperProps {
  index: number;
  getResidents: () => Resident[];
  selectedResident: Resident | undefined;
  setResidentId: (id: string, index: number, type: 'add' | 'delete') => void;
  error: boolean;
  helperText?: string;
  handleBlur?: () => void;
}

const SelectResidentWrapper: FC<SelectResidentWrapperProps> = memo(
  ({
    index,
    getResidents,
    selectedResident,
    setResidentId: setResidentIdOriginal,
    helperText,
    error,
    handleBlur,
  }) => {
    const [residents, setResidents] = useState<Resident[]>([]);

    const handleFocus = useCallback(() => {
      setResidents(getResidents);
    }, [getResidents, setResidents]);

    const setResidentId = useCallback(
      (id?: string) => {
        if (!id && selectedResident) {
          setResidentIdOriginal(selectedResident.id, index, 'delete');
        } else if (id) {
          if (selectedResident) {
            setResidentIdOriginal(selectedResident.id, index, 'delete');
          }
          setResidentIdOriginal(id, index, 'add');
        }
      },
      [selectedResident, setResidentIdOriginal, index]
    );

    return (
      <>
        <SelectAllResidentsView
          name={`familyMembers.${index}.residentId`}
          residents={residents}
          selectedResident={selectedResident}
          setResidentId={setResidentId}
          error={error}
          handleBlur={handleBlur}
          autoFocus={index > 0}
          variant="standard"
          hideAvatar
          minWidth={250}
          handleFocus={handleFocus}
        />
        <FMImportErrorHelperText helperText={helperText} />
      </>
    );
  }
);
