import * as React from 'react';
import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import 'numeral/locales/de';
import { DEFAULT_LOCALE } from '@pflegenavi/shared/constants';
import { useTranslation } from 'react-i18next';
import type {
  LocaleStorage,
  SupportedLanguages,
  SupportedLocales,
} from '../LocaleStorage';

const LANGUAGE = {
  'en-GB': 'en',
  'de-AT': 'de',
  // Will return the translation key instead of translations
  cimode: 'cimode',
};

interface LocaleContextInterface {
  changeLocaleAndLanguage: (newLanguage: SupportedLocales) => void;
  locale: SupportedLocales;
}

export const useLocale = (): SupportedLocales => {
  return useContext(LocaleContext).locale;
};

export const useChangeLocale = (): ((locale: SupportedLocales) => void) => {
  return useContext(LocaleContext).changeLocaleAndLanguage;
};

export const useToggleLocale = (): (() => void) => {
  const { locale, changeLocaleAndLanguage } = useContext(LocaleContext);
  return useCallback(() => {
    changeLocaleAndLanguage(locale === 'de-AT' ? 'en-GB' : 'de-AT');
  }, [locale, changeLocaleAndLanguage]);
};

export const LocaleContext = React.createContext<LocaleContextInterface>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  changeLocaleAndLanguage: (newLanguage) => {},
  locale: DEFAULT_LOCALE,
});

export const LocaleProvider = ({
  children,
  localeStorage,
}: {
  children: React.ReactElement;
  localeStorage: LocaleStorage;
}): JSX.Element => {
  const { i18n } = useTranslation();
  // for resetting values previous to refactoring

  const _updateLocaleIfNeeded = useCallback(async () => {
    const savedLocaleValue = await localeStorage.getLocale();
    if (savedLocaleValue === null) {
      await localeStorage.setLocale(DEFAULT_LOCALE);
    } else if (savedLocaleValue === '"en-US"') {
      await localeStorage.setLocale('en-GB');
    } else if (savedLocaleValue === '"de-DE"') {
      await localeStorage.setLocale('de-AT');
    }

    // Fallback in case the supported locales are not found
    if (!Object.keys(LANGUAGE).includes(savedLocaleValue ?? '')) {
      await localeStorage.setLocale(DEFAULT_LOCALE);
    }
  }, [localeStorage]);

  const getLanguage = useCallback(async (): Promise<SupportedLocales> => {
    await _updateLocaleIfNeeded();
    const locale = await localeStorage.getLocale();
    return (locale ?? DEFAULT_LOCALE) as SupportedLocales;
  }, [localeStorage, _updateLocaleIfNeeded]);

  const [locale, setLocale] = useState<SupportedLocales>(DEFAULT_LOCALE);

  useEffect(() => {
    getLanguage().then(setLocale);
  }, [getLanguage]);

  useLayoutEffect(() => {
    if (global.document) {
      global.document.documentElement.lang = LANGUAGE[locale];
    }
  }, [locale]);

  const changeLocaleAndLanguage = async (newLocale: SupportedLocales) => {
    const language = (LANGUAGE[newLocale] ?? 'de') as SupportedLanguages;
    await i18n.changeLanguage(language);
    await localeStorage.setLanguage(language);
    await localeStorage.setLocale(newLocale);
    setLocale(newLocale);
  };

  return (
    <LocaleContext.Provider
      value={{
        changeLocaleAndLanguage,
        locale: locale,
      }}
    >
      {children}
    </LocaleContext.Provider>
  );
};
