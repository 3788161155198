import type { FC, ReactNode } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { User } from './AuthenticationContext';
import { AuthenticationContext } from './AuthenticationContext';
import type { KeycloakProfile } from 'keycloak-js';
import { getTokenPayloadFromToken } from '@pflegenavi/shared/utils';
import { useClientStoredResourcesContext } from '@pflegenavi/frontend/client-stored-resources-context';

interface AuthenticationProviderProps {
  children: ReactNode;
  login: (setUser: (user: User) => void) => void;
  keycloak: {
    onTokenExpired?: () => void;
    updateToken: (seconds: number) => Promise<boolean>;
    logout: ({ redirectUri }: { redirectUri?: string }) => Promise<void>;
    token?: string;
    loadUserProfile: () => Promise<KeycloakProfile>;
    getTokenImmediate: () => string | undefined;
  };
  redirectUri?: string;
  isNewAuth?: boolean;
}

export const AuthenticationProvider: FC<AuthenticationProviderProps> = ({
  children,
  login,
  keycloak,
  redirectUri,
  isNewAuth,
}) => {
  const clientStoredResources = useClientStoredResourcesContext();

  const [user, setUser] = useState<User | undefined>(undefined);
  const tokenBeingRefreshed = useRef<Promise<void> | undefined>(undefined);
  const [testMode, setTestMode] = useState(false);

  const [listeners, setListeners] = useState<
    Array<(token: string | undefined) => void>
  >([]);
  const listenersRef = useRef(listeners);
  listenersRef.current = listeners;

  const addTokenListener = useCallback(
    (callback: (token: string | undefined) => void) => {
      setListeners((listeners) => [...listeners, callback]);
      return {
        unlisten: () => {
          setListeners((listeners) =>
            listeners.filter((listener) => listener !== callback)
          );
        },
      };
    },
    []
  );

  useEffect(() => {
    listenersRef.current.forEach((listener) => listener(keycloak.token));
  }, [keycloak.token, listenersRef]);

  useEffect(() => {
    const tokenPayload = getTokenPayloadFromToken(keycloak.token);
    if (tokenPayload?.v === 'pn0.1') {
      // This is a pflegenavi internal token and contains the user information
      setUser({
        userId: tokenPayload.sub,
        firstName: tokenPayload.given_name,
        lastName: tokenPayload.family_name,
        email: tokenPayload.email ?? '',
        username: tokenPayload.name,
        locale: tokenPayload.locale,
        attributes: tokenPayload.ff?.reduce((acc, curr) => {
          acc[`ff.${curr}`] = ['true'];
          return acc;
        }, {} as Record<string, string[]>),
      });
    } else if (!keycloak.token && isNewAuth) {
      (async function () {
        await clientStoredResources.clearAll();
        setUser(undefined);
      })();
    }
  }, [keycloak.token, isNewAuth, clientStoredResources]);

  // TODO: We need to call "setUser" to initialize the user
  useEffect(() => {
    login(setUser);
    const oldTokenExpired = keycloak.onTokenExpired;
    keycloak.onTokenExpired = () => {
      const promise = (async () => {
        await keycloak.updateToken(30);
        tokenBeingRefreshed.current = undefined;
      })();
      tokenBeingRefreshed.current = promise;
    };
    return () => {
      keycloak.onTokenExpired = oldTokenExpired;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const authentication = useMemo(() => {
    return {
      user,
      setUser,
      updateToken: async () => {
        const promise = (async () => {
          await keycloak.updateToken(30);
          tokenBeingRefreshed.current = undefined;
        })();
        tokenBeingRefreshed.current = promise;
        await promise;
        return true;
      },
      updateUserProfile: () => {
        const tokenPayload = getTokenPayloadFromToken(keycloak.token);
        setUser(
          tokenPayload
            ? {
                userId: tokenPayload.sub,
                firstName: tokenPayload.given_name,
                lastName: tokenPayload.family_name,
                email: tokenPayload.email ?? '',
                username: tokenPayload.name,
              }
            : undefined
        );
        return true;
      },
      logout: async () => {
        await clientStoredResources.clearAll();
        await keycloak.logout({ redirectUri }).then(() => setUser(undefined));
      },
      getToken: () => Promise.resolve(keycloak.getTokenImmediate()),
      getTokenImmediate: () => keycloak.getTokenImmediate(),
      testMode,
      setTestMode,
      addTokenListener,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirectUri, user, testMode, keycloak]);

  return (
    <AuthenticationContext.Provider value={authentication}>
      {children}
    </AuthenticationContext.Provider>
  );
};
