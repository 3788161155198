import type {
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { useMutation, useQueryClient } from 'react-query';
import { createContext, useCallback } from 'react';
import {
  ClientError,
  makeApiProvider,
  useApiQuery,
} from '@pflegenavi/shared-frontend/platform';
import type {
  IReceiptBatchPhoenixApi,
  ReceiptBatch,
  ReceiptBatchResult,
  UpdateReceiptBatch,
  UpdateReceiptBatchEntry,
} from './api';
import { ReceiptBatchPhoenixApi } from './api';
import {
  CREATE_RECEIPT_BATCH,
  CREATE_RECEIPT_BATCH_ENTRY,
  DELETE_RECEIPT_BATCH,
  DELETE_RECEIPT_BATCH_ENTRY_KEY,
  RECEIPT_BATCH_KEY,
  RECEIPT_BATCH_LIST_KEY,
  RECEIPT_BATCH_LIST_PAGINATED_KEY,
  RECEIPTS_AND_RECEIPT_BATCHES_KEY,
  RECEIPTS_KEY_NURSING_HOME,
  UPDATE_RECEIPT_BATCH,
  UPDATE_RECEIPT_BATCH_ENTRY,
} from '../transaction/queryKeys';
import type {
  ReceiptBatchOwner,
  ReceiptBatchWithExpandedResidentReceiptBatchEntriesDto,
} from '@pflegenavi/shared/api';
import { RECEIPT_BATCH_PAYMENT_STATUS } from '../payment';
import { useTranslation } from 'react-i18next';
import { CASH_TRANSACTION_GROUP_KEY } from '../cash-management/queryKeys';

const ApiContext = createContext<IReceiptBatchPhoenixApi | undefined>(
  undefined
);
const {
  useApi: useReceiptBatchPhoenixApi,
  ApiProvider: ReceiptBatchPhoenixApiProvider,
} = makeApiProvider({
  name: 'ReceiptBatchPhoenix',
  ApiContext,
  newApi: (tenantId, auth, apiUrl) => {
    return new ReceiptBatchPhoenixApi(tenantId, auth, apiUrl);
  },
});

export {
  useReceiptBatchPhoenixApi,
  ReceiptBatchPhoenixApiProvider,
  ApiContext as ReceiptBatchPhoenixApiContext,
  UpdateReceiptBatch,
  ReceiptBatch,
};

export const transformPhoenixReceiptBatchToLegacyReceiptBatch = (
  row: ReceiptBatch
): ReceiptBatchWithExpandedResidentReceiptBatchEntriesDto => {
  return {
    id: row.id,
    createdOn: row.created_on,
    nursingHomeId: row.nursing_home_id,
    receiptDate: row.receipt_date!,
    // TODO
    state: undefined,
    // TODO
    cashListTransactionGroupData: undefined,
    cashListTransactionGroupId: row.cash_list_transaction_group_id,
    receiptImageIds: row.receipt_image_ids,
    receiptType: row.receipt_type,
    serviceProviderId: row.service_provider,
    lastUpdatedOn: row.updated_on,
    title: 'Deprecated',
    owner: row.owner as ReceiptBatchOwner,
    // TODO
    submissionDate: undefined,
    // TODO
    receiptTypeName: undefined,
    // TODO
    serviceProviderName: undefined,
    // TODO
    serviceProviderRequired: undefined,
    // TODO
    submissionLocked: undefined,
    useIndividualReceiptDates: row.use_individual_dates,
    receiptBatchEntries: row.receipt_batch_entries.map((entry) => ({
      id: entry.id,
      resident: {
        id: entry.resident.id,
        firstName: entry.resident.firstname,
        lastName: entry.resident.lastname,
        gender: entry.resident.gender,
        state: '', //#TODO
      },
      amount: entry.amount,
      receiptImageIds: entry.receipt_image_ids,
      notes: entry.notes,
      receiptDate: entry.receipt_date,
    })),
  };
};

function selectGetReceiptBatch({ data }: ReceiptBatchResult): ReceiptBatch {
  return {
    ...data,
    receipt_date: data.receipt_date ? new Date(data.receipt_date) : undefined,
    created_on: new Date(data.created_on),
    updated_on: new Date(data.updated_on),
    receipt_batch_entries: data.receipt_batch_entries.map((entry) => ({
      ...entry,
      receipt_date: entry.receipt_date
        ? new Date(entry.receipt_date)
        : undefined,
    })),
  };
}

export const useGetReceiptBatchPhoenix = (
  receiptBatchId: string | undefined,
  options?: UseQueryOptions<
    ReceiptBatchResult,
    unknown,
    ReceiptBatchWithExpandedResidentReceiptBatchEntriesDto
  >
): UseQueryResult<ReceiptBatchWithExpandedResidentReceiptBatchEntriesDto> => {
  const select = useCallback((data: ReceiptBatchResult) => {
    const part1 = selectGetReceiptBatch(data);
    return transformPhoenixReceiptBatchToLegacyReceiptBatch(part1);
  }, []);

  return useApiQuery(
    useReceiptBatchPhoenixApi,
    RECEIPT_BATCH_KEY(receiptBatchId),
    (api) => {
      if (receiptBatchId === undefined) {
        throw new Error('receiptBatchId is undefined');
      }

      return api.getReceiptBatch({
        params: {
          batchId: receiptBatchId,
        },
      });
    },
    {
      select,
      enabled: receiptBatchId !== undefined,
      ...options,
    }
  );
};

export const useCreateReceiptBatchPhoenix = (): UseMutationResult<
  ReceiptBatch,
  unknown,
  Omit<UpdateReceiptBatch, 'id'>
> => {
  const api = useReceiptBatchPhoenixApi();
  const queryClient = useQueryClient();
  const result = useMutation<
    ReceiptBatch,
    unknown,
    Omit<UpdateReceiptBatch, 'id'>
  >(
    CREATE_RECEIPT_BATCH,
    (data) =>
      api
        .postReceiptBatch({ body: data })
        .then((res) => selectGetReceiptBatch(res)),
    {
      onSuccess: async (data) => {
        await Promise.all([
          queryClient.invalidateQueries(
            RECEIPT_BATCH_LIST_KEY(data.nursing_home_id)
          ),
          queryClient.invalidateQueries(
            RECEIPT_BATCH_LIST_PAGINATED_KEY({
              nursingHomeId: data.nursing_home_id,
            })
          ),
          queryClient.invalidateQueries(
            RECEIPTS_AND_RECEIPT_BATCHES_KEY(data.nursing_home_id)
          ),
        ]);
      },
    }
  );
  return result;
};

const useHandleError = (
  errorSnackbar: (error: Error, message: string) => void
) => {
  const { t } = useTranslation();
  return useCallback(
    (e: unknown) => {
      if (e instanceof ClientError && 'errors' in e) {
        // @ts-expect-error costCenter untyped
        if (e.errors?.costCenter?.includes("can't be blank")) {
          errorSnackbar(
            e,
            t(`receipt-batch.preview.error-missing-cost-center`)
          );
        }
        // @ts-expect-error serviceProvider untyped
        else if (e.errors?.serviceProvider?.includes("can't be blank")) {
          errorSnackbar(
            e,
            t(`receipt-batch.preview.error-missing-service-provider`)
          );
        }
        return;
      }

      errorSnackbar(e as Error, t('errors.something-went-wrong'));
    },
    [errorSnackbar, t]
  );
};

export const useGenerateReceiptBatchPreview = (
  errorSnackbar: (error: Error, message: string) => void
): UseMutationResult<
  Blob,
  unknown,
  {
    batchId: string;
  }
> => {
  const api = useReceiptBatchPhoenixApi();

  const handleError = useHandleError(errorSnackbar);

  const result = useMutation<
    Blob,
    unknown,
    {
      batchId: string;
    }
  >(
    'GENERATE_RECEIPT_BATCH_REVIEW',
    (data) =>
      api.postReceiptBatchPreview({
        params: { batchId: data.batchId },
      }),
    {
      onError: handleError,
    }
  );
  return result;
};

export const useGenerateReceiptBatchEntryPreview = (
  errorSnackbar: (error: Error, message: string) => void
): UseMutationResult<
  Blob,
  unknown,
  {
    batchId: string;
    entryId: string;
  }
> => {
  const api = useReceiptBatchPhoenixApi();
  const handleError = useHandleError(errorSnackbar);
  const result = useMutation<
    Blob,
    unknown,
    {
      batchId: string;
      entryId: string;
    }
  >(
    'GENERATE_RECEIPT_BATCH_ENTRY_REVIEW',
    (data) => {
      return api.postReceiptBatchEntryPreview({
        params: {
          batchId: data.batchId,
          entryId: data.entryId,
        },
      });
    },
    {
      onError: handleError,
    }
  );
  return result;
};

export const usePatchReceiptBatchPhoenix = (
  options?: UseMutationOptions<ReceiptBatch, unknown, UpdateReceiptBatch>
): UseMutationResult<ReceiptBatch, unknown, UpdateReceiptBatch> => {
  const queryClient = useQueryClient();
  const api = useReceiptBatchPhoenixApi();
  const result = useMutation<ReceiptBatch, unknown, UpdateReceiptBatch>(
    UPDATE_RECEIPT_BATCH,
    (data) =>
      api
        .patchReceiptBatch({
          params: { batchId: data.id },
          body: data,
        })
        .then(selectGetReceiptBatch),
    {
      onSuccess: (data, variables, optionsIn) => {
        const promise = options?.onSuccess?.(data, variables, optionsIn);
        return Promise.all([
          ...(promise ? [promise] : []),
          queryClient.invalidateQueries(
            RECEIPT_BATCH_LIST_KEY(variables.nursing_home_id)
          ),
          queryClient.invalidateQueries(
            RECEIPT_BATCH_LIST_PAGINATED_KEY({
              nursingHomeId: variables.nursing_home_id,
            })
          ),
          queryClient.invalidateQueries(
            RECEIPTS_AND_RECEIPT_BATCHES_KEY(variables.nursing_home_id)
          ),
          queryClient.invalidateQueries(RECEIPT_BATCH_KEY(data.id)),
          queryClient.invalidateQueries(RECEIPT_BATCH_PAYMENT_STATUS(data.id)),
        ]);
      },
      ...options,
    }
  );
  return result;
};

export const usePostReceiptBatchEntryPhoenix = (): UseMutationResult<
  ReceiptBatchResult,
  unknown,
  { batchId: string; data: UpdateReceiptBatchEntry[] }
> => {
  const queryClient = useQueryClient();
  const api = useReceiptBatchPhoenixApi();
  const result = useMutation<
    ReceiptBatchResult,
    unknown,
    { batchId: string; data: UpdateReceiptBatchEntry[] }
  >(
    CREATE_RECEIPT_BATCH_ENTRY,
    (data) =>
      api.postReceiptBatchEntry({
        params: { batchId: data.batchId },
        body: { data: data.data },
      }),
    {
      onSuccess: async (data, variables) => {
        await Promise.all([
          queryClient.invalidateQueries(RECEIPT_BATCH_KEY(variables.batchId)),
          queryClient.invalidateQueries([RECEIPT_BATCH_LIST_KEY(undefined)]),
        ]);
      },
    }
  );
  return result;
};

export const usePatchReceiptBatchEntryPhoenix = (
  nursingHomeId: string,
  options?: UseMutationOptions<
    ReceiptBatchResult,
    unknown,
    UpdateReceiptBatchEntry & {
      id: string;
      batchId: string;
    }
  >
): UseMutationResult<
  ReceiptBatchResult,
  unknown,
  UpdateReceiptBatchEntry & {
    id: string;
    batchId: string;
  }
> => {
  const queryClient = useQueryClient();
  const api = useReceiptBatchPhoenixApi();
  const result = useMutation<
    ReceiptBatchResult,
    unknown,
    UpdateReceiptBatchEntry & {
      id: string;
      batchId: string;
    }
  >(
    UPDATE_RECEIPT_BATCH_ENTRY,
    (data) =>
      api.patchReceiptBatchEntry({
        params: {
          batchId: data.batchId,
          entryId: data.id!,
        },
        body: data,
      }),
    {
      onSuccess: (data, variables, optionsIn) => {
        const promise = options?.onSuccess?.(data, variables, optionsIn);
        return Promise.all([
          ...(promise ? [promise] : []),
          queryClient.invalidateQueries(RECEIPT_BATCH_LIST_KEY(nursingHomeId)),
          queryClient.invalidateQueries(
            RECEIPT_BATCH_LIST_PAGINATED_KEY({
              nursingHomeId: nursingHomeId,
            })
          ),
          queryClient.invalidateQueries(
            RECEIPTS_AND_RECEIPT_BATCHES_KEY(nursingHomeId)
          ),
          queryClient.invalidateQueries(RECEIPT_BATCH_KEY(variables.batchId)),
          queryClient.invalidateQueries(
            RECEIPT_BATCH_PAYMENT_STATUS(variables.batchId)
          ),
        ]);
      },
      ...options,
    }
  );
  return result;
};

export const useDeleteReceiptBatchPhoenix = (
  nursingHomeId?: string
): UseMutationResult<
  { status: 'OK' },
  unknown,
  {
    batchId: string;
  }
> => {
  const queryClient = useQueryClient();
  const api = useReceiptBatchPhoenixApi();
  const result = useMutation<
    { status: 'OK' },
    unknown,
    {
      batchId: string;
    }
  >(
    DELETE_RECEIPT_BATCH,
    (data) =>
      api.deleteReceiptBatch({
        params: { batchId: data.batchId },
      }),
    {
      onSuccess: async () => {
        await Promise.all([
          queryClient.invalidateQueries(RECEIPT_BATCH_LIST_KEY(nursingHomeId)),
          queryClient.invalidateQueries(
            RECEIPT_BATCH_LIST_PAGINATED_KEY({ nursingHomeId })
          ),
          queryClient.invalidateQueries(
            RECEIPTS_AND_RECEIPT_BATCHES_KEY(nursingHomeId)
          ),
        ]);
      },
    }
  );
  return result;
};

export const useDeleteReceiptBatchEntryPhoenix = ({
  nursingHomeId,
}: {
  nursingHomeId: string;
}): UseMutationResult<
  ReceiptBatchResult,
  unknown,
  {
    batchId: string;
    entryId: string;
  }
> => {
  const api = useReceiptBatchPhoenixApi();
  const queryClient = useQueryClient();
  const result = useMutation<
    ReceiptBatchResult,
    unknown,
    {
      batchId: string;
      entryId: string;
    }
  >(
    DELETE_RECEIPT_BATCH_ENTRY_KEY,
    (data) =>
      api.deleteReceiptBatchEntry({
        params: { batchId: data.batchId, entryId: data.entryId },
      }),
    {
      onSuccess: async (_, variables) => {
        await Promise.all([
          queryClient.invalidateQueries(RECEIPT_BATCH_KEY(variables.batchId)),
          queryClient.invalidateQueries(
            RECEIPT_BATCH_PAYMENT_STATUS(variables.batchId)
          ),
          queryClient.invalidateQueries(RECEIPT_BATCH_LIST_KEY(nursingHomeId)),
          queryClient.invalidateQueries(
            RECEIPT_BATCH_LIST_PAGINATED_KEY({
              nursingHomeId: nursingHomeId,
            })
          ),
          queryClient.invalidateQueries(
            RECEIPTS_AND_RECEIPT_BATCHES_KEY(nursingHomeId)
          ),
        ]);
      },
    }
  );
  return result;
};

export const useSubmitReceiptBatchPhoenix = ({
  nursingHomeId,
}: {
  nursingHomeId?: string;
}): UseMutationResult<
  void,
  unknown,
  {
    batchId: string;
    cashTransactionGroupId?: string;
  }
> => {
  const api = useReceiptBatchPhoenixApi();
  const queryClient = useQueryClient();
  const result = useMutation<
    void,
    unknown,
    {
      batchId: string;
      cashTransactionGroupId?: string;
    }
  >(
    'SUBMIT_RECEIPT_BATCH',
    (data) =>
      api.submitReceiptBatch({
        params: { batchId: data.batchId },
      }),
    {
      onSuccess: async (_, variables) => {
        await Promise.all([
          queryClient.invalidateQueries(RECEIPT_BATCH_LIST_KEY(nursingHomeId)),
          queryClient.invalidateQueries(
            RECEIPT_BATCH_LIST_PAGINATED_KEY({
              nursingHomeId: nursingHomeId,
            })
          ),
          queryClient.invalidateQueries(
            RECEIPTS_KEY_NURSING_HOME(nursingHomeId, {})
          ),

          queryClient.invalidateQueries(RECEIPT_BATCH_KEY(variables.batchId)),
          queryClient.invalidateQueries(
            RECEIPT_BATCH_PAYMENT_STATUS(variables.batchId)
          ),
          queryClient.invalidateQueries(
            RECEIPTS_AND_RECEIPT_BATCHES_KEY(nursingHomeId)
          ),
          queryClient.invalidateQueries(
            CASH_TRANSACTION_GROUP_KEY(variables.cashTransactionGroupId ?? '')
          ),
        ]);
      },
    }
  );
  return result;
};
