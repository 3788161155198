import type { RangeDateFilter } from '@pflegenavi/shared/utils';
import type { QueryClient, QueryKey } from 'react-query';

export const ALL_RESIDENT_BALANCE_KEY = (nursingHomeId?: string): QueryKey => [
  'all-resident-balance',
  nursingHomeId,
];

export const REPORTING_KEY = (
  nursingHomeId: string | undefined,
  dateRange: RangeDateFilter<Date>
): QueryKey => ['reporting', nursingHomeId, { dateRange }];

export const SUMMARY_REPORT_KEY = (
  nursingHomeId: string | undefined,
  dateRange: RangeDateFilter<Date>
): QueryKey => ['summary-report', nursingHomeId, { dateRange }];

export const invalidateAllResidentBalance = async (
  client: QueryClient,
  nursingHomeId?: string
): Promise<void> => {
  await client.invalidateQueries(ALL_RESIDENT_BALANCE_KEY(nursingHomeId));
};
