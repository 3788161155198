import { useEffect, useRef } from 'react';
import {
  createNewFamilyMember,
  type ImportFamilyMemberFormValues,
} from '../components/ImportFamilyMembersTable';
import { useFormikContext } from 'formik';

interface UseFMImportTableNavigationProps {
  index: number;
}

export const useFMImportTableNavigation = ({
  index,
}: UseFMImportTableNavigationProps): {
  lastInputRef: React.RefObject<HTMLInputElement>;
} => {
  const {
    values: { familyMembers },
    setFieldValue,
  } = useFormikContext<ImportFamilyMemberFormValues>();

  const lastInputRef = useRef<HTMLInputElement>(null);

  // handle Tab
  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (
        event.key === 'Tab' &&
        !event.shiftKey &&
        event.target === lastInputRef.current
      ) {
        if (index === familyMembers.length - 1 && familyMembers.length < 50) {
          event.preventDefault();
          setFieldValue('familyMembers', [
            ...familyMembers,
            createNewFamilyMember(),
          ]);
        }
      }
    }

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [lastInputRef, familyMembers, setFieldValue, index]);

  return {
    lastInputRef,
  };
};
