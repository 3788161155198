import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useState } from 'react';
import axios from 'axios';
import * as Sentry from '@sentry/react';

export interface UploadState {
  progress: number;
  error?: Error;
  finished: boolean;
  receiptImageId?: string;
}

export type UploadFile = (
  image: File,
  fileType?: string,
  useFetch?: boolean
) => Promise<string | undefined>;

interface UseUploadReceiptImageResult {
  upload: UploadFile;
  uploadState: UploadState | undefined;
}

interface UseUploadReceiptImageProps {
  uuid: () => string;
  computeMd5Hash: (file: File) => Promise<string>;
  getUploadUrl: (
    receiptId: string,
    fileType: string,
    md5Hash: string
  ) => Promise<string | undefined>;
}

function determineFileTypeFromFileName(fileName: string): string | undefined {
  const fileParts = fileName.split('.');

  if (fileParts.length < 2) {
    throw new Error(
      `Unsupported file name ${fileName} - can't determine file type without extension.`
    );
  }

  const extension = fileParts[fileParts.length - 1];

  let determinedFileType: string | undefined;
  switch (extension.toLowerCase()) {
    case 'pjp':
    case 'jpg':
    case 'jpeg':
    case 'pjpeg':
    case 'jfif':
      // These are the file extensions that Windows file explorer shows when image/jpeg is set as file type
      determinedFileType = 'image/jpeg';
      break;
    case 'png':
      // Only .png is image/png
      determinedFileType = 'image/png';
      break;
    case 'pdf':
      determinedFileType = 'application/pdf';
      break;
    default:
      determinedFileType = undefined;
  }

  return determinedFileType;
}

export const useUploadFileBase = ({
  uuid,
  getUploadUrl,
  setUploadState,
  computeMd5Hash,
}: UseUploadReceiptImageProps & {
  setUploadState?: Dispatch<SetStateAction<UploadState | undefined>>;
}): UseUploadReceiptImageResult['upload'] => {
  return useCallback(
    async (
      file: File,
      fileType?: string,
      useFetch?: boolean
    ): Promise<string | undefined> => {
      try {
        if (!fileType) {
          fileType = determineFileTypeFromFileName(file.name);
        }

        if (!fileType) {
          throw new Error(
            'File type not specified and could not be determined'
          );
        }

        const receiptImageId = uuid();
        setUploadState?.({
          receiptImageId,
          finished: false,
          error: undefined,
          progress: 0,
        });

        const md5Hash = await computeMd5Hash(file);
        const uploadUrl = await getUploadUrl(
          receiptImageId,
          fileType,
          md5Hash
        ).then((url) => url);

        if (!uploadUrl) {
          setUploadState?.((oldState) => ({
            progress: 0,
            receiptImageId,
            finished: false,
            ...(oldState ?? {}),
            error: new Error('Failed to retrieve upload url'),
          }));
          if (!setUploadState) {
            throw Error('Failed to retrieve upload url');
          }
          return undefined;
        }

        const headers = {
          'content-type': fileType,
          'content-length': file.size.toString(),
          'content-disposition': `attachment; filename="${file.name}"`,
          'content-md5': md5Hash,
        };

        if (useFetch) {
          await fetch(uploadUrl, {
            method: 'PUT',
            headers: new Headers(headers),
            body: file,
          });
        } else {
          await axios.put(uploadUrl, file, {
            headers,
            onUploadProgress: (data) => {
              const progress = data.loaded / data.total;
              setUploadState?.((oldState) => ({
                receiptImageId,
                finished: false,
                error: undefined,
                ...oldState,
                progress,
              }));
            },
          });
        }

        setUploadState?.((oldState) => ({
          receiptImageId,
          progress: 1,
          ...oldState,
          finished: true,
          error: undefined,
        }));
        return receiptImageId;
      } catch (e) {
        Sentry.captureException(e);
        setUploadState?.((oldState) => ({
          receiptImageId: undefined,
          progress: 1,
          ...oldState,
          finished: false,
          error: e as Error,
        }));
        if (!setUploadState) {
          throw e;
        }
        return undefined;
      }
    },
    [uuid, setUploadState, computeMd5Hash, getUploadUrl]
  );
};

export const useUploadFile = ({
  uuid,
  getUploadUrl,
  computeMd5Hash,
}: UseUploadReceiptImageProps): UseUploadReceiptImageResult => {
  const [uploadState, setUploadState] = useState<UploadState | undefined>(
    undefined
  );

  const upload = useUploadFileBase({
    uuid,
    getUploadUrl,
    setUploadState,
    computeMd5Hash,
  });

  return {
    upload,
    uploadState,
  };
};
