import type { FC } from 'react';
import React, { useMemo } from 'react';
import { Box, Button, Divider, Stack, Tooltip } from '@mui/material';
import { TransferConfirmation } from './TransferConfirmation';
import { Header } from './Header';
import { CashListColumn } from './CashListColumn';
import type { CashListState } from './TransferFormStoreProvider';
import {
  TransferFormStoreProvider,
  useTransferFormStore,
} from './TransferFormStoreProvider';
import { useTranslation } from 'react-i18next';
import { sumCashStateInCents } from '../../euro';
import { useShallow } from 'zustand/react/shallow';

interface TransferFormProps {
  step: 1 | 2;
  onNextStep: () => void;
  onPrevStep: () => void;
  onCancel: () => void;
}

/**
 * This form is used to create a new transfer.
 *
 * It is used inside ChangeCashForm and replaces the content of ChangeCashForm. ChangeCashForm
 * contains state that is only relevant for deposits/withdrawal using a single cash list. This
 * form supports multiple cash lists.
 */
export const TransferForm = (props: TransferFormProps): JSX.Element => {
  return (
    <TransferFormStoreProvider>
      <Stack gap={2} flex={1}>
        <Stack sx={{ overflowY: 'auto', scrollbarGutter: 'stable' }}>
          <Header readonly={props.step === 2} />
        </Stack>
        <Divider sx={{ borderStyle: 'dashed' }} />
        {props.step === 1 && (
          <Stack sx={{ overflow: 'scroll' }}>
            <Stack maxHeight={500}>
              <Stack direction={'row'} gap={0}>
                <CashListColumn bucket={'from'} />
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  width={60}
                >
                  <Divider flexItem orientation="vertical" variant="middle" />
                </Box>
                <CashListColumn bucket={'to'} />
              </Stack>
            </Stack>
          </Stack>
        )}
        {props.step === 2 && <TransferConfirmation onCancel={props.onCancel} />}
        {props.step === 1 && (
          <NextStepButton
            onCancel={props.onCancel}
            onNextStep={props.onNextStep}
          />
        )}
      </Stack>
    </TransferFormStoreProvider>
  );
};

function sumCashListState(bucket: CashListState): number {
  if (bucket.isLoading) {
    return 0;
  }
  return sumCashStateInCents(bucket.cashState) + (bucket.bankAmount ?? 0);
}

interface NewTransferFormProps {
  onCancel: () => void;
  onNextStep: () => void;
}
const NextStepButton: FC<NewTransferFormProps> = ({ onNextStep, onCancel }) => {
  const { t } = useTranslation();

  const [fromState, toState, targetAmountState] = useTransferFormStore(
    useShallow((state) => [state.from, state.to, state.targetAmount])
  );
  const fromStateAmount = sumCashListState(fromState);
  const toStateAmount = sumCashListState(toState);

  const emptyTargetAmount = targetAmountState === 0;
  const mismatchAmount = fromStateAmount !== toStateAmount;

  const canContinue = fromStateAmount !== 0 && !mismatchAmount;

  const confirmButtonTooltipTitle = useMemo(() => {
    if (emptyTargetAmount) {
      return t(
        'cashManagement.transfer-confirm-button-tooltip-empty-target-amount'
      );
    }
    if (mismatchAmount) {
      return t(
        'cashManagement.transfer-confirm-button-tooltip-amount-mismatch'
      );
    }
    return undefined;
  }, [emptyTargetAmount, mismatchAmount, t]);

  return (
    <Stack direction="row" justifyContent="flex-end" gap={1}>
      <Button variant="outlined" onClick={onCancel}>
        {t('actions.cancel')}
      </Button>
      <Tooltip title={confirmButtonTooltipTitle} arrow>
        <div>
          <Button
            data-cy="cash-mgmt-button"
            variant="contained"
            onClick={onNextStep}
            disabled={!canContinue}
          >
            {t('action.confirm')}
          </Button>
        </div>
      </Tooltip>
    </Stack>
  );
};
