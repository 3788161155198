import * as fc from 'fast-check';
import type { GetTransactionsWithReceiptDto } from '@pflegenavi/shared/api';
import {
  TransactionInitiatedBy,
  TransactionSourceType,
  TransactionType,
} from '@pflegenavi/shared/api';

interface TransactionWithReceiptArbOpts {
  id?: fc.Arbitrary<string>;
  date?: fc.Arbitrary<Date>;
  sourceType?: fc.Arbitrary<TransactionSourceType>;
  cancelled?: fc.Arbitrary<boolean>;
  receiptType?: fc.Arbitrary<string>;
  amount?: fc.Arbitrary<number>;
  receiptCreatedOn?: fc.Arbitrary<Date | undefined>;
  resident?: fc.Arbitrary<{ id: string; firstName: string; lastName: string }>;
  serviceProvider?: fc.Arbitrary<string>;
}

const transactionSourceTypeArb = fc.constantFrom(
  TransactionSourceType.Receipt,
  TransactionSourceType.CancelledReceipt,
  TransactionSourceType.RecurringItem,
  TransactionSourceType.Dispute,
  TransactionSourceType.ManualDeposit,
  TransactionSourceType.DirectDebit,
  TransactionSourceType.InitialDeposit,
  TransactionSourceType.FinalSettlement
);

export const receiptSourceTypeArb = fc.constantFrom(
  TransactionSourceType.Receipt,
  TransactionSourceType.CancelledReceipt,
  TransactionSourceType.RecurringItem
);

export const depositSourceTypeArb = fc.constantFrom(
  TransactionSourceType.Dispute,
  TransactionSourceType.ManualDeposit,
  TransactionSourceType.DirectDebit,
  TransactionSourceType.InitialDeposit,
  TransactionSourceType.FinalSettlement
);

export const receiptTypeArb = fc.constantFrom(
  'Friseur',
  'Apotheke',
  'Pediküre',
  'Maniküre',
  'Supermarkt',
  'Andere',
  'Barauslage'
);

const serviceProviderArb = fc
  .tuple(
    fc.constantFrom('Herr', 'Frau', ''),
    fc.constantFrom('Haar', 'Markt', 'Kür'),
    fc.constantFrom(' von', ''),
    fc.constantFrom('Graf', 'Hausen', 'Klein'),
    fc.constantFrom('mit mehr details hier', '')
  )
  .map(([title, service, preposition, lastname, detail]) =>
    `${title} ${service}${preposition} ${lastname} ${detail}`.trim()
  );

export const makeTransactionWithReceiptArb = (
  opts: TransactionWithReceiptArbOpts
): fc.Arbitrary<GetTransactionsWithReceiptDto> => {
  const result = fc
    .record({
      sourceType: opts.sourceType ?? transactionSourceTypeArb,
      receiptType: opts?.receiptType ?? receiptTypeArb,
    })

    .chain(({ sourceType, receiptType }) => {
      return fc.record<GetTransactionsWithReceiptDto>({
        date: opts.date ?? fc.date(),
        receiptId: fc.uuid(),
        familyMemberId: fc.uuid(),
        sourceType: fc.constant(sourceType),
        amount: opts?.amount ?? fc.integer(),
        amountInCent: opts?.amount ?? fc.integer(),
        disputed: fc.constant(false),
        type: fc.constant(TransactionType.WITHDRAWAL),
        cancelled: opts.cancelled ?? fc.boolean(),
        creatorName: fc.string(),
        id: opts?.id ?? fc.uuid(),
        receipt_not_available: fc.boolean(),
        receiptType: fc.constant(receiptType),
        initiatedBy: fc.constant(TransactionInitiatedBy.System),
        serviceProvider:
          receiptType === 'Barauslage'
            ? fc.constant('')
            : opts?.serviceProvider ?? serviceProviderArb,
        notes: fc.asciiString(),
        recurringItem:
          sourceType === TransactionSourceType.RecurringItem
            ? fc.record({
                id: fc.uuid(),
                name: fc.asciiString(),
              })
            : fc.constant(undefined),
        receiptCreatedOn: opts?.receiptCreatedOn ?? fc.date(),
        resident:
          opts?.resident ??
          fc.record({
            id: fc.uuid(),
            firstName: fc.asciiString(),
            lastName: fc.asciiString(),
          }),
        sequenceID: fc.string(),
      });
    });

  return result;
};
