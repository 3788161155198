import type { Api } from '@pflegenavi/shared-frontend/platform';
import {
  get,
  getPhoenixApiTenantUrl,
} from '@pflegenavi/shared-frontend/platform';
import type { Tenant } from '@pflegenavi/frontend/tenant';
import type { AuthenticationContext } from '@pflegenavi/frontend/authentication';
import type { Gender, PendingChargeStatus } from '@pflegenavi/shared/api';

export interface FinanceResult {
  data: {
    nursing_home_id: string;
    total_resident_balance: number;
    mangopay: {
      wallet: number;
      payins: number;
      payouts: number;
    };
    bank: {
      total: number;
    };
    cash: {
      total: number;
    };
    receipts: {
      draft: number;
    };
  };
}

export interface GetPayoutsResult {
  data: Array<{
    id: string;
    inserted_at: Date;
    transferred: Date | undefined;
    statement_descriptor: string;
    iban_last_4: string;
    amount: number;
    currency: string;
    state: 'CREATED' | 'SUCCEEDED' | 'FAILED';
  }>;
  meta: {
    total_count: number;
    total_pages: number;
  };
}

export interface GetPayoutsParams {
  params: {
    nursing_home_id: string;
    page: number;
    pageSize: number;
    state: 'CREATED' | 'SUCCEEDED' | 'FAILED' | undefined;
  };
}

export interface GetPayinsResult {
  data: Array<{
    id: string;
    amount: number;
    action_timestamp: Date;
    pending_charge_status: Omit<
      PendingChargeStatus,
      | PendingChargeStatus.initializing
      | PendingChargeStatus.accountMissing
      | PendingChargeStatus.dataMissing
    >;
    employee: {
      id: string;
      first_name: string;
      last_name: string;
    };
    resident: {
      id: string;
      firstname: string;
      lastname: string;
      gender: Gender;
    };
  }>;
  meta: {
    total_count: number;
    total_pages: number;
  };
}

export interface GetPayinsParams {
  params: {
    nursing_home_id: string;
    page: number;
    pageSize: number;
    pending_charge_status: Array<
      'Success' | 'Failed' | 'Frozen' | 'Upcoming' | 'Pending'
    >;
    dateFrom?: Date;
    dateTo?: Date;
  };
}

export interface IReportingPhoenixApi extends Api {
  getFinance(dataIn: {
    params: {
      nursing_home_id: string;
    };
  }): Promise<FinanceResult>;
  getPayouts(params: GetPayoutsParams): Promise<GetPayoutsResult>;
  getPayins(params: GetPayinsParams): Promise<GetPayinsResult>;
}

export class ReportingPhoenixApi implements IReportingPhoenixApi {
  baseUrl: string;
  headers: Headers;

  getFinance: IReportingPhoenixApi['getFinance'];
  getPayouts: IReportingPhoenixApi['getPayouts'];
  getPayins: IReportingPhoenixApi['getPayins'];

  constructor(
    tenantId: Tenant,
    public authContext: AuthenticationContext,
    apiUrl?: string
  ) {
    this.headers = new Headers({
      'content-type': 'application/json',
      'x-pn-stripemode': 'live',
    });

    this.baseUrl = getPhoenixApiTenantUrl(tenantId, apiUrl);

    this.getFinance = get({
      authContext,
      url: (params) =>
        `${this.baseUrl}/reporting/nursing-home/${params.nursing_home_id}/finance`,
    });

    this.getPayouts = get({
      authContext,
      headers: this.headers,
      url: (params) => {
        const query = new URLSearchParams();
        query.append('page', (params.page + 1).toString());
        query.append('page_size', params.pageSize.toString());

        if (params.state) {
          query.append(`filters[][field]`, 'mangopay_state');
          query.append(`filters[][op]`, '==');
          query.append(`filters[][value]`, params.state);
        }

        return `${this.baseUrl}/reporting/nursing-home/${
          params.nursing_home_id
        }/payouts?${query.toString()}`;
      },
    });

    this.getPayins = get({
      authContext,
      headers: this.headers,
      url: (params) => {
        const query = new URLSearchParams();
        query.append('page', (params.page + 1).toString());
        query.append('page_size', params.pageSize.toString());

        let i = 0;
        if (params.pending_charge_status) {
          query.append(`filters[${i}][field]`, 'pending_charge_status');
          query.append(`filters[${i}][op]`, 'in');
          for (const state of params.pending_charge_status) {
            query.append(`filters[${i}][value][]`, state);
          }
          i++;
        }

        if (params.dateFrom) {
          query.append(`filters[${i}][field]`, 'action_timestamp');
          query.append(`filters[${i}][op]`, '>=');
          query.append(`filters[${i}][value]`, params.dateFrom.toISOString());
          i++;
        }
        if (params.dateTo) {
          query.append(`filters[${i}][field]`, 'action_timestamp');
          query.append(`filters[${i}][op]`, '<=');
          query.append(`filters[${i}][value]`, params.dateTo.toISOString());
          i++;
        }

        return `${this.baseUrl}/reporting/nursing-home/${
          params.nursing_home_id
        }/payins?${query.toString()}`;
      },
    });
  }
}
