import type { FC } from 'react';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';

export interface CountrySelectorProps {
  disabled?: boolean;
  onChangeCountry: (value: string) => void;
  onBlur?: () => void;
  countries: Array<{
    value: string;
    label: string;
  }>;
  countryId: string | undefined;
  errorMessage?: string;
  name?: string;
  label?: string;
  fullWidth?: boolean;
}

export const CountrySelector: FC<CountrySelectorProps> = ({
  disabled,
  onChangeCountry,
  onBlur,
  countries,
  countryId,
  errorMessage,
  name,
  label,
  fullWidth,
}) => {
  const { t } = useTranslation();

  const [selectedCountry, setSelectedCountry] = useState<string | null>(
    () =>
      countries?.find((country) => country.value === countryId)?.value ?? null
  );

  useEffect(() => {
    const newlySelectedCountry =
      countries.find((country) => country.value === countryId) ?? null;
    if (newlySelectedCountry?.value !== selectedCountry) {
      setSelectedCountry(newlySelectedCountry?.value ?? null);
    }
    // selectedCountry missing to avoid circular dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryId, countries]);

  const selected = useMemo(() => {
    if (!selectedCountry) {
      return null;
    }

    const selectedLabel = countries.find(
      (type) => type.value === selectedCountry
    )?.label;

    return {
      value: selectedCountry,
      label: selectedLabel,
    };
  }, [countries, selectedCountry]);

  const defaultInputValue = selected?.label ?? '';

  const [inputValue, setInputValue] = useState(defaultInputValue);
  useEffect(() => {
    setInputValue(defaultInputValue);
  }, [setInputValue, defaultInputValue]);

  const value = useMemo(() => {
    return (
      selected ?? {
        value: '',
        label: '',
      }
    );
  }, [selected]);

  return (
    <Autocomplete
      fullWidth={fullWidth}
      data-cy="country"
      disabled={disabled}
      freeSolo={true}
      openOnFocus={true}
      value={value}
      clearOnBlur
      onChange={(_, value) => {
        if (typeof value === 'string') {
          return;
        }
        onChangeCountry(value.value);
      }}
      onBlur={onBlur}
      ListboxProps={{
        style: {
          maxHeight: '354px',
        },
      }}
      inputValue={inputValue}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      onInputChange={(_, value) => {
        setInputValue(value);
      }}
      disableClearable={true}
      renderInput={({ ...params }) => (
        <TextField
          name={name}
          label={label ?? t('common.country')}
          type={'text'}
          error={Boolean(errorMessage)}
          helperText={errorMessage}
          {...params}
        />
      )}
      options={countries}
    />
  );
};
