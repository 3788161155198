import type { Coin } from '@pflegenavi/shared/api';
import { CashListStorageType } from '@pflegenavi/shared/api';
import React, {
  createContext,
  type FC,
  type PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { createStore, type StoreApi, useStore } from 'zustand';
import type { CashState } from '../model';
import {
  getOperationalCashList,
  getReserveCashList,
  useCashListConfiguration,
} from '@pflegenavi/frontend/api-nursing-home';
import { isBanknote, isCoin } from '../../euro';

const TransferFormContext = createContext<StoreApi<State>>(undefined as any);
const otherBucket = (bucket: 'from' | 'to') =>
  bucket === 'from' ? 'to' : 'from';
export const TransferFormStoreProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const cashListConfiguration = useCashListConfiguration();

  const [, rerender] = useState({});

  const getCashList = (cashListId: string) => {
    return cashListConfiguration.cashLists.find(
      (cashList) => cashList.id === cashListId
    )!;
  };

  const store = useMemo(
    () => {
      return createStore<State>((set) => {
        const cashListSlice = (bucket: 'from' | 'to') => {
          const setCashListId = (cashListId: string) => {
            set((old) => ({
              [bucket]: {
                loadData: old[bucket].loadData,
                setCashListId: old[bucket].setCashListId,
                cashListId,
                isLoading: true as const,
              },
              // Swap cash list ids
              ...(old[otherBucket(bucket)].cashListId === cashListId
                ? {
                    [otherBucket(bucket)]: {
                      loadData: old[otherBucket(bucket)].loadData,
                      setCashListId: old[otherBucket(bucket)].setCashListId,
                      cashListId: old[bucket].cashListId,
                      isLoading: true as const,
                    },
                  }
                : {}),
            }));
            rerender({});
          };

          const loadData = (data: LoadDataOpts) => {
            set((old) => {
              const selectedStorageType =
                CashListStorageType.Cash &
                getCashList(old[bucket].cashListId).storageType
                  ? CashListStorageType.Cash
                  : CashListStorageType.BankAccount;
              return {
                [bucket]: {
                  ...old[bucket],
                  isLoading: false,
                  selectedStorageType,
                  setSelectedStorageType: (
                    storageType: CashListStorageType
                  ) => {
                    set((old) => ({
                      [bucket]: {
                        ...old[bucket],
                        selectedStorageType: storageType,
                      },
                    }));
                  },
                  cashState: {},
                  setCashState: (cashState: CashState) => {
                    set((old) => ({
                      [bucket]: {
                        ...old[bucket],
                        cashState,
                      },
                    }));
                  },
                  bankAmount:
                    selectedStorageType === CashListStorageType.BankAccount
                      ? old.targetAmount
                      : 0,
                  availableBankAmount: data.bankAmount,
                  availableBankNotes: data.bankNotes,
                  availableCoins: data.coins,
                } satisfies CashListState,
              };
            });
          };

          return {
            cashListId:
              bucket === 'from'
                ? getOperationalCashList(cashListConfiguration).id
                : getReserveCashList(cashListConfiguration).id,
            isLoading: true as const,
            setCashListId,
            loadData,
          };
        };

        return {
          from: cashListSlice('from'),
          to: cashListSlice('to'),
          note: '',
          setNote: (noteOrCb: string | ((note: string) => string)) => {
            set((old) => {
              let newNote = '';
              if (typeof noteOrCb === 'function') {
                newNote = noteOrCb(old.note);
              } else {
                newNote = noteOrCb;
              }
              return {
                note: newNote,
              };
            });
          },
          noteError: false,
          setNoteError: (
            noteErrorOrCb: boolean | ((noteError: boolean) => boolean)
          ) => {
            set((old) => {
              let newNoteError = false;
              if (typeof noteErrorOrCb === 'function') {
                newNoteError = noteErrorOrCb(old.noteError);
              } else {
                newNoteError = noteErrorOrCb;
              }
              return {
                noteError: newNoteError,
              };
            });
          },
          targetAmount: 0,
          setTargetAmount: (
            targetAmountOrCb:
              | number
              | undefined
              | ((old: number | undefined) => number | undefined)
          ) => {
            set((old) => {
              let newTargetAmount = 0;
              if (typeof targetAmountOrCb === 'function') {
                newTargetAmount = targetAmountOrCb(old.targetAmount) ?? 0;
              } else {
                newTargetAmount = targetAmountOrCb ?? 0;
              }

              const shouldUpdateFromBankAmount =
                !old.from.isLoading &&
                old.from.selectedStorageType ===
                  CashListStorageType.BankAccount;
              const shouldUpdateToBankAmount =
                !old.to.isLoading &&
                old.to.selectedStorageType === CashListStorageType.BankAccount;

              return {
                targetAmount: newTargetAmount,
                ...(shouldUpdateFromBankAmount
                  ? {
                      from: {
                        ...old.from,
                        bankAmount: newTargetAmount,
                      },
                    }
                  : {}),
                ...(shouldUpdateToBankAmount
                  ? {
                      to: {
                        ...old.to,
                        bankAmount: newTargetAmount,
                      },
                    }
                  : {}),
              };
            });
          },
        };
      });
    },
    // Only on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const fromData = getCashList(store.getState().from.cashListId);
  const toData = getCashList(store.getState().to.cashListId);

  useEffect(() => {
    const state = store.getState();
    if (state.from.isLoading) {
      state.from.loadData({
        bankAmount: fromData.bankAccountAmountInCent,
        bankNotes: fromData.coins.filter((coin) => isBanknote(coin.factor)),
        coins: fromData.coins.filter((coin) => isCoin(coin.factor)),
      });
    }
  }, [fromData, store]);

  useEffect(() => {
    const state = store.getState();
    if (state.to.isLoading) {
      state.to.loadData({
        bankAmount: toData.bankAccountAmountInCent,
        bankNotes: toData.coins.filter((coin) => isBanknote(coin.factor)),
        coins: toData.coins.filter((coin) => isCoin(coin.factor)),
      });
    }
  }, [toData, store]);

  return (
    <TransferFormContext.Provider value={store}>
      {children}
    </TransferFormContext.Provider>
  );
};

interface LoadDataOpts {
  bankNotes: Coin[];
  coins: Coin[];
  bankAmount: number;
}

export type CashListState = {
  setCashListId: (cashListId: string) => void;
  loadData: (data: LoadDataOpts) => void;
} & (
  | {
      cashListId: string;
      isLoading: true;
    }
  | {
      cashListId: string;
      isLoading: false;
      /**
       * The selected cash
       */
      cashState: CashState;
      setCashState: (cashState: CashState) => void;
      /**
       * The selected bank amount
       */
      bankAmount: number;
      // The bank amount will always equal the target amount
      //setBankAmount: (bankAmount: number) => void;
      availableBankNotes: Coin[];
      availableCoins: Coin[];
      availableBankAmount: number;

      selectedStorageType: CashListStorageType;
      setSelectedStorageType: (storageType: CashListStorageType) => void;
    }
);

interface State {
  from: CashListState;
  to: CashListState;
  targetAmount: number;
  setTargetAmount: React.Dispatch<React.SetStateAction<number | undefined>>;

  note: string;
  setNote: React.Dispatch<React.SetStateAction<string>>;
  noteError: boolean;
  setNoteError: React.Dispatch<React.SetStateAction<boolean>>;
}

export function useTransferFormStore<T>(selector: (state: State) => T): T {
  const store = useContext(TransferFormContext);
  return useStore(store, selector);
}
