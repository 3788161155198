import { useShallow } from 'zustand/react/shallow';
import { useCashListConfiguration } from '@pflegenavi/frontend/api-nursing-home';
import { Stack } from '@mui/material';
import { CashListStorageType } from '@pflegenavi/shared/api';
import { ModeChanger } from './ModeChanger';
import { ChangeCashMode } from '../model';
import type { FC } from 'react';
import React from 'react';

import { useTransferFormStore } from './TransferFormStoreProvider';
import { CustomQuickSelection } from './CustomQuickSelection';
import { CustomBankContent } from './CustomBankContent';

interface CashListColumnProps {
  bucket: 'from' | 'to';
}

export const CashListColumn: FC<CashListColumnProps> = (props) => {
  const [bucketState, targetAmount, setTargetAmount] = useTransferFormStore(
    useShallow((state) => [
      state[props.bucket],
      state.targetAmount,
      state.setTargetAmount,
    ])
  );
  const cashList = useCashListConfiguration().cashLists.find(
    (cashList) => cashList.id === bucketState.cashListId
  )!;

  return (
    <Stack sx={{ flex: 1 }} gap={1}>
      <Stack direction="row" gap={2}>
        {!bucketState.isLoading &&
        cashList.storageType === CashListStorageType.CashOrBankAccount ? (
          <ModeChanger
            mode={bucketState.selectedStorageType}
            setMode={bucketState.setSelectedStorageType}
          />
        ) : undefined}
      </Stack>

      {bucketState.isLoading && 'Loading...'}

      {!bucketState.isLoading && (
        <div>
          {bucketState.selectedStorageType === CashListStorageType.Cash ? (
            <CustomQuickSelection
              mode={
                props.bucket === 'from'
                  ? ChangeCashMode.Withdraw
                  : ChangeCashMode.Deposit
              }
              cashState={bucketState.cashState}
              setCashState={(cashState) => {
                if (typeof cashState === 'function') {
                  bucketState.setCashState(cashState(bucketState.cashState));
                } else {
                  bucketState.setCashState(cashState);
                }
              }}
              coins={bucketState.availableCoins}
              bankNotes={bucketState.availableBankNotes}
              targetAmount={targetAmount}
              setTargetAmount={setTargetAmount}
            />
          ) : undefined}
          {bucketState.selectedStorageType ===
            CashListStorageType.BankAccount && (
            <CustomBankContent
              bankAccountChange={targetAmount}
              totalBankBalance={bucketState.availableBankAmount}
              mode={
                props.bucket === 'from'
                  ? ChangeCashMode.Withdraw
                  : ChangeCashMode.Deposit
              }
            />
          )}
        </div>
      )}
    </Stack>
  );
};
