interface Resident {
  id: string;
  name: string;
  firstName: string;
  lastName: string;
}

export interface State {
  searchTerm: string;
  nonSelected: Resident[];
  staged: Resident[];
  selected: Resident[];
  stagedForDeselection: Resident[];
  filteredStagedForDeselection: Resident[];
  filteredNonSelected: Resident[];
  filteredSelected: Resident[];
  receiptAmount?: number;
  date?: Date;
  isSplitAmountEnabled: boolean;
  sharedNote: string;
}

export interface SetDateAction {
  type: 'SET_DATE';
  payload?: Date;
}

export interface UpdateResidentsAction {
  type: 'UPDATE_RESIDENTS';
  payload: Resident[];
}

export interface SetSearchTermAction {
  type: 'SET_SEARCH_TERM';
  payload: string;
}

export interface StageResidentAction {
  type: 'STAGE_RESIDENT';
  payload: Resident;
}

export interface StageAllNonSelectedAction {
  type: 'STAGE_ALL_NONSELECTED';
}

export interface UnstageResidentAction {
  type: 'UNSTAGE_RESIDENT';
  payload: Resident;
}

export interface UnstageAllAction {
  type: 'UNSTAGE_ALL';
}

export interface MoveStagedToSelectedAction {
  type: 'MOVE_STAGED_TO_SELECTED';
}

export interface StageForDeselectionAction {
  type: 'STAGE_FOR_DESELECTION';
  payload: Resident;
}

export interface UnstageForDeselectionAction {
  type: 'UNSTAGE_FOR_DESELECTION';
  payload: Resident;
}

export interface MoveStagedForDeselectionToNonSelectedAction {
  type: 'MOVE_STAGED_FOR_DESELECTION_TO_NON_SELECTED';
}

export interface SetReceiptAmountAction {
  type: 'SET_RECEIPT_AMOUNT';
  payload: number;
}

export interface ResetReceiptAmountAction {
  type: 'RESET_RECEIPT_AMOUNT';
}

export interface SetSplitAmountEnabledAction {
  type: 'SET_SPLIT_AMOUNT_ENABLED';
  payload: boolean;
}

export interface SetSharedNoteAction {
  type: 'SET_SHARED_NOTE';
  payload: string;
}

type Action =
  | SetDateAction
  | UpdateResidentsAction
  | SetSearchTermAction
  | StageResidentAction
  | StageAllNonSelectedAction
  | UnstageResidentAction
  | UnstageAllAction
  | MoveStagedToSelectedAction
  | StageForDeselectionAction
  | UnstageForDeselectionAction
  | MoveStagedForDeselectionToNonSelectedAction
  | SetReceiptAmountAction
  | ResetReceiptAmountAction
  | SetSplitAmountEnabledAction
  | SetSharedNoteAction;

export function quickResidentsSelectionReducer(
  state: State,
  action: Action
): State {
  switch (action.type) {
    case 'SET_DATE': {
      return {
        ...state,
        date: action.payload,
      };
    }
    case 'UPDATE_RESIDENTS': {
      const nonSelected = action.payload.filter(
        (resident) => !state.selected.includes(resident)
      );

      const filteredNonSelected = nonSelected.filter((resident) =>
        resident.name.toLowerCase().includes(state.searchTerm)
      );
      const staged = state.staged.filter((resident) =>
        action.payload.includes(resident)
      );
      const selected = state.selected.filter((resident) =>
        action.payload.includes(resident)
      );
      const stagedForDeselection = state.stagedForDeselection.filter(
        (resident) => action.payload.includes(resident)
      );
      const filteredStagedForDeselection =
        state.filteredStagedForDeselection.filter((resident) =>
          action.payload.includes(resident)
        );
      const filteredSelected = state.filteredSelected.filter((resident) =>
        action.payload.includes(resident)
      );

      return {
        ...state,
        staged,
        nonSelected,
        filteredNonSelected,
        selected,
        stagedForDeselection,
        filteredStagedForDeselection,
        filteredSelected,
      };
    }
    case 'SET_SEARCH_TERM': {
      const searchTerm = action.payload.toLowerCase();

      const staged = state.staged;
      const filteredNonSelected = state.nonSelected.filter(
        (resident) =>
          resident.name.toLowerCase().includes(searchTerm) &&
          !staged.map((resident) => resident.name).includes(resident.name)
      );

      const filteredSelected = state.selected.filter((resident) =>
        resident.name.toLowerCase().includes(searchTerm)
      );

      const filteredStagedForDeselection = state.stagedForDeselection.filter(
        (resident) => resident.name.toLowerCase().includes(searchTerm)
      );

      return {
        ...state,
        searchTerm,
        filteredStagedForDeselection,
        filteredNonSelected:
          searchTerm === ''
            ? state.nonSelected
            : [...filteredNonSelected, ...staged],
        filteredSelected,
      };
    }
    case 'STAGE_RESIDENT': {
      const staged = [...state.staged, action.payload];

      return {
        ...state,
        staged,
      };
    }

    case 'STAGE_ALL_NONSELECTED': {
      const staged = state.nonSelected;
      return {
        ...state,
        staged,
      };
    }
    case 'UNSTAGE_RESIDENT': {
      const staged = state.staged.filter(
        (resident) => resident.id !== action.payload.id
      );

      return {
        ...state,
        staged,
      };
    }

    case 'UNSTAGE_ALL': {
      const nonSelected = state.staged;
      const filteredNonSelected = nonSelected.filter((resident) =>
        resident.name.toLowerCase().includes(state.searchTerm)
      );
      return {
        ...state,
        staged: [],
        nonSelected,
        filteredNonSelected,
      };
    }

    case 'MOVE_STAGED_TO_SELECTED': {
      const newSelected = [...state.selected, ...state.staged];
      const newFilteredSelected = [...state.filteredSelected, ...state.staged];

      const newNonSelected = state.nonSelected.filter(
        (resident) =>
          !state.staged.some(
            (stagedResident) => resident.id === stagedResident.id
          )
      );
      const newFilteredNonSelected = state.filteredNonSelected.filter(
        (resident) =>
          !state.staged.some(
            (stagedResident) => resident.id === stagedResident.id
          )
      );

      return {
        ...state,
        selected: newSelected,
        filteredSelected: newFilteredSelected,
        nonSelected: newNonSelected,
        filteredNonSelected: newFilteredNonSelected,
        staged: [],
      };
    }

    case 'STAGE_FOR_DESELECTION': {
      const newStagedForDeselection = [
        ...state.stagedForDeselection,
        action.payload,
      ];
      const filteredStagedForDeselection = newStagedForDeselection.filter(
        (resident) => resident.name.toLowerCase().includes(state.searchTerm)
      );
      return {
        ...state,
        stagedForDeselection: newStagedForDeselection,
        filteredStagedForDeselection,
      };
    }

    case 'UNSTAGE_FOR_DESELECTION': {
      const stagedForDeselection = state.stagedForDeselection.filter(
        (resident) => resident.id !== action.payload.id
      );
      const filteredStagedForDeselection = stagedForDeselection.filter(
        (resident) => resident.name.toLowerCase().includes(state.searchTerm)
      );
      return {
        ...state,
        stagedForDeselection,
        filteredStagedForDeselection,
      };
    }
    case 'MOVE_STAGED_FOR_DESELECTION_TO_NON_SELECTED': {
      const newSelected = state.selected.filter(
        (resident) =>
          !state.filteredStagedForDeselection.some(
            (stagedResident) => resident.id === stagedResident.id
          )
      );
      const newFilteredSelected = state.filteredSelected.filter(
        (resident) =>
          !state.filteredStagedForDeselection.some(
            (stagedResident) => resident.id === stagedResident.id
          )
      );

      const newNonSelected = [
        ...state.nonSelected,
        ...state.filteredStagedForDeselection,
      ];
      const newFilteredNonSelected = newNonSelected
        .filter((resident) =>
          resident.name.toLowerCase().includes(state.searchTerm)
        )
        .sort(compareResidentsAlphabetically);

      return {
        ...state,
        selected: newSelected,
        nonSelected: newNonSelected,
        filteredNonSelected: newFilteredNonSelected,
        filteredSelected: newFilteredSelected,
        stagedForDeselection: [],
        filteredStagedForDeselection: [],
      };
    }

    case 'SET_RECEIPT_AMOUNT': {
      return {
        ...state,
        receiptAmount: action.payload,
      };
    }

    case 'RESET_RECEIPT_AMOUNT': {
      return {
        ...state,
        receiptAmount: undefined,
      };
    }

    case 'SET_SPLIT_AMOUNT_ENABLED': {
      const isSplitAmountEnabled = action.payload;

      return {
        ...state,
        isSplitAmountEnabled,
      };
    }

    case 'SET_SHARED_NOTE': {
      const sharedNote = action.payload;

      return {
        ...state,
        sharedNote,
      };
    }

    default:
      return state;
  }
}

export function initializeResidentQuickSelectionState(
  residents: Resident[]
): State {
  return {
    nonSelected: residents.sort(compareResidentsAlphabetically),
    staged: [],
    selected: [],
    stagedForDeselection: [],
    filteredStagedForDeselection: [],
    searchTerm: '',
    filteredNonSelected: residents.sort(compareResidentsAlphabetically),
    filteredSelected: [],
    receiptAmount: undefined,
    date: undefined,
    isSplitAmountEnabled: false,
    sharedNote: '',
  };
}

export const compareResidentsAlphabetically = (
  a: Resident,
  b: Resident
): number => {
  return a.lastName.localeCompare(b.lastName);
};
