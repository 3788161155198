import {
  makeApiProvider,
  useApiQuery,
  useApiQueryNoRefetchOverride,
} from '@pflegenavi/shared-frontend/platform';
import { createContext, useCallback } from 'react';
import type { IPaymentApi } from './api';
import { PaymentApi } from './api';
import { TestPaymentApi } from './testApi';
import type {
  QueryKey,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { useMutation, useQueryClient } from 'react-query';
import type {
  DirectDebitStatusDto,
  FamilyMemberInitiatedPaymentDto,
  GetPayoutsResultEntryDto,
  GetResidentBalanceGraphDatapointsDto,
  MoneyFlowType,
  PaymentDashboardStatisticsDto,
  PaymentDto,
  PaymentInfo,
  PaymentInitiationDto,
  PaymentMoneyFlowEntry,
  PaymentQueryFilters,
  PayoutQueryFilters,
  PayoutReconciliationEntryDto,
  PendingTransferReversalDto,
  ReceiptBatchPaymentStatus,
  ResidentBalanceGraphDatapointDto,
  ResidentBalanceInfo,
  ServiceProviderPayment,
  ServiceProviderPaymentListQuery,
  SetupIntentPayload,
  StripeAccount,
} from '@pflegenavi/shared/api';
import { ReceiptBatchPaymentState } from '@pflegenavi/shared/api';
import { PAYMENT_STATUS_KEY } from '@pflegenavi/shared/constants';
import type {
  PaginatedResultSubset,
  RangeDateFilter,
} from '@pflegenavi/shared/utils';
import { ANALYTICS_RESIDENT_SUMMARY } from '../analytics';
import { PAYMENTS_INFO_KEY } from '../payment-phoenix';

export { TestPaymentApi } from './testApi';

export { isApiError } from './api';
export type { ApiError, IPaymentApi } from './api';

// TODO Replace direct debit endpoints with payment info
const PENDING_CHARGES_KEY = (residentId: string) =>
  `PAYMENT_PENDING_CHARGES-${residentId}`;
const PAYMENT_METHOD_KEY = (residentId: string | undefined) =>
  `PAYMENT_PAYMENT_METHOD-${residentId}`;
const DELETE_MANDATE_KEY = `PAYMENT_DELETE_MANDATE`;
const DELETE_PENDING_CHARGE = `PAYMENT_DELETE_PENDING_CHARGE`;
const STRIPE_ACCOUNT_DETAILS_KEY = (nursingHomeId: string | undefined) => [
  `PAYMENT_STRIPE_ACCOUNT_DETAILS_KEY`,
  nursingHomeId,
];
const CREATE_NURSING_HOME_STRIPE_ACCOUNT_KEY = (
  nursingHomeId: string | undefined
) => [`CREATE_NURSING_HOME_STRIPE_ACCOUNT_KEY`, nursingHomeId];

export const PAYMENT_DASHBOARD_STATISTICS_KEY =
  'PAYMENT_DASHBOARD_STATISTICS_KEY';
export const PAYMENT_DASHBOARD_STATISTICS_KEY_NURSING_HOME = (
  nursingHomeId: string | undefined
): QueryKey => [PAYMENT_DASHBOARD_STATISTICS_KEY, nursingHomeId];
export const TRANSFER_KEY_ALL = 'PAYMENT_TRANSFER_KEY';
export const TRANSFER_KEY = (nursingHomeId: string | undefined): QueryKey => [
  TRANSFER_KEY_ALL,
  nursingHomeId,
];
export const SUBMIT_MANUAL_PAYMENT = `SUBMIT_MANUAL_PAYMENT`;
export const PAYMENT_INITIATED_BY = `PAYMENT_INITIATED_BY`;
export const SET_PAYMENT_INITIATION = `SET_PAYMENT_INITIATION`;
export const RESIDENT_BALANCE_INFO = (id: string): QueryKey => [
  'RESIDENT_BALANCE_INFO',
  id,
];
const INITIATE_SERVICE_PROVIDER_PAYMENT = `INITIATE_SERVICE_PROVIDER_PAYMENT`;
const SERVICE_PROVIDER_PAYMENT_FOR_RECEIPT_BATCH = (
  receiptBatchId: string | undefined
): QueryKey => ['SERVICE_PROVIDER_PAYMENT_FOR_RECEIPT_BATCH', receiptBatchId];
const SERVICE_PROVIDER_PAYMENTS = (params: object) => [
  'SERVICE_PROVIDER_PAYMENTS',
  params,
];
export const RECEIPT_BATCH_PAYMENT_STATUS = (
  receiptBatchId: string | undefined
): QueryKey => [
  'SERVICE_PROVIDER_PAYMENT_FOR_RECEIPT_BATCH_STATUS',
  receiptBatchId,
];

export const PAYMENT_INFO_KEY = (residentId: string): QueryKey => [
  'PAYMENT_INFO',
  residentId,
];

const NURSING_HOME_DIRECT_DEBIT_STATUS = (nursingHomeId: string): QueryKey => [
  'NURSING_HOME_DIRECT_DEBIT_STATUS',
  nursingHomeId,
];

const PAYMENT_DASHBOARD_RESIDENT_BALANCE_GRAPH_KEY = (
  nursingHomeId: string | undefined,
  dateStart: Date | undefined,
  dateEnd: Date | undefined
) => [
  'PAYMENT_DASHBOARD_RESIDENT_BALANCE_GRAPH_KEY',
  nursingHomeId,
  {
    dateStart: dateStart?.toDateString(),
    dateEnd: dateEnd?.toDateString(),
  },
];

const ACCOUNTING_PAYOUTS_WITH_PAGINATION_KEY = (
  nursingHomeId: string | undefined,
  page: number,
  pageSize: number,
  filters: PayoutQueryFilters
) => [
  'ACCOUNTING_PAYOUTS_WITH_PAGINATION_KEY_PAGE',
  nursingHomeId,
  {
    page,
    pageSize,
    filters,
  },
];

const ACCOUNTING_PAYMENTS_WITH_PAGINATION_KEY = (
  nursingHomeId: string | undefined,
  page: number,
  pageSize: number,
  filters: PaymentQueryFilters
) => [
  'ACCOUNTING_PAYMENTS_WITH_PAGINATION_KEY',
  nursingHomeId,
  {
    page,
    pageSize,
    filters,
  },
];

const PAYOUT_RECONCILIATION_KEY = (payoutId: string | undefined) => [
  'PAYOUT_RECONCILIATION',
  payoutId,
];

const PAYMENT_MONEY_FLOW_KEY = (
  nursingHomeIds: string[],
  type?: MoneyFlowType,
  dateRange?: RangeDateFilter<Date>
) => ['PAYMENT_MONEY_FLOW', nursingHomeIds, type, dateRange];

const PENDING_REVERSALS_KEY = (nursingHomeId: string | undefined) => [
  'PENDING_REVERSALS_KEY',
  nursingHomeId,
];

const ApiContext = createContext<IPaymentApi | undefined>(undefined);
const { useApi: usePaymentApi, ApiProvider: PaymentApiProvider } =
  makeApiProvider({
    name: 'Payment',
    ApiContext,
    newApi: (tenantId, auth, apiUrl, testMode) =>
      testMode
        ? new TestPaymentApi(auth)
        : new PaymentApi(tenantId, auth, apiUrl),
  });

export { usePaymentApi, PaymentApiProvider, ApiContext as PaymentApiContext };

type InvalidateDirectDebitStatusCallback = () => Promise<void>;
export const useInvalidateDirectDebitStatusAndInitiatedPaymentBy = (
  residentId?: string
): InvalidateDirectDebitStatusCallback => {
  const client = useQueryClient();
  return useCallback(async () => {
    await Promise.all([
      client.invalidateQueries(PAYMENT_INFO_KEY(residentId as string)),
      client.invalidateQueries([PAYMENT_INITIATED_BY, residentId]),
    ]);
  }, [client, residentId]);
};

export const useStripeAccountDetails = (
  nursingHomeId: string | undefined
): UseQueryResult<StripeAccount> => {
  return useApiQuery<IPaymentApi, StripeAccount, StripeAccount>(
    usePaymentApi,
    STRIPE_ACCOUNT_DETAILS_KEY(nursingHomeId),
    async (api) => {
      if (!nursingHomeId) {
        throw new Error('nursingHomeId is undefined');
      }

      return await api.getStripeAccountDetails({
        params: { nursingHomeId },
      });
    },
    {
      enabled: nursingHomeId !== undefined,
      select: (data) => {
        return {
          ...data,
          dueDate: data.dueDate ? new Date(data.dueDate) : undefined,
        };
      },
    }
  );
};

export const useCreateStripeAccountForNursingHome = (
  nursingHomeId: string | undefined
): UseMutationResult<
  {
    accountId: string;
  },
  unknown,
  void
> => {
  const api = usePaymentApi();
  const queryClient = useQueryClient();

  return useMutation<
    {
      accountId: string;
    },
    unknown,
    void
  >(
    CREATE_NURSING_HOME_STRIPE_ACCOUNT_KEY(nursingHomeId),
    () => {
      if (!nursingHomeId) {
        throw new Error('nursingHomeId is undefined');
      }
      return api.createStripeAccountForNursingHome({
        params: {
          nursingHomeId: nursingHomeId,
        },
      });
    },
    {
      onSuccess: async () => {
        await Promise.all([
          queryClient.invalidateQueries(
            STRIPE_ACCOUNT_DETAILS_KEY(nursingHomeId)
          ),
        ]);
      },
    }
  );
};

type InvalidatePaymentStatusByResidentIdCallback = () => Promise<void>;
export const useInvalidatePaymentStatusByResidentId = (
  residentId?: string
): InvalidatePaymentStatusByResidentIdCallback => {
  const client = useQueryClient();
  return useCallback(async () => {
    await Promise.all([
      client.invalidateQueries(PAYMENT_STATUS_KEY(residentId as string)),
      client.invalidateQueries(PAYMENT_INFO_KEY(residentId as string)),
    ]);
  }, [client, residentId]);
};

export const usePaymentInfoByResidentId = (
  residentId: string
): UseQueryResult<PaymentInfo> => {
  return useApiQuery(
    usePaymentApi,
    PAYMENT_INFO_KEY(residentId),
    (api) =>
      api.getPaymentInfoByResidentId({
        params: { residentId: residentId as string },
      }),
    {
      enabled: Boolean(residentId),
    }
  );
};

export const usePaymentInfoByResidentIdAsFamilyMember = (
  residentId: string | undefined
): UseQueryResult<PaymentInfo> => {
  return useApiQuery(
    usePaymentApi,
    PAYMENT_INFO_KEY(residentId ?? ''),
    (api) => {
      return api.getPaymentInfoByResidentIdAsFamilyMember({
        params: { residentId: residentId as string },
      });
    },
    {
      enabled: Boolean(residentId),
    }
  );
};

export const useNursingHomeDirectDebitStatus = (
  nursingHomeId: string | undefined
): UseQueryResult<DirectDebitStatusDto> => {
  return useApiQuery(
    usePaymentApi,
    NURSING_HOME_DIRECT_DEBIT_STATUS(nursingHomeId ?? 'unknown'),
    (api) => {
      if (!nursingHomeId) {
        throw new Error('nursingHomeId is undefined');
      }
      return api.getDirectDebitStatusForNursingHome({
        params: { nursingHomeId },
      });
    },
    {
      enabled: Boolean(nursingHomeId),
    }
  );
};

export const useDeleteDirectDebitMandate = (
  residentId: string | undefined,
  onSuccess: () => void,
  onError: () => void
): UseMutationResult<
  void,
  unknown,
  {
    residentId: string;
  }
> => {
  const api = usePaymentApi();
  const queryClient = useQueryClient();
  return useMutation<
    void,
    unknown,
    {
      residentId: string;
    }
  >(
    DELETE_MANDATE_KEY,
    (data) => {
      if (!data.residentId) {
        throw new Error('residentId is undefined');
      }
      return api.deleteDirectDebitMandate(data.residentId);
    },
    {
      onSuccess: async () => {
        onSuccess();
        if (!residentId) {
          return;
        }
        await Promise.all([
          queryClient.invalidateQueries(PAYMENT_INFO_KEY(residentId)),
          queryClient.invalidateQueries(PAYMENT_METHOD_KEY(residentId)),
        ]);
      },
      onError: () => {
        onError();
      },
    }
  );
};

export const useDeleteDirectDebitMandateForNursingHome = (
  nursingHomeId: string | undefined,
  onSuccess: () => void,
  onError: () => void
): UseMutationResult<
  void,
  unknown,
  {
    nursingHomeId: string;
  }
> => {
  const api = usePaymentApi();
  const queryClient = useQueryClient();
  return useMutation<
    void,
    unknown,
    {
      nursingHomeId: string;
    }
  >(
    DELETE_MANDATE_KEY,
    (data) => {
      if (!data.nursingHomeId) {
        throw new Error('residentId is undefined');
      }
      return api.deleteDirectDebitMandateForNursingHome(data.nursingHomeId);
    },
    {
      onSuccess: async () => {
        onSuccess();
        if (!nursingHomeId) {
          return;
        }
        // TODO: invalidate payment method of the nursing home once it is implemented
        await Promise.all([
          queryClient.invalidateQueries(
            NURSING_HOME_DIRECT_DEBIT_STATUS(nursingHomeId)
          ),
        ]);
      },
      onError: () => {
        onError();
      },
    }
  );
};

export const useDeletePendingChargeByResidentId = (
  residentId: string,
  familyMemberId: string | undefined
): UseMutationResult<
  void,
  unknown,
  {
    notes: string;
  }
> => {
  const api = usePaymentApi();
  const queryClient = useQueryClient();
  return useMutation<
    void,
    unknown,
    {
      notes: string;
    }
  >(
    DELETE_PENDING_CHARGE,
    (data) => api.deletePendingChargeByResidentId(residentId, data.notes),
    {
      onSuccess: async () => {
        return await Promise.all([
          ...(familyMemberId
            ? [
                queryClient.invalidateQueries([
                  PAYMENT_INFO_KEY(residentId),
                  familyMemberId,
                ]),
              ]
            : []),
          queryClient.invalidateQueries(PENDING_CHARGES_KEY(residentId)),
          queryClient.invalidateQueries(PAYMENT_STATUS_KEY(residentId)),
          queryClient.invalidateQueries(PAYMENT_INFO_KEY(residentId)),
        ]);
      },
    }
  );
};

const parsePaymentDashboardStatisticsDto = (
  data: PaymentDashboardStatisticsDto
): PaymentDashboardStatisticsDto => {
  return {
    ...data,
    date: new Date(data.date),
  };
};

export const usePaymentDashboardStatistics = (
  nursingHomeId: string | undefined
): UseQueryResult<PaymentDashboardStatisticsDto> => {
  return useApiQuery(
    usePaymentApi,
    PAYMENT_DASHBOARD_STATISTICS_KEY_NURSING_HOME(nursingHomeId),
    (api) => {
      if (!nursingHomeId) {
        throw new Error('nursingHomeId is undefined');
      }
      return api.getAccountingDashboard({ params: { nursingHomeId } });
    },
    {
      enabled: nursingHomeId !== undefined,
      select: parsePaymentDashboardStatisticsDto,
    }
  );
};

export const usePaymentDashboardResidentBalanceGraph = ({
  nursingHomeId,
  dateStart,
  dateEnd,
}: GetResidentBalanceGraphDatapointsDto): UseQueryResult<
  ResidentBalanceGraphDatapointDto[]
> => {
  return useApiQuery(
    usePaymentApi,
    PAYMENT_DASHBOARD_RESIDENT_BALANCE_GRAPH_KEY(
      nursingHomeId,
      dateStart,
      dateEnd
    ),
    (api) => {
      if (!nursingHomeId) {
        throw new Error('nursingHomeId is required');
      }
      return api.getAccountingResidentBalanceGraph({
        params: {
          nursingHomeId,
          dateStart,
          dateEnd,
        },
      });
    },
    {
      enabled: nursingHomeId !== undefined,
    }
  );
};

export const useAccountingPayouts = ({
  nursingHomeId,
  page,
  pageSize,
  filters,
}: {
  nursingHomeId: string | undefined;
  page: number;
  pageSize: number;
  filters: PayoutQueryFilters;
}): UseQueryResult<PaginatedResultSubset<GetPayoutsResultEntryDto>> => {
  const select = useCallback(
    (result: PaginatedResultSubset<GetPayoutsResultEntryDto>) => {
      const { data, links, meta } = result;
      return {
        links,
        meta,
        data: data.map((row) => ({
          ...row,
          initiationDate: new Date(row.initiationDate),
          transferDate: new Date(row.transferDate),
        })),
      };
    },
    []
  );

  return useApiQuery(
    usePaymentApi,
    ACCOUNTING_PAYOUTS_WITH_PAGINATION_KEY(
      nursingHomeId,
      page,
      pageSize,
      filters
    ),
    (api) => {
      if (!nursingHomeId) {
        throw new Error('nursingHomeId is undefined');
      }

      return api.getAccountingPayouts({
        params: {
          nursingHomeId,
          page,
          pageSize,
          filters,
        },
      });
    },
    {
      enabled: nursingHomeId !== undefined,
      select,
      keepPreviousData: true,
    }
  );
};

export const usePayoutReconciliation = ({
  payoutId,
  enabled,
}: {
  payoutId?: string;
  enabled?: boolean;
}): UseQueryResult<PayoutReconciliationEntryDto[]> => {
  return useApiQueryNoRefetchOverride(
    usePaymentApi,
    PAYOUT_RECONCILIATION_KEY(payoutId),
    (api) => {
      if (!payoutId) {
        throw new Error('payoutId is undefined');
      }
      return api.getPayoutReconciliation({ params: { payoutId } });
    },
    {
      enabled: enabled && Boolean(payoutId),
      select: (data) =>
        data.map((row) => ({
          ...row,
          creationDate: new Date(row.creationDate),
        })),
    }
  );
};

export const useMoneyFlow = ({
  nursingHomeIds,
  type,
  dateRange,
  options,
}: {
  nursingHomeIds: string[];
  type?: MoneyFlowType;
  dateRange?: RangeDateFilter<Date>;
  options?: UseQueryOptions<
    PaymentMoneyFlowEntry[],
    unknown,
    PaymentMoneyFlowEntry[]
  >;
}): UseQueryResult<PaymentMoneyFlowEntry[]> => {
  return useApiQuery(
    usePaymentApi,
    PAYMENT_MONEY_FLOW_KEY(nursingHomeIds, type, dateRange),
    (api) => {
      if (!nursingHomeIds || nursingHomeIds.length === 0) {
        throw new Error('nursingHomeIds is undefined');
      }
      return api.getMoneyFlow({
        params: {
          nursingHomeIds,
          type,
          dateRange,
        },
      });
    },
    {
      ...options,
      enabled:
        (options?.enabled ?? true) &&
        nursingHomeIds &&
        nursingHomeIds.length > 0,
      select: (data) =>
        data.map((row) => {
          return {
            ...row,
            date: new Date(row.date),
            receiptBatch: row.receiptBatch
              ? {
                  ...row.receiptBatch,
                  receiptDate: row.receiptBatch.receiptDate
                    ? new Date(row.receiptBatch.receiptDate)
                    : undefined,
                  earliestEntryDate: row.receiptBatch.earliestEntryDate
                    ? new Date(row.receiptBatch.earliestEntryDate)
                    : undefined,
                  latestEntryDate: row.receiptBatch.latestEntryDate
                    ? new Date(row.receiptBatch.latestEntryDate)
                    : undefined,
                }
              : undefined,
          };
        }),
    }
  );
};

export const useAccountingPayments = ({
  nursingHomeId,
  page,
  pageSize,
  filters,
}: {
  nursingHomeId: string | undefined;
  page: number;
  pageSize: number;
  filters: PaymentQueryFilters;
}): UseQueryResult<PaginatedResultSubset<PaymentDto>> => {
  const select = useCallback((result: PaginatedResultSubset<PaymentDto>) => {
    const { data, links, meta } = result;
    return {
      links,
      meta,
      data: data.map((row) => ({
        ...row,
        creationDate: new Date(row.creationDate),
        cancellationDate:
          row.cancellationDate && new Date(row.cancellationDate),
      })),
    };
  }, []);

  return useApiQuery(
    usePaymentApi,
    ACCOUNTING_PAYMENTS_WITH_PAGINATION_KEY(
      nursingHomeId,
      page,
      pageSize,
      filters
    ),
    (api) => {
      if (!nursingHomeId) {
        throw new Error('nursingHomeId is undefined');
      }
      return api.getAccountingPayments({
        params: {
          nursingHomeId,
          page,
          pageSize,
          filters,
        },
      });
    },
    {
      enabled: nursingHomeId !== undefined,
      select,
      keepPreviousData: true,
    }
  );
};

export const useTransfer = (
  nursingHomeId: string | undefined
): UseQueryResult<number> => {
  return useApiQuery(
    usePaymentApi,
    TRANSFER_KEY(nursingHomeId),
    (api) => {
      if (!nursingHomeId) {
        throw new Error('nursingHomeId is undefined');
      }
      return api.getTransfer({ params: { nursingHomeId } });
    },
    {
      enabled: nursingHomeId !== undefined,
    }
  );
};

export const useSubmitDirectDebitManualPayment = (
  residentId: string | undefined
): UseMutationResult<
  SetupIntentPayload,
  unknown,
  FamilyMemberInitiatedPaymentDto
> => {
  const api = usePaymentApi();
  const queryClient = useQueryClient();
  return useMutation<
    SetupIntentPayload,
    unknown,
    FamilyMemberInitiatedPaymentDto
  >(
    [SUBMIT_MANUAL_PAYMENT, residentId],
    (data) => {
      if (!residentId) {
        throw new Error('residentId is undefined');
      }
      return api.submitDirectDebitManualPayment(residentId, data);
    },
    {
      onSuccess: async () => {
        await Promise.all([
          queryClient.invalidateQueries([
            ANALYTICS_RESIDENT_SUMMARY(residentId),
          ]),
          queryClient.invalidateQueries([PAYMENT_INFO_KEY(residentId!)]),
        ]);
      },
    }
  );
};
export const useSetPaymentInitiation = (
  residentId: string,
  familyMemberId: string
): UseMutationResult<
  {
    success: boolean;
  },
  unknown,
  PaymentInitiationDto
> => {
  const api = usePaymentApi();
  const queryClient = useQueryClient();

  return useMutation<
    {
      success: boolean;
    },
    unknown,
    PaymentInitiationDto
  >(
    [SET_PAYMENT_INITIATION, residentId, familyMemberId],
    (data) =>
      api.setPaymentInitiation({
        body: data,
        params: {
          residentId,
          familyMemberId,
        },
      }),
    {
      onSuccess: async () => {
        return await Promise.all([
          queryClient.invalidateQueries([
            PAYMENT_INITIATED_BY,
            residentId,
            familyMemberId,
          ]),
          // TODO: Family member specific PAYMENT_INFO_KEY
          queryClient.invalidateQueries(PAYMENT_INFO_KEY(residentId)),
          queryClient.invalidateQueries(PAYMENTS_INFO_KEY(residentId)),
          queryClient.invalidateQueries([
            PAYMENT_INFO_KEY(residentId),
            familyMemberId,
          ]),
        ]);
      },
    }
  );
};

export const useResidentBalanceInfo = ({
  residentId,
  options,
}: {
  residentId: string | undefined;
  options?: UseQueryOptions<ResidentBalanceInfo, unknown, ResidentBalanceInfo>;
}): UseQueryResult<ResidentBalanceInfo> => {
  return useApiQuery(
    usePaymentApi,
    RESIDENT_BALANCE_INFO(residentId ?? 'undefined'),
    (api) => {
      if (!residentId) {
        throw new Error('residentId is undefined');
      }
      return api.getResidentBalanceInfo({ params: { residentId } });
    },
    {
      ...options,
      enabled: !!residentId,
    }
  );
};

export const usePendingReversals = (
  nursingHomeId: string | undefined
): UseQueryResult<PendingTransferReversalDto[]> => {
  return useApiQuery(
    usePaymentApi,
    PENDING_REVERSALS_KEY(nursingHomeId),
    (api) => {
      if (!nursingHomeId) {
        throw new Error('nursingHomeId is undefined');
      }
      return api.getPendingReversals({ params: { nursingHomeId } });
    },
    {
      enabled: nursingHomeId !== undefined,
    }
  );
};
export const useServiceProviderPayments = (
  params: Omit<ServiceProviderPaymentListQuery, 'nursingHomeId'> & {
    nursingHomeId: string | undefined;
  }
): UseQueryResult<PaginatedResultSubset<ServiceProviderPayment>> => {
  const select = useCallback(
    (data: PaginatedResultSubset<ServiceProviderPayment>) => ({
      ...data,
      data: data.data.map((item) => ({
        ...item,
        created: new Date(item.created),
        updated: new Date(item.updated),
        canceled: item.canceled ? new Date(item.canceled) : null,
        completedAt: item.completedAt ? new Date(item.completedAt) : null,
      })),
    }),
    []
  );

  return useApiQuery(
    usePaymentApi,
    SERVICE_PROVIDER_PAYMENTS(params),
    (api) => {
      if (!params.nursingHomeId) {
        throw new Error('nursingHomeId is undefined');
      }
      return api.getServiceProviderPayments({
        params: {
          ...params,
          nursingHomeId: params.nursingHomeId,
        },
      });
    },
    {
      select,
      enabled: params.nursingHomeId !== undefined,
    }
  );
};

export const useReceiptBatchPaymentStatus = (
  receiptBatchId: string | undefined,
  options?: UseQueryOptions<
    ReceiptBatchPaymentStatus,
    unknown,
    ReceiptBatchPaymentStatus
  >
): UseQueryResult<ReceiptBatchPaymentStatus> => {
  return useApiQuery(
    usePaymentApi,
    RECEIPT_BATCH_PAYMENT_STATUS(receiptBatchId),
    (api) => {
      if (!receiptBatchId) {
        throw new Error('receiptBatchId is undefined');
      }
      return api.getReceiptBatchPaymentStatus({ params: { receiptBatchId } });
    },
    {
      select: (data) => {
        if (data.status === ReceiptBatchPaymentState.PAYMENT_INITIATED) {
          return {
            status: ReceiptBatchPaymentState.PAYMENT_INITIATED,
            payment: {
              ...data.payment,
              created: new Date(data.payment.created),
              updated: new Date(data.payment.updated),
              canceled: data.payment.canceled
                ? new Date(data.payment.canceled)
                : null,
              completedAt: data.payment.completedAt
                ? new Date(data.payment.completedAt)
                : null,
            },
          };
        }
        return data;
      },
      enabled: receiptBatchId !== undefined,
      ...options,
    }
  );
};

export const useCreateServiceProviderPayment = (): UseMutationResult<
  ServiceProviderPayment,
  unknown,
  {
    receiptBatchId: string;
  }
> => {
  const api = usePaymentApi();
  const queryClient = useQueryClient();

  return useMutation<
    ServiceProviderPayment,
    unknown,
    {
      receiptBatchId: string;
    }
  >(
    INITIATE_SERVICE_PROVIDER_PAYMENT,
    ({ receiptBatchId }) =>
      api.initiateServiceProviderPayment({
        body: undefined,
        params: {
          receiptBatchId,
        },
      }),
    {
      onSuccess: async (data) => {
        if (data.receiptBatchId) {
          await Promise.all([
            queryClient.invalidateQueries(
              SERVICE_PROVIDER_PAYMENT_FOR_RECEIPT_BATCH(data.receiptBatchId)
            ),
            queryClient.invalidateQueries(
              RECEIPT_BATCH_PAYMENT_STATUS(data.receiptBatchId)
            ),
            queryClient.invalidateQueries(
              SERVICE_PROVIDER_PAYMENTS({ nursingHomeId: data.nursingHomeId })
            ),
          ]);
        }
      },
    }
  );
};
