import type { FilterDefinition } from './declarations/type';
import { Iconify } from '../Iconify';
import { Label } from '../Label';
import type { FC } from 'react';
import React, { useMemo } from 'react';
import { YEAR_MONTH_DAY_SHORT_FORMAT } from '@pflegenavi/shared/constants';
import { IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormatDate } from '@pflegenavi/frontend/localization';

function rangeToString<T>(
  range: { from?: T | undefined; to?: T | undefined } | undefined,
  getString: (value: T) => string
) {
  if (range?.from && !range.to) {
    return `≥ ${getString(range.from)}`;
  } else if (!range?.from && range?.to) {
    return `≤ ${getString(range.to)}`;
  } else if (range?.from && range.to) {
    const fromString = getString(range.from);
    const toString = getString(range.to);
    return fromString === toString ? fromString : `${fromString} - ${toString}`;
  }
  return undefined;
}

interface ActiveFilterLabelProps {
  filter: FilterDefinition<any>;
}

export const ActiveFilterLabel: FC<ActiveFilterLabelProps> = ({ filter }) => {
  const { t } = useTranslation();
  const formatDate = useFormatDate();

  const labelContent = useMemo(() => {
    let filterStringRepresentation = undefined;

    if (filter.toStringRepresentation) {
      filterStringRepresentation = filter.toString();
    } else if (filter.type === 'dateRange') {
      filterStringRepresentation = rangeToString(filter.range, (value) =>
        formatDate(value, YEAR_MONTH_DAY_SHORT_FORMAT)
      );
    } else if (filter.type === 'numberRange') {
      filterStringRepresentation = rangeToString(filter.range, (value) =>
        value.toString(10)
      );
    } else if (filter.type === 'boolean') {
      filterStringRepresentation = filter.value
        ? t('common.yes')
        : t('common.no');
    }

    return filterStringRepresentation
      ? `${filter.label}: ${filterStringRepresentation}`
      : filter.label;
  }, [filter, formatDate, t]);

  const canUnset = filter.unset !== undefined;

  return (
    <Label sx={{ pr: canUnset ? 0.5 : undefined }} alignItems={'center'}>
      {labelContent}
      {canUnset && (
        <IconButton sx={{ p: 0.4, ml: 0.2, mb: 0.1 }} onClick={filter.unset}>
          <Iconify icon={'akar-icons:circle-x-fill'} width={13} height={13} />
        </IconButton>
      )}
    </Label>
  );
};
