import type { FC, PropsWithChildren } from 'react';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { getPhoenixApiWebsocketBaseUrl } from '@pflegenavi/shared-frontend/platform';
import type { Channel } from 'phoenix';
import { Socket } from 'phoenix';
import { useAuthenticationInternal } from '@pflegenavi/frontend/authentication';
import { useTenantId } from '@pflegenavi/frontend/tenant';
import * as Sentry from '@sentry/react';
import { getTokenPayloadFromToken } from '@pflegenavi/shared/utils';

interface IPhoenixContext {
  onReceiptBatchSubmitted(batchId: string, callback: () => void): () => void;

  connect(): void;

  disconnect(): void;
}
class PhoenixContext implements IPhoenixContext {
  private url: string;
  private socket: Socket;

  private channels: Map<string, Channel> = new Map();

  constructor(
    apiUrl: string | undefined,
    private tenant: string,
    private getToken: () => string | undefined,
    private updateToken: () => Promise<boolean>
  ) {
    this.url = getPhoenixApiWebsocketBaseUrl(apiUrl);
    this.socket = new Socket(`${this.url}/nursing-home-employee-socket`, {
      params: () => ({ token: getToken() }),
    });

    this.socket.onOpen(() => {
      // eslint-disable-next-line no-console
      console.info('Phoenix socket connected');
    });

    this.socket.onClose(async () => {
      // eslint-disable-next-line no-console
      console.info('Phoenix socket closed');
      await updateToken();
      this.connect();
    });

    this.socket.onError(async (error, transport, establishedConnections) => {
      if (establishedConnections === 0) {
        const err = new Error(
          `Phoenix websocket connection error: ${JSON.stringify(error)}`
        );
        Sentry.captureException(err);
      }
      await updateToken();
    });
  }

  onReceiptBatchSubmitted(batchId: string, callback: () => void): () => void {
    const topic = `nursing_home_receipt_batch:${this.tenant}:${batchId}`;
    let channel = this.channels.get(topic);
    if (!channel) {
      channel = this.socket.channel(topic, {});
    }

    if (channel.state !== 'joined' && channel.state !== 'joining') {
      channel.join();
    }

    const ref = channel.on('submitted', () => {
      callback();
    });

    return () => {
      if (channel) {
        channel.off('submitted', ref);
        channel.leave();
      }
    };
  }

  connect() {
    if (this.socket.isConnected()) {
      this.socket.disconnect();
    }
    const token = this.getToken();

    if (token === undefined) {
      return;
    }

    const payload = getTokenPayloadFromToken(token);

    if (!payload || payload.exp * 1000 < new Date().valueOf()) {
      this.updateToken().then(() => this.socket.connect());
    } else {
      this.socket.connect();
    }
  }

  disconnect() {
    if (this.socket.isConnected()) {
      this.socket.disconnect();
    }
  }
}

const Context = createContext<IPhoenixContext | undefined>(undefined);

export const usePhoenix = (): IPhoenixContext | undefined => {
  const phoenix = useContext(Context);
  return phoenix;
};

export const PhoenixProvider: FC<
  PropsWithChildren & {
    apiUrl?: string;
  }
> = ({ children, apiUrl }) => {
  const auth = useAuthenticationInternal();
  const tenant = useTenantId();

  if (!tenant) {
    throw new Error('Tenant not set');
  }

  const authRef = useRef(auth);
  authRef.current = auth;
  const getTokenImmediate = useCallback(
    () => authRef.current.getTokenImmediate(),
    []
  );
  const updateToken = useCallback(() => authRef.current.updateToken(), []);

  const hasToken = Boolean(auth.getTokenImmediate());

  const phoenixContext = useMemo(
    () => {
      return new PhoenixContext(apiUrl, tenant, getTokenImmediate, updateToken);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [apiUrl]
  );

  useEffect(() => {
    if (hasToken) {
      phoenixContext.connect();
    }

    return () => {
      phoenixContext.disconnect();
    };
  }, [hasToken, phoenixContext]);

  return <Context.Provider value={phoenixContext}>{children}</Context.Provider>;
};
