import {
  HeaderBreadcrumbs,
  LoadingContainer,
} from '@pflegenavi/web-components';
import IconRoundReceipt from '~icons/ic/round-receipt';
import IconCheckmarkCircle2Fill from '~icons/eva/checkmark-circle-2-fill';
import IconFileFill from '~icons/eva/file-fill';
import IconBellFill from '~icons/eva/bell-fill';

import { useAnalytics } from '@pflegenavi/frontend/api-nursing-home';
import { Card, Container, Grid, Stack, styled, useTheme } from '@mui/material';
import { ReceiptAnalytic } from './components/ReceiptAnalytic';
import { nhAppMainPages } from '@pflegenavi/frontend/routing';
import { ReceiptTypeChart } from './components/ReceiptTypeChart';
import { RecentReceiptsTable } from './components/RecentReceipts';
import { useTranslation } from 'react-i18next';
import { BalanceCard } from './components/BalanceCard';
import type { Analytics } from '@pflegenavi/shared/api';
import type { FC } from 'react';
import React from 'react';
import { YEAR_MONTH_FORMAT } from '@pflegenavi/shared/constants';
import { useLocale } from '@pflegenavi/frontend/localization';

import NoLowBalanceResidentsSvg from './assets/undraw_no_records.svg';
import { useNursingHomeContext } from '@pflegenavi/frontend/nursing-home-context';
import { TabFilterValues } from '@pflegenavi/frontend/pages/residents';

export function DashboardPage(): JSX.Element {
  const { selectedNursingHome } = useNursingHomeContext();

  const { data: analytics, isLoading } = useAnalytics(selectedNursingHome?.id);

  return <DashboardPageView isLoading={isLoading} analytics={analytics} />;
}

interface DashBoardPageViewProps {
  isLoading: boolean;
  analytics: Analytics | undefined;
}

export const DashboardPageView: FC<DashBoardPageViewProps> = ({
  isLoading,
  analytics,
}) => {
  const theme = useTheme();
  const locale = useLocale();
  const { t } = useTranslation();

  if (!analytics || isLoading) {
    return <LoadingContainer height={200} />;
  }

  return (
    <Container sx={{ maxWidth: 1400 }}>
      <HeaderBreadcrumbs
        heading={t('dashboard.main')}
        links={[
          {
            name: t('dashboard.main'),
            href: nhAppMainPages.DASHBOARD,
          },
          { name: new Date().toLocaleDateString(locale, YEAR_MONTH_FORMAT) },
        ]}
      />
      <Stack direction="column" gap={3}>
        <Card data-cy="receipts-breakdown">
          <AnalyticsGridContainer container>
            <Grid item xs={6} lg={3}>
              <ReceiptAnalytic
                data-cy="receipts-total"
                title={t('analytics.label.total')}
                total={analytics.receipts.total}
                percent={100}
                price={analytics.receipts.amount}
                icon="ic:round-receipt"
                color={theme.palette.info.main}
                Icon={() => (
                  <IconRoundReceipt color={theme.palette.info.main} />
                )}
              />
            </Grid>

            <Grid item xs={6} lg={3}>
              <ReceiptAnalytic
                data-cy="receipts-submitted"
                title={t('analytics.label.submitted')}
                total={analytics.receipts.submitted.total}
                percent={
                  (100 * analytics.receipts.submitted.total) /
                  analytics.receipts.total
                }
                price={analytics.receipts.submitted.amount}
                icon="eva:checkmark-circle-2-fill"
                color={theme.palette.success.main}
                Icon={() => (
                  <IconCheckmarkCircle2Fill
                    color={theme.palette.success.main}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} lg={3}>
              <ReceiptAnalytic
                data-cy="receipts-draft"
                title={t('analytics.label.draft')}
                total={analytics.receipts.draft.total}
                percent={
                  (100 * analytics.receipts.draft.total) /
                  analytics.receipts.total
                }
                price={analytics.receipts.draft.amount}
                icon="eva:file-fill"
                color={theme.palette.text.secondary}
                Icon={() => (
                  <IconFileFill color={theme.palette.text.secondary} />
                )}
              />
            </Grid>
            <Grid item xs={6} lg={3}>
              <ReceiptAnalytic
                data-cy="receipts-incomplete"
                title={t('analytics.label.incomplete')}
                total={analytics.receipts.incomplete.total}
                percent={
                  (100 * analytics.receipts.incomplete.total) /
                  analytics.receipts.total
                }
                price={analytics.receipts.incomplete.amount}
                icon="eva:bell-fill"
                color={theme.palette.error.main}
                Icon={() => (
                  <IconBellFill color={theme.palette.error.main}></IconBellFill>
                )}
              />
            </Grid>
          </AnalyticsGridContainer>
        </Card>
        <Stack direction="row" flexWrap="wrap" gap={3}>
          <RecentReceiptsTable data={analytics.latestReceipts} />
          <BalanceCard
            negative={true}
            cardTitle={t('dashboard.label.negativeBalance')}
            dataCyPrefix="balance-negative"
            data={analytics.negativeBalance}
            navigateTo={TabFilterValues.NEGATIVE_BALANCE}
            noDataImg={NoLowBalanceResidentsSvg}
            noDataText={t('table.no-negative-balance')}
          />
        </Stack>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <ReceiptTypeChart data={analytics.receiptTypes} />
          </Grid>
          <Grid item xs={12} md={6}>
            <BalanceCard
              negative={false}
              cardTitle={t('dashboard.label.belowThreshold')}
              dataCyPrefix="balance-below-threshold"
              data={analytics.balanceBelowThreshold}
              navigateTo={TabFilterValues.LOW_BALANCE}
              noDataImg={NoLowBalanceResidentsSvg}
              noDataText={t('table.no-low-balance')}
            />
          </Grid>
        </Grid>
      </Stack>
    </Container>
  );
};

const AnalyticsGridContainer = styled(Grid)(({ theme }) =>
  theme.unstable_sx({
    padding: 2,
    '& .MuiGrid-item': {
      borderRight: '1px dashed lightGrey',
      borderBottom: {
        xs: '1px dashed lightGrey',
        lg: '0px',
      },
    },
    '& .MuiGrid-item:nth-of-type(even)': {
      borderRightWidth: {
        xs: 0,
        lg: 1,
      },
    },
    '& .MuiGrid-item:nth-last-of-type(-n + 2)': {
      borderBottomWidth: 0,
    },
    '& .MuiGrid-item:last-of-type': {
      borderRightWidth: 0,
    },
  })
);
