import { Box, TablePagination } from '../index';
import { ROWS_PER_PAGE } from '@pflegenavi/shared/constants';
import type { ChangeEventHandler } from 'react';
import React from 'react';
import type { TableKeyType } from './hooks/useTable';

interface CustomTablePaginationProps {
  page: number;
  items?: any[];
  count?: number;
  rowsPerPage: number | 'all';
  onChangePage: any;
  onChangeRowsPerPage: any;
  tableKey: TableKeyType;
  removeFromTabSelection?: boolean;
}

export const CustomTablePagination: React.FC<CustomTablePaginationProps> = ({
  page,
  items,
  count,
  onChangePage,
  rowsPerPage,
  onChangeRowsPerPage,
  tableKey,
  removeFromTabSelection = false,
}) => {
  const key = `rows_per_page_for__${tableKey}`;

  const handleChangeRowsPerPage: ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    localStorage.setItem(key, event?.target?.value?.toString() ?? '10');
    onChangeRowsPerPage(event);
  };

  if (rowsPerPage === 'all') {
    return null;
  }

  return (
    <Box sx={{ position: 'relative', width: '100%' }}>
      <TablePagination
        tabIndex={removeFromTabSelection ? -1 : 0}
        rowsPerPageOptions={ROWS_PER_PAGE}
        component="div"
        count={count || items?.length || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{ tabIndex: removeFromTabSelection ? -1 : 0 }}
        onPageChange={onChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          fontSize: 15,
        }}
      />
    </Box>
  );
};
