import { TransactionSourceType } from '@pflegenavi/shared/api';

interface opts {
  t: (key: string) => string;
}

export const getTypeString = (
  sourceType: TransactionSourceType,
  opts: opts
): string => {
  let typeString = '';
  switch (sourceType) {
    case TransactionSourceType.InitialDeposit:
      typeString = opts.t('transaction-type.initial-deposit');
      break;
    case TransactionSourceType.Payout:
      typeString = opts.t('transaction-type.payout');
      break;
    case TransactionSourceType.ManualDeposit:
      typeString = opts.t('transaction-type.manual-deposit');
      break;
    case TransactionSourceType.CancelledManualDeposit:
      typeString = opts.t('transaction-type.cancelled-deposit');
      break;
    case TransactionSourceType.DirectDebit:
      typeString = opts.t('transaction-type.direct-debit');
      break;
    case TransactionSourceType.RecurringItem:
      typeString = opts.t('transaction-type.recurring-item');
      break;
    case TransactionSourceType.Receipt:
      typeString = opts.t('transaction-type.receipt');
      break;
    case TransactionSourceType.CancelledReceipt:
      typeString = opts.t('transaction-type.cancelled-receipt');
      break;
    case TransactionSourceType.Dispute:
      typeString = opts.t('transaction-type.dispute');
      break;
    case TransactionSourceType.FinalSettlement:
      typeString = opts.t('transaction-type.final-settlement');
      break;
  }
  return typeString;
};
