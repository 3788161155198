import type { FC, MouseEvent, PropsWithChildren } from 'react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import type { ConfigType, Row, TabItem } from './declarations/type';
import {
  Card,
  CircularProgress,
  Divider,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
} from '@mui/material';
import { Filters } from './Filters';
import type { LabelColor } from '../Label';
import { Label } from '../Label';
import { NumberLabel } from '../NumberLabel';
import { NoDataDefaultTable } from '../table/NoDataDefaultTable';
import { Iconify } from '../Iconify';
import TableSkeleton from '../table/TableSkeleton';
import {
  ActiveFiltersBar,
  InputAdornment,
  MenuItem,
  TableMoreMenu,
  TextField,
} from '../../index';

import type {
  StructuredEvent,
  UseTrackStructuredEvent,
} from '@pflegenavi/frontend/tracking';
import { useTranslation } from 'react-i18next';

interface PaginatedTable2Props {
  name: string;
  config: ConfigType<any, any, any>;
  rows: any[];
  isLoading: boolean;
  useTrackStructuredEvent?: UseTrackStructuredEvent;
}

interface TabComponentProps {
  tab: TabItem<unknown>;
}

const TabComponent: FC<TabComponentProps> = ({ tab }) => {
  const initialTabCount = typeof tab.count === 'number' ? tab.count : undefined;
  const [count, setCount] = useState(initialTabCount);

  useEffect(() => {
    if (tab.count && typeof tab.count !== 'number') {
      tab.count.then((count) => setCount(count));
    } else if (typeof tab.count === 'number') {
      setCount(tab.count);
    }
  }, [tab.count]);

  if (count === undefined) {
    return (
      <Stack spacing={1} direction="row" alignItems="center">
        <Label
          variant={tab.variant ?? 'ghost'}
          color={tab.color as LabelColor}
          sx={{ cursor: 'pointer' }}
        >
          {tab.label}
        </Label>
      </Stack>
    );
  }

  return (
    <Stack spacing={1} direction="row" alignItems="center">
      <div>{tab.label}</div>
      <NumberLabel color={tab.color} variant="ghost">
        {count}
      </NumberLabel>
    </Stack>
  );
};

interface TabsComponentProps {
  tabs: ConfigType<any, any, any>['tabs'];
  onChange: (value: any) => void;
}

declare module '@pflegenavi/frontend/tracking' {
  interface StructuredEvents {
    PaginatedTable: StructuredEvent<
      'PaginatedTable',
      'tabChanged' | 'filterOpened' | 'filterChanged',
      string
    >;
  }
}

const TabsComponent: FC<TabsComponentProps> = ({ tabs, onChange }) => {
  return (
    <>
      <Tabs
        allowScrollButtonsMobile
        variant="scrollable"
        scrollButtons="auto"
        value={tabs?.selectedItem ?? null}
        onChange={(event, value: any) => onChange(value)}
        sx={{
          px: 2,
        }}
      >
        {tabs?.items?.map((tab) => (
          <Tab
            data-cy={`tab-${tab.value}`}
            disableRipple
            key={tab.value as unknown as string}
            value={tab.value}
            label={<TabComponent tab={tab} />}
          />
        ))}
      </Tabs>
      <Divider />
    </>
  );
};

export const PaginatedTable2: FC<PaginatedTable2Props> = ({
  name,
  config,
  rows,
  isLoading,
  useTrackStructuredEvent,
}) => {
  const { t } = useTranslation();
  const trackEvent =
    useTrackStructuredEvent?.('PaginatedTable') ??
    (() => {
      return Promise.resolve();
    });

  return (
    <Card>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          bgcolor: 'background.neutral',
        }}
      >
        {config.tabs && (
          <TabsComponent
            tabs={config.tabs}
            onChange={(value) => {
              void trackEvent({
                action: 'tabChanged',
                label: name,
                property: value,
              });
              config?.tabs?.updateFilterModel(value);
              config?.pagination?.onPageChange(0);
            }}
          />
        )}
        {config.filters && (
          <Filters
            name={name}
            trackEvent={trackEvent}
            filters={config.filters}
            onChange={(_) => {
              config?.pagination?.onPageChange(0);
            }}
          />
        )}
      </Stack>
      {config.tabs && <Divider />}

      {config.toolbar && <TableToolbar config={config} />}

      {config.filters && config.filters.showActiveFiltersBar && (
        <Stack pt={3} pb={1}>
          <ActiveFiltersBar
            filters={config.filters}
            noFiltersLabel={
              config.filters.noActiveFiltersLabel ??
              t('common.table-no-filter-set')
            }
          />
        </Stack>
      )}

      <TableContainer>
        <Table>
          <TableHeadComponent config={config} />
          <TableBodyComponent
            config={config}
            rows={rows}
            isLoading={isLoading}
          />
        </Table>
        {config.pagination && (
          <TablePagination
            rowsPerPageOptions={config.pagination.pageSizeOptions}
            colSpan={config.columns.length}
            count={config.pagination.total}
            rowsPerPage={config.pagination.pageSize}
            page={config.pagination.page}
            onPageChange={(_e, newPage) =>
              config?.pagination?.onPageChange(newPage)
            }
            onRowsPerPageChange={(event) => {
              config?.pagination?.onPageSizeChange(
                parseInt(event.target.value, 10)
              );
              config?.pagination?.onPageChange(0);
            }}
            component="div"
          />
        )}
      </TableContainer>
    </Card>
  );
};

interface TableHeadComponentProps {
  config: ConfigType<any, any, any>;
}

function TableHeadComponent({ config }: TableHeadComponentProps) {
  return (
    <TableHead>
      <TableRow>
        {config.columns.map((column) => (
          <TableCell key={column.key} align={column.align} sx={column.sx}>
            {
              // Replace space with non breaking space
              column.label.replace(' ', '\u00a0')
            }
          </TableCell>
        ))}
        {config.options && <TableCell />}
      </TableRow>
    </TableHead>
  );
}

interface TableBodyComponentProps {
  config: ConfigType<any, any, any>;
  rows: any[];
  isLoading: boolean;
}

function TableBodyComponent({ isLoading, ...props }: TableBodyComponentProps) {
  const rowsPerPage = props.config.pagination?.pageSize ?? 5;
  const colSpan = props.config.options
    ? props.config.columns.length + 1
    : props.config.columns.length;
  const rows = props.rows;
  const emptyRows = rowsPerPage - rows.length;
  const emptyRowHeight = props.config.emptyRowHeight ?? 60;

  if (isLoading) {
    return (
      <TableBody>
        {Array.from(Array(rowsPerPage).keys()).map((row: number) => (
          <TableSkeleton key={`row- ${row}`} colSpan={colSpan} />
        ))}
      </TableBody>
    );
  }

  if (rows.length === 0) {
    return (
      <TableBody>
        <NoDataDefaultTable
          isNoData
          dataAvailableBeforeFilter
          colSpan={colSpan}
        />
      </TableBody>
    );
  }

  return (
    <TableBody>
      {rows.map((row, index) => (
        <TableRowComponent key={row.id} config={props.config} row={row} />
      ))}
      {emptyRows > 0 && (
        <TableRow style={{ height: emptyRowHeight * emptyRows }}>
          <TableCell colSpan={colSpan} />
        </TableRow>
      )}
    </TableBody>
  );
}

interface TableRowMenuProps<T extends Row<unknown>> {
  options: ConfigType<T, any, any>['options'];
  row: T;
}

function TableRowMenu<T extends Row<unknown>>(props: TableRowMenuProps<T>) {
  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);
  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
    event.preventDefault();
    event.stopPropagation();
  };

  const handleCloseMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(null);
    event.preventDefault();
    event.stopPropagation();
  };

  const items = useMemo(() => {
    if (!props.options) {
      return [];
    }

    let items = [];
    if (typeof props.options.items === 'function') {
      items = props.options.items(props.row);
    } else {
      items = props.options.items;
    }

    return items.map((item) => {
      return {
        ...item,
        onClick: async (event: MouseEvent<HTMLElement>) => {
          event.preventDefault();
          event.stopPropagation();
          const result = await item.onClick(props.row);
          handleCloseMenu(event);
          return result;
        },
      };
    });
  }, [props.options, props.row]);

  if (props.options === undefined) {
    return null;
  }

  if (items.length === 0) {
    return <TableCell align="right"></TableCell>;
  }

  return (
    <TableCell align="right">
      <TableMoreMenu
        open={openMenu}
        onOpen={handleOpenMenu}
        onClose={handleCloseMenu}
        actions={
          <>
            {items.map((item) => {
              return (
                <LoadingMenuItem
                  open={Boolean(openMenu)}
                  key={item.key ?? item.label}
                  onClick={item.onClick}
                  errorVariant={item.errorVariant}
                >
                  {item.icon}
                  {item.label}
                </LoadingMenuItem>
              );
            })}
          </>
        }
      />
    </TableCell>
  );
}

interface LoadingMenuItemProps {
  onClick: (event: MouseEvent<HTMLElement>) => Promise<void>;
  open: boolean;
  errorVariant?: boolean;
}

const LoadingMenuItem: FC<PropsWithChildren<LoadingMenuItemProps>> = ({
  onClick: onClickOriginal,
  open,
  errorVariant,
  children,
}) => {
  const [loading, setLoading] = useState(false);
  const onClick = useCallback(
    async (event: MouseEvent<HTMLElement>) => {
      setLoading(true);
      await onClickOriginal(event);
    },
    [onClickOriginal]
  );
  return (
    <MenuItem
      onClick={onClick}
      disabled={loading}
      sx={{
        color: errorVariant ? 'error.main' : undefined,
      }}
    >
      {loading && <CircularProgress size={20} sx={{ mr: 1 }} />}
      {!loading && children}
    </MenuItem>
  );
};

interface TableRowComponentProps {
  config: ConfigType<any, any, any>;
  row: any;
}

function TableRowComponent(props: TableRowComponentProps) {
  const { onClickRow, hoverRow } = props.config;
  const onClick = useCallback(() => {
    onClickRow?.(props.row);
  }, [onClickRow, props.row]);

  const rowCursorStyle = useMemo(() => {
    if (onClickRow) {
      return 'pointer';
    } else {
      if (hoverRow) {
        return 'text';
      } else {
        return undefined;
      }
    }
  }, [hoverRow, onClickRow]);

  return (
    <TableRow
      hover={hoverRow ?? Boolean(onClickRow)}
      onClick={onClickRow && onClick}
      sx={rowCursorStyle ? { cursor: rowCursorStyle } : {}}
    >
      {props.config.columns.map((column) => {
        return (
          <TableCell
            key={column.key}
            align={column.align}
            sx={{
              minWidth: column.minWidth,
              maxWidth: column.maxWidth,
            }}
          >
            <column.component row={props.row} />
          </TableCell>
        );
      })}
      <TableRowMenu options={props.config.options} row={props.row} />
    </TableRow>
  );
}

interface TableToolbarProps {
  config: ConfigType<any, any, any>;
}

export const TableToolbar: FC<TableToolbarProps> = ({ config }) => {
  if (!config.toolbar?.searchBar) {
    return null;
  }

  const value = config.toolbar.searchBar.query;
  const onChangeValue = (value: string) => {
    config.pagination?.onPageChange(0);
    config.toolbar?.searchBar?.onQueryChange(value);
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={1}
      sx={{
        py: 2.5,
        px: 3,
      }}
    >
      {config.toolbar.primarySelection &&
        config.toolbar.primarySelection.component({
          onChange: (value: any) => {
            config.pagination?.onPageChange(0);
            config.toolbar?.primarySelection?.onChange(value);
          },
        })}
      <TextField
        fullWidth
        value={value}
        onChange={(event) => onChangeValue(event.target.value)}
        placeholder={config.toolbar.searchBar.label}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify
                icon={'eva:search-fill'}
                sx={{
                  color: 'text.disabled',
                  width: 20,
                  height: 20,
                }}
              />
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  );
};
