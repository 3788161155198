import type { StandardTextFieldProps } from '@mui/material';
import { TextField } from '@mui/material';
import type { FormikProps } from 'formik';
import { getIn } from 'formik';
import type { ChangeEvent } from 'react';
import { useCallback } from 'react';
import { NUMBER_WITH_TWO_DECIMALS } from '@pflegenavi/shared/constants';
import type { NestedKeysToObject } from './FTextInput';

const exceptThisSymbols = (allowNegative: boolean) =>
  allowNegative ? ['e', 'E', '+'] : ['e', 'E', '+', '-'];
const decimalSeparators = ['.', ','];
const positiveIntegerRegex = /^\d+$/;
const negativeIntegerRegex = /^-\d+[.,]?\d{0,2}$/;
const NUMBER_WITH_TWO_DECIMALS_AND_NEGATIVE = /^-?\d+[.,]?\d{0,2}$/;

type StandardTextFieldPropsWithFilled = Omit<
  StandardTextFieldProps,
  'variant'
> & {
  variant?: 'standard' | 'filled';
};

interface FNumberInputProps<T extends string, V extends NestedKeysToObject<T>>
  extends IProps {
  formik: FormikProps<V>;
  name: T;
  defaultValue?: T;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const FNumberInputStateless = <
  Field extends string,
  V extends NestedKeysToObject<Field>
>({
  formik,
  name,
  defaultValue,
  onChange,
  ...props
}: FNumberInputProps<Field, V>): JSX.Element => {
  const formikValue = getIn(formik.values, name);

  const { handleChange } = formik;
  const onChangeCallback = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange ? onChange(event) : handleChange(event);
    },
    [onChange, handleChange]
  );

  return (
    <NumberInputStateless
      value={defaultValue ?? formikValue ?? ''}
      onChange={onChangeCallback}
      disabled={formik.isSubmitting}
      onBlur={formik.handleBlur}
      helperText={getIn(formik.touched, name) && getIn(formik.errors, name)}
      error={Boolean(getIn(formik.errors, name) && getIn(formik.touched, name))}
      {...props}
    />
  );
};

interface IProps extends StandardTextFieldPropsWithFilled {
  allowDecimal?: boolean;
  allowNegative?: boolean;
}
export const NumberInputStateless = ({
  inputProps,
  InputProps,
  InputLabelProps,
  allowDecimal = true,
  allowNegative = false,
  onChange,
  value,
  ...props
}: IProps): JSX.Element => {
  const validateInput = (event: any) => {
    const alreadyEnteredValue = event.target.value;

    // Checking the `event.nativeEvent.data` for the minus sign is necessary
    // to prevent the user from entering a minus sign anywhere else but the start
    if (alreadyEnteredValue === '' && event.nativeEvent.data !== '-') {
      onChange?.(event);
      return;
    }

    if (allowNegative && !allowDecimal) {
      if (negativeIntegerRegex.test(alreadyEnteredValue)) {
        onChange?.(event);
        return;
      }
    }

    if (allowDecimal) {
      if (
        (allowNegative
          ? NUMBER_WITH_TWO_DECIMALS_AND_NEGATIVE
          : NUMBER_WITH_TWO_DECIMALS
        ).test(alreadyEnteredValue)
      ) {
        onChange?.(event);
        return;
      }
    }

    if (positiveIntegerRegex.test(alreadyEnteredValue)) {
      onChange?.(event);
      return;
    }
  };

  const handleKeyDownEvent = (event: any) => {
    const disableDecimal =
      allowDecimal === false && decimalSeparators.includes(event.key);
    const notAllowedSymbol =
      exceptThisSymbols(allowNegative).includes(event.key) ||
      (value === '' && decimalSeparators.includes(event.key));

    if (disableDecimal || notAllowedSymbol) {
      event.stopPropagation();
      event.preventDefault();
      return;
    }
  };

  return (
    <TextField
      onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
      type="number"
      InputLabelProps={{
        ...InputLabelProps,
      }}
      InputProps={{
        ...InputProps,
      }}
      inputProps={{
        ...inputProps,
        onKeyDown: handleKeyDownEvent,
      }}
      value={value}
      onPaste={validateInput}
      onChange={validateInput}
      {...props}
    />
  );
};
