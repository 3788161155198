import type { FC } from 'react';
import React from 'react';
import { Stack, Typography } from '@mui/material';
import { Trans } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface SelectionHintProps {}

export const SelectionHint: FC<SelectionHintProps> = () => {
  return (
    <Stack sx={{ height: 162.35 }}>
      <Stack
        direction="row"
        sx={{
          backgroundColor: 'grey.200',
          borderRadius: 2,
          p: 2,
          height: 162.35,
        }}
      >
        <Typography variant="body2" fontSize={14} color="grey.600">
          <Trans
            i18nKey="quick-selection.help-text"
            components={{
              bold: <strong />,
              br: <br />,
            }}
          />
        </Typography>
      </Stack>
    </Stack>
  );
};
