import { styled } from '@mui/material/styles';
import { Typography } from '@pflegenavi/web-components';

export const LetterChip = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    borderRadius: 2,
    backgroundColor: 'primary.light',
    color: 'white',
    fontSize: 16,
    width: 32,
    height: 32,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  })
);
