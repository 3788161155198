import type { FC, ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { Modal, ModalInner, NameAvatar } from '@pflegenavi/web-components';
import { useTestMode } from '@pflegenavi/frontend/authentication';
import {
  FormControlLabel,
  Stack,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import type { TestPaymentApi } from '@pflegenavi/frontend/api-nursing-home';
import {
  usePaymentApi,
  useResidents,
} from '@pflegenavi/frontend/api-nursing-home';
import { useFeatureFlag } from '@pflegenavi/frontend/feature-flags';
import {
  FeatureFlag,
  FeatureFlagStatus,
  PendingChargePaymentStatus,
} from '@pflegenavi/shared/api';
import { useQueryClient } from 'react-query';
import { useNursingHomeContext } from '@pflegenavi/frontend/nursing-home-context';

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface TestingModalProps {}

export const TestingModal: FC<TestingModalProps> = () => {
  const status = useFeatureFlag(FeatureFlag.DevTools);

  const [open, setOpen] = useState(false);
  const [testMode, setTestMode] = useTestMode();

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.key === 'T' && event.shiftKey && event.altKey) {
        setOpen(true);
        event.preventDefault();
        event.stopPropagation();
      }
    };
    if (status === FeatureFlagStatus.Disabled) {
      return () => {
        return;
      };
    }

    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [status]);

  return (
    <Modal open={open} handleClose={() => setOpen(false)}>
      <ModalInner>
        <Stack direction="column">
          <Stack direction="row">
            <FormControlLabel
              control={
                <Switch
                  checked={testMode}
                  onChange={(_e, checked) => setTestMode(checked)}
                />
              }
              label={'Test mode'}
            />
          </Stack>
          {testMode && <PaymentStatusResult />}
        </Stack>
      </ModalInner>
    </Modal>
  );
};

interface SectionProps {
  children: ReactNode;
  title: string;
}

const Section: FC<SectionProps> = ({ children, title }) => {
  return (
    <Stack direction="column" gap={1}>
      <Typography>{title}</Typography>
      {children}
    </Stack>
  );
};

const paymentStatuses = [
  PendingChargePaymentStatus.Idle,
  PendingChargePaymentStatus.StripeAccountDeactivated,
  PendingChargePaymentStatus.ResidentNotActive,
  PendingChargePaymentStatus.PrimaryFamilyMemberMissing,
  PendingChargePaymentStatus.DirectDebitMissing,
  PendingChargePaymentStatus.AmountTooHigh,
  PendingChargePaymentStatus.AmountTooLow,
  PendingChargePaymentStatus.Initiating,
  PendingChargePaymentStatus.Upcoming,
  PendingChargePaymentStatus.Pending,
  PendingChargePaymentStatus.Success,
  PendingChargePaymentStatus.Failed,
  PendingChargePaymentStatus.Frozen,
];

export const PaymentStatusResult: FC = () => {
  const [, rerender] = useState({});
  const { selectedNursingHome } = useNursingHomeContext();
  const { data: residents, isLoading } = useResidents(selectedNursingHome?.id, {
    keepPreviousData: true,
  });
  const api = usePaymentApi() as TestPaymentApi;

  const client = useQueryClient();

  useEffect(() => {
    residents?.forEach((resident) =>
      api.initialize(resident.id, PendingChargePaymentStatus.Idle)
    );
    client
      .resetQueries({
        predicate: (query) => query.queryKey.toString().startsWith('PAYMENT'),
      })
      .then(() => rerender({}));
  }, [api, client, residents]);

  if (isLoading) {
    return null;
  }

  return (
    <Section title="Payment state">
      {residents?.map((resident) => {
        return (
          <Stack key={resident.id} direction="row">
            <NameAvatar name={`${resident.firstName} ${resident.lastName}`} />
            {resident.firstName} {resident.lastName}
            <ToggleButtonGroup
              color="primary"
              value={api.testGetPaymentStatus(resident.id)}
              exclusive
              onChange={(event) => {
                // @ts-expect-error // value untyped
                api.setPaymentStatus(resident.id, event.target.value);
                client
                  .resetQueries({
                    predicate: (query) => {
                      return query.queryKey.toString().startsWith('PAYMENT');
                    },
                  })
                  .then(() => rerender({}));
              }}
            >
              {paymentStatuses.map((paymentStatus) => {
                return (
                  <ToggleButton key={paymentStatus} value={paymentStatus}>
                    {paymentStatus}
                  </ToggleButton>
                );
              })}
            </ToggleButtonGroup>
          </Stack>
        );
      })}
    </Section>
  );
};
