import { makeApiProvider } from '@pflegenavi/shared-frontend/platform';
import { createContext } from 'react';
import type {
  CreateCashListTransactionGroupParams,
  ICashManagementPhoenixApi,
} from './api';
import { CashManagementPhoenixApi } from './api';
import type { UseMutationResult } from 'react-query';
import { useMutation, useQueryClient } from 'react-query';
import {
  CASH_LIST_CONFIGURATION_KEY,
  CASH_TRANSACTION_GROUP_LIST_KEY,
} from '../cash-management/queryKeys';
import { PAYMENTS_INFO_KEY } from '../payment-phoenix';

export type { ICashManagementPhoenixApi } from './api';

const ApiContext = createContext<ICashManagementPhoenixApi | undefined>(
  undefined
);
const {
  useApi: useCashManagementPhoenixApi,
  ApiProvider: CashManagementPhoenixApiProvider,
} = makeApiProvider({
  name: 'CashManagementPhoenixApi',
  ApiContext,
  newApi: (tenantId, auth, apiUrl) =>
    new CashManagementPhoenixApi(tenantId, auth, apiUrl),
});

export {
  useCashManagementPhoenixApi,
  CashManagementPhoenixApiProvider,
  ApiContext as CashManagementPhoenixApiContext,
};

export function useCashListTransactionGroupCreate(
  nursingHomeId: string | undefined
): UseMutationResult<
  { id: string },
  unknown,
  CreateCashListTransactionGroupParams
> {
  const api = useCashManagementPhoenixApi();
  const queryClient = useQueryClient();
  const result = useMutation<
    { id: string },
    unknown,
    CreateCashListTransactionGroupParams
  >(
    'cash-transaction-group-add-transaction',
    (data) => api.createCashListTransactionGroup(data),
    {
      onSuccess: async () => {
        return await Promise.all([
          queryClient.invalidateQueries(CASH_TRANSACTION_GROUP_LIST_KEY),
          queryClient.invalidateQueries(PAYMENTS_INFO_KEY(undefined)),
          queryClient.invalidateQueries(
            CASH_LIST_CONFIGURATION_KEY(nursingHomeId)
          ),
        ]);
      },
    }
  );
  return result;
}
