import type {
  GetFilteredBalanceChangesQueryParams,
  GetTotalResidentBalanceQueryParams,
  ReceiptsAndReceiptBatchesQueryParams,
} from '@pflegenavi/shared/api';
import type {
  GetReceiptBatchesQueryDto,
  GetTransactionsPaginatedQueryParams,
} from '@pflegenavi/shared/api';
import type { QueryKey } from 'react-query';

export const RECEIPTS_KEY = 'receipts';
export const getReceiptKey = (
  id: string,
  params?: Record<string, any>
): QueryKey => [
  RECEIPTS_KEY,
  id,
  {
    ...params,
  },
];
export const RECEIPTS_KEY_NURSING_HOME = (
  nursingHomeId: string | undefined,
  params?: Record<string, any>
): QueryKey => [RECEIPTS_KEY, nursingHomeId, params ?? {}];
export const TRANSACTION_TYPES_KEY = 'receiptTypes';

export const CREATE_RECEIPT_KEY: QueryKey = 'create-transaction';
export const UPDATE_TRANSACTION_KEY: QueryKey = 'update-transaction';
export const DELETE_TRANSACTION_KEY: QueryKey = 'delete-transaction';
export const CANCEL_RECEIPT_KEY = (receiptId: string): QueryKey =>
  `cancel-receipt-${receiptId}`;
export const SUBMIT_RECEIPTS_KEY: QueryKey = 'submit-receipts';
export const MANUAL_DEPOSIT_KEY: QueryKey = 'manual-deposit';
export const MANUAL_DEPOSIT_CANCELLATION_KEY = 'manual-deposit-cancellation';
export const getReceiptImagesKey = (id?: string): QueryKey =>
  `receipt-images-multi-${id}`;

export const CREATE_RECEIPT_BATCH = 'create-receipt-batch';
export const CREATE_RECEIPT_BATCH_ENTRY = 'create-receipt-batch-entry';
export const RECEIPT_BATCH_LIST_KEY = (
  nursingHomeId: string | undefined
): QueryKey => ['receipt-batch_list', nursingHomeId];
export const RECEIPT_BATCH_KEY = (batchId?: string): QueryKey => [
  'receipt-batch',
  batchId,
];
export const UPDATE_RECEIPT_BATCH: QueryKey = 'update-receipt-batch';
export const UPDATE_RECEIPT_BATCH_ENTRY: QueryKey =
  'update-receipt-batch-entry';
export const DELETE_RECEIPT_BATCH: QueryKey = 'delete-receipt-batch';
export const DELETE_RECEIPT_BATCH_ENTRY_KEY: QueryKey = [
  'deleteReceiptBatchEntry',
];
export const CREATE_RECEIPT_BATCH_JOB: QueryKey = 'create-receipt-batch-job';
export const RECEIPT_BATCH_LIST_PAGINATED_KEY = (
  opts: Partial<GetReceiptBatchesQueryDto> & {
    nursingHomeId: string | undefined;
  }
): QueryKey => [
  'receipt-batch_list-paginated',
  opts?.nursingHomeId,
  {
    ...opts,
  },
];

export const RECEIPTS_AND_RECEIPT_BATCHES_PREFIX =
  'receipts-and-receipt-batches';
export const RECEIPTS_AND_RECEIPT_BATCHES_KEY = (
  nursingHomeId: string | undefined,
  params?: Omit<ReceiptsAndReceiptBatchesQueryParams, 'page'>
): QueryKey => [
  RECEIPTS_AND_RECEIPT_BATCHES_PREFIX,
  nursingHomeId,
  params ?? {},
];

export const TRANSACTIONS_PAGINATED_KEY = (
  opts: Partial<
    GetTransactionsPaginatedQueryParams & {
      nursingHomeId: string | undefined;
    }
  >
): QueryKey => [
  opts.nursingHomeId,
  {
    ...opts,
  },
];

export const TOTAL_BALANCE_PERIOD_KEY = (
  opts: GetTotalResidentBalanceQueryParams & {
    nursingHomeId: string | undefined;
  }
): QueryKey => [
  'total-balance-period',
  opts.nursingHomeId,
  {
    ...opts,
  },
];

export const FILTERED_BALANCE_CHANGES_PERIOD_KEY = (
  opts: GetFilteredBalanceChangesQueryParams & {
    nursingHomeId: string | undefined;
  }
): QueryKey => [
  'filtered-balance-changes-period',
  opts.nursingHomeId,
  {
    ...opts,
  },
];

export const RECEIPT_BATCH_JOB_KEY = (batchJobId?: string): QueryKey => [
  'receipt-batch-job',
  batchJobId,
];
export const RESIDENT_BALANCE_KEY = (
  residentId?: string,
  date?: Date
): QueryKey => ['resident-balance', residentId, date];

export const getTransactionsKey = (
  residentId: string,
  transactionTypes: string[] = []
): string => `transactions-${residentId}-${transactionTypes.join('-')}`;

export const getTransactionsAsFamilyMemberKey = (
  residentId: string,
  transactionTypes: string[] = []
): string =>
  `transactions-family-member-${residentId}-${transactionTypes.join('-')}`;
