import type React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { ChangeCashMode } from '../form/model';
import { cashStateToCoins } from '../euro';
import type { Coin } from '@pflegenavi/shared/api';
import { getCoinsFittingIntoAmount } from './quickSelection';
import type { QuickSelectionCardProps } from './QuickSelectionCard';

export const useQuickSelection = ({
  targetAmount,
  mode,
  bankNotes,
  cashState,
  setCashState,
  coins,
}: Omit<QuickSelectionCardProps, 'setTargetAmount'>): {
  overrideFactor: (factor: number, target: number) => void;
  incrementFactor: (factor: number) => void;
  decrementFactor: (factor: number) => void;
  targetReached: boolean;
  fittingCoins: Coin[];
  handleCoinSelect: (event: React.MouseEvent, coin: Coin) => void;
  showAllCoins: boolean;
  onlyShowCash: boolean;
  selectedCoins: Coin[];
  setShowAllCoins: React.Dispatch<React.SetStateAction<boolean>>;
} => {
  const incrementFactor = useCallback(
    (factor: number) => {
      setCashState((state) => {
        return {
          ...state,
          [factor]: (state[factor] ?? 0) + 1,
        };
      });
    },
    [setCashState]
  );

  const decrementFactor = useCallback(
    (factor: number) => {
      setCashState((state) => {
        return {
          ...state,
          [factor]: (state[factor] ?? 0) > 0 ? state[factor] - 1 : 0,
        };
      });
    },
    [setCashState]
  );

  const overrideFactor = useCallback(
    (factor: number, newValue: number) => {
      setCashState((state) => {
        return {
          ...state,
          [factor]: newValue,
        };
      });
    },
    [setCashState]
  );

  // Don't show target amount and selection suggestion.
  // With this flag, the default behaviour is to show all coins (the user can still hide the ones not in use)
  const onlyShowCash = mode === ChangeCashMode.Adjust;

  const [showAllCoins, setShowAllCoins] = useState(onlyShowCash);

  const { remainingAmount, selectedCoins } = useMemo(() => {
    const newCashState = { ...cashState };
    if (showAllCoins) {
      const allCoins = (coins ?? []).concat(bankNotes ?? []);
      for (const coin of allCoins) {
        if (newCashState[coin.factor] === undefined) {
          newCashState[coin.factor] = 0;
        }
      }
    }

    const selectedCoins = cashStateToCoins(newCashState);

    let remaining = targetAmount;
    for (const coin of selectedCoins) {
      remaining -= coin.factor * coin.amount;
    }
    return {
      remainingAmount: remaining,
      selectedCoins,
    };
  }, [targetAmount, cashState, coins, bankNotes, showAllCoins]);

  const handleCoinSelect = (event: React.MouseEvent, coin: Coin) => {
    if (event.ctrlKey) {
      overrideFactor(coin.factor, coin.amount);
    } else {
      overrideFactor(coin.factor, 1);
    }
  };

  const allCoins = (coins ?? []).concat(bankNotes ?? []);
  const fittingCoins = allCoins
    ? getCoinsFittingIntoAmount(remainingAmount, allCoins, {
        ignoreAvailableCoins: mode === ChangeCashMode.Deposit,
        blacklist: selectedCoins,
      })
    : [];

  const targetReached = targetAmount !== 0 && remainingAmount === 0;

  return {
    overrideFactor,
    incrementFactor,
    decrementFactor,
    targetReached,
    fittingCoins,
    handleCoinSelect,
    showAllCoins,
    onlyShowCash,
    selectedCoins,
    setShowAllCoins,
  };
};
