import {
  ArrayNotEmpty,
  IsDate,
  IsEnum,
  IsNotEmpty,
  IsNumber,
  IsString,
  IsUUID,
  ValidateNested,
} from 'class-validator';
import { CashTransactionGroupType } from './CashTransactionGroupType';

import { Coin } from './GetCashManagementDto';

export class CashListTransactionGroupTransactionDto {
  @IsNotEmpty()
  @IsString()
  @IsUUID(4)
  id!: string;

  @IsNotEmpty()
  @IsNumber()
  total!: number;

  @IsNotEmpty()
  @ArrayNotEmpty()
  @ValidateNested({ each: true })
  coin!: Coin[];
}

export class CashListTransactionGroupDto {
  @IsString()
  @IsUUID(4)
  id!: string;

  @IsString()
  @IsUUID(4)
  cashListId!: string;

  @IsNotEmpty()
  @IsNumber()
  amount!: number;

  @IsNotEmpty()
  @IsDate()
  creationDate!: Date;

  @IsNotEmpty()
  @IsEnum(CashTransactionGroupType)
  type!: CashTransactionGroupType;

  @IsNotEmpty()
  @IsNumber()
  transactionCount!: number;
}

export class CashListTransactionGroupWithTransactionsDto extends CashListTransactionGroupDto {
  @IsNotEmpty()
  @ValidateNested({ each: true })
  transactions!: CashListTransactionGroupTransactionDto[];
}
