import { useCallback, useState } from 'react';

interface UseTablePaginationProps<TColumn = string> {
  defaultOrderBy: TColumn;
  defaultOrder?: 'asc' | 'desc';
  defaultCurrentPage?: number;
}

export const useTablePagination = <TColumn extends string = string>(
  props: UseTablePaginationProps<TColumn>
): {
  orderBy: TColumn;
  order: 'asc' | 'desc';
  page: number;
  setOrderBy: (orderBy: TColumn) => void;
  setOrder: (order: 'asc' | 'desc') => void;
  setPage: (page: number) => void;
} => {
  const [page, setPage] = useState<number>(props?.defaultCurrentPage || 0);
  const [orderBy, setOrderBy] = useState<TColumn>(props?.defaultOrderBy);

  const [order, setOrder] = useState<'asc' | 'desc'>(
    props?.defaultOrder || 'asc'
  );

  const handleSetOrderBy = useCallback((orderBy: TColumn) => {
    setOrderBy(orderBy);
    setPage(0);
  }, []);

  const handleSetOrder = useCallback((order: 'asc' | 'desc') => {
    setOrder(order);
    setPage(0);
  }, []);

  return {
    orderBy,
    order,
    page,
    setOrderBy: handleSetOrderBy,
    setOrder: handleSetOrder,
    setPage,
  };
};
