import type { UseMutationResult } from 'react-query';
import {
  useQueryClient,
  type QueryKey,
  type UseQueryOptions,
  type UseQueryResult,
  useMutation,
} from 'react-query';
import { createContext, useCallback } from 'react';
import {
  makeApiProvider,
  useApiQuery,
} from '@pflegenavi/shared-frontend/platform';
import type {
  GetAllPaymentInfoResponse,
  GetReceiptBatchPaymentPhoenixResult,
  IPaymentPhoenixApi,
  MangopayPaymentResolution,
  MangopayReceiptBatchEntry,
  MangopayReceiptBatchPayment,
  PayinRequest,
  PaymentInfo,
} from './api';
import { MangopayPaymentStatus, PaymentPhoenixApi } from './api';
import { PendingChargePaymentStatus } from '@pflegenavi/shared/api';

const ApiContext = createContext<IPaymentPhoenixApi | undefined>(undefined);
const { useApi: usePaymentPhoenixApi, ApiProvider: PaymentPhoenixApiProvider } =
  makeApiProvider({
    name: 'PaymentPhoenix',
    ApiContext,
    newApi: (tenantId, auth, apiUrl) => {
      return new PaymentPhoenixApi(tenantId, auth, apiUrl);
    },
  });

export {
  usePaymentPhoenixApi,
  PaymentPhoenixApiProvider,
  ApiContext as PaymentPhoenixApiContext,
  GetReceiptBatchPaymentPhoenixResult,
  MangopayReceiptBatchEntry,
  MangopayPaymentResolution,
  MangopayPaymentStatus,
  MangopayReceiptBatchPayment,
  GetAllPaymentInfoResponse,
  PaymentInfo,
};

export const getReceiptBatchPaymentPhoenix = (
  receiptBatchId: string | undefined
): QueryKey => ['GET_RECEIPT_BATCH_PAYMENT_PHOENIX', receiptBatchId];

export const useGetNursingHomeReceiptBatchPaymentPhoenix = ({
  receiptBatchId,
  options,
}: {
  receiptBatchId: string | undefined;
  options?: UseQueryOptions<GetReceiptBatchPaymentPhoenixResult>;
}): UseQueryResult<GetReceiptBatchPaymentPhoenixResult> => {
  return useApiQuery(
    usePaymentPhoenixApi,
    getReceiptBatchPaymentPhoenix(receiptBatchId),
    (api) => {
      if (!receiptBatchId) {
        throw new Error('receiptBatchId is required to get payment');
      }
      return api.getReceiptBatchPaymentPhoenix({
        params: {
          receiptBatchId,
        },
      });
    },
    {
      ...options,
      enabled: options?.enabled ?? !!receiptBatchId,
    }
  );
};

export const PAYMENTS_INFO_KEY = (residentId: string | undefined): QueryKey => {
  if (!residentId) {
    return ['payments-info'];
  }
  return ['payments-info', residentId];
};

export const useGetAllPaymentInfoPhoenix = (
  nursingHomeId?: string,
  options?: UseQueryOptions<GetAllPaymentInfoResponse>
): UseQueryResult<GetAllPaymentInfoResponse> => {
  return useApiQuery(
    usePaymentPhoenixApi,
    ['GET_ALL_PAYMENT_INFO_PHOENIX', nursingHomeId],
    (api) => {
      if (!nursingHomeId) {
        throw new Error('nursingHomeId is required');
      }
      return api.getAllPaymentInfo({
        params: {
          nursingHomeId,
        },
      });
    },
    options
  );
};

export const useGetPaymentInfoPhoenix = (
  residentId: string,
  options?: UseQueryOptions<PaymentInfo, unknown, PaymentInfo>
): UseQueryResult<PaymentInfo> => {
  const select = useCallback((data: PaymentInfo): PaymentInfo => {
    return {
      ...data,
      additional_data: {
        ...data.additional_data,
        nursing_home_payments_disabled_until: data.additional_data
          .nursing_home_payments_disabled_until
          ? new Date(data.additional_data.nursing_home_payments_disabled_until)
          : undefined,
      },
      pending_charge: data.pending_charge
        ? {
            ...data.pending_charge,
            charge_date: new Date(data.pending_charge.charge_date),
          }
        : undefined,
    };
  }, []);

  return useApiQuery(
    usePaymentPhoenixApi,
    PAYMENTS_INFO_KEY(residentId),
    (api) => {
      return api?.getPaymentInfo({
        params: {
          residentId,
        },
      });
    },
    {
      ...options,
      select,
      enabled: residentId !== '',
    }
  );
};

export const mapPreconditionsToPendingChargePaymentStatus = (
  paymentInfo?: PaymentInfo
): PendingChargePaymentStatus => {
  if (!paymentInfo) {
    return PendingChargePaymentStatus.Idle;
  }

  if (paymentInfo.pending_charge) {
    return paymentInfo.pending_charge
      .pending_charge_status as PendingChargePaymentStatus;
  }

  if (paymentInfo.preconditions.disabled_for_nursing_home) {
    return PendingChargePaymentStatus.DisabledForNursingHome;
  }
  if (paymentInfo.preconditions.disabled) {
    return PendingChargePaymentStatus.Disabled;
  }
  if (paymentInfo.preconditions.amount_too_high) {
    return PendingChargePaymentStatus.AmountTooHigh;
  }
  if (paymentInfo.preconditions.amount_too_low) {
    return PendingChargePaymentStatus.AmountTooLow;
  }
  if (paymentInfo.preconditions.direct_debit_missing) {
    return PendingChargePaymentStatus.DirectDebitMissing;
  }
  if (paymentInfo.preconditions.primary_family_member_missing) {
    return PendingChargePaymentStatus.PrimaryFamilyMemberMissing;
  }
  if (paymentInfo.preconditions.resident_not_active) {
    return PendingChargePaymentStatus.ResidentNotActive;
  }
  if (paymentInfo.preconditions.stripe_account_deactivated) {
    return PendingChargePaymentStatus.StripeAccountDeactivated;
  }

  return PendingChargePaymentStatus.Idle;
};

export const useGetPaymentInfoForFamilyMemberPhoenix = (
  residentId: string,
  tenant: string,
  options?: UseQueryOptions<PaymentInfo, unknown, PaymentInfo>
): UseQueryResult<PaymentInfo> => {
  const select = useCallback((data: PaymentInfo): PaymentInfo => {
    return {
      ...data,
      additional_data: {
        ...data.additional_data,
        nursing_home_payments_disabled_until: data.additional_data
          .nursing_home_payments_disabled_until
          ? new Date(data.additional_data.nursing_home_payments_disabled_until)
          : undefined,
      },
      pending_charge: data.pending_charge
        ? {
            ...data.pending_charge,
            charge_date: new Date(data.pending_charge.charge_date),
          }
        : undefined,
    };
  }, []);

  return useApiQuery(
    usePaymentPhoenixApi,
    PAYMENTS_INFO_KEY(residentId),
    (api) => {
      return api?.getPaymentInfoForFamilyMember({
        params: {
          residentId,
          tenant,
        },
      });
    },
    {
      ...options,
      select,
      enabled: residentId !== '',
    }
  );
};

export const useCreatePayinPhoenix = (): UseMutationResult<
  void,
  unknown,
  PayinRequest
> => {
  const api = usePaymentPhoenixApi();
  const queryClient = useQueryClient();
  return useMutation(
    'CREATE_PAYIN',
    (params) => {
      return api.createPayin(params);
    },
    {
      onSuccess: async (_, params) => {
        await Promise.all([
          queryClient.invalidateQueries(
            PAYMENTS_INFO_KEY(params.params.resident_id)
          ),
        ]);
      },
    }
  );
};
