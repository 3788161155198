import { useEffect, useMemo, useRef } from 'react';
import type {
  QueryKey,
  UseInfiniteQueryResult,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { useInfiniteQuery, useQuery, useQueryClient } from 'react-query';
import type { Api } from '../makeApiProvider';
import type { UseInfiniteQueryOptions } from 'react-query/types/react/types';
import type { PaginatedResultSet } from '@pflegenavi/shared/utils';

export const useApiQuery = <A extends Api | undefined, T, U>(
  useApi: () => A,
  key: QueryKey,
  callback: (api: A) => Promise<U>,
  options?: Omit<UseQueryOptions<U, unknown, T>, 'queryKey' | 'queryFn'>
): UseQueryResult<T> => {
  const api = useApi();

  const enabled =
    (options?.enabled ?? true) &&
    api?.authContext.getTokenImmediate() !== undefined;

  const result = useQuery(
    key,
    () => {
      // While we also disable the query we still need to check in here
      // because refetch in the useEffect hook will still be called and ignores the enabled option
      if (
        options?.enabled === false ||
        api?.authContext.getTokenImmediate() === undefined
      ) {
        return undefined as unknown as U;
      }
      return callback(api);
    },
    {
      ...options,
      enabled,
    }
  );

  const { refetch } = result;

  const userId = useMemo(
    () => api?.authContext.user?.userId,
    [api?.authContext]
  );

  const isIdleRef = useRef(result.isIdle);

  useEffect(() => {
    // We only want to refetch if we transition from idle to not idle.
    const transitionedFromIdle = isIdleRef.current && !result.isIdle;
    if (userId !== undefined && transitionedFromIdle) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, result.isIdle]);

  // Order matters. This needs to run after the refetch useEffect
  useEffect(() => {
    if (isIdleRef.current !== result.isIdle) {
      isIdleRef.current = result.isIdle;
    }
  }, [result.isIdle]);

  return result;
};

/**
 * Same as useApiQuery, but allows to refetch when enabled is false (like useQuery default behaviour)
 */
export const useApiQueryNoRefetchOverride = <A extends Api | undefined, T, U>(
  useApi: () => A,
  key: QueryKey,
  callback: (api: A) => Promise<U>,
  options?: Omit<UseQueryOptions<U, unknown, T>, 'queryKey' | 'queryFn'>
): UseQueryResult<T> => {
  const api = useApi();

  // We disable the query if there is no token available
  const enabled =
    api?.authContext.getTokenImmediate() !== undefined
      ? options?.enabled
      : false;

  const result = useQuery(
    key,
    () => {
      // While we also disable the query we still need to check in here
      // because refetch in the useEffect hook will still be called and ignores the enabled option
      if (api?.authContext.getTokenImmediate() === undefined) {
        return undefined as unknown as U;
      }
      return callback(api);
    },
    {
      ...options,
      enabled,
    }
  );

  const { refetch } = result;

  const userId = useMemo(
    () => api?.authContext.user?.userId,
    [api?.authContext]
  );

  const isIdleRef = useRef(result.isIdle);

  useEffect(() => {
    // We only want to refetch if we transition from idle to not idle.
    const transitionedFromIdle = isIdleRef.current && !result.isIdle;
    if (userId !== undefined && transitionedFromIdle) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, result.isIdle]);

  // Order matters. This needs to run after the refetch useEffect
  useEffect(() => {
    if (isIdleRef.current !== result.isIdle) {
      isIdleRef.current = result.isIdle;
    }
  }, [result.isIdle]);

  return result;
};

export const usePrefetchApiQuery = <A extends Api, T>(
  useApi: () => A,
  key: QueryKey,
  callback: (api: A) => Promise<T>,
  enabled?: boolean
): void => {
  const api = useApi();
  const client = useQueryClient();
  useEffect(() => {
    (async () => {
      if (enabled && api.authContext.user?.userId !== undefined) {
        await client.prefetchQuery(key, () => callback(api));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api.authContext.user?.userId, key, enabled]);
};

export const useApiInfiniteQuery = <
  A extends Api | undefined,
  T,
  U extends PaginatedResultSet<any>
>(
  useApi: () => A,
  key: QueryKey,
  callback: (api: A, page: number) => Promise<U>,
  options?: Omit<UseInfiniteQueryOptions<U, unknown, T>, 'queryKey' | 'queryFn'>
): UseInfiniteQueryResult<T> => {
  const api = useApi();

  const enabled =
    (options?.enabled ?? true) &&
    api?.authContext.getTokenImmediate() !== undefined;

  const result = useInfiniteQuery(
    key,
    ({ pageParam = 0 }) => {
      // While we also disable the query we still need to check in here
      // because refetch in the useEffect hook will still be called and ignores the enabled option
      if (
        options?.enabled === false ||
        api?.authContext.getTokenImmediate() === undefined
      ) {
        return undefined as unknown as U;
      }
      return callback(api, pageParam);
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.meta.currentPage === lastPage.meta.totalPages - 1) {
          return undefined;
        }
        return lastPage.meta.currentPage + 1;
      },
      getPreviousPageParam: (firstPage) => {
        if (firstPage.meta.currentPage === 0) {
          return undefined;
        }
        return firstPage.meta.currentPage - 1;
      },
      ...options,
      enabled,
    }
  );

  const { refetch } = result;

  const userId = useMemo(
    () => api?.authContext.user?.userId,
    [api?.authContext]
  );

  useEffect(() => {
    if (userId !== undefined && !result.isIdle) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, result.isIdle]);

  return result;
};
