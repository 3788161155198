import type { FC } from 'react';
import React, { memo } from 'react';
import { Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';
import { MoneyInput } from '@pflegenavi/web-components';
import { useTranslation } from 'react-i18next';
import { useFormatting } from '@pflegenavi/frontend/localization';

interface ReceiptAmountInputProps {
  value: number | undefined;
  handleSetReceiptAmount: (amount: number) => void;
  handleResetReceiptAmount: () => void;
  isSplitAmountEnabled: boolean;
  handleSetSplitAmountEnabled: (enabled: boolean) => void;
  selectedCount: number;
}

export const ReceiptAmountInput: FC<ReceiptAmountInputProps> = memo(
  ({
    value,
    handleSetReceiptAmount,
    handleResetReceiptAmount,
    isSplitAmountEnabled,
    handleSetSplitAmountEnabled,
    selectedCount,
  }) => {
    const { t } = useTranslation();
    const { fCurrencyCents } = useFormatting();

    const splitAmount = isSplitAmountEnabled
      ? (value ?? 0) / (selectedCount || 1)
      : undefined;

    return (
      <Stack alignItems="flex-start" gap={1}>
        <Typography variant="body1" fontWeight={700}>
          {t(
            'receipts.batch.form.resident-quick-selection.pre-selected-receipt-amount'
          )}
        </Typography>
        <Stack gap={1} width="100%">
          <MoneyInput
            label={t('receipts.batch.form.resident-quick-selection.amount')}
            placeholder={t(
              'receipts.batch.form.resident-quick-selection.amount'
            )}
            value={value}
            onChange={(newAmount) => {
              if (newAmount) {
                handleSetReceiptAmount(newAmount);
              } else {
                handleResetReceiptAmount();
              }
            }}
          />
          <Typography variant="caption" color="grey.500">
            {isSplitAmountEnabled
              ? t(
                  'receipts.batch.form.resident-quick-selection.receipt-amount-description-split'
                )
              : t(
                  'receipts.batch.form.resident-quick-selection.receipt-amount-description'
                )}
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={isSplitAmountEnabled}
                onChange={(e) => handleSetSplitAmountEnabled(e.target.checked)}
              />
            }
            label={
              <Stack>
                <Typography>
                  {t(
                    'receipts.batch.form.resident-quick-selection.split-amount'
                  )}
                </Typography>
                {isSplitAmountEnabled && (
                  <Typography variant="caption" color="text.secondary">
                    {t(
                      'receipts.batch.form.resident-quick-selection.split-amount-preview',
                      {
                        amount: fCurrencyCents(splitAmount ?? 0),
                        count: selectedCount,
                      }
                    )}
                  </Typography>
                )}
              </Stack>
            }
          />
        </Stack>
      </Stack>
    );
  }
);
