import type {
  ResidentType,
  ResidentWithAmount,
} from './ReceiptBatchQuickResidentSelectionForm';

export function distributeAmount(
  amountInCents: number,
  residents: ResidentType[]
): Array<Omit<ResidentWithAmount, 'note'>> {
  if (residents.length === 0) {
    return [];
  }

  const baseAmountInCents = Math.floor(amountInCents / residents.length);
  const remainingCents = amountInCents - baseAmountInCents * residents.length;
  const selectedIndexSet = new Set(
    // Randomize indices and take remainingCents amount of them
    Array.from({ length: residents.length }, (_, i) => i)
      .sort(() => Math.random() - 0.5)
      .slice(0, remainingCents)
  );

  return residents.map((resident, index) => ({
    ...resident,
    amount: baseAmountInCents + (selectedIndexSet.has(index) ? 1 : 0),
  }));
}
