import { TransactionSourceType } from '@pflegenavi/shared/api';

interface opts {
  t: (key: string) => string;
}

export const getTransactionInfo = (
  sourceType: TransactionSourceType,
  recurringItemName: string | undefined,
  receiptType: string | undefined,
  notes: string | undefined,
  opts: opts
): string => {
  let info = '--';
  switch (sourceType) {
    case TransactionSourceType.InitialDeposit:
      info = notes || info;
      break;
    case TransactionSourceType.Payout:
      info = notes || info;
      break;
    case TransactionSourceType.ManualDeposit:
      info = notes || info;
      break;
    case TransactionSourceType.CancelledManualDeposit:
      info = notes || info;
      break;
    case TransactionSourceType.DirectDebit:
      break;
    case TransactionSourceType.RecurringItem:
      info = recurringItemName || info;
      break;
    case TransactionSourceType.Receipt:
      info = opts.t(`receiptType.${receiptType ?? 'Andere'}`);
      break;
    case TransactionSourceType.CancelledReceipt:
      info = opts.t(`receiptType.${receiptType ?? 'Andere'}`);
      break;
    case TransactionSourceType.Dispute:
      break;
    case TransactionSourceType.FinalSettlement:
      info = notes || info;
      break;
  }
  return info;
};
