import type { FC } from 'react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import type { UrlTypeNameMapping } from '@pflegenavi/web-components';
import {
  Box,
  Button,
  Iconify,
  Modal,
  useArrowKeys,
} from '@pflegenavi/web-components';
import { CircularProgress, Stack, styled, Typography } from '@mui/material';
import { ImageNavigationButtons } from './ImageNavigationButtons';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';

type ImageTitleMapping = Map<string, string | undefined>;

export interface EnlargeImageGalleryProps {
  loading?: boolean;
  open: boolean;
  handleClose: () => void;
  initialSelectedIndex: number;
  imageUrls: string[];
  imageUrlToTypeMap: UrlTypeNameMapping;
  imageUrlToTitleMap?: ImageTitleMapping;
  handlePreviousSet?: () => void;
  handleNextSet?: () => void;
  disableRightArrow?: boolean;
  disableLeftArrow?: boolean;
  title?: string;
  subTitle?: string;
  galleryNavigationOptions?: {
    selectedImageIndex: number;
    NavigationButtons: React.ReactElement | null;
  };
}

export const EnlargeImageGallery: FC<EnlargeImageGalleryProps> = ({
  loading = false,
  open,
  handleClose,
  initialSelectedIndex,
  imageUrls,
  imageUrlToTypeMap,
  imageUrlToTitleMap,
  title,
  subTitle,
  handlePreviousSet,
  handleNextSet,
  disableRightArrow,
  disableLeftArrow,
  galleryNavigationOptions,
}) => {
  const { t } = useTranslation();
  const [selectedImageIndex, setSelectedImageIndex] =
    useState(initialSelectedIndex);
  const [pdfLoading, setPdfLoading] = useState(false);

  useEffect(() => {
    setSelectedImageIndex(initialSelectedIndex);
    // TODO Looks intentional to me (initialSelectedIndex missing)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, imageUrls]);

  const onSelectPrevious = useCallback(() => {
    const minIndex = 0;
    if (selectedImageIndex - 1 < minIndex && handlePreviousSet) {
      handlePreviousSet();
      return;
    }

    const nextIndex =
      selectedImageIndex - 1 > minIndex ? selectedImageIndex - 1 : minIndex;
    setSelectedImageIndex(nextIndex);
  }, [handlePreviousSet, selectedImageIndex]);

  const onSelectNext = useCallback(() => {
    const maxIndex = imageUrls.length - 1;

    if (selectedImageIndex + 1 > maxIndex && handleNextSet) {
      handleNextSet();
      return;
    }

    const nextIndex =
      selectedImageIndex + 1 < maxIndex ? selectedImageIndex + 1 : maxIndex;

    setSelectedImageIndex(nextIndex);
  }, [handleNextSet, imageUrls.length, selectedImageIndex]);

  const selectedImageUrl = useMemo(() => {
    return imageUrls[
      galleryNavigationOptions?.selectedImageIndex ?? selectedImageIndex
    ];
  }, [
    galleryNavigationOptions?.selectedImageIndex,
    imageUrls,
    selectedImageIndex,
  ]);

  const image = imageUrlToTypeMap.get(selectedImageUrl);
  const isPdf = image?.type === 'application/pdf';
  const isImage = image?.type.startsWith('image/') ?? false;

  useEffect(() => {
    if (image?.type && !isImage && !isPdf) {
      // eslint-disable-next-line no-console
      console.warn(
        `File with content-type ${image.type} is not supported: ${selectedImageUrl}`
      );
      Sentry.captureMessage(
        `Open file with content-type ${image.type} is not supported: ${selectedImageUrl}`,
        'warning'
      );
    }
  }, [
    imageUrls,
    selectedImageIndex,
    image?.type,
    isImage,
    isPdf,
    selectedImageUrl,
  ]); // Intentionally only selectedImageIndex + imageUrls, so that warning is only sent once on change in gallery page

  const [data, setData] = useState<string | undefined>(undefined);
  useEffect(() => {
    const newData = imageUrlToTypeMap.get(selectedImageUrl)?.data;
    if (!newData) {
      return;
    }
    (async () => {
      setPdfLoading(true);
      const blob = await newData;
      const url = URL.createObjectURL(blob);
      setData(url); // Add #toolbar=0 to the end of the url to hide the pdf toolbar entirely if desired
      setPdfLoading(false);
    })();
  }, [imageUrls, selectedImageIndex, imageUrlToTypeMap, selectedImageUrl]);

  const titleToDisplay = title || imageUrlToTitleMap?.get(selectedImageUrl);

  // The passed NavigationButtons component is responsible for handling arrow keys
  useArrowKeys(
    open && !galleryNavigationOptions?.NavigationButtons,
    onSelectPrevious,
    onSelectNext
  );

  return (
    <Modal open={open} handleClose={handleClose}>
      <PictureModalInner>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: 1 }}
        >
          <Stack
            direction="row"
            justifyContent="start"
            sx={{
              width: '100%',
              overflow: 'hidden',
              minWidth: 150,
            }}
          />
          <Stack direction="row" justifyContent="center" sx={{ width: '100%' }}>
            {galleryNavigationOptions?.NavigationButtons ? (
              galleryNavigationOptions.NavigationButtons
            ) : (
              <ImageNavigationButtons
                currentImageIndex={selectedImageIndex}
                imagesCount={imageUrls.length}
                showButtons={
                  imageUrls.length > 1 || !!handleNextSet || !!handlePreviousSet
                }
                onPrevious={onSelectPrevious}
                onNext={onSelectNext}
                disabledLeft={
                  selectedImageIndex === 0 &&
                  (disableLeftArrow === undefined || disableLeftArrow)
                }
                disabledRight={
                  selectedImageIndex === imageUrls.length - 1 &&
                  (disableRightArrow === undefined || disableRightArrow)
                }
              />
            )}
          </Stack>

          <Stack
            direction="row"
            justifyContent="end"
            sx={{ width: '100%', ml: 0.3 }}
          >
            <a
              href={
                imageUrls[
                  galleryNavigationOptions?.selectedImageIndex ??
                    selectedImageIndex
                ]
              }
              target="_blank"
              rel="noreferrer"
              download={image?.name}
              style={{ textDecoration: 'none' }}
            >
              <Button
                startIcon={<Iconify icon={'eva:download-fill'} />}
                variant="contained"
              >
                {t('actions.download-file')}
              </Button>
            </a>
          </Stack>
        </Stack>

        <PictureModalInnerInner>
          {image?.type && !loading && !pdfLoading ? (
            <picture style={{ position: 'relative' }}>
              {!isImage && !isPdf ? (
                <Typography
                  variant={'body1'}
                  color={'white'}
                  marginTop={10}
                  marginBottom={10}
                >
                  {t('common.file-type-not-supported', {
                    fileType: image?.type,
                  })}
                </Typography>
              ) : isPdf ? (
                <object
                  type="application/pdf"
                  data={data}
                  style={{ zIndex: 8, borderRadius: 4 }}
                  width={window.innerWidth * 0.8}
                  height={window.innerHeight * 0.8}
                />
              ) : (
                <ReceiptImage alt="" src={selectedImageUrl} />
              )}
            </picture>
          ) : (
            <CircularProgress color="primary" size={60} />
          )}
        </PictureModalInnerInner>

        <Stack
          direction="row"
          justifyContent="center"
          sx={{ width: '100%', overflow: 'hidden', marginY: 1 }}
        >
          {(titleToDisplay || subTitle) && (
            <ImageTitle variant="body2">{`${titleToDisplay ?? ''}${
              titleToDisplay && subTitle ? ' - ' : ''
            }${subTitle ?? ''}`}</ImageTitle>
          )}
        </Stack>
      </PictureModalInner>
    </Modal>
  );
};

const PictureModalInner = styled(Box)(
  () =>
    ({ theme }) =>
      theme.unstable_sx({
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: 'inherit',
        height: '95vh',
        maxHeight: '95vh',
        overflow: 'visible',
        outline: 'none',
      })
);

const PictureModalInnerInner = styled(Box)(
  () =>
    ({ theme }) =>
      theme.unstable_sx({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        maxHeight: '90vh',
        overflow: 'auto',
      })
);

const ReceiptImage = styled('img')(({ theme }) =>
  theme.unstable_sx({
    zIndex: 8,
    borderRadius: 4,
    minWidth: 300,
    maxWidth: '95vw',
    maxHeight: '85vh',
  })
);

const ImageTitle = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    background: 'rgba(0, 0, 0, 0.7)',
    p: '1px 8px 1px 8px',
    color: 'white',
    fontSize: 15,
    borderRadius: 2,
    fontWeight: 700,
  })
);
