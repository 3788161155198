import type { ValidatorConstraintInterface } from 'class-validator';
import {
  IsArray,
  IsEnum,
  IsInt,
  IsNotEmpty,
  IsOptional,
  IsString,
  IsUUID,
  Max,
  Min,
  Validate,
  ValidatorConstraint,
} from 'class-validator';
import {
  ServiceProviderInviteStatus,
  ServiceProviderStatus,
  ServiceProviderStatusCombined,
} from '../service-provider';
import type { IPaginatedQuery } from '@pflegenavi/shared/utils';
import type { Gender } from '../resident';
import type { ReceiptStatusValues } from './ReceiptStatusValues';

export {
  ServiceProviderStatusCombined,
  ServiceProviderStatus,
  ServiceProviderInviteStatus,
} from '../service-provider';

export { ManualDepositDto } from './ManualDepositDto';
export {
  GetTransactionsWithReceiptDto,
  TransactionType,
  TransactionSourceType,
  TransactionInitiatedBy,
  TransactionRecurringItemTransactionDto,
} from './GetTransactionsWithReceiptDto';

export {
  CreateReceiptBatch,
  CreateReceiptBatchResponse,
} from './createReceiptBatch';
export type { CreateReceiptBatchWithNursingHome } from './createReceiptBatch';
export {
  ReceiptBatchDto,
  ReceiptBatchWithReceiptBatchEntriesDto,
  ReceiptBatchWithExpandedResidentReceiptBatchEntriesDto,
  ReceiptBatchGetDto,
  ReceiptBatchWithEntriesAndReceiptsDto,
  GetReceiptBatchesQueryDto,
  ReceiptBatchPaginatedDto,
  ReceiptBatchJobState,
  ReceiptBatchOwner,
  ReceiptBatchListDto,
  UpdateReceiptBatchResultDto,
} from './receiptBatchDto';
export type { SortBy, SortOrder } from './receiptBatchDto';
export {
  UpdateReceiptBatchDto,
  UpdateReceiptBatchCashTransactionGroupDto,
  PatchReceiptBatchDto,
} from './updateReceiptBatchDto';
export type { UpdateReceiptBatchWithNursingHome } from './updateReceiptBatchDto';
export {
  ReceiptBatchJob,
  CreateReceiptBatchJobParams,
  CreateReceiptBatchJobAction,
} from './ReceiptBatchJob.dto';
export {
  UpdateReceiptBatchEntryResultDto,
  ReceiptBatchEntryDto,
  ExpandedResidentReceiptBatchEntryDto,
  ExpandedResidentDto,
  CreateReceiptBatchEntryDto,
  CreateOrUpdateReceiptBatchEntryDto,
  ReceiptBatchWithoutResident,
} from './receiptBatchEntryDto';
export {
  GetTransactionsSearchField,
  GetTransactionsSearch,
  GetTransactionsPaginatedQueryParams,
  GetFilteredBalanceChangesQueryParams,
  GetTotalResidentBalanceQueryParams,
  TotalResidentBalanceResultDto,
  FilteredTransactionChangesResultDto,
  GetTransactionsPaginatedQuerySortBy,
} from './transaction.dto';
export { CancelManualDepositDto } from './cancelManualDeposit.dto';
export { ReceiptError, ReceiptBatchError } from './error';
export {
  isAndPeriodValid,
  isPeriodEndSpecified,
  isPeriodStartSpecified,
} from './util';

export type {
  ReceiptItemDto,
  ReceiptBatchItemDto,
  ReceiptsAndReceiptBatchesDto,
} from './receiptsAndReceiptBatches.dto';
export {
  ReceiptsAndReceiptBatchesQueryParams,
  ReceiptsAndReceiptBatchesQuerySortBy,
} from './receiptsAndReceiptBatches.dto';

export type { ReceiptBatchReceipt } from './receiptBatchReceipt';

interface Creator {
  firstName: string;
  lastName: string;
}

/**
 * This interface contains fields that are optionally returned by the API.
 */
interface OptionalReceiptFields {
  linkedTransactionGroups?: string[];
}

export interface ReceiptFull extends OptionalReceiptFields {
  id: string;
  /** @deprecated use amountInCent instead */
  amount: number;
  amountInCent: number;
  date: Date;
  resident: TransactionResident;
  receipt_not_available: boolean;
  receipt_type?: IReceiptType;
  service_provider?: {
    id: string;
    name: string;
  };
  creator?: Creator;
  status: ReceiptStatusValues;
  notes?: string;
  receiptImageIds: string[];
  created_on: Date;
  cancellation?: ReceiptCancellation;
  submissionDate?: Date;
  receiptBatch?: {
    id: string;
    title: string;
    receiptImageIds: string[];
  };
}

export interface ReceiptCancellation {
  transactionId: string;
  cancelTransactionId: string;
  employee: Creator;
  date: Date;
  note?: string;
}

export interface TransactionResident {
  id: string;
  firstName: string;
  lastName: string;
  gender: Gender;
}

export interface IReceiptType {
  id: string;
  name: string;
  requiresServiceProvider: boolean;
}

export enum TransactionStatusColors {
  ALL = 'default',
  SUBMITTED = 'success',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  DRAFT = 'default',
  INCOMPLETE = 'error',
}

export interface CreateReceiptDto {
  resident_id: string;
  /** @deprecated Use amountInCent instead */
  amount?: number;
  amountInCent?: number;
  receipt_type_id?: string;
  service_provider?: string;
  date: Date;
  notes?: string;
  receipt_image_ids?: string[];
  receipt_not_available: boolean;
}

export interface UpdateTransactionDto {
  receipt_id: string;
  resident_id?: string | null;
  /** @deprecated Use amountInCent instead */
  amount?: number | null;
  amountInCent?: number | null;
  receipt_type_id?: string | null;
  service_provider?: string | null;
  date?: Date | null;
  notes?: string | null;
  receipt_not_available?: boolean;
  receipt_image_ids?: string[];
}

export interface CancelReceiptDto {
  receiptId: string;
  note?: string;
}

export enum BalanceStatusValues {
  ALL = 'All',
  LOW_BALANCE = 'Low balance',
  NEGATIVE_BALANCE = 'Negative balance',
  SETTLEMENT = 'Settlement',
}

export enum BalanceStatusColors {
  ALL = 'default',
  LOW_BALANCE = 'warning',
  NEGATIVE_BALANCE = 'error',
  ENOUGH_BALANCE = 'success',
  SETTLEMENT = 'info',
}

export interface ServiceProvidersQuery {
  nursingHomeId?: string;
  searchTerms?: string;
  residentId?: string;
  receiptType?: string;
}

// TODO: Can be removed when not in use anymore in ServiceProvidersPaginatedQueryParams
@ValidatorConstraint()
class IsStatusValidConstraint implements ValidatorConstraintInterface {
  // eslint-disable-next-line class-methods-use-this
  validate(value: any) {
    // Check if value is an array of ServiceProviderStatus enum values
    if (Array.isArray(value)) {
      return value.every((val) =>
        Object.values(ServiceProviderStatus).includes(val)
      );
    }
    // Check if value is a single ServiceProviderStatusCombined enum
    if (typeof value === 'string') {
      return Object.values(ServiceProviderStatusCombined).includes(
        value as ServiceProviderStatusCombined
      );
    }
    // If neither, validation fails
    return false;
  }

  // eslint-disable-next-line class-methods-use-this
  defaultMessage() {
    // Custom error message
    return 'The status must be either a ServiceProviderStatusCombined enum or an array of ServiceProviderStatus enums.';
  }
}

export class ServiceProvidersPaginatedQueryParams implements IPaginatedQuery {
  @IsInt()
  @IsNotEmpty()
  @Min(0)
  page!: number;

  @IsInt()
  @IsNotEmpty()
  @Min(1)
  @Max(100)
  pageSize!: number;

  @IsNotEmpty()
  @IsUUID(4)
  nursingHomeId!: string;

  @IsOptional()
  @IsUUID(4)
  globalServiceProviderId?: string;

  /**
   * If provided, the search term will be used to filter the service providers by name.
   */
  @IsOptional()
  @IsString()
  searchTerm?: string;

  @IsOptional()
  @IsEnum(ServiceProviderStatusCombined)
  combinedStatus?: ServiceProviderStatusCombined;

  @IsOptional()
  @Validate(IsStatusValidConstraint) // TODO: Remove and replace with the validation commented out below once frontend is deployed
  // @IsArray({ groups: [] })
  // @IsEnum(ServiceProviderStatus, { each: true })
  status?: ServiceProviderStatus[] | ServiceProviderStatusCombined;

  @IsOptional()
  @IsArray()
  @IsEnum(ServiceProviderInviteStatus, { each: true })
  inviteStatus?: ServiceProviderInviteStatus[];

  /**
   * If provided, the service provider will be filtered based on the stored receipt type (Not the receipts usage).
   */
  @IsOptional()
  @IsUUID()
  receiptTypeId?: string;

  /**
   * If provided, additional fields will be returned.
   */
  @IsOptional()
  additionalFields?: {
    lastUsed?: boolean;
  };
}

export interface ServiceProviderListDto {
  id: string;
  name: string;
  alternateNames: string[];
  combinedStatus: ServiceProviderStatusCombined;
  email?: string;
  globalServiceProviderId?: string;
  receiptType: {
    id: string;
    name: string;
    // Requires service provider is not included, because a receipt type used in
    // a service provider should always have "requiresServiceProvider" set to true.
    // requiresServiceProvider: boolean;
  };
  lastUsed?: Date;
}

export interface ServiceProviderCompanyDto {
  id: string; // global service provider id
  name?: string;
  phone?: string;
  address?: string;
  zipCode?: string;
  city?: string;
  countryId?: string;
}

export interface ServiceProviderCompanyAdminDto {
  id: string; // global service provider id
  email: string;
  firstName?: string;
  lastName?: string;
}

export interface ServiceProviderDto {
  id: string;
  name: string;
  alternateNames: string[];
  combinedStatus: ServiceProviderStatusCombined;
  stripeStatus: 'complete' | 'pending' | 'inactive';
  email?: string;
  globalServiceProviderId?: string;
  receiptType: {
    id: string;
    name: string;
    // Requires service provider is not included, because a receipt type used in
    // a service provider should always have "requiresServiceProvider" set to true.
    // requiresServiceProvider: boolean;
  };
  company?: ServiceProviderCompanyDto;
  companyAdmin?: ServiceProviderCompanyAdminDto;
}

export interface ServiceProviderWithStatementDescriptorDto {
  global_service_provider_id: string;
  id: string;
  invite_status: ServiceProviderInviteStatus;
  name: string;
  nursing_home_id: string;
  receipt_type_id: string;
  service_provider_email: string;
  statement_descriptor: string | null;
  status: ServiceProviderStatusCombined;
}

export {
  PaginatedReceiptQueryDto,
  PaginatedReceiptQueryAdditionalFields,
  PaginatedReceiptQuerySortBy,
} from './PaginatedReceiptQueryDto';
export type { PaginatedReceiptResult } from './PaginatedReceiptResult';
