import * as React from 'react';
import type { FC } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import AllSetSvg from '../../../assets/all_set.svg';
import { styled, Typography } from '@mui/material';
import { Box, Button } from '@pflegenavi/web-components';
import { useNavigate } from 'react-router-dom';
import { nhAppMainPages } from '@pflegenavi/frontend/routing';

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface ImportFamilyMembersEmptyProps {}

export const ImportFamilyMembersEmpty: FC<
  ImportFamilyMembersEmptyProps
> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleGoHome = useCallback(() => {
    navigate(nhAppMainPages.DASHBOARD);
  }, [navigate]);

  return (
    <PageContainer>
      <img
        alt={t('family-members-import.empty-page.title')}
        src={AllSetSvg}
        style={{ marginBottom: 20 }}
      />
      <Typography variant="inherit" fontWeight="700">
        {t('family-members-import.empty-page.title')}
      </Typography>
      <Button variant="contained" autoFocus onClick={handleGoHome}>
        {t('family-members-import.empty-page.go-home-button')}
      </Button>
    </PageContainer>
  );
};

export const PageContainer = styled(Box)`
  width: 100%;
  height: 70vh;
  overflow: hidden;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;
