import { isSameDay } from 'date-fns';

export function getReceiptBatchFileNameDateString(
  useIndividualReceiptDates: boolean,
  receiptDate?: Date,
  minReceiptDate?: Date,
  maxReceiptDate?: Date
): string {
  if (useIndividualReceiptDates) {
    if (!minReceiptDate || !maxReceiptDate) {
      return '--';
    }

    if (isSameDay(minReceiptDate, maxReceiptDate)) {
      return dateAsYYYY_MM_DD(minReceiptDate);
    } else {
      return `${dateAsYYYY_MM_DD(minReceiptDate)}-${dateAsYYYY_MM_DD(
        maxReceiptDate
      )}`;
    }
  } else {
    if (!receiptDate) {
      return '--';
    }
    return dateAsYYYY_MM_DD(receiptDate);
  }
}

export function getIndividualReceiptFileName(
  receiptDateString: string,
  receiptString: string,
  firstName?: string,
  lastName?: string
): string {
  return `${receiptDateString}_${receiptString}_${firstName}_${lastName}.pdf`.replace(
    ' ',
    '_'
  );
}

export function getReceiptBatchFileName(
  dateString: string,
  receiptBatchString: string
): string {
  return `${dateString}_${receiptBatchString}.pdf`.replace(' ', '_');
}

export function dateAsYYYY_MM_DD(date: Date): string {
  return date.toISOString().split('T')[0].replace(/-/g, '_');
}
