import { Container, Grid, Skeleton, Stack, Typography } from '@mui/material';
import { CashManagementHeaderBreadcrumbs } from './CashOverviewPage';
import type { FC } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  addMissingFactorsAndSort,
  useCashTransactionGroup,
  useCashTransactionGroupAddTransaction,
} from '@pflegenavi/frontend/api-nursing-home';
import type {
  Coin,
  GetCashTransactionGroupResultDto,
} from '@pflegenavi/shared/api';
import {
  CashManagementTransactionType,
  CashTransactionGroupType,
} from '@pflegenavi/shared/api';
import { Button, Iconify, Modal, ModalInner } from '@pflegenavi/web-components';
import { ChangeCashMode } from './components/form/model';
import { ChangeCashForm } from './components/form/ChangeCashForm';
import { ReceiptTable } from './components/groupDetail/ReceiptTable';
import { GroupOverview } from './components/groupDetail/GroupOverview';
import { CashTransactionCard } from './components/groupDetail/CashTransactionCard';
import { TransactionsTable } from './components/groupDetail/TransactionsTable';
import { useNursingHomeContext } from '@pflegenavi/frontend/nursing-home-context';

interface AddCashTransactionModalProps {
  open: boolean;
  onClose: () => void;
  type: ChangeCashMode;

  onAddCashTransactionGroup: (
    type: ChangeCashMode,
    coins: Coin[],
    bankAccountAmount?: number,
    notes?: string
  ) => void;
  cashListId: string;
}

export const AddCashTransactionModal: FC<AddCashTransactionModalProps> = ({
  open,
  onClose,
  type,
  onAddCashTransactionGroup,
  cashListId,
}) => {
  return (
    <Modal open={open} handleClose={onClose}>
      <ModalInner>
        <ChangeCashForm
          forceCashListId={cashListId}
          requireNote={false}
          handleClose={onClose}
          accountingPaymentsFeatureFlag={true}
          initialValues={{
            mode: type,
            step: 1,
          }}
          onConfirm={(data) =>
            Promise.resolve(
              onAddCashTransactionGroup(
                type,
                data.coins,
                data.bankAccountAmount,
                data.notes
              )
            )
          }
        />
      </ModalInner>
    </Modal>
  );
};

const CashTransactionGroupDetailPage: FC = () => {
  const { t } = useTranslation();

  return (
    <Container maxWidth={'lg'}>
      <CashManagementHeaderBreadcrumbs
        pageTitle={t('nav.cash-management.overview')}
        hideActionButton={true}
      />

      <CashTransactionGroupDetailContent />
    </Container>
  );
};

const CashTransactionGroupDetailContent: FC = () => {
  const { groupId } = useParams();
  const { t } = useTranslation();

  const { data, isLoading } = useCashTransactionGroup(groupId);

  const numCashTransactions = data?.cashListTransactions?.length ?? 0;

  if (isLoading || !data) {
    return (
      <Stack direction="column" gap={3} width="fit-content">
        <Skeleton variant="rectangular" height={400} width="100%" />
      </Stack>
    );
  }

  return (
    <Stack direction="column" gap={3}>
      <Stack gap={3}>
        <Stack gap={2}>
          <Typography variant="h6" fontWeight="bold">
            {t('cashManagement.group-detail.overview.title')}
          </Typography>

          <GroupOverview
            type={data.type}
            lastUpdateDate={data.updateDate}
            cashAmount={data.cashChanged ?? 0}
            cashTransactionCount={data.cashListTransactions?.length ?? 0}
            linkedTransactionCount={data.transactionLinkCount ?? 0}
            linkedReceiptCount={data.receiptLinkCount ?? 0}
            linkedAmount={data.amountLinked}
            linkedCashListTransactionCount={data.cashListTransactionCount ?? 0}
            linkedPaymentIntentCount={data.paymentIntentLinkCount ?? 0}
            linkedPayoutCount={data.payoutLinkCount ?? 0}
          />
        </Stack>
        {numCashTransactions > 0 && (
          <Stack gap={2}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              flexWrap="wrap"
            >
              <Stack gap={0.5}>
                <Typography variant="h6" fontWeight="bold">
                  {t(
                    'cashManagement.group-detail.overview.cash-transaction-title'
                  )}
                </Typography>
                <Typography variant="subtitle2" color="text.secondary">
                  {t('cashManagement.link.table-row.transfer-count', {
                    count: numCashTransactions,
                  })}
                </Typography>
              </Stack>
              <WithdrawalDepositButtons group={data} />
            </Stack>

            <Grid container spacing={2}>
              {data.cashListTransactions?.map((transfer) => (
                <Grid key={transfer.id} item xs={12} sm={6} lg={4}>
                  <CashTransactionCard
                    showDownloadPdf={
                      data.type ===
                        CashTransactionGroupType.ReceiptWithdrawal &&
                      transfer.type === CashManagementTransactionType.Withdrawal
                    }
                    {...transfer}
                  />
                </Grid>
              ))}
            </Grid>
          </Stack>
        )}
        <ReceiptTable groupId={groupId} />
        <TransactionsTable transactions={data.transactions ?? []} />
      </Stack>
    </Stack>
  );
};

export default CashTransactionGroupDetailPage;

const WithdrawalDepositButtons: FC<{
  group?: GetCashTransactionGroupResultDto;
}> = ({ group }) => {
  const { t } = useTranslation();

  const { selectedNursingHome } = useNursingHomeContext();

  const { mutateAsync: addTransaction } = useCashTransactionGroupAddTransaction(
    selectedNursingHome?.id
  );

  const [cashLinkOpen, setCashLinkOpen] = useState<undefined | ChangeCashMode>(
    undefined
  );

  if (
    !group ||
    group.type === CashTransactionGroupType.Transfer ||
    group.type === CashTransactionGroupType.Adjustment
  ) {
    return null;
  }

  return (
    <>
      <Stack direction="row" gap={1} justifyContent="flex-end">
        <Button
          variant="text"
          onClick={() => setCashLinkOpen(ChangeCashMode.Withdraw)}
          sx={{ width: 'fit-content' }}
          startIcon={<Iconify icon="eva:plus-fill" />}
        >
          {t('cashManagement.link.add-withdrawal')}
        </Button>
        <Button
          variant="text"
          onClick={() => setCashLinkOpen(ChangeCashMode.Deposit)}
          sx={{ width: 'fit-content' }}
          startIcon={<Iconify icon="eva:plus-fill" />}
        >
          {t('cashManagement.link.add-deposit')}
        </Button>
      </Stack>

      <AddCashTransactionModal
        key={(cashLinkOpen ?? 'closed').toString()}
        cashListId={group.cashListId}
        open={Boolean(cashLinkOpen)}
        onClose={() => setCashLinkOpen(undefined)}
        type={
          cashLinkOpen === 'withdraw'
            ? ChangeCashMode.Withdraw
            : ChangeCashMode.Deposit
        }
        onAddCashTransactionGroup={(type, coins, bankAccountAmount, notes) => {
          const coinsSorted =
            coins !== undefined && coins.length > 0
              ? addMissingFactorsAndSort(coins).map((coin) => coin.amount)
              : undefined;
          addTransaction({
            cashTransactionGroupId: group.id,
            type: type === ChangeCashMode.Withdraw ? 'withdrawal' : 'deposit',
            coins: coinsSorted,
            bankAccountAmount: bankAccountAmount,
            notes,
          });
        }}
      />
    </>
  );
};
