import { useCallback, useMemo, useState } from 'react';
import get from 'lodash.get';
import { ROWS_PER_PAGE_DEFAULT } from '@pflegenavi/shared/constants';
import { useLocalStorage } from './useLocalStorage';
import { useTableWithUrlParamsPagination } from './useTableWithUrlParamsPagination';
import { useTablePagination } from './useTablePagination';

// ----------------------------------------------------------------------

export interface UseTableProps<TColumn = string, TData = any> {
  dense: boolean;
  page: number;
  setPage: (newPage: number) => void;
  rowsPerPage: number;
  order: 'asc' | 'desc';
  orderBy: TColumn;
  setOrder: (newOrder: 'asc' | 'desc') => void;
  setOrderBy: (newOrderBy: TColumn) => void;
  //
  selected: string[];
  setSelected: React.Dispatch<React.SetStateAction<string[]>>;
  onSelectRow?: (id: string) => void;
  onSelectAllRows: (checked: boolean, newSelects: string[]) => void;
  //
  onSort: (id: TColumn) => void;
  onChangePage: (event: unknown, newPage: number) => void;
  onChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeDense: (event: React.ChangeEvent<HTMLInputElement>) => void;
  comparator: (a: TData, b: TData) => number;
}

type NestedKeyof<T, TRequired = Required<T>> = {
  [key in keyof TRequired]: key extends string
    ? TRequired[key] extends any[]
      ? `${key}`
      : IsFunctionless<TRequired[key]> extends true
      ? `${key}.${ValueOf<NestedKeyof<TRequired[key]>>}`
      : `${key}`
    : never;
};

type IsFunctionless<T> = T extends object
  ? // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
    ValueOf<T> extends Function
    ? false
    : true
  : false;

type ValueOf<T> = T[keyof T];

type Flat<T> = ValueOf<NestedKeyof<T>>;

type NestedValue<
  TData_,
  K,
  TData = Required<TData_>
> = K extends `${infer K2}.${infer Rest}`
  ? K2 extends keyof TData
    ? NestedValue<TData[K2], Rest>
    : never
  : K extends keyof TData
  ? TData[K]
  : never;

export type TableKeyType =
  | 'table'
  | 'residents'
  | 'reporting'
  | 'reporting-to-print'
  | 'transactions'
  | 'cashList'
  | 'recurring'
  | 'recurring-edit'
  | 'recurringItems'
  | 'real'
  | 'residentTransaction'
  | 'familyMemberTransactions'
  | 'familyMemberPayments'
  | 'nursingHomePayments'
  | 'inactiveResidents'
  | 'service-provider-residents-edit'
  | 'resident-service-providers-edit'
  | 'employees'
  | 'test';

export interface Props<TData = any, TColumn extends string = string> {
  useUrlParamPagination?: boolean;
  tableKey: TableKeyType;
  defaultDense?: boolean;
  defaultOrder?: 'asc' | 'desc';
  defaultOrderBy: TColumn;
  defaultSelected?: string[];
  defaultRowsPerPage?: number | 'dynamic';
  defaultCurrentPage?: number;
  sortKeys: Record<
    TColumn,
    Array<Flat<TData>>
  > /** static. Changes will have no effect */;
  sortComparator?: {
    [key in Flat<TData>]?: (
      a: NestedValue<TData, key>,
      b: NestedValue<TData, key>
    ) => number;
  } /** static. Changes will have no effect */;
}

export type RowsPerPageType = number | 'dynamic' | 'all';

export function useTable<TData = any, TColumn extends string = string>(
  props: Props<TData, TColumn>
): UseTableProps<TColumn, TData> {
  const [dense, setDense] = useState(props?.defaultDense || false);
  const defaultRowsPerPage = props?.defaultRowsPerPage ?? ROWS_PER_PAGE_DEFAULT;
  const [savedRowsPerPage, setSavedRowsPerPage] = useLocalStorage<
    number | 'dynamic'
  >(`rows_per_page_for__${props.tableKey}`, defaultRowsPerPage);

  const tablePaginationOptions = useTablePagination<TColumn>({
    defaultCurrentPage: props.defaultCurrentPage,
    defaultOrder: props.defaultOrder,
    defaultOrderBy: props.defaultOrderBy,
  });

  const urlParamsTablePaginationOptions =
    useTableWithUrlParamsPagination<TColumn>({
      defaultCurrentPage: props.defaultCurrentPage,
      defaultOrder: props.defaultOrder,
      defaultOrderBy: props.defaultOrderBy,
    });

  const { order, setOrder, orderBy, setOrderBy, page, setPage } =
    props.useUrlParamPagination
      ? urlParamsTablePaginationOptions
      : tablePaginationOptions;

  const [rowsPerPage, setRowsPerPage] = useState(savedRowsPerPage);

  const [selected, setSelected] = useState<string[]>(
    props?.defaultSelected || []
  );

  const onSort = (id: TColumn) => {
    const isAsc = orderBy === id && order === 'asc';
    if (id !== '') {
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(id);
    }
  };

  const onSelectRow = useCallback(
    (id: string) => {
      const selectedIndex = selected.indexOf(id);

      let newSelected: string[] = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
      setSelected(newSelected);
    },
    [selected]
  );

  const onSelectAllRows = (checked: boolean, newSelects: string[]) => {
    if (checked) {
      setSelected(newSelects);
      return;
    }
    setSelected([]);
  };

  const onChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const onChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setSavedRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const onChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const comparator = useMemo(() => {
    const fields = props.sortKeys[orderBy];

    // Retrieve a nested value based on a key string separated by dots ('some.nested.key')
    const get = (field: Flat<TData>) => {
      const parts = field.split('.');
      return (a: TData): any => {
        return parts.reduce((last: any, part) => last?.[part], a);
      };
    };

    const getters = new Map(fields.map((field) => [field, get(field)]));

    return (a: TData, b: TData): number => {
      return order === 'desc'
        ? descendingComparator(a, b, fields, getters, props.sortComparator)
        : -descendingComparator(a, b, fields, getters, props.sortComparator);
    };
    // TODO: sortKeys etc. missing
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, orderBy]);

  return {
    dense,
    order,
    setOrder,
    page,
    setPage,
    orderBy,
    setOrderBy,
    // @ts-expect-error // type is off. Fixing it creates 50 new type errors
    rowsPerPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangePage,
    onChangeDense,
    onChangeRowsPerPage,
    comparator,
  };
}

// ----------------------------------------------------------------------

const propertyFallback = (property: string) => (value: any) => value[property];

export function descendingComparator<TData>(
  a: TData,
  b: TData,
  orderBy: Array<Flat<TData>>,
  getters: Map<Flat<TData>, (a: TData) => any>,
  optCompareFn?: { [key in Flat<TData>]?: (a: any, b: any) => number }
): number {
  return orderBy.reduce((result, field) => {
    if (result === 1) {
      return result;
    }
    if (result === -1) {
      return result;
    }

    const fallback = propertyFallback(field);
    const getter = getters.get(field) ?? fallback;

    return (get(optCompareFn, field) ?? compareFn)(getter(a), getter(b));
  }, 0);
}

function compareFn(a: any, b: any): number {
  if (b < a) {
    return -1;
  }
  if (b > a) {
    return 1;
  }
  return 0;
}

export function emptyRows(
  page: number,
  rowsPerPage: number,
  arrayLength: number
): number {
  return page >= 0
    ? Math.max(0, ((1 + page) * rowsPerPage - arrayLength) % rowsPerPage)
    : 0;
}
