import type { CashState } from './model';
import { ChangeCashMode } from './model';
import type { Gender } from '@pflegenavi/shared/api';
import { BankOrCash } from './ChangeCashForm';
import { sumCashStateInCentsByType } from '../euro';
import type { FC } from 'react';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Box, Button, Stack, Typography } from '@mui/material';
import { Iconify, LoadingPromiseButton } from '@pflegenavi/web-components';
import { AdjustSummaryCard } from './cards/AdjustSummaryCard';
import { ChangeCashSummaryCard } from './cards/ChangeCashSummaryCard';
import { ChangeBankAccountSummaryCard } from './cards/ChangeBankAccountSummaryCard';
import { ResidentPreviewCard } from './cards/ResidentPreviewCard';
import { CashNotesField } from './inputs/CashNotesField';

export interface ChangeCashFormConfirmationProps {
  cashState: CashState;
  mode: ChangeCashMode;
  handleClose: VoidFunction;
  previousStep: VoidFunction;
  residentData?: {
    name: string;
    balanceInCents: number;
    gender: Gender;
  };
  bankAccountChange?: number;
  bankOrCash: BankOrCash;
  skippedCashSelection: boolean;
  skippedAmount?: number;
  confirm: () => Promise<void>;
  skip: () => Promise<void>;

  requireNote?: boolean;

  totalBankAccountBalance: number | undefined;
  totalBankAccountBalanceLoading: boolean;

  notes: string;
  setNotes: React.Dispatch<React.SetStateAction<string>>;

  showResidentPreview?: boolean;
}

export const ChangeCashFormConfirmation: FC<
  ChangeCashFormConfirmationProps
> = ({
  mode,
  cashState,
  handleClose,
  previousStep,
  residentData,
  confirm,
  requireNote,
  bankOrCash,
  skippedAmount,
  bankAccountChange,
  skippedCashSelection,
  skip,
  totalBankAccountBalance,
  totalBankAccountBalanceLoading,
  notes,
  setNotes,
  showResidentPreview,
}) => {
  const { t } = useTranslation();

  const [promise, setPromise] = useState<Promise<unknown> | undefined>(
    undefined
  );

  const { banknotes, total, coins } = useMemo(() => {
    return sumCashStateInCentsByType(cashState);
  }, [cashState]);

  const [notesIsError, setNotesIsError] = useState(false);
  const isNotesRequired =
    requireNote === undefined
      ? mode === ChangeCashMode.Adjust && total + (bankAccountChange ?? 0) !== 0
      : requireNote;
  const isReadyToSubmit = isNotesRequired ? notes.length > 1 : true;

  const onClick = useCallback(() => {
    if (!isReadyToSubmit) {
      setNotesIsError(true);
      return;
    }

    if (!skippedCashSelection) {
      const promise = confirm();
      promise.finally(() => {
        setPromise(undefined);
      });
      setPromise(promise);
    } else {
      const promise = skip();
      promise.finally(() => {
        setPromise(undefined);
      });
      setPromise(promise);
    }
  }, [isReadyToSubmit, confirm, skippedCashSelection, skip]);

  return (
    <Stack direction="column" gap={2}>
      <Typography variant="h6">
        {t('cashManagement.change-confirmation')}
      </Typography>
      <Stack
        direction={
          showResidentPreview && !skippedCashSelection ? 'column' : 'row'
        }
        alignItems={
          showResidentPreview && !skippedCashSelection ? '' : 'flex-end'
        }
        justifyContent="space-between"
        spacing={2}
      >
        {!skippedCashSelection ? (
          <Stack direction="row" spacing={2}>
            {bankOrCash === BankOrCash.Cash ||
            bankOrCash === BankOrCash.Adjustment ? (
              mode === ChangeCashMode.Adjust ? (
                <AdjustSummaryCard
                  mode={mode}
                  totalCash={total}
                  coins={coins}
                  banknotes={banknotes}
                  bankAccountChange={bankAccountChange}
                />
              ) : (
                <ChangeCashSummaryCard
                  mode={mode}
                  total={total}
                  coins={coins}
                  banknotes={banknotes}
                />
              )
            ) : (
              <ChangeBankAccountSummaryCard
                mode={mode}
                total={bankAccountChange ?? 0}
                totalBankAccountBalance={totalBankAccountBalance}
                totalBankAccountBalanceLoading={totalBankAccountBalanceLoading}
              />
            )}

            {showResidentPreview && residentData && (
              <ResidentPreviewCard
                mode={mode}
                total={
                  (mode === ChangeCashMode.Withdraw ? -1 : 1) * total +
                  (mode === ChangeCashMode.Withdraw ? -1 : 1) *
                    (bankAccountChange ?? 0)
                }
                resident={residentData}
              />
            )}
          </Stack>
        ) : (
          showResidentPreview &&
          residentData && (
            <Stack direction="row" spacing={2}>
              <ResidentPreviewCard
                mode={mode}
                total={
                  (mode === ChangeCashMode.Withdraw ? -1 : 1) *
                  (skippedAmount ?? 0)
                }
                resident={residentData}
              />
            </Stack>
          )
        )}

        <Box sx={{ flex: 1 }}>
          <CashNotesField
            notes={notes}
            setNotes={setNotes}
            error={notesIsError}
            setError={setNotesIsError}
            required={isNotesRequired}
            rows={showResidentPreview && !skippedCashSelection ? 3 : 6.3}
          />
        </Box>
      </Stack>

      {skippedCashSelection && (
        <Stack sx={{ mt: 2 }} direction="row" gap={1} alignItems="stretch">
          <Alert severity="warning" sx={{ width: '100%' }}>
            <Typography variant="body2" sx={{ pt: 0.2 }}>
              {t('cashManagement.skip-cash-selection-warning')}
            </Typography>
          </Alert>
        </Stack>
      )}

      <Stack direction="row" gap={1} justifyContent="end">
        <Button variant="outlined" color="primary" onClick={previousStep}>
          {t('cashManagement.change-selection')}
        </Button>
        <LoadingPromiseButton
          data-cy="confirm-cash-mgmt-button"
          promise={promise}
          sx={{ width: 140 }}
          variant="contained"
          startIcon={
            skippedCashSelection && <Iconify icon="eva:alert-triangle-fill" />
          }
          endIcon={
            !skippedCashSelection && <Iconify icon="eva:checkmark-fill" />
          }
          onClick={onClick}
          disabled={!isReadyToSubmit}
        >
          {t('cashManagement.confirm')}
        </LoadingPromiseButton>
      </Stack>
    </Stack>
  );
};
