import type { FC } from 'react';
import React, { useCallback, useMemo, useState } from 'react';
import { useFormatting } from '@pflegenavi/frontend/localization';
import { useTranslation } from 'react-i18next';
import { useBankNotesAndCoins } from '../euro';
import { Button, Card, CardHeader, Divider } from '@mui/material';
import { Iconify, Stack, Typography } from '@pflegenavi/web-components';
import { AdjustProceedRow } from './BankAccountForm';
import { BankAccountAdjustInput } from './BankAccountAdjustInput';
import { ResetButton } from './atoms/ResetButton';
import { CashListStorageType } from '@pflegenavi/shared/api';

interface BankAccountAdjustFormProps {
  bankAccountChange?: number;
  setBankAccountChange: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  handleClose: () => void;
  nextStep?: () => void;
  cashListId: string;
  storageType: CashListStorageType;
}

export const BankAccountAdjustForm: FC<BankAccountAdjustFormProps> = ({
  bankAccountChange,
  setBankAccountChange,
  nextStep,
  handleClose,
  cashListId,
  storageType,
}) => {
  const {
    bankAccountAmount: totalBankBalance,
    isLoading: totalBankBalanceLoading,
  } = useBankNotesAndCoins(cashListId);

  const modePreviouslySelected =
    bankAccountChange === undefined || bankAccountChange === 0
      ? undefined
      : bankAccountChange > 0
      ? 'Deposit'
      : 'Withdrawal';

  const [localMode, setLocalMode] = useState<
    'Deposit' | 'Withdrawal' | undefined
  >(modePreviouslySelected);
  const [showForm, setShowForm] = useState(false);

  const handleResetState = useCallback(() => {
    setLocalMode(undefined);
    setShowForm(false);
    setBankAccountChange(undefined);
  }, [setBankAccountChange]);

  const handleSetAmount = useCallback(
    (mode: 'Deposit' | 'Withdrawal', amount: number) => {
      const amountFactor = mode === 'Deposit' ? 1 : -1;
      setLocalMode(mode);
      setBankAccountChange(
        amount === 0 ? undefined : amountFactor * Math.abs(amount)
      );
      setShowForm(false);
    },
    [setBankAccountChange]
  );

  if (totalBankBalance === undefined || totalBankBalanceLoading) {
    return null;
  }

  return (
    <BankAccountAdjustFormView
      totalBankBalance={totalBankBalance}
      localMode={localMode}
      showForm={showForm}
      setShowForm={setShowForm}
      handleResetState={handleResetState}
      handleSetAmount={handleSetAmount}
      bankAccountChange={bankAccountChange}
      nextStep={nextStep}
      handleClose={handleClose}
      storageType={storageType}
    />
  );
};

interface BankAccountAdjustFormViewProps {
  totalBankBalance: number;
  localMode: 'Deposit' | 'Withdrawal' | undefined;
  showForm: boolean;
  setShowForm: (show: boolean) => void;
  handleResetState: () => void;
  handleSetAmount: (mode: 'Deposit' | 'Withdrawal', amount: number) => void;
  bankAccountChange?: number;
  handleClose: () => void;
  nextStep?: () => void;
  storageType: CashListStorageType;
}

export const BankAccountAdjustFormView: FC<BankAccountAdjustFormViewProps> = ({
  totalBankBalance,
  localMode,
  showForm,
  handleResetState,
  handleSetAmount,
  bankAccountChange,
  handleClose,
  nextStep,
  setShowForm,
  storageType,
}) => {
  const { fCurrencyCents } = useFormatting();
  const { t } = useTranslation();

  const newBankAccountAmount = totalBankBalance + (bankAccountChange ?? 0);

  const newBankAccountAmountNegative = useMemo(() => {
    return localMode === 'Deposit' ? false : newBankAccountAmount < 0;
  }, [localMode, newBankAccountAmount]);

  return (
    <>
      {storageType & CashListStorageType.BankAccount ? (
        <Card
          sx={{
            width: '100%',
            p: 3,
          }}
        >
          <Stack direction="column">
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ marginBottom: 2 }}
            >
              <CardHeader
                title={t('cashManagement.total-account')}
                sx={{ p: 0 }}
                titleTypographyProps={{
                  variant: 'subtitle1',
                  sx: { fontWeight: 700 },
                }}
              />

              <ResetButton
                reset={handleResetState}
                disabled={
                  (!showForm && bankAccountChange === undefined) ||
                  bankAccountChange === 0
                }
              >
                {t(`cashManagement.reset-adjust-mode`)}
              </ResetButton>
            </Stack>

            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={5}
            >
              <Typography variant="subtitle2" fontWeight={700}>
                {t('cashManagement.current-bank-account-balance')}
              </Typography>
              <Typography variant="subtitle2" fontWeight={700}>
                {fCurrencyCents(totalBankBalance)}
              </Typography>
            </Stack>

            {showForm && (
              <BankAccountAdjustInput
                bankAccountChange={bankAccountChange}
                handleSetAmount={handleSetAmount}
                showForm={showForm}
              />
            )}

            {!showForm && localMode !== undefined && (
              <>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={5}
                >
                  <Typography variant="subtitle2">
                    {t(
                      `cashManagement.${
                        localMode && localMode === 'Deposit'
                          ? 'deposit'
                          : 'withdrawal'
                      }-amount`
                    )}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    color={
                      localMode && localMode === 'Deposit'
                        ? 'success.main'
                        : 'error.main'
                    }
                  >
                    {bankAccountChange === 0
                      ? '--'
                      : fCurrencyCents(bankAccountChange ?? 0)}
                  </Typography>
                </Stack>
                <Divider
                  sx={{
                    mt: 1,
                    mb: 1,
                  }}
                />
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={5}
                >
                  <Typography variant="subtitle2" fontWeight={700}>
                    {t('cashManagement.new-bank-account-balance')}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    fontWeight={700}
                    color={newBankAccountAmountNegative ? 'error' : 'main'}
                  >
                    {fCurrencyCents(newBankAccountAmount)}
                  </Typography>
                </Stack>
                {newBankAccountAmountNegative && (
                  <Stack alignItems="flex-end">
                    <Typography
                      variant="caption"
                      align="right"
                      alignSelf="flex-end"
                      color="error"
                      textAlign="right"
                    >
                      {t(
                        'cashManagement.bank-account-new-balance-negative-error'
                      )}
                    </Typography>
                  </Stack>
                )}
              </>
            )}

            {!showForm && (
              <Stack
                alignItems="flex-end"
                sx={{
                  mb: -1,
                  mt: 1,
                }}
              >
                <Button
                  name={'changeCashModalModifyBankAccountBalance'}
                  sx={{ mr: -1 }}
                  startIcon={<Iconify icon="ic:baseline-plus" />}
                  onClick={() => setShowForm(true)}
                >
                  {t('cashManagement.modify-bank-account-balance')}
                </Button>
              </Stack>
            )}
          </Stack>
        </Card>
      ) : undefined}
      <AdjustProceedRow
        nextStep={newBankAccountAmountNegative ? undefined : nextStep}
        handleClose={handleClose}
        disabled={showForm}
      />
    </>
  );
};
