import { ChangeCashMode } from '../model';
import type { FC } from 'react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormatting } from '@pflegenavi/frontend/localization';
import { Divider, Stack } from '@mui/material';
import { Typography } from '@pflegenavi/web-components';

interface CustomBankContentProps {
  totalCashBalance: number;
  mode: ChangeCashMode;
  cashChange: number;
}

export const CustomCashContent: FC<CustomBankContentProps> = ({
  totalCashBalance,
  cashChange,
  mode,
}) => {
  const { t } = useTranslation();
  const { fCurrencyCents } = useFormatting();

  const isDeposit = mode === ChangeCashMode.Deposit;

  const changeFactor = useMemo(() => {
    if (isDeposit) {
      return 1;
    }
    return -1;
  }, [isDeposit]);

  const newBalance = totalCashBalance + changeFactor * (cashChange ?? 0);

  return (
    <Stack sx={{ gap: 0.5 }}>
      <Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={5}
        >
          <Typography variant="subtitle2" fontWeight={700}>
            {t('cashManagement.current-cash-account-balance')}
          </Typography>
          <Typography variant="subtitle2" fontWeight={700}>
            {fCurrencyCents(totalCashBalance)}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={5}
        >
          <Typography variant="subtitle2">
            {t(`cashManagement.${isDeposit ? 'deposit' : 'withdrawal'}-amount`)}
          </Typography>
          <Typography
            variant="subtitle2"
            color={isDeposit ? 'success.main' : 'error.main'}
          >
            {cashChange === 0 || cashChange === undefined
              ? '--'
              : fCurrencyCents(changeFactor * cashChange)}
          </Typography>
        </Stack>
      </Stack>
      <Divider />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={5}
      >
        <Typography variant="subtitle2" fontWeight={700}>
          {t('cashManagement.new-cash-account-balance')}
        </Typography>
        <Typography variant="subtitle2" fontWeight={700}>
          {fCurrencyCents(newBalance)}
        </Typography>
      </Stack>
    </Stack>
  );
};
