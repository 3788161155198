import type { AuthenticationContext } from '@pflegenavi/frontend/authentication';

async function retryFetchOn401(
  authContext: AuthenticationContext,
  response: Response,
  url: string,
  options?: RequestInit & { headers?: Headers }
) {
  // We retry fetch only for GET because for other operations a 401 might not indicate a stale token

  const shouldRetryFetch = response.status === 401;
  if (shouldRetryFetch) {
    await authContext.updateToken();
    const optionsWithAuth = await getOptionsWithAuth(authContext, options);
    if (!optionsWithAuth) {
      // A concurrent fetch might have logged us out here
      return response;
    }
    return await fetch(url, optionsWithAuth);
  }

  return response;
}

async function logoutOn401(
  authContext: AuthenticationContext,
  response: Response
) {
  // We retry fetch only for GET because for other operations a 401 might not indicate a stale token
  // The token might turn undefined due to a concurrent fetch. Don't logout twice in that case.
  const token = await authContext.getToken();
  const shouldLogout = response.status === 401 && token !== undefined;
  if (shouldLogout) {
    await authContext.logout();
  }
  return response;
}

const getOptionsWithAuth = async (
  authContext: AuthenticationContext,
  options?: RequestInit & { headers?: Headers }
): Promise<
  | (RequestInit & { headers?: Headers; credentials?: RequestCredentials })
  | undefined
> => {
  const headers = options?.headers ?? new Headers();
  const token = await authContext.getToken();
  if (!token) {
    return undefined;
  }

  if (shouldUseCredentials()) {
    return {
      ...options,
      credentials: 'include',
    };
  }

  headers.set('Authorization', `Bearer ${token}`);

  return {
    ...options,
    headers,
  };
};

export function setApplicationHeaders(
  options?: RequestInit & { headers?: Headers }
): RequestInit & { headers?: Headers } {
  // @ts-expect-error //global is untyped
  const application = window.global.pflegenaviApplication;
  // @ts-expect-error //global is untyped
  const applicationVersion = window.global.pflegenaviApplicationVersion;
  // @ts-expect-error //global is untyped
  const tenant = window.global.tenant;
  const headers = options?.headers ?? new Headers();
  headers.set('X-PN-Application', application);
  headers.set('X-PN-Application-Version', applicationVersion);
  if (tenant) {
    headers.set('X-PN-Tenant', tenant);
  }
  const optionsWithApplication = {
    ...options,
    headers,
  };

  return optionsWithApplication;
}

// Credentials are only used on web, not on mobile
function shouldUseCredentials() {
  if (
    window?.location?.hostname?.includes('staging.pflegenavi.at') ||
    window?.location?.hostname?.includes('.pflegenavi.at')
    // Cookies don't work on localhost subdomains
    // window.location.hostname.includes('localhost')
  ) {
    return true;
  }

  return false;
}

export async function superFetch(
  authContext: AuthenticationContext,
  url: string,
  options?: RequestInit & { headers?: Headers }
): Promise<Response> {
  const optionsWithApplication = setApplicationHeaders(options);

  const optionsWithAuth = await getOptionsWithAuth(
    authContext,
    optionsWithApplication
  );

  if (!optionsWithAuth) {
    throw new ClientError('Not logged in');
  }

  let result = await fetch(url, optionsWithAuth);
  const token = await authContext.getToken();
  // A concurrent fetch might have logged us out already
  if (!token) {
    const resultType = result.headers.get('content-type');
    throw new ClientError(
      resultType?.includes('application/json')
        ? await result.json()
        : await result.text()
    );
  }
  result = await retryFetchOn401(
    authContext,
    result,
    url,
    optionsWithApplication
  );
  result = await logoutOn401(authContext, result);

  const resultType = result.headers.get('content-type');
  if (result.status >= 500) {
    throw new ServerError(
      resultType?.includes('application/json')
        ? await result.json()
        : await result.text()
    );
  }
  if (result.status >= 400) {
    if (result.status === 413) {
      throw new ClientError({ statusCode: 413 });
    } else {
      throw new ClientError(
        resultType?.includes('application/json')
          ? await result.json()
          : await result.text()
      );
    }
  }

  return result;
}

export class ClientError extends Error {
  statusCode?: number;

  constructor(data: { statusCode?: number; [key: string]: any } | string) {
    if (typeof data === 'string') {
      super(data);
    } else {
      super();
      Object.keys(data).forEach((key) => {
        if (key === 'statusCode') {
          return;
        } else {
          // @ts-expect-error can't be typed
          this[key] = data[key];
        }
      });
      this.statusCode = data?.statusCode ?? -1;
    }
  }
}

export class ServerError extends Error {
  statusCode?: number;

  constructor(data: { statusCode?: number; [key: string]: any } | string) {
    if (typeof data === 'string') {
      super(data);
    } else {
      super();
      Object.keys(data).forEach((key) => {
        if (key === 'statusCode') {
          return;
        } else {
          // @ts-expect-error can't be typed
          this[key] = data[key];
        }
      });
      this.statusCode = data?.statusCode ?? -1;
    }
  }
}
