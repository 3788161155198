import type { FC, SVGProps } from 'react';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Iconify,
  Modal,
  ModalInner,
  OneLineTypography,
  Stack,
  Typography,
} from '@pflegenavi/web-components';
import {
  Alert,
  AlertTitle,
  Button,
  Card,
  FormHelperText,
  TextField,
  useTheme,
} from '@mui/material';
import type {
  ReceiptBatchFormValues,
  RecurringItemCalculationData,
} from '../form/ReceiptBatchForm';
import { DatePicker } from '@mui/x-date-pickers';
import {
  endOfDay,
  endOfMonth,
  isSameDay,
  startOfDay,
  startOfMonth,
} from 'date-fns';
import type { RecurringItemDto, TaxCodeDto } from '@pflegenavi/shared/api';
import { computeAmountEndDate } from '@pflegenavi/shared/frontend';
import {
  useFormatDate,
  useFormatting,
} from '@pflegenavi/frontend/localization';
import { useFormikContext } from 'formik';
import { YEAR_MONTH_DAY_SHORT_FORMAT } from '@pflegenavi/shared/constants';

interface RecurringItemAmountByDateRangeModalProps
  extends RecurringItemCalculationData {
  open: boolean;
  setOpen: (open: boolean) => void;
  recurringItem: RecurringItemDto;
  originalAmount: number;
  receiptBatchDate: Date;
  setRecurringItemCalculationData: (
    value: React.SetStateAction<RecurringItemCalculationData>
  ) => void;
}

export const RecurringItemAmountByDateRangeModal: FC<
  RecurringItemAmountByDateRangeModalProps
> = ({
  open,
  setOpen,
  recurringItem,
  originalAmount,
  receiptBatchDate,
  entryId,
  index,
  setRecurringItemCalculationData,
}) => {
  const fDate = useFormatDate();

  const { setFieldValue } = useFormikContext<ReceiptBatchFormValues>();

  const [startDate, setStartDate] = useState<Date | null>(
    startOfMonth(receiptBatchDate)
  );
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [computedAmount, setComputedAmount] = useState(originalAmount);

  const handleSave = useCallback(() => {
    if (!startDate || !endDate || !entryId) {
      throw new Error(
        'Missing required data for saving recurring item calculation'
      );
    }
    const newAmount = computedAmount;
    let newNotes: string;

    if (isSameDay(startDate, endDate)) {
      newNotes = `${recurringItem?.name} ${fDate(
        startDate,
        YEAR_MONTH_DAY_SHORT_FORMAT
      )}`;
    } else {
      newNotes = `${recurringItem?.name} ${new Date(startDate)
        .getDate()
        .toString()
        .padStart(2, '0')}.-${fDate(endDate, YEAR_MONTH_DAY_SHORT_FORMAT)}`;
    }

    setFieldValue(`receipts.${index}.amount`, newAmount);
    setFieldValue(`receipts.${index}.notes`, newNotes);
    setOpen(false);
    setRecurringItemCalculationData((prev) => ({
      ...prev,
      entryId: undefined,
      amount: 0,
      index: undefined,
    }));
  }, [
    computedAmount,
    endDate,
    entryId,
    fDate,
    index,
    recurringItem,
    setFieldValue,
    setOpen,
    setRecurringItemCalculationData,
    startDate,
  ]);

  return (
    <RecurringItemAmountByDateRangeModalView
      recurringItem={recurringItem}
      open={open}
      handleClose={() => setOpen(false)}
      receiptBatchDate={receiptBatchDate}
      startDate={startDate}
      setStartDate={setStartDate}
      endDate={endDate}
      setEndDate={setEndDate}
      originalAmount={originalAmount}
      computedAmount={computedAmount}
      setComputedAmount={setComputedAmount}
      handleSave={handleSave}
    />
  );
};

export interface RecurringItemAmountByDateRangeModalViewProps {
  recurringItem: RecurringItemDto;
  open: boolean;
  handleClose: () => void;
  receiptBatchDate: Date;
  startDate: Date | null;
  setStartDate: (date: Date | null) => void;
  endDate: Date | null;
  setEndDate: (date: Date | null) => void;
  originalAmount: number;
  computedAmount: number;
  setComputedAmount: (amount: number) => void;
  handleSave: () => void;
}

export const RecurringItemAmountByDateRangeModalView: FC<
  RecurringItemAmountByDateRangeModalViewProps
> = ({
  recurringItem,
  open,
  handleClose,
  receiptBatchDate,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  originalAmount,
  computedAmount,
  setComputedAmount,
  handleSave,
}) => {
  const { t } = useTranslation();
  const { fCurrencyCents } = useFormatting();
  const [dateError, setDateError] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (endDate) {
      // computeAmountEndDate uses euros instead of cents
      const { sum } = computeAmountEndDate(
        recurringItem.amount,
        recurringItem.chargeFullPeriod,
        recurringItem.computeDaily,
        startDate ?? undefined,
        endDate
      );
      setComputedAmount(sum);
    }
  }, [
    startDate,
    endDate,
    recurringItem,
    originalAmount,
    receiptBatchDate,
    setComputedAmount,
  ]);

  return (
    <Modal open={open} handleClose={handleClose}>
      <ModalInner>
        <Card
          sx={{
            width: 650,
            p: 2,
            maxHeight: '100vh',
            overflowY: 'auto',
          }}
        >
          <Stack gap={2}>
            <Typography variant="h5">
              {t('receipts.batch.form.recurring-item-calculations.modal.title')}
            </Typography>
            <RecurringItemOverview
              name={recurringItem.name}
              amount={recurringItem.amount}
              computeDaily={recurringItem.computeDaily}
              chargeFullPeriod={recurringItem.chargeFullPeriod}
              taxCode={recurringItem.taxCode}
            />
            <Stack>
              <RecurringItemDateRangePicker
                receiptBatchDate={receiptBatchDate}
                chargeFullPeriod={recurringItem.chargeFullPeriod}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                dateError={dateError}
                setDateError={setDateError}
              />
              <Stack direction="column" alignItems="center">
                <Typography>
                  {t(
                    'receipts.batch.form.recurring-item-calculations.modal.amount-to-be-charged'
                  )}
                  :
                </Typography>
                <Typography color="success.main" fontWeight={700} variant="h4">
                  {fCurrencyCents(computedAmount)}
                </Typography>
              </Stack>
            </Stack>

            <Stack direction="row" justifyContent="flex-end" gap={1}>
              <Button variant="outlined" onClick={handleClose}>
                {t(
                  'receipts.batch.form.recurring-item-calculations.modal.cancel'
                )}
              </Button>

              <Button
                disabled={!!dateError || originalAmount === computedAmount}
                color="primary"
                variant="contained"
                onClick={handleSave}
              >
                {t(
                  'receipts.batch.form.recurring-item-calculations.modal.save'
                )}
              </Button>
            </Stack>
          </Stack>
        </Card>
      </ModalInner>
    </Modal>
  );
};

interface RecurringItemDateRangePickerProps {
  startDate: Date | null;
  setStartDate: (date: Date | null) => void;
  endDate: Date | null;
  setEndDate: (date: Date | null) => void;
  receiptBatchDate: Date;
  chargeFullPeriod: boolean;
  dateError?: string;
  setDateError?: (error: string | undefined) => void;
}

const RecurringItemDateRangePicker: FC<RecurringItemDateRangePickerProps> = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  receiptBatchDate,
  chargeFullPeriod,
}) => {
  const { t } = useTranslation();

  const [dateError, setDateError] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (startDate && endDate) {
      if (startDate >= endDate) {
        setDateError(
          t('receipts.batch.form.recurring-item-calculations.modal.date-error')
        );
      } else if (startDate < startOfMonth(new Date(receiptBatchDate))) {
        setDateError(
          t(
            'receipts.batch.form.recurring-item-calculations.modal.date-is-before-batch-date'
          )
        );
      } else if (endDate > endOfMonth(new Date(receiptBatchDate))) {
        setDateError(
          t(
            'receipts.batch.form.recurring-item-calculations.modal.date-is-after-batch-date'
          )
        );
      } else {
        setDateError(undefined);
      }
    } else {
      setDateError(undefined);
    }
  }, [startDate, endDate, receiptBatchDate, t]);

  const validateDateOnBlur = useCallback(
    (value: string) => {
      if (value.trim() === '') {
        setDateError(undefined);
        return;
      }
      if (!isValidDate(new Date(value))) {
        setDateError(
          t(
            'receipts.batch.form.recurring-item-calculations.modal.date-invalid'
          )
        );
      }
    },
    [t]
  );

  return (
    <Stack gap={1}>
      {chargeFullPeriod ? (
        <Alert
          severity="warning"
          sx={{
            mb: 2,
          }}
        >
          {t(
            'receipts.batch.form.recurring-item-calculations.modal.full-charge-warning'
          )}
        </Alert>
      ) : (
        <>
          <Typography variant="body1">
            {t(
              'receipts.batch.form.recurring-item-calculations.modal.please-select-dates'
            )}
            :
          </Typography>
          <Stack direction="row" justifyContent="space-between" gap={1}>
            <DatePicker
              label={t(
                'receipts.batch.form.recurring-item-calculations.modal.start'
              )}
              value={startDate}
              onChange={(newValue) => {
                if (isValidDate(newValue)) {
                  setStartDate(newValue ? startOfDay(newValue) : newValue);
                } else {
                  setStartDate(null);
                }
              }}
              minDate={startOfMonth(new Date(receiptBatchDate))}
              maxDate={endDate ?? endOfMonth(new Date(receiptBatchDate))}
              defaultCalendarMonth={new Date(receiptBatchDate)}
              renderInput={(params) => (
                <Stack width="100%">
                  <TextField
                    {...params}
                    error={!!dateError}
                    size="small"
                    onBlur={(e) => validateDateOnBlur(e.target.value)}
                  />
                  <FormHelperText
                    sx={{
                      color: 'error.main',
                    }}
                  >
                    {dateError ? dateError : ' '}
                  </FormHelperText>
                </Stack>
              )}
            />
            <DatePicker
              label={t(
                'receipts.batch.form.recurring-item-calculations.modal.end'
              )}
              value={endDate}
              onChange={(newValue) => {
                if (isValidDate(newValue)) {
                  setEndDate(endOfDay(newValue));
                } else {
                  setEndDate(null);
                }
              }}
              minDate={startDate ?? startOfMonth(new Date(receiptBatchDate))}
              maxDate={endOfMonth(new Date(receiptBatchDate))}
              defaultCalendarMonth={new Date(receiptBatchDate)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={!!dateError}
                  size="small"
                  onBlur={(e) => validateDateOnBlur(e.target.value)}
                  sx={{
                    width: '100%',
                  }}
                />
              )}
            />
          </Stack>
        </>
      )}
    </Stack>
  );
};

const isValidDate = (d: any): d is Date => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return d instanceof Date && !isNaN(d);
};

interface RecurringItemOverviewProps {
  name: string;
  amount: number;
  computeDaily: boolean;
  chargeFullPeriod?: boolean;
  taxCode: TaxCodeDto | undefined;
}

export const RecurringItemOverview: FC<RecurringItemOverviewProps> = ({
  amount,
  name,
  computeDaily,
  chargeFullPeriod,
  taxCode,
}) => {
  const { fCurrencyCents } = useFormatting();
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Alert variant="outlined" severity="info">
      <Stack direction="column" alignItems="flex-start">
        <AlertTitle>
          <OneLineTypography
            variant="overline"
            sx={{
              fontSize: 13,
            }}
            color="text.secondary"
          >
            {name}
          </OneLineTypography>
        </AlertTitle>

        <Stack direction="row" alignItems="center" flexWrap="wrap" gap={0.5}>
          <Stack
            direction="row"
            sx={{
              alignItems: 'center',
              mr: 3,
            }}
            gap={0.5}
          >
            <Typography fontSize={16} color="text.secondary">
              {fCurrencyCents(amount)}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {computeDaily ? `/${t('common.day')}` : `/${t('common.month')}`}
            </Typography>
          </Stack>
          {!chargeFullPeriod && (
            <Stack mr={3}>
              <InfoItem
                Icon={() => (
                  <Iconify
                    icon="material-symbols:info"
                    color={theme.palette.grey['600']}
                    width={18}
                    height={18}
                  />
                )}
                text={t(
                  'receipts.batch.form.recurring-item-calculations.modal.prorate-activated'
                )}
              />
            </Stack>
          )}
          {taxCode && (
            <InfoItem
              Icon={() => (
                <Iconify
                  icon="tabler:tax-euro"
                  color={theme.palette.grey['600']}
                  width={20}
                  height={20}
                  sx={{
                    mt: '-2px',
                    ml: '-2px',
                  }}
                />
              )}
              text={`${taxCode.taxName} (${
                parseFloat(taxCode.taxAmount) * 100
              }%)`}
            />
          )}
        </Stack>
      </Stack>
    </Alert>
  );
};

interface InfoItemProps {
  Icon: React.ComponentType<SVGProps<SVGSVGElement>>;
  text: string;
}

const InfoItem: FC<InfoItemProps> = ({ Icon, text }) => {
  const theme = useTheme();
  return (
    <Stack direction="row" alignItems="center" gap={0.7}>
      <Icon width={18} height={18} color={theme.palette.text.secondary} />
      <Typography variant="body2" color="text.secondary">
        {text}
      </Typography>
    </Stack>
  );
};
