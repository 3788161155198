import type { FC } from 'react';
import { ReportingStatus } from '@pflegenavi/shared/api';
import type { LabelProps } from './Label';
import { Label } from './Label';
import { useTranslation } from 'react-i18next';

interface ResidentBalanceStatusLabelProps
  extends Omit<LabelProps, 'variant' | 'color'> {
  status: ReportingStatus;
  isInactive: boolean;
}

export const ResidentBalanceStatusLabel: FC<
  ResidentBalanceStatusLabelProps
> = ({ status, isInactive, ...other }) => {
  const { t } = useTranslation();

  if (isInactive) {
    return (
      <Label variant="ghost" color={'default'} {...other}>
        {t('statusLabel.Inactive')}
      </Label>
    );
  }

  const balanceColor =
    status === ReportingStatus.Negative
      ? 'error'
      : status === ReportingStatus.Low
      ? 'warning'
      : 'success';

  const balanceLabel =
    status === ReportingStatus.Negative
      ? t('residents.balanceStatus.negative')
      : status === ReportingStatus.Low
      ? t('residents.balanceStatus.low')
      : t('residents.balanceStatus.sufficient');

  return (
    <Label variant="ghost" color={balanceColor} {...other}>
      {balanceLabel}
    </Label>
  );
};
