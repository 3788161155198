import { TransactionSourceType, TransactionType } from '@pflegenavi/shared/api';
import type { FC } from 'react';
import React, { useMemo } from 'react';
import { Label } from '@pflegenavi/web-components';

interface TypeLabelProps {
  label: string;
  type: TransactionType;
  sourceType: TransactionSourceType;
}

export const TypeLabel: FC<TypeLabelProps> = ({ label, type, sourceType }) => {
  const color = useMemo(() => {
    if (
      sourceType === TransactionSourceType.CancelledReceipt ||
      sourceType === TransactionSourceType.CancelledManualDeposit ||
      sourceType === TransactionSourceType.Dispute
    ) {
      return 'warning';
    }
    if (type === TransactionType.DEPOSIT) {
      return 'success';
    }
    if (type === TransactionType.WITHDRAWAL) {
      return 'info';
    }
    return 'default';
  }, [sourceType, type]);

  return (
    <Label variant="ghost" color={color} sx={{ textTransform: 'capitalize' }}>
      {label}
    </Label>
  );
};
