import type { FC, PropsWithChildren } from 'react';
import { useEffect, useState } from 'react';
import type { NursingHomeProfileDto } from '@pflegenavi/shared/api';
import { useClientStoredResourcesContext } from '@pflegenavi/frontend/client-stored-resources-context';
import { NursingHomeContext } from './NursingHomeContext';

export const NursingHomeProvider: FC<PropsWithChildren> = ({ children }) => {
  const resources = useClientStoredResourcesContext();

  const [selectedNursingHome, setSelectedNursingHome] = useState<
    NursingHomeProfileDto | undefined
  >(undefined);

  const [nursingHomeList, setNursingHomeListInternal] = useState<
    NursingHomeProfileDto[]
  >([]);

  useEffect(() => {
    resources.registerResource('selectedNursingHomeId');
    resources.registerResource('selectedNursingHome');
  }, [resources]);

  useEffect(() => {
    // Pre-load the nursing home information in case we have it.
    (async function () {
      const nursingHomeJson = await resources.get('selectedNursingHome');
      if (!nursingHomeJson) {
        return;
      }
      setSelectedNursingHome((nursingHome) => {
        // We already received data from the backend - do not overwrite with storage data.
        if (nursingHome) {
          return nursingHome;
        }
        return JSON.parse(nursingHomeJson);
      });
    })();
  }, [resources]);

  const initNursingHome = async (nursingHome: NursingHomeProfileDto) => {
    await resources.set('selectedNursingHomeId', nursingHome.id);
    await resources.set('selectedNursingHome', JSON.stringify(nursingHome));
    setSelectedNursingHome(nursingHome);
  };

  const setNursingHomeList = async (list: NursingHomeProfileDto[]) => {
    setNursingHomeListInternal(list);

    const itemId = await resources.get('selectedNursingHomeId');

    if (itemId) {
      const item = list.find((item) => item.id === itemId);
      if (item) {
        await initNursingHome(item);
      } else if (list.length > 0) {
        await initNursingHome(list[0]);
      }
    } else if (list.length > 0) {
      await initNursingHome(list[0]);
    }
  };

  return (
    <NursingHomeContext.Provider
      value={{
        selectedNursingHome,
        initNursingHome,
        nursingHomeList,
        setNursingHomeList,
      }}
    >
      {children}
    </NursingHomeContext.Provider>
  );
};

/**
 * This is a dummy provider for the family member apps until we need to fetch the nursing home (e.g. for feature flags)
 */
export const EmptyNursingHomeProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  return (
    <NursingHomeContext.Provider
      value={{
        selectedNursingHome: undefined,
        initNursingHome: () => {
          return Promise.resolve();
        },
        nursingHomeList: [],
        setNursingHomeList: () => {
          return;
        },
      }}
    >
      {children}
    </NursingHomeContext.Provider>
  );
};
