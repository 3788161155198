// @mui
import { styled } from '@mui/material/styles';
import type {
  LinkProps,
  LinkTypeMap,
  ListItemButtonProps,
} from '@mui/material';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
// config
import { ICON, NAVBAR } from './config';
import type { OverridableComponent } from '@mui/material/OverridableComponent';
import type { ForwardRefExoticComponent, RefAttributes } from 'react';
import type { NavLinkProps } from 'react-router-dom';

// ----------------------------------------------------------------------

type IProps = LinkProps & ListItemButtonProps;

interface ListItemStyleProps extends IProps {
  component?:
    | OverridableComponent<LinkTypeMap<Record<never, never>, 'a'>>
    | ForwardRefExoticComponent<
        NavLinkProps & RefAttributes<HTMLAnchorElement>
      >;
  to?: string;
  activeRoot?: boolean;
  activeSub?: boolean;
  subItem?: boolean;
}

export const ListItemStyle = styled(ListItemButton, {
  shouldForwardProp: (prop) =>
    prop !== 'activeRoot' && prop !== 'activeSub' && prop !== 'subItem',
})<ListItemStyleProps>(({ activeRoot, activeSub, subItem, theme }) => ({
  ...theme.typography.body2,
  position: 'relative',
  height: NAVBAR.DASHBOARD_ITEM_ROOT_HEIGHT,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(1.5),
  marginBottom: theme.spacing(0.5),
  color: theme.palette.text.primary,
  borderRadius: theme.shape.borderRadius,

  // activeRoot
  ...(activeRoot && {
    backgroundColor: theme.palette.primary[500_8],
  }),
  // activeSub
  ...(activeSub && {
    ...theme.typography.subtitle2,
    color: theme.palette.text.primary,
  }),
  // subItem
  ...(subItem && {
    height: NAVBAR.DASHBOARD_ITEM_SUB_HEIGHT,
  }),
}));

interface ListItemTextStyleProps extends ListItemButtonProps {
  isCollapse?: boolean;
  active?: boolean;
}

export const ListItemTextStyle = styled(ListItemText, {
  shouldForwardProp: (prop) => prop !== 'isCollapse' && prop !== 'active',
})<ListItemTextStyleProps>(({ isCollapse, theme, active }) => ({
  whiteSpace: 'nowrap',
  color: theme.palette.text.secondary,
  fontSize: 16,
  paddingTop: '2px',
  transition: theme.transitions.create(['width', 'opacity'], {
    duration: theme.transitions.duration.shorter,
  }),
  ...(active && {
    color: theme.palette.text.primary,
  }),
  ...(isCollapse && {
    width: 0,
    opacity: 0,
  }),
}));

interface ListSubItemTextStyleProps extends ListItemButtonProps {
  active?: boolean;
}

export const ListSubItemTextStyle = styled(ListItemText, {
  shouldForwardProp: (prop) => prop !== 'active',
})<ListSubItemTextStyleProps>(({ theme, active }) => ({
  color: theme.palette.text.secondary,
  ...(active && {
    color: theme.palette.text.primary,
  }),
}));

export const ListItemIconStyle = styled(ListItemIcon, {
  shouldForwardProp: (prop) => prop !== 'active',
})<ListSubItemTextStyleProps>(({ theme, active }) => ({
  width: ICON.NAVBAR_ITEM,
  height: ICON.NAVBAR_ITEM,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& svg': { width: '100%', height: '100%' },
  color: theme.palette.grey[50032],
  ...(active && {
    color: theme.palette.text.primary,
  }),
}));
