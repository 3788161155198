import type { FC } from 'react';
import { memo, useCallback, useEffect, useState } from 'react';
import { Button, Stack } from '@pflegenavi/web-components';

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface DevPageProps {}

interface ChildComponentProps {
  name: string;
  value: number;
  onClick: (id: string) => void;
}
const ChildComponent: FC<ChildComponentProps> = memo(({ name, value }) => {
  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log(`${name} mounted`);
    return () => {
      // eslint-disable-next-line no-console
      console.log(`${name} unmounted`);
    };
  }, [name]);

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log(`${name} rendered`);
  });

  return <span>{value}</span>;
});

export const DevPage: FC<DevPageProps> = () => {
  const [value, setValue] = useState(1);

  const otherValue = Math.round(value / 2);

  // const info = useMemo(() => {
  //   return () => {}
  // }, [])

  const info = useCallback((id: string) => {
    return;
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('mounted');
    return () => {
      // eslint-disable-next-line no-console
      console.log('unmounted');
    };
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('rendered');
  });

  return (
    <Stack direction="column">
      <Button onClick={() => setValue((old) => old + 1)}>Increment</Button>
      <ChildComponent name="Hello" value={value} onClick={info} />
      <ChildComponent name="World" value={otherValue} onClick={info} />
    </Stack>
  );
};
