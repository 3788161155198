import fc from 'fast-check';
import type { CreateResidentWithoutNursingHomeDto } from '@pflegenavi/shared/api';
import { Gender } from '@pflegenavi/shared/api';
import { firstNames, lastNames } from './names';
import { makeCreateResidentFamilyMemberArb } from './familyMember';
import { subYears } from 'date-fns';

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface MakeCreateResidentDtoArb {}

export const genderArb = fc.constantFrom(
  Gender.Male,
  Gender.Female,
  Gender.Other
);

export const makeCreateResidentDtoArb =
  (): fc.Arbitrary<CreateResidentWithoutNursingHomeDto> => {
    return fc.record({
      firstName: firstNames,
      lastName: lastNames,
      birthdate: fc.date({
        min: subYears(new Date(), 90),
        max: subYears(new Date(), 60),
      }),
      gender: genderArb,
      entryDate: fc.date({ min: subYears(new Date(), 1), max: new Date() }),
      familyMember: makeCreateResidentFamilyMemberArb(),
      initial_balance_in_cent: fc.integer({ min: 5000, max: 25000 }),
    });
  };
