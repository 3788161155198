function abbreviateName(fullName: string): string {
  const names = fullName.trim().split(' ');
  let abbreviation = '';

  if (names.length === 0) {
    return abbreviation;
  }

  if (names.length === 3) {
    names.splice(1, 1);
  }

  for (let i = 0; i < names.length - 1; i++) {
    if (names[i].includes('-')) {
      const hyphenatedNames = names[i].split('-');

      for (let j = 0; j < hyphenatedNames.length; j++) {
        if (j === hyphenatedNames.length - 1) {
          abbreviation += hyphenatedNames[j][0].toUpperCase() + '.';
        } else {
          abbreviation += hyphenatedNames[j][0].toUpperCase() + '.-';
        }
      }

      abbreviation = abbreviation.slice(0, -1) + '. ';
      continue;
    }
    abbreviation += names[i][0].toUpperCase() + '. ';
  }

  abbreviation += names[names.length - 1];

  return abbreviation;
}

export { abbreviateName };
