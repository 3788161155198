import {
  makeApiProvider,
  useApiQuery,
} from '@pflegenavi/shared-frontend/platform';
import { createContext, useContext } from 'react';
import type { IUserProfileApi } from './api';
import { UserProfileApi } from './api';
import type {
  EmployeeProfileDto,
  FamilyMemberProfileDto,
  FamilyMemberWithIdDto,
  UpdateFamilyMemberDto,
  UpdateUserProfileOptionalInfoDto,
  UserProfileInterface,
} from '@pflegenavi/shared/api';
import {
  useAuthentication,
  useAuthenticationInternal,
} from '@pflegenavi/frontend/authentication';
import type {
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { useMutation, useQueryClient } from 'react-query';
import { invalidateResident } from '../resident/queryKeys';
import { USER_PROFILE_KEY } from './queryKeys';

const ApiContext = createContext<IUserProfileApi | undefined>(undefined);
const { useApi: useUserProfileApi, ApiProvider: UserProfileApiProvider } =
  makeApiProvider({
    name: 'UserProfile',
    ApiContext,
    newApi: (tenantId, auth, apiUrl) =>
      new UserProfileApi(tenantId, auth, apiUrl),
  });

export {
  useUserProfileApi,
  UserProfileApiProvider,
  ApiContext as UserProfileApiContext,
};
export type { IUserProfileApi } from './api';

export const ProfileTypeContext = createContext<
  'employee' | 'familyMember' | undefined
>(undefined);

/**
 * Will call the correct endpoint based on the context
 */
export const useUserProfileWithContext = (
  options: UseQueryOptions<UserProfileInterface>
): UseQueryResult<UserProfileInterface> => {
  const app = useContext(ProfileTypeContext);

  const auth = useAuthenticationInternal();

  const api = useUserProfileApi(options.enabled === false);

  return useApiQuery(
    () => api,
    USER_PROFILE_KEY(auth.user?.userId ?? ''),
    (api) => {
      return app === 'employee'
        ? (api?.getEmployeeProfile() as unknown as Promise<UserProfileInterface>)
        : (api?.get() as unknown as Promise<FamilyMemberProfileDto>);
    },
    {
      ...options,
      staleTime: options?.staleTime ?? Infinity,
    }
  );
};

export const useFamilyMemberProfile = (
  options: UseQueryOptions<FamilyMemberProfileDto>
): UseQueryResult<FamilyMemberProfileDto> => {
  const { user } = useAuthentication();

  const api = useUserProfileApi(options.enabled === false);

  return useApiQuery(
    () => api,
    USER_PROFILE_KEY(user?.userId ?? ''),
    (api) => {
      return api?.get() as unknown as Promise<FamilyMemberProfileDto>;
    },

    {
      ...options,
      staleTime: options?.staleTime ?? Infinity,
    }
  );
};

export const useEmployeeProfile = (
  options: UseQueryOptions<EmployeeProfileDto>
): UseQueryResult<EmployeeProfileDto> => {
  const { user } = useAuthentication();

  const api = useUserProfileApi(options.enabled === false);

  return useApiQuery(
    () => api,
    USER_PROFILE_KEY(user?.userId ?? ''),
    (api) => {
      return api?.getEmployeeProfile() as unknown as Promise<EmployeeProfileDto>;
    },
    {
      ...options,
      staleTime: options?.staleTime ?? Infinity,
    }
  );
};

export const useUpdateUserProfile = (): UseMutationResult<
  UpdateUserProfileOptionalInfoDto,
  unknown,
  UpdateUserProfileOptionalInfoDto
> => {
  const api = useUserProfileApi();
  const queryClient = useQueryClient();
  const { user } = useAuthentication();

  return useMutation<
    UpdateUserProfileOptionalInfoDto,
    unknown,
    UpdateUserProfileOptionalInfoDto
  >(
    'update_user',
    (data) => api?.updateUser(data) as unknown as Promise<{ id: string }>,
    {
      onSuccess: async () => {
        return await queryClient.invalidateQueries(
          USER_PROFILE_KEY(user?.userId ?? '')
        );
      },
    }
  );
};

export const useUpdateFamilyMemberByEmployee = (
  residentId: string
): UseMutationResult<UpdateFamilyMemberDto, unknown, UpdateFamilyMemberDto> => {
  const api = useUserProfileApi();
  const queryClient = useQueryClient();

  return useMutation<UpdateFamilyMemberDto, unknown, UpdateFamilyMemberDto>(
    'update_family_member',
    (data) =>
      api?.updateByEmployee(data) as unknown as Promise<FamilyMemberWithIdDto>,
    {
      onSuccess: async () => {
        return await invalidateResident(queryClient, residentId, undefined);
      },
    }
  );
};

export const usePostTest400 = (): UseMutationResult<void, unknown, void> => {
  const api = useUserProfileApi();

  return useMutation<void, unknown, void>('TEST_POST_400', () =>
    api?.test400Post()
  );
};
