import * as Sentry from '@sentry/react';
import React from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  Routes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { HttpClient } from '@sentry/integrations';

const getEnvironment = () => {
  if (window.location.hostname.includes('localhost')) {
    return 'local';
  }
  return window.location.hostname.includes('staging.pflegenavi.at')
    ? 'staging'
    : 'production';
};

const getTracePropagationTargets = () => {
  if (getEnvironment() === 'local') {
    return ['localhost:3005'];
  } else if (getEnvironment() === 'staging') {
    return ['api.staging.pflegenavi.at'];
  } else {
    return ['api.pflegenavi.at'];
  }
};

const additionalPlugins = () => {
  // Test it on staging first
  if (getEnvironment() !== 'production') {
    const apiUrl =
      getEnvironment() === 'staging'
        ? 'api.staging.pflegenavi.at'
        : 'localhost:3005';
    return [
      new Sentry.Replay({
        // Additional SDK configuration goes in here, for example:
        maskAllInputs: true,
        maskAllText: false,
        blockAllMedia: false,
        stickySession: true,
        networkDetailAllowUrls: [apiUrl],
      }),
      new Sentry.BrowserProfilingIntegration(),
    ];
  }

  return [
    new Sentry.Replay({
      maskAllInputs: true,
      maskAllText: true,
      blockAllMedia: true,
      stickySession: true,
      networkDetailAllowUrls: ['pflegenavi.at'],
      mutationLimit: 1000,
      mutationBreadcrumbLimit: 250,
    }),
  ];
};

const sampleRates = () => {
  const environment = getEnvironment();
  if (environment === 'local') {
    return {
      replaysSessionSampleRate: 1.0,
      replaysOnErrorSampleRate: 1.0,
      sampleRate: 1.0,
      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 1.0,
      profilesSampleRate: 1.0,
    };
  } else {
    return {
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      sampleRate: 1.0,
      tracesSampleRate: 0.1,
      // Profiles sample rate is relative to tracesSampleRate
      profilesSampleRate: 1.0,
    };
  }
};

Sentry.init({
  dsn: 'https://92df199ec69343bd987bc95784b88510@o1342624.ingest.sentry.io/6619093',
  enabled: import.meta.env.PROD,
  environment: getEnvironment(),
  integrations: [
    ...additionalPlugins(),
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
      tracePropagationTargets: getTracePropagationTargets(),
      idleTimeout: 2000,
    }),
    new HttpClient(),
  ],
  ...sampleRates(),
});

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
