export const nhAppMainPages = {
  HOME: '/',
  DASHBOARD: '/dashboard',
  RESIDENTS: '/residents',
  RECURRING_ITEMS: '/recurring-items',
  RECURRING_ITEMS_V2: '/recurring-items-v2',
  RECURRING_ITEMS_V2_DETAIL: '/recurring-items-v2/:recurringItemId',
  RECURRING_ITEMS_V2_DETAIL_RESIDENTS:
    '/recurring-items-v2/:recurringItemId/residents',
  RECURRING_ITEMS_V2_DETAIL_TRANSACTIONS:
    '/recurring-items-v2/:recurringItemId/transactions',
  RECURRING_ITEMS_V2_DETAIL_RECEIPT_BATCHES:
    '/recurring-items-v2/:recurringItemId/receipt-batches',
  RESIDENTS_ADD: '/residents/add',
  RESIDENTS_INACTIVE: '/residents/inactive',
  RESIDENT_DETAIL: '/residents/:resident_id/detail',
  RESIDENT_DETAIL_ACCOUNT: '/residents/:resident_id',
  RESIDENT_DETAIL_FAMILY_MEMBERS: '/residents/:resident_id/family-members',
  RESIDENT_DETAIL_SERVICE_PROVIDERS:
    '/residents/:resident_id/service-providers',
  RESIDENTS_IMPORT_FAMILY_MEMBERS: '/residents/family-members/import',
  PAYMENTS: '/residents/payments',
  TRANSACTIONS: '/transactions',
  TRANSACTIONS_PENDING: '/transactions/pending',
  TRANSACTIONS_SUBMITTED: '/transactions/submitted',
  TRANSACTIONS_ADD_EMPTY_RESIDENT: '/transactions/add',
  TRANSACTIONS_ADD: '/transactions/add/:resident_id',
  TRANSACTIONS_EDIT: '/transactions/edit/:transaction_id',
  RECEIPTS_BATCH: '/transactions/batch',
  RECEIPTS_BATCH_ADD: '/transactions/batch/add',
  RECEIPTS_BATCH_EDIT: '/transactions/batch/edit/:batchId',
  CASH_MANAGEMENT: '/cash-management',
  CASH_MANAGEMENT_CASH: '/cash-management/cash',
  CASH_MANAGEMENT_GROUP_DETAIL: '/cash-management/group/:groupId',
  CASH_MANAGEMENT_CHANGE_CASH: '/cash-management/update',
  CASH_MANAGEMENT_TRANSACTIONS: '/cash-management/transactions',
  REPORTING: '/reporting',
  REPORTING_PRINT: '/reporting/print/:month',
  REPORTING_TRANSACTIONS: '/reporting/transactions',
  NURSING_HOME: '/nursing-home',
  NURSING_HOME_SERVICE_PROVIDER_DIRECT_DEBIT:
    '/nursing-home/service-provider-direct-debit',
  NURSING_HOME_REFRESH: '/nursing-home/refresh',
  NURSING_HOME_ACCOUNTING: '/accounting',
  NURSING_HOME_ACCOUNTING_OVERVIEW: '/accounting/overview',
  NURSING_HOME_ACCOUNTING_OVERVIEW_NEW: '/accounting/overview_new',
  NURSING_HOME_ACCOUNTING_PAYOUTS: '/accounting/payouts',
  NURSING_HOME_ACCOUNTING_PAYMENTS: '/accounting/payments',
  NURSING_HOME_ACCOUNTING_TRANSACTION_GROUPS: '/accounting/transaction-groups',
  NURSING_HOME_ACCOUNTING_TRANSACTIONS: '/accounting/transactions',
  NURSING_HOME_ACCOUNTING_GROUP_DETAIL: '/accounting/group/:groupId',
  NURSING_HOME_ACCOUNTING_SERVICE_PROVIDER_PAYMENTS:
    '/accounting/service-provider-payments',
  NURSING_HOME_SELECT: '/select-nursing-home/:nursingHomeId',
  NURSING_HOME_EMPLOYEES_LIST: '/nursing-home/employees-list',
  NURSING_HOME_LEGAL_ENTITIES: '/nursing-home/legal-entities',
  SERVICE_PROVIDER: '/service-provider',
  SERVICE_PROVIDER_ADD: '/service-provider/add',
  SERVICE_PROVIDER_DETAILS: '/service-provider/:serviceProviderId',
  SERVICE_PROVIDER_DETAILS_RESIDENTS:
    '/service-provider/:serviceProviderId/residents',
  DEV_PAGE: '/dev-page',
  WELCOME: '/welcome',
} as const;
