import type { ChangeEvent, FC } from 'react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { CircularProgress, Stack, Typography } from '@mui/material';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { Iconify } from './Iconify';

export interface FileInputProps {
  label: string;
  setFile: (file: File | undefined) => void;
  loading?: boolean;
  disabled?: boolean;
  disabledMessage?: string;
}

export const FileInput: FC<FileInputProps> = ({
  label,
  setFile,
  loading,
  disabled,
  disabledMessage,
}) => {
  const theme = useTheme();
  const [inputKey, setInputKey] = useState<number>(0);

  const inputRef = useRef<HTMLInputElement>(null);
  // A ref to the drop area element
  const dropRef = useRef<HTMLDivElement | null>(null);

  const handleClickContainer = () => {
    inputRef.current?.click();
  };
  const handleFileInput = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files ? event.target.files[0] : null;
      if (file) {
        setFile(file);
        setInputKey((prevKey) => prevKey + 1);
      }
    },
    [setFile]
  );

  // Define the drop spec
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item: { files: File[] }) {
        const file = item.files[0];
        if (file) {
          setFile(file);
          setInputKey((prevKey) => prevKey + 1);
        }
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [setFile, setInputKey]
  );

  // Attach the drop handler to the drop area element
  useEffect(() => {
    if (!disabled) {
      drop(dropRef);
    }
  }, [disabled, drop]);

  const isIdle = canDrop && isOver;

  if (loading) {
    return (
      <DropZoneStyle disabled={disabled ?? false} isIdle={false}>
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ height: '100%' }}
        >
          <CircularProgress sx={{ color: 'grey.400' }} size={40} />
        </Stack>
      </DropZoneStyle>
    );
  }

  return (
    <DropZoneStyle
      ref={dropRef}
      isIdle={isIdle}
      disabled={disabled ?? false}
      onClick={disabled ? undefined : handleClickContainer}
      title={disabled ? disabledMessage : undefined}
    >
      <input
        accept="image/jpeg,image/png,application/pdf"
        type="file"
        style={{ display: 'none' }}
        onChange={handleFileInput}
        ref={inputRef}
        key={inputKey} // <-- Use the inputKey state as the key attribute
      />
      <Stack
        alignItems="center"
        sx={{
          paddingY: 3,
          paddingX: 2,
        }}
      >
        <Iconify
          icon="eva:cloud-upload-fill"
          width={40}
          height={40}
          color={theme.palette.grey['500']}
        />
        <Typography variant="body2" color="grey.500">
          {label}
        </Typography>
      </Stack>
    </DropZoneStyle>
  );
};

// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')<{
  isIdle: boolean;
  disabled: boolean;
}>(({ theme, isIdle, disabled }) => ({
  outline: 'none',
  overflow: 'hidden',
  position: 'relative',
  height: 120,
  borderRadius: theme.shape.borderRadius * 3,
  transition: theme.transitions.create('padding'),
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  opacity: disabled ? 0.5 : isIdle ? 0.72 : 1,
  '&:hover': disabled
    ? undefined
    : {
        opacity: 0.72,
        cursor: 'pointer',
      },
  cursor: disabled ? 'not-allowed' : 'pointer',
}));
