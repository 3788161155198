import type JsPDF from 'jspdf';

export const drawMultilineText = (
  doc: JsPDF,
  label: string | undefined,
  text: string,
  options: {
    y: number;
    boldLabel: boolean;
    boldText?: boolean;
    allLinesIndented: boolean;
    baseMarginLeft: number;
    baseMarginRight: number;
    fontSize: number;
    lineHeight: number;
    fixedLabelWidth?: number;
  }
): number => {
  const pageSize = doc.internal.pageSize;
  const { fontSize, lineHeight } = options;

  doc.setFontSize(fontSize);
  doc.setTextColor('black');

  let labelTextWidth = 0;
  if (label) {
    if (options.boldLabel) {
      // @ts-expect-error it doesn't let me not specify the font family
      doc.setFont(undefined, 'bold');
    } else {
      // @ts-expect-error it doesn't let me not specify the font family
      doc.setFont(undefined, 'normal');
    }

    doc.text(label, options.baseMarginLeft, options.y);
    labelTextWidth = options.fixedLabelWidth ?? doc.getTextWidth(label);
  }

  if (options.boldText) {
    // @ts-expect-error it doesn't let me not specify the font family
    doc.setFont(undefined, 'bold');
  } else {
    // @ts-expect-error it doesn't let me not specify the font family
    doc.setFont(undefined, 'normal');
  }

  const baseLineWidth =
    pageSize.width - options.baseMarginLeft - options.baseMarginRight;

  const firstLineWidth = baseLineWidth - labelTextWidth;
  const lineWidth = options.allLinesIndented ? firstLineWidth : baseLineWidth;

  // Uses the current font settings (doc.setFont, doc.setFontSize, ...), so set in the correct order
  const firstLineLines = doc.splitTextToSize(text, firstLineWidth);
  const firstLine = firstLineLines[0];

  const allOtherLines =
    firstLineLines.length > 1
      ? doc.splitTextToSize(text.slice(firstLine.length).trim(), lineWidth)
      : [];

  const firstLineLeftMargin = options.baseMarginLeft + labelTextWidth;
  doc.text(firstLine, firstLineLeftMargin, options.y);

  const allOtherLinesLeftMargin = options.allLinesIndented
    ? firstLineLeftMargin
    : options.baseMarginLeft;
  allOtherLines.forEach((line: string, index: number) => {
    doc.text(
      line,
      allOtherLinesLeftMargin,
      options.y + (index + 1) * lineHeight
    );
  });

  // Just to reset font style
  // @ts-expect-error it doesn't let me not specify the font family
  doc.setFont(undefined, 'normal');

  return (allOtherLines.length + 1) * lineHeight;
};

export const drawEnumeration = (
  doc: JsPDF,
  texts: string[],
  options: {
    y: number;
    baseMarginLeft: number;
    baseMarginRight: number;
    fontSize: number;
    lineHeight: number;
    getEnumerationSymbol: (index: number) => string;
    boldEnumeration: boolean;
  }
): number => {
  doc.setFontSize(options.fontSize);
  doc.setTextColor('black');

  let widestEnumerationSymbolWidth = 0;
  const enumerationSymbols: string[] = [];
  for (let i = 0; i < texts.length; i++) {
    const symbol = options.getEnumerationSymbol(i);
    enumerationSymbols.push(symbol);

    if (options.boldEnumeration) {
      // @ts-expect-error it doesn't let me not specify the font family
      doc.setFont(undefined, 'bold');
    } else {
      // @ts-expect-error it doesn't let me not specify the font family
      doc.setFont(undefined, 'normal');
    }

    const candidate = doc.getTextWidth(symbol);
    if (candidate > widestEnumerationSymbolWidth) {
      widestEnumerationSymbolWidth = candidate;
    }
  }

  // @ts-expect-error it doesn't let me not specify the font family
  doc.setFont(undefined, 'normal');

  let currentHeight = 0;
  for (let i = 0; i < texts.length; i++) {
    const symbol = enumerationSymbols[i];
    const text = texts[i];

    const height = drawMultilineText(doc, symbol, text, {
      fixedLabelWidth: widestEnumerationSymbolWidth + 3,
      baseMarginLeft: options.baseMarginLeft,
      baseMarginRight: options.baseMarginRight,
      allLinesIndented: true,
      boldLabel: options.boldEnumeration,
      fontSize: options.fontSize,
      lineHeight: options.lineHeight,
      y: options.y + currentHeight,
    });
    currentHeight += height;
  }

  return currentHeight;
};

export const drawDotLeaderTextLine = (
  doc: JsPDF,
  leftText: string,
  rightText: string,
  options: {
    minX: number;
    maxX: number;
    y: number;
    textToConnectorSpacing: number;
    fontSize: number;
    lineHeight: number;
    leftFontStyle?: string;
    rightFontStyle?: string;
  }
): number => {
  doc.setFontSize(options.fontSize);
  doc.setTextColor('black');

  // @ts-expect-error it doesn't let me not specify the font family
  doc.setFont(undefined, options.leftFontStyle ?? 'normal');
  const leftTextWidth = doc.getTextWidth(leftText);
  doc.text(leftText, options.minX, options.y);

  // @ts-expect-error it doesn't let me not specify the font family
  doc.setFont(undefined, options.rightFontStyle ?? 'normal');
  const rightTextWidth = doc.getTextWidth(rightText);
  doc.text(rightText, options.maxX - rightTextWidth, options.y);

  // @ts-expect-error it doesn't let me not specify the font family
  doc.setFont(undefined, 'normal');

  doc.setLineWidth(0.3);
  doc.setDrawColor(0, 0, 0);
  doc.setLineDashPattern([0.5, 0.5], 0);
  doc.line(
    options.minX + leftTextWidth + options.textToConnectorSpacing,
    options.y,
    options.maxX - rightTextWidth - options.textToConnectorSpacing,
    options.y
  );

  return options.y + options.lineHeight;
};
