/**
 * The authorization header contains a string `Bearer <token>`.
 *
 * This function extracts the token and parses the token payload from it.
 *
 * Example payload:
 * {
 *   "exp": 1690487670,
 *   "iat": 1690487610,
 *   "auth_time": 1690448519,
 *   "jti": "579ca7e5-2285-4853-872e-e5837e7ef8fd",
 *   "iss": "https://auth-staging.pflegenavi.at/auth/realms/default_tenant",
 *   "aud": "account",
 *   "sub": "d84c3d16-9881-4d9b-86c3-ff560a28f862",
 *   "typ": "Bearer",
 *   "azp": "webapp",
 *   "nonce": "925dd47d-b514-40e4-a22e-5e946216a59d",
 *   "session_state": "bb742668-60d0-4b2e-b960-52edc3b472c7",
 *   "acr": "0",
 *   "allowed-origins": [
 *     "https://*.staging.pflegenavi.at",
 *     "https://test.extranet.pflegenavi.at",
 *     "https://default_tenant.staging.pflegenavi.at"
 *   ],
 *   "realm_access": {
 *     "roles": [
 *       "default-roles-default_tenant",
 *       "offline_access",
 *       "uma_authorization"
 *     ]
 *   },
 *   "resource_access": {
 *     "account": {
 *       "roles": [
 *         "manage-account",
 *         "manage-account-links",
 *         "view-profile"
 *       ]
 *     }
 *   },
 *   "scope": "openid email profile",
 *   "sid": "bb742668-60d0-4b2e-b960-52edc3b472c7",
 *   "email_verified": true,
 *   "name": "Admin Employee",
 *   "preferred_username": "e",
 *   "locale": "de",
 *   "given_name": "Admin",
 *   "family_name": "Employee",
 *   "email": "e@pflegenavi.at"
 * }
 *
 * The service provider token has an additional field
 *   "gm": [
 *     "/service_providers/ea2cc1e4-8406-4969-a184-4d51e72f6741"
 *   ],
 */
export const extractTokenPayloadFromAuthorizationHeader = (
  headers:
    | {
        authorization: string | undefined;
        'x-pn-application': string | undefined;
        'x-pn-tenant': string | undefined;
      }
    | undefined,
  cookies: Record<string, string>
): TokenPayload | undefined => {
  const token = getTokenFromRequest(headers, cookies);
  return getTokenPayloadFromToken(token);
};

const getTokenFromAuthorizationHeader = (
  headers:
    | {
        authorization: string | undefined;
      }
    | undefined
): string | undefined => {
  const authHeaders = headers?.authorization;
  if (!authHeaders) {
    return undefined;
  }

  // Remove 'Bearer ' prefix
  const auth = authHeaders.split(' ');
  if (auth[0].toLowerCase() !== 'bearer') {
    return undefined;
  }

  return auth[1];
};

export const getTokenFromRequest = (
  headers:
    | {
        authorization: string | undefined;
        'x-pn-application': string | undefined;
        'x-pn-tenant': string | undefined;
      }
    | undefined,
  cookies: Record<string, string>
): string | undefined => {
  const token = getTokenFromAuthorizationHeader(headers);
  if (token) {
    return token;
  }

  const application = headers?.['x-pn-application'];
  const tenant = headers?.['x-pn-tenant'];
  if (!application) {
    return undefined;
  }
  const suffix = getCookieSuffix(application, tenant);
  return cookies[`JWT_PFLEGENAVI${suffix}`];
};

const getCookieSuffix = (
  application: string | undefined,
  tenant: string | undefined
) => {
  if (application === 'nursing-home-web') {
    if (!tenant) {
      throw new Error('Tenant is required for nursing-home-web application');
    }
    return `_${tenant}`;
  } else if (application === 'service-provider-web') {
    return '_SP';
  } else if (application === 'family-member-web') {
    return '_FM';
  } else {
    throw new Error(
      'Unknown application. Auth Cookie is only supported in web applications.'
    );
  }
};

export interface TokenPayload {
  v?: 'pn0.1';
  exp: number;
  iat: number;
  auth_time: number;
  jti: string;
  iss: string;
  aud: string;
  sub: string;
  typ: string;
  azp: string;
  nonce: string;
  session_state: string;
  acr: string;
  'allowed-origins': string[];
  realm_access: {
    roles: string[];
  };
  resource_access: {
    account: {
      roles: string[];
    };
  };
  scope: string;
  sid: string;
  email_verified: boolean;
  gm: string[];
  name: string;
  preferred_username: string;
  given_name: string;
  family_name: string;
  locale: string;
  email: string;
  ff?: string[];
  reduri?: string;
  pp: 'mangopay' | 'stripe';
}

export const getTokenPayloadFromToken = <T extends TokenPayload>(
  token: string | undefined | null
): T | undefined => {
  if (!token) {
    return undefined;
  }

  // The middle part contains the base64 encoded token payload
  const parts = token.split('.');
  if (parts.length < 2) {
    return undefined;
  }
  return decodeBase64<T>(parts[1]) ?? undefined;
};

export const decodeBase64 = <T>(data: string): T | null => {
  // Detecting if the function is running in a browser environment
  if (typeof window !== 'undefined' && typeof window.atob === 'function') {
    const decoded = window.atob(data);
    return JSON.parse(decodeURIComponent(escape(decoded)));
  }
  // Fallback for Node.js
  else if (typeof Buffer === 'function') {
    return JSON.parse(Buffer.from(data, 'base64').toString('utf8'));
  } else {
    return decodeBase64Manually(data);
  }
};

export const decodeBase64Manually = <T>(data: string): T | null => {
  const decoded = atob(urlSafeDecode(data));
  return JSON.parse(decodeURIComponent(escape(decoded)));
};

const DEC = {
  '-': '+',
  _: '/',
  '.': '=',
};

// Taken from https://github.com/commenthol/url-safe-base64/blob/master/src/index.js
/**
 * decode url-safe-base64 string to base64
 * @param {String} safe - url-safe-base64 string
 * @return {String} base64 encoded
 */
export const urlSafeDecode = (safe: string): string => {
  // @ts-expect-error // DEC[m] is not a valid index
  return safe.replace(/[-_.]/g, (m) => DEC[m]);
};

// Taken from https://stackoverflow.com/a/42833475
const chars =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';

export const atob: (input?: string) => string = (input = '') => {
  const str = input.replace(/=+$/, '');
  let output = '';

  if (str.length % 4 === 1) {
    throw new Error(
      "'atob' failed: The string to be decoded is not correctly encoded."
    );
  }
  for (
    let bc = 0, bs = 0, buffer, i = 0;
    (buffer = str.charAt(i++));
    ~buffer && ((bs = bc % 4 ? bs * 64 + buffer : buffer), bc++ % 4)
      ? (output += String.fromCharCode(255 & (bs >> ((-2 * bc) & 6))))
      : 0
  ) {
    buffer = chars.indexOf(buffer);
  }

  return output;
};
