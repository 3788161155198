import type {
  ResidentListItemDto,
  GetAllResidentsResult,
} from '@pflegenavi/shared/api';
import {
  BalanceStatusColors,
  DirectDebitStatus,
  PaymentInitiatedBy,
  PaymentSchedule,
  ResidentPendingChargePaymentStatus,
  ResidentState,
  ResidentWalletMode,
} from '@pflegenavi/shared/api';
import type {
  useFormatDate,
  useFormatting,
} from '@pflegenavi/frontend/localization';
import { differenceInDays } from 'date-fns';
import type { ResidentTableRowModel } from '../interfaces/ResidentTableRowModel';
import type { PaymentInfo } from '@pflegenavi/frontend/api-nursing-home';

interface formatResidentTableRowModelOpts {
  fDate: ReturnType<typeof useFormatDate>;
  fCurrency: ReturnType<typeof useFormatting>['fCurrency'];
  t: (key: string) => string;
}

export const formatResidentTableRowModel = (
  resident: ResidentListItemDto,
  nursingHomeMaxPaymentAmount: number,
  opts: formatResidentTableRowModelOpts
): ResidentTableRowModel => {
  const familyMember = resident?.familyMembers?.[0]
    ? `${resident?.familyMembers?.[0].firstName} ${resident?.familyMembers?.[0].lastName}`
    : undefined;

  const genderString = opts.t(`gender.${resident.gender}`);

  const daysOverdue = differenceInDays(
    new Date(),
    new Date(
      resident.balance < 0
        ? resident.negative_due_date ?? 0
        : resident.threshold_due_date ?? 0
    )
  );

  const balanceString = opts.fCurrency(resident.balance || balanceFallback);

  const getBalanceColor = (): BalanceStatusColors => {
    if (resident.balance >= 0 && resident.lowBalance) {
      return BalanceStatusColors.LOW_BALANCE;
    }
    if (resident.balance < 0) {
      return BalanceStatusColors.NEGATIVE_BALANCE;
    }
    return BalanceStatusColors.ENOUGH_BALANCE;
  };

  const stateString =
    resident.state === ResidentState.archived
      ? opts.t('statusLabel.Inactive')
      : resident.state === ResidentState.active
      ? opts.t('statusLabel.Active')
      : opts.t('statusLabel.AwaitingSettlement');

  return {
    id: resident.id,
    name: resident.name,
    firstName: resident.firstName,
    lastName: resident.lastName,
    residentAccountingId: resident.residentAccountingId ?? '--',
    genderString,
    gender: resident.gender,
    balanceString,
    balance: resident.balance ?? balanceFallback,
    balanceColor: getBalanceColor(),
    lowBalance: resident.lowBalance,
    threshold_due_date: resident.threshold_due_date,
    daysOverdue,
    negativeBalance: resident.balance < 0,
    negative_due_date: resident.negative_due_date,
    familyMember,
    state: resident.state,
    stateString,
    paymentInfo: resident.paymentInfo,
    payment_info_phoenix: undefined,
    cashPayer: resident.cashPayer,
    familyMemberEmailVerified:
      resident.familyMembers && resident.familyMembers[0]?.emailVerified,
    ...getResidentsTableFilterProperties(resident, nursingHomeMaxPaymentAmount),
  };
};

export const getResidentsTableFilterProperties = (
  resident: ResidentListItemDto,
  nursingHomeMaxPaymentAmount: number
): {
  settlement: boolean;
  familyMemberMissing: boolean;
  sepaMissing: boolean;
  amountTooHigh: boolean;
  onGoingPayments: boolean;
  thresholdPayment: boolean;
  monthlyPayment: boolean;
  manualPayment: boolean;
  disabledPayment: boolean;
} => {
  let settlement = false;
  let familyMemberMissing = false;
  let sepaMissing = false;
  let amountTooHigh = false;
  let onGoingPayments = false;
  let thresholdPayment = false;
  let monthlyPayment = false;
  let manualPayment = false;
  let disabledPayment = false;

  const topUpAmountInCents =
    resident.paymentInfo?.initiatedBy === PaymentInitiatedBy.System
      ? resident.paymentInfo?.topUpAmountInCents
      : 0;

  const residentBalanceInCents = resident.balance * 100;

  const calculatedChargeAmount = topUpAmountInCents - residentBalanceInCents;

  const chargeAmount =
    resident.paymentInfo?.initiatedBy === PaymentInitiatedBy.System &&
    resident.paymentInfo.residentMaxAmount
      ? Math.min(resident.paymentInfo.residentMaxAmount, calculatedChargeAmount)
      : calculatedChargeAmount;

  if (
    resident.state === ResidentState.awaitingSettlement ||
    resident.state === ResidentState.exited
  ) {
    settlement = true;
  }
  if (resident.state === ResidentState.active) {
    if (!resident.familyMembers || resident.familyMembers.length === 0) {
      familyMemberMissing = true;
    }
    if (
      resident.familyMembers &&
      resident.familyMembers.length > 0 &&
      resident.paymentInfo?.initiatedBy === PaymentInitiatedBy.System &&
      resident.paymentInfo?.directDebit === DirectDebitStatus.Inactive
    ) {
      sepaMissing = true;
    }
    if (chargeAmount > nursingHomeMaxPaymentAmount) {
      amountTooHigh = true;
    }
    if (
      resident.paymentInfo?.status &&
      resident.paymentInfo?.status !== ResidentPendingChargePaymentStatus.Idle
    ) {
      onGoingPayments = true;
    }
    if (resident.paymentInfo?.initiatedBy === PaymentInitiatedBy.FamilyMember) {
      manualPayment = true;
    }
    if (resident.paymentInfo?.initiatedBy === PaymentInitiatedBy.System) {
      if (resident.paymentInfo?.schedule === PaymentSchedule.Threshold) {
        thresholdPayment = true;
      }
      if (resident.paymentInfo?.schedule === PaymentSchedule.Monthly) {
        monthlyPayment = true;
      }
    }
    if (resident.paymentInfo?.initiatedBy === PaymentInitiatedBy.Disabled) {
      disabledPayment = true;
    }
  }

  return {
    settlement,
    familyMemberMissing,
    sepaMissing,
    amountTooHigh,
    onGoingPayments,
    thresholdPayment,
    monthlyPayment,
    manualPayment,
    disabledPayment,
  };
};

export const formatResidentTableRowModelMangopay = (
  resident: GetAllResidentsResult['data'][number],
  paymentInfo: PaymentInfo | undefined,
  nursingHomeMaxPaymentAmount: number,
  opts: formatResidentTableRowModelOpts
): ResidentTableRowModel => {
  const familyMember = resident?.family_members?.[0]
    ? `${resident?.family_members?.[0].first_name} ${resident?.family_members?.[0].last_name}`
    : undefined;

  const genderString = opts.t(`gender.${resident.gender}`);

  const primary_family_member_id = resident.family_members?.find(
    (member) => member.primary_contact === true
  )?.user_id;
  const primary_family_member_balance =
    resident.resident_family_member_balances.find(
      (balance) => balance.family_member_id === primary_family_member_id
    ) ?? {
      amount: 0,
      threshold_due_date: undefined,
      negative_due_date: undefined,
    };
  const balance =
    resident.wallet_mode === ResidentWalletMode.resident
      ? resident.balance
      : primary_family_member_balance;

  const daysOverdue = differenceInDays(
    new Date(),
    new Date(
      balance.amount < 0
        ? balance.negative_due_date ?? 0
        : balance.threshold_due_date ?? 0
    )
  );

  const balanceString = opts.fCurrency(balance.amount || balanceFallback);

  const getBalanceColor = (): BalanceStatusColors => {
    if (balance.amount >= 0 && resident.low_balance) {
      return BalanceStatusColors.LOW_BALANCE;
    }
    if (balance.amount < 0) {
      return BalanceStatusColors.NEGATIVE_BALANCE;
    }
    return BalanceStatusColors.ENOUGH_BALANCE;
  };

  const stateString =
    resident.state === ResidentState.archived
      ? opts.t('statusLabel.Inactive')
      : resident.state === ResidentState.active
      ? opts.t('statusLabel.Active')
      : opts.t('statusLabel.AwaitingSettlement');

  const name = `${resident.firstname} ${resident.lastname}`;

  return {
    id: resident.id,
    name,
    firstName: resident.firstname,
    lastName: resident.lastname,
    residentAccountingId: resident.resident_accounting_id ?? '--',
    genderString,
    gender: resident.gender,
    balanceString,
    balance: balance.amount ?? balanceFallback,
    balanceColor: getBalanceColor(),
    lowBalance: resident.low_balance,
    threshold_due_date: balance.threshold_due_date ?? undefined,
    daysOverdue,
    negativeBalance: balance.amount < 0,
    negative_due_date: balance.negative_due_date ?? undefined,
    familyMember,
    state: resident.state,
    stateString,
    paymentInfo: undefined,
    cashPayer: resident.cash_payer,
    payment_info_phoenix: paymentInfo,
    familyMemberEmailVerified:
      // TODO
      false, //resident.family_members && resident.family_members[0]?.emailVerified,
    ...getResidentsTableFilterPropertiesMangopay(
      resident,
      paymentInfo,
      nursingHomeMaxPaymentAmount
    ),
  };
};

export const getResidentsTableFilterPropertiesMangopay = (
  resident: GetAllResidentsResult['data'][number],
  paymentInfo: PaymentInfo | undefined,
  nursingHomeMaxPaymentAmount: number
): {
  settlement: boolean;
  familyMemberMissing: boolean;
  sepaMissing: boolean;
  amountTooHigh: boolean;
  onGoingPayments: boolean;
  thresholdPayment: boolean;
  monthlyPayment: boolean;
  manualPayment: boolean;
  disabledPayment: boolean;
} => {
  let settlement = false;
  let familyMemberMissing = false;
  let sepaMissing = false;
  let amountTooHigh = false;
  let onGoingPayments = false;
  let thresholdPayment = false;
  let monthlyPayment = false;
  let manualPayment = false;
  let disabledPayment = false;

  if (
    resident.state === ResidentState.awaitingSettlement ||
    resident.state === ResidentState.exited
  ) {
    settlement = true;
  }
  if (resident.state === ResidentState.active) {
    if (!resident.family_members || resident.family_members.length === 0) {
      familyMemberMissing = true;
    }
    if (
      resident.family_members &&
      resident.family_members.length > 0 &&
      paymentInfo?.payment_settings.payment_initiation === 'system' &&
      paymentInfo?.preconditions.direct_debit_missing === true
    ) {
      sepaMissing = true;
    }
    if (paymentInfo?.preconditions.amount_too_high) {
      amountTooHigh = true;
    }
    if (
      paymentInfo?.pending_charge &&
      ['Pending', 'Failed', 'Frozen', 'Upcoming'].includes(
        paymentInfo?.pending_charge?.pending_charge_status
      )
    ) {
      onGoingPayments = true;
    }
    if (paymentInfo?.payment_settings?.payment_initiation === 'familyMember') {
      manualPayment = true;
    }
    if (paymentInfo?.payment_settings?.payment_initiation === 'system') {
      if (
        paymentInfo?.payment_settings.payment_schedule ===
        PaymentSchedule.Threshold
      ) {
        thresholdPayment = true;
      }
      if (
        paymentInfo?.payment_settings.payment_schedule ===
        PaymentSchedule.Monthly
      ) {
        monthlyPayment = true;
      }
    }
    if (
      paymentInfo?.payment_settings?.payment_initiation ===
      PaymentInitiatedBy.Disabled
    ) {
      disabledPayment = true;
    }
  }

  return {
    settlement,
    familyMemberMissing,
    sepaMissing,
    amountTooHigh,
    onGoingPayments,
    thresholdPayment,
    monthlyPayment,
    manualPayment,
    disabledPayment,
  };
};

const balanceFallback = 0;
