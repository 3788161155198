import JsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import {
  useFormatDate,
  useFormatting,
} from '@pflegenavi/frontend/localization';
import { YEAR_MONTH_DAY_SHORT_FORMAT } from '@pflegenavi/shared/constants';
import type { TFunction } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import {
  calculateImagePlacement,
  drawMultilineText,
} from '@pflegenavi/shared/pdf-generation';
import type { CashList } from '@pflegenavi/frontend/api-nursing-home';
import { CashListStorageType } from '@pflegenavi/shared/api';

// colors used
const DIVIDER_GREY = '#F2F2F2';
const PINK = '#FBE3DE';
const TITLE_GREY = '#919EAB';

// baseline margins applied to all elements rendered to be properly aligned and do not overlap with the header/footer, except the header and footer which only have the left and right baseline margins
const BASELINE_TOP_MARGIN = 40;
const BASELINE_BOTTOM_MARGIN = 30;
const BASELINE_LEFT_MARGIN = 10;
const BASELINE_RIGHT_MARGIN = 10;

const FONTSIZE = 10;
const LINE_HEIGHT = 5;

interface HandlePrintProps {
  nursingHomeName: string;
  logo?: string;
  date: Date;
  bankAccounts: CashList[];
  payoutTargetCashListId?: string;
  payinTargetCashListId?: string;
  payoutAmountInCents: number;
  payinAmountInCents: number;
  cashAccounts: CashList[];
  walletAmountInCents: number;
}

export interface HandlePrintAccountingOverviewResult {
  blob: Blob;
  url: string;
  save: (filename: string) => void;
  date: Date;
}

export interface Context {
  fCurrency: ReturnType<typeof useFormatting>['fCurrency'];
  fCurrencyCents: ReturnType<typeof useFormatting>['fCurrencyCents'];
  fDate: ReturnType<typeof useFormatDate>;
  t: TFunction<'translation', undefined>;
}

interface DrawHeaderOpts {
  logo?: string;
}

export const drawHeader = async (
  context: Context,
  doc: JsPDF,
  opts: DrawHeaderOpts
): Promise<void> => {
  const { t } = context;

  const HEADER_HEIGHT = 35;
  const logo = opts.logo;

  doc.setFillColor(PINK);
  const fontSize = 14;
  const lineHeight = 1.2;

  // create a rectangle at the top of the page with a height of 10% of the page
  doc.rect(0, 0, doc.internal.pageSize.width, HEADER_HEIGHT, 'F');

  const textHeight = fontSize * lineHeight;
  const titleY = (HEADER_HEIGHT - textHeight) / 2 + textHeight / 2 + 2;

  doc.setFontSize(fontSize);
  // @ts-expect-error it doesn't let me not specify the font family
  doc.setFont(undefined, 'bold');
  doc.setTextColor(0, 0, 0); // RGB for black
  doc.text(t('accounting.report.title'), BASELINE_LEFT_MARGIN, titleY);

  if (!logo) {
    return;
  }

  const img: HTMLImageElement = await new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve(img);
    };
    img.onerror = (err) => {
      reject(err);
    };
    img.crossOrigin = 'Anonymous';
    img.src = logo;
  });

  const { x, y, width, height } = calculateImagePlacement(
    img,
    60,
    35,
    5,
    5,
    10
  );

  doc.addImage(
    img,
    'PNG',
    doc.internal.pageSize.width - 60 + x,
    y,
    width,
    height
  );
};

export const drawFooter = (
  context: Context,
  doc: JsPDF,
  pageNumber: number,
  pageCount: number
): void => {
  const { t } = context;
  const pageHeight = doc.internal.pageSize.height;

  autoTable(doc, {
    body: [
      [
        {
          content: 'help@pflegenavi.at',
          styles: {
            halign: 'left',
          },
        },
        {
          content: `${t('resident-pdf.document.page')}: ${String(
            pageNumber
          )} ${t('resident-pdf.document.of')} ${String(pageCount)}`,
          styles: {
            halign: 'right',
          },
        },
      ],
    ],
    theme: 'plain',
    startY: pageHeight - 15,
    margin: {
      bottom: 0,
      top: 0,
      left: BASELINE_LEFT_MARGIN,
      right: BASELINE_RIGHT_MARGIN,
    },
  });
};

export const drawHeaders = async (
  context: Context,
  doc: JsPDF,
  opts: DrawHeaderOpts
): Promise<void> => {
  const pageCount = doc.internal.pages.length - 1;

  for (let i = 1; i <= pageCount; i++) {
    // Go to page i
    doc.setPage(i);

    await drawHeader(context, doc, opts);
  }
};

export const drawFooters = (context: Context, doc: JsPDF): void => {
  const pageCount = doc.internal.pages.length - 1;

  for (let i = 1; i <= pageCount; i++) {
    // Go to page i
    doc.setPage(i);
    //Print Page 1 of 4 for example
    drawFooter(context, doc, i, pageCount);
  }
};

export const drawDate = (
  context: Context,
  doc: JsPDF,
  positionY: number,
  date: Date
): void => {
  const { t, fDate } = context;
  // Set Title Style
  doc.setFontSize(12);
  doc.setTextColor(TITLE_GREY);
  // @ts-expect-error it doesn't let me not specify the font family
  doc.setFont(undefined, 'bold');

  // Calculate the page width
  const pageWidth = doc.internal.pageSize.getWidth();

  // Calculate the x-coordinate for the text to be right-aligned
  const titlePosX = pageWidth - BASELINE_RIGHT_MARGIN - 1.5;

  // Add Title
  doc.text(
    t('accounting.report.date'),
    titlePosX,
    positionY + 6, // 12 / 2 = line height
    {
      align: 'right',
    }
  );

  positionY += 6;
  positionY += 2; // Add some spacing

  doc.setFontSize(FONTSIZE);
  doc.setTextColor(0, 0, 0);
  // @ts-expect-error it doesn't let me not specify the font family
  doc.setFont(undefined, 'normal');

  autoTable(doc, {
    body: [
      [
        {
          content: fDate(new Date(date), YEAR_MONTH_DAY_SHORT_FORMAT),
          styles: { halign: 'right' },
        },
      ],
    ],
    theme: 'plain',
    styles: {
      fontSize: FONTSIZE,
    },
    startY: positionY,
    margin: {
      bottom: BASELINE_BOTTOM_MARGIN + 13,
      left: doc.internal.pageSize.getWidth() - BASELINE_RIGHT_MARGIN - 30,
      right: BASELINE_RIGHT_MARGIN,
    },
  });
};

export const drawNursingHome = (
  context: Context,
  doc: JsPDF,
  positionY: number,
  nursingHome: string
): number => {
  const { t } = context;
  // Set Title Style
  doc.setFontSize(12);
  doc.setTextColor(TITLE_GREY);
  // @ts-expect-error it doesn't let me not specify the font family
  doc.setFont(undefined, 'bold');

  // Add Title
  doc.text(
    t('accounting.report.nursing-home'),
    BASELINE_LEFT_MARGIN + 1.5,
    positionY + 6 // 12 / 2 = line height
  );

  positionY += 6;
  positionY += 2; // Add some spacing

  doc.setFontSize(FONTSIZE);
  doc.setTextColor(0, 0, 0);
  // @ts-expect-error it doesn't let me not specify the font family
  doc.setFont(undefined, 'normal');

  autoTable(doc, {
    body: [[{ content: nursingHome }]],
    theme: 'plain',
    styles: {
      fontSize: FONTSIZE,
    },
    startY: positionY,
    margin: {
      bottom: BASELINE_BOTTOM_MARGIN + 13,
      left: BASELINE_LEFT_MARGIN,
      right: BASELINE_RIGHT_MARGIN,
    },
  });

  // @ts-expect-error again function exists but poor typing
  return doc.lastAutoTable?.finalY;
};

export const drawDivider = (doc: JsPDF, y: number, lineWidth: number): void => {
  doc.setLineWidth(lineWidth);
  doc.setDrawColor(DIVIDER_GREY);
  doc.line(
    BASELINE_LEFT_MARGIN,
    y,
    doc.internal.pageSize.width - BASELINE_RIGHT_MARGIN,
    y
  );
};

export const drawGrandTotalLine = (
  context: Context,
  doc: JsPDF,
  positionY: number,
  opts: {
    grandTotal: number;
    bankAccountNames: string[];
    cashAccountNames: string[];
    drawShortVersion: boolean;
  }
): number => {
  const grandTotalAmount = context.fCurrencyCents(opts.grandTotal);
  const grandTotalTextShort = `${context.t(
    'accounting.report.grand-total'
  )} ${context.t('accounting.report.grand-total-wallet')}${
    opts.bankAccountNames.length > 0
      ? ` + ${context.t('accounting.report.grand-total-bank')}${
          opts.bankAccountNames.length > 1
            ? ` (${opts.bankAccountNames.length})`
            : ''
        }`
      : ''
  }${
    opts.cashAccountNames.length > 0
      ? ` + ${context.t('accounting.report.grand-total-cash')}${
          opts.cashAccountNames.length > 1
            ? ` (${opts.cashAccountNames.length})`
            : ''
        }`
      : ''
  }: ${grandTotalAmount}`;

  const grandTotalTextLong = `${[
    `${context.t('accounting.report.grand-total')} ${context.t(
      'accounting.report.grand-total-wallet'
    )}`,
    ...opts.bankAccountNames,
    ...opts.cashAccountNames,
  ].join(' + ')}: ${grandTotalAmount}`;

  drawMultilineText(
    doc,
    undefined,
    opts.drawShortVersion ? grandTotalTextShort : grandTotalTextLong,
    {
      y: positionY + LINE_HEIGHT,
      boldLabel: true,
      boldText: true,
      allLinesIndented: false,
      baseMarginLeft: BASELINE_LEFT_MARGIN + 1.5,
      baseMarginRight: BASELINE_RIGHT_MARGIN + 1.5,
      fontSize: FONTSIZE,
      lineHeight: LINE_HEIGHT,
    }
  );

  // @ts-expect-error it doesn't let me not specify the font family
  doc.setFont(undefined, 'normal');

  return positionY + LINE_HEIGHT;
};

export const drawSignatureField = (
  context: Context,
  doc: JsPDF,
  x: number,
  y: number,
  width: number,
  title: string
): number => {
  doc.setLineWidth(0.3);
  doc.setDrawColor(0, 0, 0);
  doc.setLineDashPattern([0.5, 0.5], 0);
  doc.line(x, y, x + width, y);

  doc.setFontSize(FONTSIZE);

  const titleWidth = doc.getTextWidth(title);
  const titleOffset = (width - titleWidth) / 2;

  doc.setTextColor(0, 0, 0);
  doc.setFontSize(10);
  doc.text(title, x + titleOffset, y + LINE_HEIGHT);

  return y + LINE_HEIGHT;
};

interface DrawWalletOverviewOpts {
  walletAmountInCents: number;
}

export const drawWalletOverview = (
  context: Context,
  doc: JsPDF,
  positionY: number,
  opts: DrawWalletOverviewOpts
): number => {
  const { t, fCurrencyCents } = context;
  const { walletAmountInCents } = opts;

  const dividerLineWidth = 0.3;
  const onlyDrawLastLine = true;

  // Set Title Style
  doc.setFontSize(12);
  doc.setTextColor(TITLE_GREY);
  // @ts-expect-error it doesn't let me not specify the font family
  doc.setFont(undefined, 'bold');

  // Add Title
  doc.text(
    t('accounting.dashboard.wallet-title'),
    BASELINE_LEFT_MARGIN + 1.5,
    positionY + 6 // 12 / 2 = line height
  );

  positionY += 6;
  positionY += 2; // Add some spacing

  doc.setFontSize(FONTSIZE);
  doc.setTextColor(0, 0, 0);
  // @ts-expect-error it doesn't let me not specify the font family
  doc.setFont(undefined, 'normal');

  autoTable(doc, {
    body: [
      [
        {
          content: t('accounting.dashboard.total-wallet-balance'),
          styles: { fontStyle: 'bold' },
        },
        {
          content: fCurrencyCents(walletAmountInCents),
          styles: { fontStyle: 'bold' },
        },
      ],
    ],
    theme: 'plain',
    columnStyles: {
      0: {
        halign: 'left',
        cellPadding: {
          left: 1.5,
          top: 2,
          bottom: 2 - dividerLineWidth,
        },
      },
      1: {
        halign: 'right',
        cellWidth: 27,
        cellPadding: {
          right: 1.5,
          top: 2,
          bottom: 2 - dividerLineWidth,
        },
      },
    },

    didDrawCell: function (data) {
      if (
        data.row.section === 'body' &&
        ((!onlyDrawLastLine && data.row.index < data.table.body.length - 1) ||
          data.row.index === data.table.body.length - 2) &&
        dividerLineWidth > 0
      ) {
        // Don't draw a line before the first and after the last row
        const bottomY = data.cell.y + data.cell.height;
        doc.setLineWidth(dividerLineWidth);
        doc.setDrawColor(DIVIDER_GREY);
        doc.line(
          BASELINE_LEFT_MARGIN,
          bottomY,
          doc.internal.pageSize.width - BASELINE_RIGHT_MARGIN,
          bottomY
        );
      }
    },
    startY: positionY,
    margin: {
      bottom: BASELINE_BOTTOM_MARGIN + 15,
      left: BASELINE_LEFT_MARGIN,
      right: BASELINE_RIGHT_MARGIN,
    },
  });

  // @ts-expect-error again function exists but poor typing
  return doc.lastAutoTable?.finalY;
};

interface DrawBankAccountOverviewOpts {
  bankAccountName?: string;
  bankAmountInCents: number;
  bankIncomingPayoutsAmountInCents: number;
  bankOutgoingPayinsAmountInCents: number;
  isPayoutTarget: boolean;
  isPayinTarget: boolean;
}

export const getBankAccountOverviewHeight = (
  context: Context,
  opts: DrawBankAccountOverviewOpts
): number => {
  const tempDoc = new JsPDF();

  return drawBankAccountOverview(context, tempDoc, 0, opts);
};

export const drawBankAccountOverview = (
  context: Context,
  doc: JsPDF,
  positionY: number,
  opts: DrawBankAccountOverviewOpts
): number => {
  const { t, fCurrencyCents } = context;
  const {
    bankAccountName,
    bankAmountInCents,
    bankIncomingPayoutsAmountInCents,
    bankOutgoingPayinsAmountInCents,
  } = opts;

  const dividerLineWidth = 0.3;
  const onlyDrawLastLine = true;

  // Set Title Style
  doc.setFontSize(12);
  doc.setTextColor(TITLE_GREY);
  // @ts-expect-error it doesn't let me not specify the font family
  doc.setFont(undefined, 'bold');

  // Add Title
  doc.text(
    bankAccountName ?? t('accounting.dashboard.bank-title'),
    BASELINE_LEFT_MARGIN + 1.5,
    positionY + 6 // 12 / 2 = line height
  );

  positionY += 6;
  positionY += 2; // Add some spacing

  doc.setFontSize(FONTSIZE);
  doc.setTextColor(0, 0, 0);
  // @ts-expect-error it doesn't let me not specify the font family
  doc.setFont(undefined, 'normal');

  autoTable(doc, {
    body: [
      [
        {
          content: t('accounting.dashboard.bank-current-balance'),
        },
        {
          content: fCurrencyCents(bankAmountInCents),
        },
      ],

      ...(opts.isPayoutTarget
        ? [
            [
              {
                content: `- ${t('accounting.dashboard.bank-incoming-payouts')}`,
              },
              {
                content: fCurrencyCents(bankIncomingPayoutsAmountInCents),
              },
            ],
          ]
        : []),

      ...(opts.isPayinTarget
        ? [
            [
              {
                content: `- ${t(
                  'accounting.dashboard.bank-outgoing-payments'
                )}`,
              },
              {
                content: fCurrencyCents(-bankOutgoingPayinsAmountInCents),
              },
            ],
          ]
        : []),

      [
        {
          content: t('accounting.dashboard.bank-balance', {
            bankName: bankAccountName,
          }),
          styles: { fontStyle: 'bold' },
        },
        {
          content: fCurrencyCents(bankAmountInCents),
          styles: { fontStyle: 'bold' },
        },
      ],
    ],
    theme: 'plain',
    columnStyles: {
      0: {
        halign: 'left',
        cellPadding: {
          left: 1.5,
          top: 2,
          bottom: 2 - dividerLineWidth,
        },
      },
      1: {
        halign: 'right',
        cellWidth: 27,
        cellPadding: {
          right: 1.5,
          top: 2,
          bottom: 2 - dividerLineWidth,
        },
      },
    },

    didDrawCell: function (data) {
      if (
        data.row.section === 'body' &&
        ((!onlyDrawLastLine && data.row.index < data.table.body.length - 1) ||
          data.row.index === data.table.body.length - 2) &&
        dividerLineWidth > 0
      ) {
        // Don't draw a line before the first and after the last row
        const bottomY = data.cell.y + data.cell.height;
        doc.setLineWidth(dividerLineWidth);
        doc.setDrawColor(DIVIDER_GREY);
        doc.line(
          BASELINE_LEFT_MARGIN,
          bottomY,
          doc.internal.pageSize.width - BASELINE_RIGHT_MARGIN,
          bottomY
        );
      }
    },
    startY: positionY,
    margin: {
      bottom: BASELINE_BOTTOM_MARGIN + 15,
      left: BASELINE_LEFT_MARGIN,
      right: BASELINE_RIGHT_MARGIN,
    },
  });

  // @ts-expect-error again function exists but poor typing
  return doc.lastAutoTable?.finalY;
};

interface DrawCashOverviewOpts {
  cashListName?: string;
  cashAmounts: Array<{
    factor: number;
    amount: number;
  }>;
  cashSum: number;
  cashBankAccountAmountInCents: number;
  supportsSavings: boolean;
}

export const drawCashOverviewSubTable = (
  context: Context,
  doc: JsPDF,
  positionY: number,
  positionX: number,
  cashAmounts: Array<{
    factor: number;
    amount: number;
  }>,
  tableWidth: number
): number => {
  const { fCurrencyCents, fCurrency } = context;
  autoTable(doc, {
    head: [
      [
        {
          content: context.t('accounting.report.cash-table.type'),
          styles: {
            fontStyle: 'bold',
            halign: 'left',
            cellPadding: {
              left: 1.5,
              top: 2,
              bottom: 1.7, // 2 - 0.3 (line width)
            },
          },
        },
        {
          content: context.t('accounting.report.cash-table.number'),
          styles: {
            fontStyle: 'bold',
            halign: 'right',
            cellPadding: {
              top: 2,
              bottom: 1.7, // 2 - 0.3 (line width)
            },
          },
        },
        {
          content: context.t('accounting.report.cash-table.amount'),
          styles: {
            fontStyle: 'bold',
            halign: 'right',
            cellWidth: 27,
            cellPadding: {
              right: 1.5,
              top: 2,
              bottom: 1.7, // 2 - 0.3 (line width)
            },
          },
        },
      ],
    ],
    body: cashAmounts.map((cashAmount) => {
      return [
        {
          content: fCurrency(cashAmount.factor / 100, {
            minimumFractionDigits: 0,
          }),
        },
        {
          content: String(cashAmount.amount),
        },
        {
          content: fCurrencyCents(cashAmount.amount * cashAmount.factor),
        },
      ];
    }),
    theme: 'plain',
    tableWidth: tableWidth,
    columnStyles: {
      0: {
        halign: 'left',
        cellPadding: {
          left: 1.5,
          top: 2,
          bottom: 1.7, // 2 - 0.3 (line width)
        },
      },
      1: {
        halign: 'right',
        cellPadding: {
          top: 2,
          bottom: 1.7, // 2 - 0.3 (line width)
        },
      },
      2: {
        halign: 'right',
        cellWidth: 27,
        cellPadding: {
          right: 1.5,
          top: 2,
          bottom: 1.7, // 2 - 0.3 (line width)
        },
      },
    },
    didDrawCell: function (data) {
      if (
        data.row.section === 'head' ||
        (data.row.section === 'body' &&
          data.row.index < data.table.body.length - 1)
      ) {
        // Don't draw a line before the first and after the last row
        const bottomY = data.cell.y + data.cell.height;
        doc.setLineWidth(0.3);
        doc.setDrawColor(DIVIDER_GREY);
        doc.line(positionX, bottomY, positionX + tableWidth, bottomY);
      }
    },
    startY: positionY,
    margin: {
      bottom: BASELINE_BOTTOM_MARGIN + 15,
      left: positionX,
      right: BASELINE_RIGHT_MARGIN,
    },
  });

  // @ts-expect-error again function exists but poor typing
  return doc.lastAutoTable?.finalY;
};

export const getCashOverviewHeight = (
  context: Context,
  opts: DrawCashOverviewOpts
): number => {
  const tempDoc = new JsPDF();

  return drawCashOverview(context, tempDoc, 0, opts);
};

export const drawCashOverview = (
  context: Context,
  doc: JsPDF,
  positionY: number,
  opts: DrawCashOverviewOpts
): number => {
  const { t, fCurrencyCents } = context;
  const { cashListName, cashAmounts, cashBankAccountAmountInCents } = opts;

  const spacingBetweenTables = 10;
  const tableWidth =
    (doc.internal.pageSize.width -
      BASELINE_LEFT_MARGIN -
      BASELINE_RIGHT_MARGIN -
      spacingBetweenTables) /
    2;
  const secondTableX = BASELINE_LEFT_MARGIN + tableWidth + spacingBetweenTables;

  // Set Title Style
  doc.setFontSize(12);
  doc.setTextColor(TITLE_GREY);
  // @ts-expect-error it doesn't let me not specify the font family
  doc.setFont(undefined, 'bold');

  // Add Title
  doc.text(
    cashListName ?? t('accounting.dashboard.cash-title'),
    BASELINE_LEFT_MARGIN + 1.5,
    positionY + 6 // 12 / 2 = line height
  );

  positionY += 6;
  positionY += 2; // Add some spacing

  doc.setFontSize(FONTSIZE);
  doc.setTextColor(0, 0, 0);
  // @ts-expect-error it doesn't let me not specify the font family
  doc.setFont(undefined, 'normal');

  const leftSubtableFinalTableY = drawCashOverviewSubTable(
    context,
    doc,
    positionY,
    BASELINE_LEFT_MARGIN,
    cashAmounts.slice(0, Math.floor(cashAmounts.length / 2)),
    tableWidth
  );

  const rightSubtableFinalTableY = drawCashOverviewSubTable(
    context,
    doc,
    positionY,
    secondTableX,
    cashAmounts.slice(Math.floor(cashAmounts.length / 2)),
    tableWidth
  );

  let finalTableY =
    leftSubtableFinalTableY > rightSubtableFinalTableY
      ? leftSubtableFinalTableY
      : rightSubtableFinalTableY;

  finalTableY += LINE_HEIGHT;

  const savingsString = `${t('accounting.dashboard.cash-savings-title')}: `;
  const savingsAmountString = fCurrencyCents(cashBankAccountAmountInCents);
  const totalCashString = `${t('accounting.dashboard.total-cash-balance', {
    cashListName: cashListName,
  })}: `;
  const totalCashAmountString = fCurrencyCents(opts.cashSum);

  if (opts.supportsSavings) {
    // @ts-expect-error it doesn't let me not specify the font family
    doc.setFont(undefined, 'normal');
    const savingsStringWidth = doc.getTextWidth(savingsString);
    doc.text(savingsString, BASELINE_LEFT_MARGIN + 1.5, finalTableY);
    // Kept separately so it can easily be drawn normal instead of bold
    // @ts-expect-error it doesn't let me not specify the font family
    doc.setFont(undefined, 'normal');
    doc.text(
      savingsAmountString,
      BASELINE_LEFT_MARGIN + 1.5 + savingsStringWidth,
      finalTableY
    );
  }

  // Kept separately so it can easily be drawn normal instead of bold
  // @ts-expect-error it doesn't let me not specify the font family
  doc.setFont(undefined, 'bold');
  const totalCashAmountStringWidth = doc.getTextWidth(totalCashAmountString);
  const totalCashAmountStartingPointX =
    doc.internal.pageSize.width -
    BASELINE_RIGHT_MARGIN -
    1.5 -
    totalCashAmountStringWidth;
  doc.text(totalCashAmountString, totalCashAmountStartingPointX, finalTableY);
  // @ts-expect-error it doesn't let me not specify the font family
  doc.setFont(undefined, 'bold');
  const totalCashStringWidth = doc.getTextWidth(totalCashString);
  doc.text(
    totalCashString,
    totalCashAmountStartingPointX - totalCashStringWidth,
    finalTableY
  );

  // @ts-expect-error it doesn't let me not specify the font family
  doc.setFont(undefined, 'normal');

  return finalTableY;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const usePrintPdfAccountingOverviewMangopay = () => {
  const { t } = useTranslation();
  const { fCurrency, fCurrencyCents } = useFormatting();
  const fDate = useFormatDate();

  const handlePrintPdfAccountingOverviewMangopay = async ({
    nursingHomeName,
    logo,
    date,
    bankAccounts,
    payoutTargetCashListId,
    payinTargetCashListId,
    payoutAmountInCents,
    payinAmountInCents,
    cashAccounts,
    walletAmountInCents,
  }: HandlePrintProps): Promise<HandlePrintAccountingOverviewResult> => {
    const doc = new JsPDF();

    const context: Context = {
      t,
      fCurrency: (amount, opts) =>
        fCurrency(amount, { ...opts, useNonBreakingCharacters: false }),
      fCurrencyCents: (amount) =>
        fCurrencyCents(amount, { useNonBreakingCharacters: false }),
      fDate,
    };

    let positionY = drawNursingHome(
      context,
      doc,
      BASELINE_TOP_MARGIN,
      nursingHomeName
    );
    drawDate(context, doc, BASELINE_TOP_MARGIN, date);

    positionY += 5; // Add some spacing

    positionY = drawWalletOverview(context, doc, positionY, {
      walletAmountInCents,
    });

    positionY += 5; // Add some spacing

    let bankAccountGrandTotal = 0;
    const bankAccountNames: string[] = [];
    let cashAccountGrandTotal = 0;
    const cashAccountNames: string[] = [];
    for (const bankAccount of bankAccounts) {
      const isPayoutTarget = bankAccount.id === payoutTargetCashListId;
      const isPayinTarget = bankAccount.id === payinTargetCashListId;
      const bankAccountOverviewOpts = {
        bankAmountInCents: bankAccount.bankAccountAmountInCent,
        bankIncomingPayoutsAmountInCents: isPayoutTarget
          ? payoutAmountInCents
          : 0,
        bankOutgoingPayinsAmountInCents: isPayinTarget ? payinAmountInCents : 0,
        bankAccountName:
          bankAccount.name ?? t('accounting.dashboard.bank-title'),
        isPayoutTarget: isPayoutTarget,
        isPayinTarget: isPayinTarget,
      };

      const prospectiveHeight = getBankAccountOverviewHeight(
        context,
        bankAccountOverviewOpts
      );
      if (
        positionY + prospectiveHeight >
        doc.internal.pageSize.height - BASELINE_BOTTOM_MARGIN
      ) {
        doc.addPage();
        positionY = BASELINE_TOP_MARGIN;
      }

      positionY = drawBankAccountOverview(
        context,
        doc,
        positionY,
        bankAccountOverviewOpts
      );

      positionY += 5; // Add some spacing

      bankAccountGrandTotal +=
        bankAccountOverviewOpts.bankAmountInCents +
        bankAccountOverviewOpts.bankIncomingPayoutsAmountInCents -
        bankAccountOverviewOpts.bankOutgoingPayinsAmountInCents;
      bankAccountNames.push(bankAccountOverviewOpts.bankAccountName);

      for (const cashAccount of cashAccounts) {
        const cashSum = cashAccount.coins.reduce((acc, value) => {
          acc += value.amount * value.factor;
          return acc;
        }, 0);

        const sortedCoins = cashAccount.coins.toSorted((a, b) => {
          return b.factor - a.factor;
        });
        const supportsSavings =
          cashAccount.storageType ===
          (CashListStorageType.Cash | CashListStorageType.BankAccount);
        const cashOverviewOpts = {
          cashListName:
            cashAccount.name ?? t('accounting.dashboard.cash-title'),
          cashAmounts: sortedCoins,
          cashSum: cashSum,
          cashBankAccountAmountInCents: cashAccount.bankAccountAmountInCent,
          supportsSavings: supportsSavings,
        };

        const prospectiveHeight = getCashOverviewHeight(
          context,
          cashOverviewOpts
        );

        if (
          positionY + prospectiveHeight >
          doc.internal.pageSize.height - BASELINE_BOTTOM_MARGIN
        ) {
          doc.addPage();
          positionY = BASELINE_TOP_MARGIN;
        }

        positionY = drawCashOverview(context, doc, positionY, cashOverviewOpts);

        positionY += 5; // Add some spacing

        cashAccountGrandTotal +=
          cashOverviewOpts.cashSum +
          cashOverviewOpts.cashBankAccountAmountInCents;
        cashAccountNames.push(cashOverviewOpts.cashListName);
      }
    }

    // Page break if grand total + signatures do not fit on the same page
    if (
      positionY + LINE_HEIGHT + 25 >
      doc.internal.pageSize.height - BASELINE_BOTTOM_MARGIN
    ) {
      doc.addPage();
      positionY = BASELINE_TOP_MARGIN;
    }

    positionY = drawGrandTotalLine(context, doc, positionY, {
      grandTotal:
        walletAmountInCents + bankAccountGrandTotal + cashAccountGrandTotal,
      bankAccountNames: bankAccountNames,
      cashAccountNames: cashAccountNames,
      drawShortVersion: false,
    });

    drawSignatureField(
      context,
      doc,
      BASELINE_LEFT_MARGIN + 1.5,
      positionY + 25,
      60,
      t('accounting.report.signature-employee')
    );

    drawSignatureField(
      context,
      doc,
      doc.internal.pageSize.width - BASELINE_RIGHT_MARGIN - 1.5 - 60,
      positionY + 25,
      60,
      t('accounting.report.signature-supervisor')
    );

    await drawHeaders(context, doc, {
      logo,
    });

    drawFooters(context, doc);

    // Get the PDF data as a Blob
    const pdfBlob = doc.output('blob');

    // Create a URL from the Blob
    const url = URL.createObjectURL(pdfBlob);

    return {
      blob: pdfBlob,
      save: doc.save,
      url,
      date,
    };
  };

  return {
    handlePrintPdfAccountingOverviewMangopay,
  };
};
