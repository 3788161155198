export const spAppMainPages = {
  HOME: '/',
  WELCOME: '/welcome',
  CONNECT_ACCOUNT: '/connect-account',
  NEW_INVITATION: '/new-invitation',
  DASHBOARD: '/dashboard',
  PAYOUTS: '/dashboard/payouts',
  RECEIPT_BATCH_NEW: '/dashboard/receipt-batch/new',
  RECEIPT_BATCH_EDIT: '/dashboard/receipt-batch/edit/:batchId',
  PROFILE: '/profile',
  SERVICES: '/services',
  SETTINGS: '/settings',
  STRIPE_ONBOARDING_COMPLETE: '/stripe-onboarding/complete',
  STRIPE_ONBOARDING_REFRESH: '/stripe-onboarding/refresh',
  DEV_NOWHERE: '/i_do_not_exist',
} as const;
