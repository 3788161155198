import type { FC, MouseEventHandler, PropsWithChildren } from 'react';
import React, { useCallback, useMemo, useState } from 'react';
import {
  Badge,
  Box,
  Card,
  Divider,
  Link,
  Stack,
  styled,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  useFormatDate,
  useFormatTime,
  useFormatting,
} from '@pflegenavi/frontend/localization';
import {
  TIME_FORMAT,
  YEAR_MONTH_DAY_SHORT_FORMAT,
} from '@pflegenavi/shared/constants';
import {
  Iconify,
  Label,
  OneLineTypography,
  TransactionTypeLogo,
  TruncatedTooltipText,
  type UrlTypeNameMapping,
  useFileUrlToTypeMapping,
} from '@pflegenavi/web-components';
import type { UseReceiptImagesType } from '@pflegenavi/shared-frontend/platform';
import { useUrlsToImages } from '@pflegenavi/shared-frontend/platform';
import { EnlargeImageGallery } from '@pflegenavi/transaction-components';
import { useGetBatchReceiptDateString } from '@pflegenavi/shared/frontend';
import {
  getReceiptBatchFileName,
  getReceiptBatchFileNameDateString,
} from '@pflegenavi/shared/utils';
import BlueRefundSvg from '../assets/refund-blue.svg';

type State =
  | 'pending'
  | 'submitted'
  | 'locked'
  | 'draft'
  | 'accepted'
  | 'change_requested'
  | 'declined'
  | 'in_review';

export interface ReceiptBatchSummaryCardDetailedProps {
  title: string;
  receiptDate: Date;
  useIndividualDates: boolean;
  minReceiptDate?: Date;
  maxReceiptDate?: Date;
  lastUpdateDate?: Date;
  receiptType?: string;
  receiptsCount: number;
  totalAmount: number;
  receiptImageIds?: string[];
  onClick?: MouseEventHandler<HTMLDivElement>;
  width?: number;
  state: State;
  useReceiptImages: UseReceiptImagesType;
  autoGenerateReceipts?: boolean;
  generateReceiptBatchPreview?: () => Promise<Blob | undefined>;
  isRecurringItem?: boolean;
}

export const ReceiptBatchSummaryCardDetailed: FC<
  ReceiptBatchSummaryCardDetailedProps
> = ({
  title,
  receiptDate,
  minReceiptDate,
  maxReceiptDate,
  useIndividualDates,
  receiptType,
  receiptsCount,
  totalAmount,
  receiptImageIds,
  onClick,
  width,
  state,
  lastUpdateDate,
  useReceiptImages,
  autoGenerateReceipts,
  generateReceiptBatchPreview,
  isRecurringItem,
}) => {
  const { t } = useTranslation();
  const fDate = useFormatDate();
  const fTime = useFormatTime();
  const { fCurrencyCents } = useFormatting();
  const { getBatchReceiptDateString } = useGetBatchReceiptDateString();

  const receiptDateString = useMemo(() => {
    return getBatchReceiptDateString(
      useIndividualDates,
      minReceiptDate,
      maxReceiptDate,
      receiptDate
    );
  }, [
    getBatchReceiptDateString,
    maxReceiptDate,
    minReceiptDate,
    receiptDate,
    useIndividualDates,
  ]);

  const receiptDateFileNameString = useMemo(() => {
    return getReceiptBatchFileNameDateString(
      useIndividualDates,
      receiptDate,
      minReceiptDate,
      maxReceiptDate
    );
  }, [maxReceiptDate, minReceiptDate, receiptDate, useIndividualDates]);

  return (
    <Card
      onClick={onClick}
      sx={{
        p: 2,
        pt: 2.5,
        width,
        cursor: onClick ? 'pointer' : 'default',
        '&:hover': onClick
          ? {
              boxShadow: (theme) => theme.shadows[4],
              opacity: 0.9,
            }
          : {},
      }}
    >
      <Stack gap={2}>
        <Stack direction="row" gap={1.5} alignItems="center">
          {receiptType ? (
            <TransactionTypeLogo
              logoType={receiptType}
              size={45}
              cornered={true}
              additionalSubIcon={isRecurringItem ? BlueRefundSvg : undefined}
            />
          ) : (
            <LogoContainer>
              <Iconify icon="ic:baseline-home" width={30} height={30} />
            </LogoContainer>
          )}

          <Stack
            justifyContent="center"
            gap={0.5}
            {...(width ? { maxWidth: width - 95 } : {})}
          >
            <TruncatedTooltipText
              text={title}
              variant="subtitle2"
              fontWeight={700}
            />
            <OneLineTypography fontSize={14} color="grey.500">
              {receiptDateString}
            </OneLineTypography>
          </Stack>
        </Stack>

        <Divider
          sx={{
            // Counter the card padding
            ml: -2,
            mr: -2,
          }}
        />

        <Stack direction="column" gap={1.5}>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" alignItems="center" gap={1}>
              <Iconify
                icon="mdi:cog"
                sx={{
                  color: 'text.secondary',
                }}
                width={18}
                height={18}
              />
              <Typography fontSize={14} color="text.primary">
                {t('receipts.batch.summary-card.state')}
              </Typography>
            </Stack>

            <StatusLabel state={state} />
          </Stack>

          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" alignItems="center" gap={1}>
              <Iconify
                icon="mdi:hashtag-box"
                sx={{
                  color: 'text.secondary',
                }}
                mt="-2px"
                width={18}
                height={18}
              />
              <Typography fontSize={14} color="text.primary">
                {t('receipts.batch.summary-card.count')}
              </Typography>
            </Stack>

            <Typography fontSize={14} color="text.primary">
              {receiptsCount}
            </Typography>
          </Stack>

          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" alignItems="center" gap={1}>
              <Iconify
                icon="eva:shopping-cart-fill"
                sx={{
                  color: 'text.secondary',
                }}
                mt="-1px"
                width={18}
                height={18}
              />
              <Typography fontSize={14} color="text.primary">
                {t('receipts.batch.summary-card.amount')}
              </Typography>
            </Stack>

            <Typography fontSize={14} color="text.primary" fontWeight={700}>
              {fCurrencyCents(totalAmount)}
            </Typography>
          </Stack>

          {lastUpdateDate && (
            <Stack direction="row" justifyContent="space-between">
              <Stack direction="row" alignItems="center" gap={1}>
                <Iconify
                  icon="mdi:clock-time-three"
                  sx={{
                    color: 'text.secondary',
                  }}
                  mt="-2px"
                  width={18}
                  height={18}
                />
                <Typography fontSize={14} color="text.primary">
                  {t('receipts.batch.summary-card.lastUpdate')}
                </Typography>
              </Stack>

              <Stack direction="row">
                <Typography fontSize={14} color="text.primary">
                  {fDate(new Date(lastUpdateDate), YEAR_MONTH_DAY_SHORT_FORMAT)}
                  ,&nbsp;
                </Typography>
                <Typography fontSize={14} color="grey.600">
                  {fTime(new Date(lastUpdateDate), TIME_FORMAT)}
                </Typography>
              </Stack>
            </Stack>
          )}

          <Divider
            sx={{
              // Counter the card padding
              ml: -2,
              mr: -2,
            }}
          />
          <ReceiptBatchImageRow
            state={state}
            autoGenerateReceipts={autoGenerateReceipts}
            generateReceiptBatchPreview={generateReceiptBatchPreview}
            receiptImageIds={receiptImageIds}
            useReceiptImages={useReceiptImages}
            receiptDateFileNameString={receiptDateFileNameString}
          />
        </Stack>
      </Stack>
    </Card>
  );
};

interface ReceiptBatchImageRowProps {
  state: State;
  receiptImageIds?: string[];
  useReceiptImages: UseReceiptImagesType;
  autoGenerateReceipts?: boolean;
  generateReceiptBatchPreview?: () => Promise<Blob | undefined>;
  receiptDateFileNameString: string;
}

const ReceiptBatchImageRow: FC<ReceiptBatchImageRowProps> = ({
  state,
  receiptImageIds,
  useReceiptImages,
  autoGenerateReceipts,
  generateReceiptBatchPreview,
  receiptDateFileNameString,
}) => {
  const { t } = useTranslation();

  const receiptImageCount = autoGenerateReceipts
    ? 1
    : receiptImageIds?.length ?? 0;

  const [galleryOpen, setGalleryOpen] = useState(false);

  const { receiptImages: receiptImageUrls, ImageUrlsLoading } = useUrlsToImages(
    {
      receiptImageIds,
      useReceiptImages,
    }
  );
  const imageUrlToTypeMap = useFileUrlToTypeMapping(receiptImageUrls);

  const [generatedReceiptImageUrls, setGeneratedReceiptImageUrls] = useState<
    string[]
  >([]);
  const [generatedReceiptTypeMap, setGeneratedReceiptTypeMap] =
    useState<UrlTypeNameMapping>(new Map());

  const generateReceiptBatchPreviewCallback = useCallback(async () => {
    if (generateReceiptBatchPreview) {
      const blob = await generateReceiptBatchPreview();
      if (blob) {
        const file = window.URL.createObjectURL(blob);

        setGeneratedReceiptImageUrls([file]);
        setGeneratedReceiptTypeMap(
          new Map([
            [
              file,
              {
                type: 'application/pdf',
                data: Promise.resolve(blob),
                name: getReceiptBatchFileName(
                  receiptDateFileNameString,
                  t('receipts.batch.receipt-batch-generation-file-name')
                ),
              },
            ],
          ])
        );
        setGalleryOpen(true);
      }
    }
  }, [generateReceiptBatchPreview, receiptDateFileNameString, t]);

  const buttonToShowReceipt = useMemo(() => {
    if (autoGenerateReceipts && state === 'draft') {
      return (
        <Link
          fontSize={14}
          color="text.primary"
          onClick={generateReceiptBatchPreviewCallback}
          sx={{
            cursor: 'pointer',
            fontWeight: 'bold',
          }}
        >
          {t('actions.view')}
        </Link>
      );
    }

    if (receiptImageCount > 0) {
      return (
        <Link
          fontSize={14}
          color="text.primary"
          onClick={() => setGalleryOpen(true)}
          sx={{
            cursor: 'pointer',
            fontWeight: 'bold',
          }}
        >
          {t('actions.view')}
        </Link>
      );
    }
    return (
      <Typography fontSize={14} color="text.primary">
        --
      </Typography>
    );
  }, [
    autoGenerateReceipts,
    generateReceiptBatchPreviewCallback,
    receiptImageCount,
    state,
    t,
  ]);

  const receiptIconColor = useMemo(() => {
    if (autoGenerateReceipts && state === 'draft') {
      return 'info.main';
    }
    if (receiptImageCount > 0) {
      return 'success.main';
    }
    return 'primary.main';
  }, [autoGenerateReceipts, receiptImageCount, state]);

  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack direction="row" alignItems="center" gap={0.7}>
        <Box position="relative" height={24}>
          <ReceiptCountBadge
            badgeContent={receiptImageCount}
            invisible={receiptImageCount < 2}
            color="info"
          />
          <Iconify
            icon="material-symbols:receipt"
            sx={{ color: receiptIconColor }}
            ml="-5px"
            width={25}
            height={25}
          />
        </Box>
        <Typography fontSize={14} color="text.primary">
          {t('receipts.batch.summary-card.batchImages')}
        </Typography>
      </Stack>

      {buttonToShowReceipt}

      <EnlargeImageGallery
        loading={ImageUrlsLoading}
        open={galleryOpen}
        handleClose={() => {
          setGalleryOpen(false);
          setGeneratedReceiptImageUrls([]);
        }}
        initialSelectedIndex={0}
        imageUrls={
          generatedReceiptImageUrls.length > 0
            ? generatedReceiptImageUrls
            : receiptImageUrls || []
        }
        imageUrlToTypeMap={
          generatedReceiptImageUrls.length > 0
            ? generatedReceiptTypeMap
            : imageUrlToTypeMap
        }
      />
    </Stack>
  );
};

interface StatusLabel {
  state: State;
}

const StatusLabel: FC<StatusLabel> = ({ state }) => {
  const { t } = useTranslation();

  const color = useMemo(() => {
    switch (state) {
      case 'submitted':
        return 'success';
      case 'accepted':
        return 'success';
      case 'locked':
        return 'warning';
      case 'change_requested':
        return 'warning';
      case 'in_review':
        return 'info';
      case 'declined':
        return 'error';
      case 'draft':
        return 'default';
      case 'pending':
        return 'default';
      default:
        return 'default';
    }
  }, [state]);

  return (
    <TooltipWrapper submissionLocked={state === 'locked'}>
      <Label color={color}>{t(`receipts.batch.states.${state}`)}</Label>
    </TooltipWrapper>
  );
};

interface TooltipWrapperProps {
  submissionLocked: boolean;
}

const TooltipWrapper: FC<PropsWithChildren<TooltipWrapperProps>> = ({
  children,
  submissionLocked,
}) => {
  const { t } = useTranslation();

  if (submissionLocked) {
    return (
      <Tooltip
        arrow
        title={t('receipts.batch.locked-tooltip')}
        placement="bottom"
      >
        <div>{children}</div>
      </Tooltip>
    );
  }

  return <>{children}</>;
};

const LogoContainer = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    bgcolor: 'grey.300',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    p: 1,
    position: 'relative',
    width: 40,
    height: 40,
    borderRadius: 2,
  })
);

const ReceiptCountBadge = styled(Badge)(({ theme }) =>
  theme.unstable_sx({
    '& .MuiBadge-badge': {
      fontSize: 10,
      height: 18,
      width: 18,
      minWidth: 18,
    },
    left: -1,
  })
);
