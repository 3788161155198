import type { FC } from 'react';
import React, { useCallback } from 'react';
import { Stack } from '@pflegenavi/web-components';
import type { TooltipProps } from '@mui/material';
import { Tooltip } from '@mui/material';
import { useFormatting } from '@pflegenavi/frontend/localization';
import { isBanknote, mapFactorToImage } from './euro';

export const useFactorToCurrency = (): ((factor: number) => string) => {
  const { fCurrency } = useFormatting();
  return useCallback(
    (factor: number) => {
      return fCurrency(factor / 100, {
        minimumFractionDigits: factor < 100 ? 2 : 0,
      });
    },
    [fCurrency]
  );
};

export const CashImage: FC<
  {
    factor: number;
    tooltipPlacement?: TooltipProps['placement'];
  } & React.ComponentProps<typeof Stack>
> = ({ factor, tooltipPlacement, sx, ...props }) => {
  const factorToCurrency = useFactorToCurrency();

  const image = mapFactorToImage(factor);
  const type = isBanknote(factor) ? 'bankNote' : 'coin';

  const alignItems = type === 'bankNote' ? 'start' : 'center';
  const width = type === 'bankNote' ? 100 : 60;
  const height = 60;
  return (
    <Stack
      sx={{ width, height, ...sx }}
      justifyContent="center"
      alignItems={alignItems}
      pt={type === 'bankNote' ? 0.4 : 0}
      data-testid={`cash-${factor}`}
      {...props}
    >
      <Tooltip
        title={factorToCurrency(factor)}
        placement={tooltipPlacement ?? 'right'}
        arrow
      >
        <img src={image} />
      </Tooltip>
    </Stack>
  );
};
