import { ChangeCashMode } from '../model';
import type { FC } from 'react';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormatting } from '@pflegenavi/frontend/localization';
import { Card, Iconify, Stack, Typography } from '@pflegenavi/web-components';
import {
  CashChangeAvatarIcon,
  IconWrapperStyle,
} from '../icons/ChangeCashIcons';
import euroSign from '../../../assets/cash_list.svg';
import { Divider } from '@mui/material';

interface AdjustSummaryCardProps {
  mode: ChangeCashMode;
  banknotes: number;
  coins: number;
  totalCash: number;
  bankAccountChange?: number;
}

export const AdjustSummaryCard: FC<AdjustSummaryCardProps> = ({
  mode,
  banknotes,
  coins,
  totalCash,
  bankAccountChange,
}) => {
  const { t } = useTranslation();
  const { fCurrencyCents } = useFormatting();

  const total = totalCash + (bankAccountChange ?? 0);

  const sign = useCallback(
    (amount: number) =>
      amount === 0 ? '' : mode === ChangeCashMode.Withdraw ? '-' : '',
    [mode]
  );

  const iconBanknotes = useMemo(() => {
    return `eva:trending-${banknotes < 0 ? 'down' : 'up'}-fill`;
  }, [banknotes]);

  const iconCoins = useMemo(() => {
    return `eva:trending-${coins < 0 ? 'down' : 'up'}-fill`;
  }, [coins]);

  const bankAccountIcon = useMemo(() => {
    return `eva:trending-${(bankAccountChange ?? 0) < 0 ? 'down' : 'up'}-fill`;
  }, [bankAccountChange]);

  const iconTotal = useMemo(() => {
    return `eva:trending-${total < 0 ? 'down' : 'up'}-fill`;
  }, [total]);

  const GREY = 'grey.400';

  const getColour = useCallback((amount: number): string => {
    if (amount === 0) {
      return GREY;
    }
    return amount < 0 ? 'error.main' : 'success.main';
  }, []);

  return (
    <Card
      sx={{
        p: 2,
        width: 'fit-content',
      }}
    >
      <Stack direction="column" gap={1}>
        <Stack direction="row" gap={1} alignItems="center">
          <CashChangeAvatarIcon>
            <img style={{ width: 20 }} alt="" src={euroSign} />
          </CashChangeAvatarIcon>
          <Typography variant="subtitle2">
            {t('cashManagement.cash-and-bank-account-change')}
          </Typography>
        </Stack>
        <Divider />
        <Stack
          direction="row"
          gap={1}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="subtitle2">
            {t('cashManagement.banknotes-change')}
          </Typography>
          <Stack direction="row" gap={1} pl={8}>
            {banknotes !== 0 && (
              <IconWrapperStyle mode={mode} amount={banknotes}>
                <Iconify width={16} height={16} icon={iconBanknotes} />
              </IconWrapperStyle>
            )}
            <Typography variant="subtitle2" color={getColour(banknotes)}>
              {sign(banknotes)}
              {fCurrencyCents(banknotes)}
            </Typography>
          </Stack>
        </Stack>
        <Stack
          direction="row"
          gap={1}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="subtitle2">
            {t('cashManagement.coins-change')}
          </Typography>
          <Stack direction="row" gap={1}>
            {coins !== 0 && (
              <IconWrapperStyle mode={mode} amount={coins}>
                <Iconify width={16} height={16} icon={iconCoins} />
              </IconWrapperStyle>
            )}
            <Typography variant="subtitle2" color={getColour(coins)}>
              {sign(coins)}
              {fCurrencyCents(coins)}
            </Typography>
          </Stack>
        </Stack>
        {(bankAccountChange ?? 0) !== 0 && (
          <Stack
            direction="row"
            gap={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="subtitle2">
              {t('cashManagement.bank-account-change')}:
            </Typography>
            <Stack direction="row" gap={1} pl={8}>
              {banknotes !== 0 && (
                <IconWrapperStyle mode={mode} amount={bankAccountChange ?? 0}>
                  <Iconify width={16} height={16} icon={bankAccountIcon} />
                </IconWrapperStyle>
              )}
              <Typography
                variant="subtitle2"
                color={getColour(bankAccountChange ?? 0)}
              >
                {sign(bankAccountChange ?? 0)}
                {fCurrencyCents(bankAccountChange ?? 0)}
              </Typography>
            </Stack>
          </Stack>
        )}
        <Divider />
        <Stack
          direction="row"
          gap={1}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="subtitle2">
            {t('cashManagement.total-cash-and-bank-change')}:
          </Typography>
          <Stack direction="row" gap={1}>
            {total !== 0 && (
              <IconWrapperStyle mode={mode} amount={total}>
                <Iconify width={16} height={16} icon={iconTotal} />
              </IconWrapperStyle>
            )}
            <Typography
              variant="subtitle2"
              color={getColour(total)}
              fontWeight={700}
            >
              {sign(total)}
              {fCurrencyCents(total)}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
};
