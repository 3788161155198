import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { nhAppMainPages } from '@pflegenavi/frontend/routing';
import { DefaultSuspense } from '@pflegenavi/web-components';
import { useFeatureFlagEnabled } from '@pflegenavi/frontend/feature-flags';
import { FeatureFlag } from '@pflegenavi/shared/api';
import { DirectDebitPage } from './DirectDebitPage';
import LegalEntitiesPage from './LegalEntitiesPage';
import { useGetPaymentProcessor } from '@pflegenavi/frontend/api-nursing-home';

const NursingHomeOverviewPage = lazy(() => import('./NursingHomeOverviewPage'));
const NursingHomeDetailPage = lazy(() => import('./NursingHomeDetailPage'));
const NursingHomeRefresh = lazy(() => import('./NursingHomeRefresh'));

const EmployeesListPage = lazy(() => import('./EmployeesListPage'));

export const useNursingHomeRoutes = (): JSX.Element => {
  const EmployeeManagementFeatureFlagEnabled = useFeatureFlagEnabled(
    FeatureFlag.EmployeeManagement
  );

  const legalEntitiesFeatureFlagEnabled = useFeatureFlagEnabled(
    FeatureFlag.dev_LegalEntities
  );

  const paymentProcessor = useGetPaymentProcessor();

  return (
    <>
      <Route
        path={nhAppMainPages.NURSING_HOME}
        element={
          <DefaultSuspense>
            <NursingHomeOverviewPage />
          </DefaultSuspense>
        }
      >
        <Route
          index={true}
          element={
            <DefaultSuspense>
              <NursingHomeDetailPage />
            </DefaultSuspense>
          }
        />

        <Route
          path={nhAppMainPages.NURSING_HOME_REFRESH}
          element={
            <DefaultSuspense>
              <NursingHomeRefresh />
            </DefaultSuspense>
          }
        />
        {paymentProcessor !== 'mangopay' ? (
          <Route
            path={nhAppMainPages.NURSING_HOME_SERVICE_PROVIDER_DIRECT_DEBIT}
            element={
              <DefaultSuspense>
                <DirectDebitPage />
              </DefaultSuspense>
            }
          />
        ) : null}
      </Route>

      {EmployeeManagementFeatureFlagEnabled && (
        <Route
          path={nhAppMainPages.NURSING_HOME_EMPLOYEES_LIST}
          element={
            <DefaultSuspense>
              <EmployeesListPage />
            </DefaultSuspense>
          }
        />
      )}
      {legalEntitiesFeatureFlagEnabled && (
        <Route
          path={nhAppMainPages.NURSING_HOME_LEGAL_ENTITIES}
          element={
            <DefaultSuspense>
              <LegalEntitiesPage />
            </DefaultSuspense>
          }
        />
      )}
    </>
  );
};
