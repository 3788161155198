import {
  IsBoolean,
  IsDate,
  IsNotEmpty,
  IsNumber,
  IsObject,
  IsOptional,
  IsString,
  IsUUID,
  Max,
  Min,
  ValidateIf,
} from 'class-validator';
import { Type } from 'class-transformer';

class NursingHomeAddress {
  zip?: string;
  city?: string;
  country?: string;
  street?: string;
  number?: string;
}

export class UpdateNursingHomeDto {
  @IsNotEmpty()
  @IsString()
  @IsUUID(4)
  id!: string;

  @IsOptional()
  @IsString()
  name?: string;

  @IsOptional()
  @IsString()
  notes?: string;

  @IsOptional()
  @IsObject()
  address?: NursingHomeAddress;

  @IsOptional()
  @IsString()
  phone?: string;

  @IsOptional()
  @IsString()
  @IsUUID(4)
  legalEntityId?: string;

  @IsOptional()
  @IsString()
  costCenter?: string;

  @IsOptional()
  @IsString()
  company?: string;

  @IsOptional()
  @IsString()
  fax?: string;

  @IsOptional()
  @IsString()
  email?: string;

  @IsOptional()
  @IsString()
  website?: string;
}

export class UpdateNursingHomeAppendSupervisorDto {
  @IsNotEmpty()
  @IsString()
  @IsUUID(4)
  id!: string;

  @IsOptional()
  @IsUUID(4)
  supervisorId!: string;
}

export const ABSOLUTE_MAXIMUM_PAYMENT_AMOUNT = 100000;

export const ABSOLUTE_MINIMUM_PAYMENT_AMOUNT = 5000;

export class UpdateNursingHomePaymentSettingsDto {
  @IsOptional()
  @IsBoolean()
  paymentsDisabled?: boolean;
  @IsOptional()
  @IsDate()
  @Type(() => Date)
  @ValidateIf((_object, value) => value !== null)
  paymentsDisabledUntil?: Date | null;
  @IsOptional()
  @IsString()
  @ValidateIf((_object, value) => value !== null)
  paymentsDisabledReason?: string | null;
  @IsOptional()
  @IsNumber()
  @Min(ABSOLUTE_MINIMUM_PAYMENT_AMOUNT)
  @Max(ABSOLUTE_MAXIMUM_PAYMENT_AMOUNT)
  minimumPaymentAmount?: number;
  @IsOptional()
  @IsNumber()
  @Min(ABSOLUTE_MINIMUM_PAYMENT_AMOUNT)
  @Max(ABSOLUTE_MAXIMUM_PAYMENT_AMOUNT)
  maximumPaymentAmount?: number;
}
